import React from 'react';
import { NavLink } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { CodeAcess } from './Form';
import { useStyles } from './styles';

export const AcessCodeCheck = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.container} elevation={4}>
        <NavLink className={classes.goBack} to='/'>
          <ArrowBackIcon className={classes.backIcon} />
          Voltar para o link
        </NavLink>

        <h1 className={classes.title}>Código especial de acesso</h1>
        <p className={classes.subtitle}>
          Verifique sua caixa de e-mail e insira o código que recebeu aqui, em seguida iniciaremos
          nossa validação de segurança.
        </p>

        <CodeAcess />
      </Paper>
    </div>
  );
};
