import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, DatePicker } from 'components';
import { Field, Form, Formik } from 'formik';
import { downloadXlsx } from 'helpers/downloadXLSX';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providerService } from 'services/providers';

// import { downloadcsv } from '../../../helpers/downloadcsv';

export const InventaryExpeditionReport = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadInventaryExpeditionReport = async (values) => {
    toast.info('Aguarde, Relatório sendo gerado!');
    setIsLoading(true);
    try {
      const { data } = await providerService.getInventaryExpedition(values);

      if (data.length === 0 || null) {
        toast.error('Não há dados para gerar o relatório');
        return;
      }

      await downloadXlsx(`ingenico_inventario_expedicao`, data);
      console.log(data);
      toast.success('Relatório Gerado com sucesso');
    } catch (error) {
      console.error(error);
      toast.error('Falha ao gerar relatório');
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <PageTitle>Inventário de Expedição</PageTitle>

      <Formik
        initialValues={{
          input_data: new Date(),
          output_data: new Date(),
        }}
        onSubmit={handleDownloadInventaryExpeditionReport}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='input_data'
                  variant='outlined'
                  fullWidth
                  inputVariant='outlined'
                  label='Data Inicial'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='output_data'
                  variant='outlined'
                  fullWidth
                  inputVariant='outlined'
                  label='Data Final'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Button fullWidth type='submit' disabled={isLoading}>
                  Extrair Relatório
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
