import React from 'react';

import MuiDialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Actions } from './Actions';
import { Content } from './Content';
import { Title } from './Title';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const Dialog = ({ open, onClose, fullWidth, fullScreen, maxWidth, children, ...props }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  fullScreen = fullScreen || isMobile;

  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      fullWidth={fullWidth || true}
      fullScreen={fullScreen}
      maxWidth={maxWidth || 'sm'}
      TransitionComponent={props.TransitionComponent || Transition}
      {...props}
    >
      {children}
    </MuiDialog>
  );
};

Dialog.Title = Title;
Dialog.Content = Content;
Dialog.Actions = Actions;

export { Dialog };
