import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    opacity: 0.8,
    backgroundColor: '#fff',

    display: 'grid',
    placeItems: 'center',
  },
}));
