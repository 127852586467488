import React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog } from 'components';
import { Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useContextSelector } from 'use-context-selector';

import { screeningService } from '../../../../../services/screening';

export const DialogConfirmCancel = ({
  openDialogConfirm,
  handleCloseDialogConfirm,
  infoOs,
  dataMotivo,
}) => {
  console.log(infoOs);
  const user = useContextSelector(AuthContext, (context) => context.user);
  const [isLoading, setIsLoading] = useState(false);

  const motivo = async () => {
    setIsLoading(true);
    try {
      const { data } = await screeningService.insertCancellation({
        numeroOs: infoOs.numeroOs,
        idEtapa: infoOs.idEtapa,
        usuario: user.name,
        motivo: dataMotivo,
      });

      if (data.status === 200) {
        toast.success('Cancelamento realizado com sucesso!');
        handleCloseDialogConfirm();
      } else {
        toast.error('Falha ao cancelar.');
      }
    } catch (error) {
      toast.error('Erro ao cancelar.');
    } finally {
      setIsLoading(false);
      handleCloseDialogConfirm();
    }
  };

  return (
    <>
      <Dialog maxWidth={'md'} open={openDialogConfirm}>
        <Dialog.Title onClose={handleCloseDialogConfirm}>Confirmação de Cancelmaneto</Dialog.Title>
        <Dialog.Content>
          <Typography align='center' variant='h6' style={{ paddingBottom: '2rem' }}>
            O cancelamento do laudo de triagem cosmética atualizará a etapa da Os de volta para a
            etapa de Extract/Triagem. Confirma o cancelamento?
          </Typography>
          <Formik>
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Button fullWidth color='secondary' onClick={() => motivo()} loading={isLoading}>
                    Sim
                  </Button>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Button fullWidth color='primary' onClick={handleCloseDialogConfirm}>
                    Não
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
