import React, { useState, useEffect, useCallback, useRef } from 'react';
import { toast } from 'react-toastify';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import { Button, DatePicker, Text, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { downloadXlsx } from 'helpers/downloadXLSX';
import { AuthContext } from 'hooks/useAuth';
import { PageTitle } from 'pages/Layout/PageTitle';
import { useContextSelector } from 'use-context-selector';
import XLSX from 'xlsx';
import * as Yup from 'yup';

import { receivementService } from '../../../../services/receivement';

const optionsRecusa = [
  { label: 'Sim', value: 'Sim' },
  { label: 'Não', value: 'Não' },
];

const validationSchema = Yup.object().shape({
  input_nf: Yup.string().required('O campo NF é obrigatório'),
  input_qtd: Yup.string().required('O campo Quantidade é obrigatório'),
  input_processo: Yup.string().required('O campo Processo é obrigatório'),
  input_parceiro: Yup.string().required('O campo Parceiro é obrigatório'),
  input_cliente: Yup.string().required('O campo Cliente é obrigatório'),
});

export const PreReceivementImport = () => {
  const user = useContextSelector(AuthContext, (context) => context.user);
  const [isLoading, setIsLoading] = useState(false);
  const [processOptions, setProcessOptions] = useState([{ label: '', value: '' }]);
  const [parceiroOptions, setParceiroOptions] = useState([{ label: '', value: '' }]);
  const [clientOptions, setClientOptions] = useState([{ label: '', value: '' }]);

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const convertExcelDateToJSDate = (serial) => {
    const startDate = new Date(1899, 11, 30); // Data base do Excel
    const date = new Date(startDate.getTime() + serial * 86400000); // Adiciona o número de dias
    return date;
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();

    reader.onload = async (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      // Verifica se a planilha está vazia
      if (jsonData.length === 0 || jsonData[0].length === 0) {
        toast.error('A planilha está vazia.');
        return;
      }

      const header = jsonData[0];
      const dataRows = jsonData.slice(1);

      // Verifica se não há dados além do cabeçalho
      if (dataRows.length === 0) {
        toast.error('A planilha não contém dados.');
        return;
      }

      const formattedData = dataRows.map((row) => {
        let rowData = {};
        header.forEach((col, index) => {
          const cellValue = row[index];
          if (
            col === 'Dt.NF1 * (dd/mm/aaaa)' &&
            typeof cellValue === 'number' &&
            !isNaN(cellValue)
          ) {
            // Converte apenas a coluna de data
            rowData[col] = convertExcelDateToJSDate(cellValue).toLocaleDateString();
          } else {
            rowData[col] = cellValue;
          }
        });
        return rowData;
      });

      // Verifica se algum dado obrigatório está vazio e retorna quais colunas estão vazias
      const requiredFields = [
        'Análise de Restrição *',
        'Cliente *',
        'ClienteOPV *',
        'Dt.NF1 * (dd/mm/aaaa)',
        'NF1 *',
        'PARCEIRO *',
        'PROCESSO *',
        'QTD *',
      ];
      let errorMessages = [];

      formattedData.forEach((row, rowIndex) => {
        requiredFields.forEach((field) => {
          if (!row[field] || row[field] === '') {
            errorMessages.push(`Linha ${rowIndex + 2} - Coluna "${field}" está vazia.`);
          }
        });
      });

      if (errorMessages.length > 0) {
        // Mostrar todos os erros encontrados
        toast.error(`Erros encontrados:\n${errorMessages.join('\n')}`);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      } else {
        // Salva os dados no estado ou faz algo com eles

        try {
          const { data: preReceivementImport } = await receivementService.preReceivementImport({
            dataImport: formattedData,
            user: user.id,
          });
          await downloadXlsx(`pre-recebimento-import`, preReceivementImport);
          toast.success('Pré recebimento realizado com sucesso.');
        } catch (error) {
          toast.error('Erro ao importar dados. Tente novamente.');
        }
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const handleProcess = useCallback(async () => {
    try {
      const { data } = await receivementService.getProcess();
      setProcessOptions(data);
    } catch (error) {
      toast.error('Erro ao carregar processo:', error?.response?.data?.message);
    }
  }, []);

  const handleParceiro = useCallback(async () => {
    try {
      const { data } = await receivementService.getParceiro();
      setParceiroOptions(data);
    } catch (error) {
      toast.error('Erro ao carregar parceiro:', error?.response?.data?.message);
    }
  }, []);

  const handleClient = useCallback(async (values) => {
    try {
      const { data } = await receivementService.getClientParceiro({
        idClienteParceiro: values.input_parceiro,
        idProcesso: values.input_processo,
      });
      setClientOptions(data);
    } catch (error) {
      toast.error('Erro ao carregar cliente:', error?.response?.data?.message);
    }
  }, []);

  useEffect(() => {
    handleProcess();
    handleParceiro();
  }, [handleProcess, handleParceiro]);

  const handleDownloadLayout = async () => {
    const layout = [
      {
        'NF1 *': '',
        'QTD *': '',
        'PROCESSO *': '',
        'PARCEIRO *': '',
        'Cliente *': '',
        'ClienteOPV *': '',
        'Objeto Correios': '',
        OBS: '',
        'Análise de Restrição *': '',
        'NF 2': '',
        'Dt.NF1 * (dd/mm/aaaa)': '',
        'Recusa Terceiros': '',
      },
    ];
    await downloadXlsx(`pre-recebimento-import`, layout);
    toast.success('Layout baixado com sucesso');
  };

  const registerPreReivement = async (values, { resetForm }) => {
    setIsLoading(true);
    try {
      const { data } = await receivementService.processRegisterPreReivement({
        dataNF: values.input_data,
        nf: values.input_nf,
        qtd: values.input_qtd,
        idProcesso: values.input_processo,
        idCliente: values.input_cliente,
        obj: values.input_objetos,
        obs: values.input_obs,
        user: user.id,
        recusa: values.input_terceiros,
      });
      if (data.status === 400) {
        toast.error(data.msg);
        setIsLoading(false);
      } else {
        toast.success('NF cadastrada com sucesso!');
        resetForm();
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Pré Recebimento</PageTitle>

      <Grid container spacing={2}>
        <Grid item xs={2} sm={2} md={2} lg={2}>
          <Button
            startIcon={<DownloadIcon />}
            color='secondary'
            fullWidth
            onClick={() => handleDownloadLayout()}
          >
            Baixar Layout
          </Button>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2}>
          <Button startIcon={<UploadIcon />} onClick={handleButtonClick} fullWidth>
            Importar Planilha
          </Button>
          <input
            ref={fileInputRef}
            type='file'
            accept='.xlsx'
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
        </Grid>
      </Grid>

      <Typography
        variant='h6'
        style={{
          fontSize: '16px',
          paddingTop: '3.5rem',
          paddingBottom: '1rem',
          fontWeight: 'bold',
        }}
      >
        Cadastro Pré Recebimento
      </Typography>
      <Formik
        initialValues={{
          input_data: new Date(),
          input_nf: '',
          input_qtd: '',
          input_processo: '',
          input_parceiro: '',
          input_cliente: '',
          input_terceiros: '',
          input_objetos: '',
          input_obs: '',
        }}
        onSubmit={registerPreReivement}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue }) => {
          useEffect(() => {
            if (values.input_processo && values.input_parceiro) {
              handleClient(values);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [values.input_processo, values.input_parceiro]);
          return (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={2}>
                  <Field
                    size='small'
                    disabled={isLoading}
                    name='input_data'
                    variant='inline'
                    fullWidth
                    inputVariant='outlined'
                    label='Data NF'
                    component={DatePicker}
                  />
                </Grid>
                <Grid item xs={2} lg={2}>
                  <Field
                    size='small'
                    loading={isLoading}
                    name='input_nf'
                    variant='outlined'
                    fullWidth
                    label='NF'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={2} lg={2}>
                  <Field
                    size='small'
                    loading={isLoading}
                    name='input_qtd'
                    variant='outlined'
                    fullWidth
                    label='QTD'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={2} lg={2}>
                  <Field
                    size='small'
                    loading={isLoading}
                    name='input_processo'
                    variant='outlined'
                    fullWidth
                    label='Processo'
                    component={Select}
                    options={processOptions}
                    onChange={(value) => setFieldValue('input_processo', value)}
                  />
                </Grid>
                <Grid item xs={4} lg={4}>
                  <Field
                    size='small'
                    loading={isLoading}
                    name='input_parceiro'
                    variant='outlined'
                    fullWidth
                    label='Parceiro'
                    component={Select}
                    options={parceiroOptions}
                    onChange={(value) => setFieldValue('input_parceiro', value)}
                  />
                </Grid>
                <Grid item xs={3} lg={3}>
                  <Field
                    size='small'
                    loading={isLoading}
                    name='input_cliente'
                    variant='outlined'
                    fullWidth
                    label='Cliente'
                    component={Select}
                    options={clientOptions}
                  />
                </Grid>
                <Grid item xs={2} lg={2}>
                  <Field
                    size='small'
                    loading={isLoading}
                    name='input_terceiros'
                    variant='outlined'
                    fullWidth
                    label='Recusa Terceiros'
                    component={Select}
                    options={optionsRecusa}
                  />
                </Grid>
                <Grid item xs={5} lg={5}>
                  <Field
                    size='small'
                    loading={isLoading}
                    name='input_objetos'
                    variant='outlined'
                    fullWidth
                    label='Objetos Correios'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={8} lg={8}>
                  <Field
                    size='small'
                    loading={isLoading}
                    name='input_obs'
                    variant='outlined'
                    fullWidth
                    label='Observação'
                    component={Text}
                    multiline
                    minRows={3}
                  />
                </Grid>
              </Grid>
              <Box mt={2}>
                <Grid item xs={2} md={2} lg={2}>
                  <Button fullWidth type='submit' loading={isLoading}>
                    Cadastrar
                  </Button>
                </Grid>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
