import React from 'react';
import { FaUserAlt } from 'react-icons/fa';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DescriptionIcon from '@mui/icons-material/Description';
import HandshakeIcon from '@mui/icons-material/Handshake';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
// import VerifiedIcon from '@mui/icons-material/Verified';
import clsx from 'clsx';

import { useStyles } from './styles';

export function StepIconComponent(props) {
  const classes = useStyles();
  const { active, completed } = props;

  const icons = {
    1: <FaUserAlt FaFileInvoiceDollar size='20' />,
    2: <SmartphoneIcon size='20' />,
    3: <DescriptionIcon size='20' />,
    4: <HandshakeIcon size='20' />,
    5: <CreditCardIcon size='20' />,
    6: <CheckCircleIcon size='20' />,
    // 7: <VerifiedIcon size='20' />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}
