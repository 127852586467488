import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    width: '100%',
    backgroundColor: '#1D1B31',
    paddingLeft: theme.spacing(4),

    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: 64,
    marginBottom: theme.spacing(1),
    color: '#fff',
    '@media (max-width: 1100px)': {
      fontSize: 48,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  subtitle: {
    fontSize: 32,
    color: '#fff',
    '@media (max-width: 1100px)': {
      fontSize: 24,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  subtitleFlai: {
    position: 'fixed',
    paddingLeft: theme.spacing(0),
    top: '90%',
    height: '100%',
    width: '100%',
    display: 'flex',
    fontSize: 24,
    flexDirection: 'column',
    alignItems: 'left',
    overflowY: 'auto',
    color: '#fff',
    '@media (max-width: 1100px)': {
      fontSize: 16,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  logoGaia: {
    width: '100%',
    maxWidth: 250,
  },
  right: {
    position: 'fixed',
    right: 0,
    top: 0,
    height: '100%',
    width: '100%',
    maxWidth: 700,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 0,
    padding: `0 ${theme.spacing(3)}px 0 ${theme.spacing(12)}px`,
    overflowY: 'auto',
    backgroundColor: '#fdfdfd',

    [theme.breakpoints.down('xs')]: {
      background: '#fdfdfd',
      padding: `0 ${theme.spacing(3)}px`,
    },
  },
  icons: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '15px 0',
  },
  icon: {
    fontSize: 32,
  },
}));

export default useStyles;
