import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  item: {
    width: '49%',
    borderRadius: 8,
    border: '2px solid rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
    padding: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  },
}));
