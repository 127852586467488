import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { Grid } from '@mui/material';
import { Button, DatePicker } from 'components';
import { Field, Form, Formik } from 'formik';
import { downloadcsv } from 'helpers/downloadcsv';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providerService } from 'services';

export const OSPartReleaseControlReport = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    setIsLoading(true);
    setSubmitting(true);
    try {
      const data = await providerService.getOSPartReleaseControlReport(values);
      if (data.length === 0) {
        return toast.error('Não há dados para essas datas');
      }
      downloadcsv('ingenico_controle_liberacao_pecas_os', data);
      console.log('oi');
      toast.success('Relatório gerado com sucesso');
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setSubmitting(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Relatório Controle Liberação Peça OS</PageTitle>
      <Formik
        initialValues={{
          input_data: new Date(new Date().setDate(new Date().getDate() - 7)),
          output_data: new Date(),
        }}
        onSubmit={handleSubmit}
      >
        {({ values, resetForm }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3} lg={3}>
                <Field
                  size='small'
                  loading={isLoading}
                  name='input_data'
                  variant='outlined'
                  fullWidth
                  inputVariant='outlined'
                  label='Data inicial'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <Field
                  size='small'
                  loading={isLoading}
                  name='output_data'
                  variant='outlined'
                  fullWidth
                  inputVariant='outlined'
                  label='Data final'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <Button fullWidth type='submit' loading={isLoading}>
                  Exportar
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
