/* eslint-disable max-len */
import { api } from 'services/api';

export class ModelService {
  //Alert Instruction
  async listAlertInstruction(skip, take, search) {
    return api.get(`/models/alert-instruction/?skip=${skip}&take=${take}&search=${search}`);
  }

  async listPhotos() {
    return api.get('/models/alert-instruction/photos');
  }

  async createAlertInstruction(alert_instruction, model_id, attack_code_id, description, photo) {
    console.log('photo', photo);
    return api.post(
      `/models/alert-instruction/?alert_instruction=${alert_instruction}&model_id=${model_id}&attack_code_id=${attack_code_id}&description=${description}`,
      photo,
    );
  }

  async updateAlertInstruction(attack_code_id, code, description, active) {
    return api.put('/models/alert-instruction/', { attack_code_id, code, description, active });
  }

  //Attack Code
  async listAttackCode(skip, take, search) {
    return api.get(`/models/attack-code/?skip=${skip}&take=${take}&search=${search}`);
  }

  async findAttackCode(code) {
    return api.get(`/models/attack-code/${code}`);
  }

  async createAttackCode(code, description) {
    return api.post('/models/attack-code/', { code, description });
  }

  async updateAttackCode(attack_code_id, code, description, active) {
    return api.put('/models/attack-code/', { attack_code_id, code, description, active });
  }

  //Model
  async listModel(skip, take, search) {
    return api.get(`/models/?skip=${skip}&take=${take}&search=${search}`);
  }

  async createModel(model_code, description) {
    return api.post('/models/', { model_code, description });
  }

  async updateModel(model_id, model_code, description, active) {
    return api.put('/models/', { model_id, model_code, description, active });
  }
}

export const modelService = new ModelService();
