import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Bubble from 'assets/images/bubble.svg';
import Welcome from 'assets/images/welcome.svg';
import { AuthContext } from 'hooks/useAuth';
// import { ModalUpdatePassword } from 'pages/Layout/ModalUpdatePassword';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { serviceOrderService } from 'services';
import { accountService } from 'services';
import { useContextSelector } from 'use-context-selector';

import { GraphiRepairInspector } from './GraphiRepairInspector';
import { useStyles } from './styles';

const Home = () => {
  const user = useContextSelector(AuthContext, (context) => context.user);

  const classes = useStyles();

  const zero = 0;

  const [budgettotalprice, setTotalPrice] = useState([]);

  const [nonCompliance, setTotalNonCompliance] = useState([]);

  const [userQuantityTotal, setUserQuantity] = useState([]);

  const [count, setCount] = useState([]);

  const [repairtotal, setRepairTotal] = useState([]);

  const [qatotal, setQaTotal] = useState([]);

  const [totalorc, setTotalOrc] = useState([]);

  const [countbudget, setcountBudget] = useState([]);

  const [countfinished, setCountFinished] = useState([]);

  const [serviceQuantityFinished, , loadingServiceQuantityFinished] = useQuery(
    () => serviceOrderService.listServiceInQuantityFinished(),
    [],
  );

  const [serviceQuantityBudget, , loadingServiceQuantityBudget] = useQuery(
    () => serviceOrderService.listServiceInQuantityBudget(),
    [],
  );

  const [serviceQuantityTotalPrice, , loadingserviceQuantityTotalPrice] = useQuery(
    () => serviceOrderService.listQuantityByTotalPrice(),
    [],
  );

  const [userQuantity, , loadinguserQuantity] = useQuery(
    () => accountService.listUserQuantity(),
    [],
  );

  const [totalRepairData, , loadingagingData] = useQuery(
    () => serviceOrderService.listRepairInspectorTotal(),
    [],
  );

  const [totalQAData, , loadingqadata] = useQuery(
    () => serviceOrderService.listQaInspectorTotal(),
    [],
  );

  const [totalOrcData, , loadingorctotalData] = useQuery(
    () => serviceOrderService.listOrctotal(),
    [],
  );

  const [serviceQuantity, , loadingServiceQuantity] = useQuery(
    () => serviceOrderService.listServiceInQuantity(),
    [],
  );

  const [serviceNonCompliance, , loadingserviceNonCompliance] = useQuery(
    () => serviceOrderService.listTotalRepairNonCompliance(),
    [],
  );

  useEffect(() => {
    if (serviceNonCompliance) {
      const { totalqa } = serviceNonCompliance[0];
      setTotalNonCompliance(totalqa);
    }
  }, [serviceNonCompliance]);

  useEffect(() => {
    if (serviceQuantityTotalPrice) {
      const { total_buffer } = serviceQuantityTotalPrice[0];
      setTotalPrice(total_buffer);
    }
  }, [serviceQuantityTotalPrice]);

  useEffect(() => {
    if (serviceQuantityBudget) {
      const { countbudget } = serviceQuantityBudget[0];
      setcountBudget(countbudget);
    }
  }, [serviceQuantityBudget]);

  useEffect(() => {
    if (serviceQuantityFinished) {
      const { count } = serviceQuantityFinished[0];
      setCountFinished(count);
    }
  }, [serviceQuantityFinished]);

  useEffect(() => {
    if (serviceQuantity) {
      const { count } = serviceQuantity[0];
      setCount(count);
    }
  }, [serviceQuantity]);

  useEffect(() => {
    if (totalRepairData) {
      const { totalreparo } = totalRepairData[0];
      setRepairTotal(totalreparo);
    }
  }, [totalRepairData]);

  useEffect(() => {
    if (totalQAData) {
      const { totalqa } = totalQAData[0];
      setQaTotal(totalqa);
    }
  }, [totalQAData]);

  useEffect(() => {
    if (totalOrcData) {
      const { totalorccriado } = totalOrcData[0];
      setTotalOrc(totalorccriado);
    }
  }, [totalOrcData]);

  useEffect(() => {
    if (userQuantity) {
      const { quantity } = userQuantity[0];
      setUserQuantity(quantity);
    }
  }, [userQuantity]);

  setTimeout(function () {
    window.location.reload();
  }, 900000);

  return (
    <>
      {/* <ModalUpdatePassword /> */}
      <PageTitle>Dashboard</PageTitle>
      <Grid className={classes.containerBoxs} container spacing={2}>
        <Grid item xs={12} lg={12}>
          <Paper elevation={2}>
            <Typography className={classes.titleWelcome} gutterbottom variant='h5'>
              Bem-vindo(a) ao <strong style={{ color: '#6F50F5' }}>Gaia-System</strong> {user.name}.
            </Typography>
            <div className={classes.contextWelcome}>
              <Typography variant='body2' gutterBottom>
                O sistema de gerenciamento e gestão <strong>Gaia-System</strong>, foi desenvolvido
                para auxiliar os postos de reparos. Através dele é possível acompanhar e automatizar
                o processo de reparo das ordens de serviço!
              </Typography>

              <img className={classes.imgWelcome} src={Welcome} />
            </div>
          </Paper>
        </Grid>
      </Grid>
      <Grid className={classes.containerBoxs} container spacing={2}>
        <Grid item xs={6} lg={3}>
          <Paper elevation={5} className={classes.cardServicesOpen}>
            <section className={classes.sectionCards}>
              <div className={classes.divCards}>
                <p className={classes.titleCards}> Nº {count}</p>
                <p>Pedidos expedidos no mês</p>
              </div>
              <div>
                <img src={Bubble} className={classes.imgBubbleCards} />
              </div>
            </section>
          </Paper>
        </Grid>

        <Grid item xs={6} lg={3}>
          <Paper elevation={5} className={classes.cardOscompleted}>
            <section className={classes.sectionCards}>
              <div className={classes.divCards}>
                <p className={classes.titleCards}>Nº {countfinished ?? zero}</p>
                <p>Em atendimento</p>
              </div>
              <div>
                <img src={Bubble} className={classes.imgBubbleCards} />
              </div>
            </section>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Paper elevation={5} className={classes.cardApprovedBudget}>
            <section className={classes.sectionCards}>
              <div className={classes.divCards}>
                <p className={classes.titleCards}>Nº {countbudget ?? zero}</p>
                <p>Pedidos pendentes</p>
              </div>
              <div>
                <img src={Bubble} className={classes.imgBubbleCards} />
              </div>
            </section>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Paper elevation={5} className={classes.cardTotalSold}>
            <section className={classes.sectionCards}>
              <div className={classes.divCards}>
                <p className={classes.titleCards}>
                  {' '}
                  <p className={classes.titleCards}>Nº {budgettotalprice ?? zero}</p>
                </p>
                <p>Quantidade em buffer</p>
              </div>
              <div>
                <img src={Bubble} className={classes.imgBubbleCards} />
              </div>
            </section>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <Paper variant='elevation' elevation={2} className={classes.paperRecentTransaction}>
            <Typography gutterbottom variant='subtitle1' align='left'>
              Pedidos atendidos por dia
            </Typography>
            <GraphiRepairInspector />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
