import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import Box from '@mui/material/Box';
import { Button, DatePicker, Dialog, Text, Time, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useContextSelector } from 'use-context-selector';
import * as Yup from 'yup';

import { laboratoryService } from '../../../../../services/laboratory';

const newStatus = [
  { label: 'Aberto', value: 'Aberto' },
  { label: 'Em Processo', value: 'Em Processo' },
  { label: 'Solucionado', value: 'Solucionado' },
  { label: 'Cancelado', value: 'Cancelado' },
];

const typeArea = [
  { label: 'Selecione uma nova área', value: '0' },
  { label: 'Manuntenção', value: 'Manuntenção' },
  { label: 'TI', value: 'TI' },
  { label: 'Engenharia', value: 'Engenharia' },
  { label: 'Estoque', value: 'Estoque' },
  { label: 'Outros', value: 'Outros' },
];

const validationSchema = Yup.object().shape({
  responsible_user: Yup.string().required('O campo responsável é obrigatório'),
});

export const DialogAtualizar = ({ openDialogAtualizar, handleCloseDialogAtualizar, info }) => {
  const [isLoading, setIsLoading] = useState(false);

  const user = useContextSelector(AuthContext, (context) => context.user);
  const userName = user.name;

  const handleAtualizarDownTIme = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await laboratoryService.updateDowntime({
        acao: values.input_action,
        novo_status: values.select_new_status,
        data_encerramento: values.input_data_encerramento,
        time_final: values.time_final,
        usuario_resp: values.responsible_user,
        area_resp: values.select_area_resp,
        obs: values.input_obs,
        id_cadastro: info.id_cadastro,
        user: userName,
      });
      toast.success('Atualizado com sucesso!');
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
      await handleCloseDialogAtualizar();
      window.location.reload();
    }
  };

  return (
    <Dialog maxWidth='lg' open={openDialogAtualizar}>
      <Dialog.Title onClose={handleCloseDialogAtualizar}>Atualizar DownTime</Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{
            input_action: '',
            select_new_status: '',
            input_data_encerramento: new Date(),
            time_final: '23:59',
            responsible_user: '',
            select_area_resp: typeArea[0].value,
            input_obs: '',
          }}
          onSubmit={handleAtualizarDownTIme}
          validationSchema={validationSchema}
        >
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={9} lg={12}>
                <Field
                  size='small'
                  loading={isLoading}
                  name='input_action'
                  variant='outlined'
                  fullWidth
                  label='Ação'
                  component={Text}
                  multiline
                  minRows={3}
                />
              </Grid>
            </Grid>
            <Box mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Field
                    size='small'
                    loading={isLoading}
                    name='select_new_status'
                    variant='outlined'
                    fullWidth
                    inputVariant='outlined'
                    label='Novo Status'
                    options={newStatus}
                    component={Select}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Field
                    size='small'
                    disabled={isLoading}
                    name='input_data_encerramento'
                    variant='inline'
                    fullWidth
                    inputVariant='outlined'
                    label='Data Encerramento'
                    component={DatePicker}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Field
                    size='small'
                    loading={isLoading}
                    name='time_final'
                    variant='outlined'
                    fullWidth
                    inputVariant='outlined'
                    label='Hora Final'
                    component={Time}
                    defaultValue='23:59'
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Field
                    size='small'
                    loading={isLoading}
                    name='responsible_user'
                    variant='outlined'
                    fullWidth
                    label='Responsável pela ação'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Field
                    size='small'
                    loading={isLoading}
                    name='select_area_resp'
                    variant='outlined'
                    fullWidth
                    inputVariant='outlined'
                    label='Nova área responsável, se necessário'
                    options={typeArea}
                    component={Select}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mt={2}>
              <Grid item xs={12} md={9} lg={12}>
                <Field
                  size='small'
                  loading={isLoading}
                  name='input_obs'
                  variant='outlined'
                  fullWidth
                  inputVariant='outlined'
                  label='Observação'
                  component={Text}
                  multiline
                  minRows={3}
                />
              </Grid>
            </Box>
            <Box mt={2}>
              <Grid item xs={12} md={2} lg={2}>
                <Button fullWidth type='submit' loading={isLoading}>
                  Enviar
                </Button>
              </Grid>
            </Box>
          </Form>
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
