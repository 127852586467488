import React from 'react';
import { useRouteMatch } from 'react-router';

import { Downtime } from '../../pages/Private/Laboratory/Downtime';
import { LotsCustomization } from '../../pages/Private/Laboratory/LotsCustomization';
import { Route } from '../Route';

export const LaboratoryRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${path}/lots-of-customization`} component={LotsCustomization} />
      <Route isPrivate path={`${path}/downtime`} component={Downtime} />
    </>
  );
};
