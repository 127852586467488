import React, { useCallback, useMemo } from 'react';
import { Switch } from 'react-router';

import Toolbar from '@material-ui/core/Toolbar';

import LayoutContextProvider from 'pages/Layout/context';
import Drawer from 'pages/Layout/Drawer';
import { Footer } from 'pages/Layout/Footer';
import { Header } from 'pages/Layout/Header';
import Home from 'pages/Private/Home';
import { UserProfile } from 'pages/Private/Profile';
import { AcessCodeCheck } from 'pages/Public/AcessCode';
import { AccessControlRoutes } from 'routes/roles/AccessControl';
import { CollaboratorsRoutes } from 'routes/roles/CollaboratorsRoutes';
import { ConferenceRoutes } from 'routes/roles/ConferenceRoutes';
import { FavoritesRoutes } from 'routes/roles/FavoritesRoutes';
import { LaboratoryRoutes } from 'routes/roles/LaboratoryRoutes';
import { LaunchRoutes } from 'routes/roles/LaunchRoutes';
import { PcpRoutes } from 'routes/roles/PcpRoutes';
import { ReceivementRoutes } from 'routes/roles/ReceivementRoutes';
import { ReportConferenceRoutes } from 'routes/roles/ReportConference';
import { ReportEngineeringRoutes } from 'routes/roles/ReportEngineering';
import { ReportLabRoutes } from 'routes/roles/ReportLab';
import { ReportReleasesRoutes } from 'routes/roles/ReportReleases';
import { ReportRoutes } from 'routes/roles/ReportRoutes';
import { ReportsReceiptsRoutes } from 'routes/roles/ReportsReceipts';
import { ReportsShippingRoutes } from 'routes/roles/ReportsShipping';
import { ReportStockRoutes } from 'routes/roles/ReportStock';
import { ScreeningRoutes } from 'routes/roles/ScreeningRoutes';
import { ServiceOrderRoutes } from 'routes/roles/ServiceOrderRoutes';
import { StockRoutes } from 'routes/roles/StockRoutes';
import { ToQuoteRoutes } from 'routes/roles/ToQuote';
import { Route } from 'routes/Route';

import { useStyles } from './styles';

const Private = () => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const menuId = 'primary-search-account-menu';
  const mobileMenuId = 'primary-search-account-menu-mobile';

  const handleDrawerToggle = useCallback(() => {
    setDrawerOpen((prevState) => !prevState);
  }, []);

  const contextValue = useMemo(
    () => ({
      anchorEl,
      setAnchorEl,
      mobileMoreAnchorEl,
      setMobileMoreAnchorEl,
      menuId,
      mobileMenuId,
      drawerOpen,
      handleDrawerToggle,
    }),
    [
      anchorEl,
      setAnchorEl,
      mobileMoreAnchorEl,
      setMobileMoreAnchorEl,
      menuId,
      mobileMenuId,
      drawerOpen,
      handleDrawerToggle,
    ],
  );

  return (
    <LayoutContextProvider value={contextValue}>
      <div className={classes.root}>
        <Header />
        <Drawer />
        <main className={classes.main}>
          <div className={classes.content}>
            <Toolbar />
            <Switch>
              <Route isPrivate path='/code_acess' component={AcessCodeCheck} />
              <Route isPrivate path='/profile' component={UserProfile} />
              <Route isPrivate path='/home' component={Home} />
              <Route isPrivate path='/stock' component={StockRoutes} />
              <Route isPrivate path='/service-order' component={ServiceOrderRoutes} />
              <Route isPrivate path='/access-control' component={AccessControlRoutes} />
              <Route isPrivate path='/reports-general' component={ReportRoutes} />
              <Route isPrivate path='/collaborators' component={CollaboratorsRoutes} />
              <Route isPrivate path='/conference' component={ConferenceRoutes} />
              <Route isPrivate path='/launch' component={LaunchRoutes} />
              <Route isPrivate path='/screening' component={ScreeningRoutes} />
              <Route isPrivate path='/quote' component={ToQuoteRoutes} />
              <Route isPrivate path='/favoritos' component={FavoritesRoutes} />
              <Route isPrivate path='/pcp' component={PcpRoutes} />
              <Route isPrivate path='/laboratory' component={LaboratoryRoutes} />
              <Route isPrivate path='/receivement' component={ReceivementRoutes} />
              <Route isPrivate path='/reports-engineering' component={ReportEngineeringRoutes} />
              <Route isPrivate path='/reports-conference' component={ReportConferenceRoutes} />
              <Route isPrivate path='/reports-stock' component={ReportStockRoutes} />
              <Route isPrivate path='/reports-lab' component={ReportLabRoutes} />
              <Route isPrivate path='/reports-shipping' component={ReportsShippingRoutes} />
              <Route isPrivate path='/reports-releases' component={ReportReleasesRoutes} />
              <Route isPrivate path='/reports-receipts' component={ReportsReceiptsRoutes} />
            </Switch>
          </div>
          <Footer />
        </main>
      </div>
    </LayoutContextProvider>
  );
};

export default Private;
