import React from 'react';

import { PageTitle } from 'pages/Layout/PageTitle';

import { useStyles } from './styles';
import { UserAvatar } from './UserAvatar';
import { UserInformation } from './UserInformation';

export function UserProfile() {
  const classes = useStyles();

  return (
    <>
      <PageTitle>Seu perfil</PageTitle>
      <section className={classes.container}>
        <div className={classes.item}>
          <UserInformation />
        </div>
        <div className={classes.item}>
          <UserAvatar />
        </div>
      </section>
    </>
  );
}
