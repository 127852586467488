import React from 'react';
import { toast } from 'react-toastify';

import { Button } from 'components';
import { useFormikContext } from 'formik';

import { useStyles } from './styles';

export const Actions = ({ activeStep, handleBack, handleNext, stepsSize, AnswerIsCorrect }) => {
  const { isSubmitting } = useFormikContext();

  const classes = useStyles();

  const isFirstStep = activeStep === 0;

  return (
    <div className={classes.footer}>
      <div className={classes.actions}>
        <Button disabled={isFirstStep} onClick={handleBack} className={classes.backButton}>
          Voltar
        </Button>
        {activeStep === stepsSize - 1 && (
          <Button type='submit' color='primary' loading={isSubmitting}>
            Confirmar
          </Button>
        )}
        {activeStep !== stepsSize - 1 && (
          <Button
            color='primary'
            loading={isSubmitting}
            onClick={(answer) => {
              if (AnswerIsCorrect == true) {
                toast.success('Resposta verificada com sucesso.');
                return handleNext();
              } else {
                console.log('AnswerIsCorrect: ', AnswerIsCorrect);
                console.log('answer', answer);
                toast.error('A resposta informada não é válida.');
              }
            }}
          >
            Verificar
          </Button>
        )}
      </div>
    </div>
  );
};
