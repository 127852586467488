const { makeStyles } = require('@material-ui/core');

export const useStyles = makeStyles(() => ({
  infoAccessoryGrid: {
    width: '100%',
    border: '1px solid #512da8',
    borderRadius: '10px',
    padding: '8px',
    '&:nth-child(1n+1)': {
      marginTop: '12px',
    },
  },

  infoAccessory: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: '12px',
  },

  sellAccessoriesGrid: {
    width: '60%',
  },

  accessoriesInCart: {
    height: '100%',
    padding: '10px',
    overflow: 'scroll',
  },

  subTotal: {
    color: 'green',
    display: 'flex',
  },

  quantity: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  toCloseMargin: {
    marginTop: '0.2rem',
  },

  paymentMethod: {
    marginTop: '1rem',
  },

  sellPerson: {
    marginTop: '1rem',
  },

  alignToTheEnd: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '1rem',
  },

  paymentMethodOptions: {
    width: 'auto',
  },
}));
