import React from 'react';

import Grid from '@material-ui/core/Grid';

import { Button, Text } from 'components';
import { Field, Formik, Form } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { PageTitle } from 'pages/Layout/PageTitle';
import { screeningService } from 'services/screening';
import { useContextSelector } from 'use-context-selector';

const RequestIng = ({ orderServ }) => {
  const user = useContextSelector(AuthContext, (context) => context.user);

  const enviaSolicitacao = async (values) => {
    console.log('Valor do ID Email:', values, orderServ);
    await screeningService.getEnviaDados(values.idEmail, orderServ, values.user);
  };

  return (
    <>
      <PageTitle> Solicitação Ingenico </PageTitle>
      <Formik
        initialValues={{ idEmail: '', userGaia: user }}
        enableReinitialize={true} // Importante para evitar o reset do campo ao montar o componente
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={2}>
                <Field
                  size='small'
                  name='idEmail'
                  label='ID Email'
                  variant='outlined'
                  component={Text}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={2}>
                <Button
                  fullWidth
                  onClick={() => {
                    enviaSolicitacao(props.values); // Chama a função enviaSolicitacao com os valores do formulário
                  }}
                >
                  Enviar
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default RequestIng;
