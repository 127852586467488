import React from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Dialog, Text, Button } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { launchPrivider } from 'services';
import { useContextSelector } from 'use-context-selector';

export const DialogResendEmail = ({ openDialogResendEmail, handleCloseDialogResendEmail }) => {
  const handleResendEmail = async (values) => {
    const { data } = await launchPrivider.resendEmail({
      email: values.email,
      message: values.message,
      user: user.name,
    });
    if (data == 'Email enviado com sucesso') {
      toast.success('Email enviado com sucesso');
    } else {
      toast.error('Falha ao enviar Email');
    }
    handleCloseDialogResendEmail();
  };
  const user = useContextSelector(AuthContext, (context) => context.user);

  return (
    <>
      <Dialog open={openDialogResendEmail}>
        <Dialog.Title onClose={handleCloseDialogResendEmail}>Reenviar Email</Dialog.Title>
        <Dialog.Content>
          <Formik
            initialValues={{
              email: '',
              message: '',
            }}
            onSubmit={handleResendEmail}
          >
            {(props) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12}>
                    <Field
                      size='small'
                      name='email'
                      label='Email'
                      type='text'
                      variant='outlined'
                      value={props.values.id_call}
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Field
                      size='small'
                      name='message'
                      label='Mensagem'
                      component={Text}
                      type='text'
                      variant='outlined'
                      value={props.values.message}
                      multiline
                      minRows={5}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Button fullWidth type='submit'>
                      Reenviar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
