import React from 'react';
import { useRouteMatch } from 'react-router';

import { BorByPn } from 'pages/Reports/BorByPnReport';
import { BorComponentReport } from 'pages/Reports/BorComponentReport';
import { BorWithoutPn } from 'pages/Reports/BorWithoutPnReport';
import { CancellationChargebacks } from 'pages/Reports/CancellationChargebacksReport';
import { CarStockReport } from 'pages/Reports/CarStockReport';
import { LogMovByLocationReport } from 'pages/Reports/LogMovbyLocationReport';
import { ManualMovimentationReport } from 'pages/Reports/ManualMovimentationReport';
import { MinimumStock } from 'pages/Reports/MinimumStockReport';
import { PartsXModelBorReport } from 'pages/Reports/PartsXModelsReport';
import { RequestsReport } from 'pages/Reports/RequestsReport';
import { StockReport } from 'pages/Reports/StockReport';
import { TechnicalStock } from 'pages/Reports/TechnicalStock';
import { WfpWithPn } from 'pages/Reports/WfpWithPnReport';

import { Route } from '../Route';

export const ReportStockRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${path}/bor-by-pn`} component={BorByPn} />
      <Route isPrivate path={`${path}/bor-without-pn`} component={BorWithoutPn} />
      <Route isPrivate path={`${path}/component-bor`} component={BorComponentReport} />
      <Route
        isPrivate
        path={`${path}/cancellation-chargebacks`}
        component={CancellationChargebacks}
      />
      <Route isPrivate path={`${path}/car-stock-report`} component={CarStockReport} />
      <Route
        isPrivate
        path={`${path}/log-Mov-By-Location-Report`}
        component={LogMovByLocationReport}
      />
      <Route
        isPrivate
        path={`${path}/manual-movimentation`}
        component={ManualMovimentationReport}
      />
      <Route isPrivate path={`${path}/minimum-stock`} component={MinimumStock} />
      <Route isPrivate path={`${path}/parts-x-model-bor-report`} component={PartsXModelBorReport} />
      <Route isPrivate path={`${path}/requests-report`} component={RequestsReport} />
      <Route isPrivate path={`${path}/stock-report`} component={StockReport} />
      <Route isPrivate path={`${path}/wfp-with-pn`} component={WfpWithPn} />
      <Route isPrivate path={`${path}/technical-stock`} component={TechnicalStock} />
    </>
  );
};
