import { api } from 'services/api';

export class ComponentService {
  async listComponents() {
    return api.get('/components');
  }

  async createComponentsByManyXmls(infnfe, component_level_location_id, location_id) {
    return api.post('/components/xmls', { infnfe, component_level_location_id, location_id });
  }

  async listComponentsByIds(service_order_id, components_ids) {
    return api.post('/components/by/ids', { service_order_id, components_ids });
  }

  async listSalesComponents() {
    return api.get('/components/sales');
  }

  async listSalesComponentsWhereProcess(process_id) {
    return api.get(`/components/salesProcessid/${process_id}`);
  }

  async listSalesComponentsById(id) {
    return api.get(`/components/sales/id/${id}`);
  }

  async listGroupComponent() {
    return api.get('/components/grouped');
  }

  async listComponentGroupedByBilling() {
    return api.get('/components/grouped/ByBilling');
  }

  async listcatalog() {
    return api.get('/components/catalog');
  }

  async listcatalogBase() {
    return api.get('/components/importbase');
  }
  async downloadStockGeneralComponent() {
    return api.get('/components/stock/relatory');
  }

  async CreateComponentsCatalogByFile(data) {
    return api.post('/components/catalog', data);
  }

  async CreateBaseImport(data) {
    return api.post('/components/importbase', data);
  }

  async updateComponentsDevolution(data) {
    return api.put('/components/devolution', data);
  }

  async insertComponents(data) {
    return api.post('/components/insertComponent', data);
  }

  async CreateAccessoryPricesFile(dadosFile) {
    return api.post('/components/accessory/price/file', dadosFile);
  }

  async listLocations() {
    return api.get('/components/locations');
  }

  async listStockLocations() {
    return api.get('/components/stock/location');
  }

  async listStockLocationsByLevelLocation(level_location_id) {
    return api.get(`/components/stock/location/${level_location_id}`);
  }

  async listStockLocationsByLevelLocationAndPartNumber(level_location_id, part_number) {
    return api.post(`/components/stock/location/filter`, { level_location_id, part_number });
  }

  async listStockComponentsByLevelLocation(level_location_id) {
    return api.get(`components/stock/components/location/${level_location_id}`);
  }

  async listStockComponentsByLevelLocationAndPns(level_location_id, service_order_number) {
    console.log('LEVEL LOCATION ID: ', level_location_id);
    console.log('SERVICE CHEGA: ', service_order_number);
    return api.get(
      `components/stock/components/location/pns/${level_location_id}/${service_order_number}`,
    );
  }

  async listComponentsByStockLevelLocation(location_id) {
    return api.get(`/components/all-components/${location_id}`);
  }

  async listComponentsByStockLevelLocationDate(location_id) {
    return api.get(`/components/all-components/${location_id}`);
  }

  async createStockMovement(data) {
    return api.post('/components/stock/movement', data);
  }

  async createStockLocations(data) {
    return api.post('/components/stock/location', data);
  }

  async listComponentsStock() {
    return api.get('/components/stock');
  }

  async listComponentsStockFg() {
    return api.get('/components/stock/fg');
  }

  async listComponentsCashier() {
    return api.get('/components/cashier');
  }

  async listComponentsCashierWholesale() {
    return api.get('/components/cashier/wholesale');
  }

  async listComponentLevelLocations() {
    return api.get('/components/locations/level');
  }

  async listPnTracker(id_part_number) {
    return api.get(`/components/pntracker/${id_part_number}`);
  }

  async listOrder() {
    return api.get(`/components/orderings`);
  }

  async listOrderById(id) {
    return api.get(`/components/orderings/${id}`);
  }

  async approveOrder(id) {
    return api.put(`/components/orderings/approved`, { id });
  }

  async reproveOrder(id) {
    return api.put(`/components/orderings/reproved`, { id });
  }

  async listByOrderWFC() {
    return api.get(`/components/orderings/wfc`);
  }

  async listByOrderApproved() {
    return api.get(`/components/orderings/approved`);
  }

  async listByOrderReproved() {
    return api.get(`/components/orderings/reproved`);
  }

  async partsRequisitions() {
    return api.get('/components/list/partsRequisitionsReport');
  }

  async getBillingById(billingId) {
    return api.get(`/components/billing/${billingId}`);
  }

  async listByComponentLevelLocationId(component_level_location_id) {
    return api.get(`/components/stock/location/${component_level_location_id}`);
  }

  async listStockRequests() {
    return api.get('/components/stock/requisition');
  }

  async createStockRequisition(service_order, stock_requisition, user_id) {
    return api.post('/components/stock/requisition', { service_order, stock_requisition, user_id });
  }

  async updateStockRequisition(service_order_id, approved) {
    return api.put('/components/stock/requisition', { service_order_id, approved });
  }

  async getComponentbyId(id) {
    return api.get(`/components/${id}`);
  }

  async updatePartsFromOs(id, component_id, quantity) {
    return api.put('/components/stock/requisition/os', { id, component_id, quantity });
  }

  async partDelete(component_id, service_order_id) {
    return api.post('/components/stock/delete', { component_id, service_order_id });
  }

  async listBuffer() {
    return api.get(`/components/buffer`);
  }

  async createBuffer(data) {
    return api.post(`/components/buffer`, data);
  }

  async createBufferByFile(data) {
    return api.post(`/components/buffer/file`, data);
  }

  async listSupply() {
    return api.get(`/components/supply`);
  }

  async createSupply(data) {
    return api.post(`/components/supply`, data);
  }

  async createSupplyByFile(data) {
    return api.post(`/components/supply/file`, data);
  }

  async listStockBufferReport() {
    return api.get(`/components/report/stock-buffer`);
  }
}

export const componentService = new ComponentService();
