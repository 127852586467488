import React, { forwardRef } from 'react';

import ButtonMUI from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const Button = forwardRef((props, ref) => {
  const {
    children,
    disabled = false,
    variant = 'contained',
    color = 'primary',
    startIcon,
    loading = false,
    loadingText,
    ...buttonProps
  } = props;

  return (
    <ButtonMUI
      {...buttonProps}
      disabled={disabled || loading}
      startIcon={loading ? <CircularProgress size={18} color='inherit' /> : startIcon}
      disableElevation
      variant={variant}
      color={color}
      ref={ref}
    >
      {!loading && children}
      {loading && (loadingText ?? children)}
    </ButtonMUI>
  );
});

Button.displayName = 'Button';

export { Button };
