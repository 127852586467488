import React from 'react';
import { useEffect, useRef } from 'react';

import Grid from '@material-ui/core/Grid';
//import TextField from '@material-ui/core/TextField';
//import Typography from '@material-ui/core/Typography';

import { Text } from 'components';
import { Formik, Form, Field } from 'formik';

export const IngFat = ({ ingFatFormData }) => {
  const formRef = useRef();
  // console.log('hello');
  // console.log(ingFatFormData.service_order);
  useEffect(() => {
    formRef.current.setFieldValue('outlined_service_order', ingFatFormData.service_order);
    formRef.current.setFieldValue('outlined_day', ingFatFormData.valor);
    formRef.current.setFieldValue('outlined_forn', ingFatFormData.forncedor);
  }, [ingFatFormData]);

  const handleSubmit = (values, { setFieldValue }) => {
    console.log(values);
  };

  return (
    <>
      <Formik
        innerRef={(f) => (formRef.current = f)}
        onSubmit={handleSubmit}
        initialValues={{
          outlined_service_order: '',
          outlined_forn: '',
          outlined_day: '',
        }}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={5} md={4} lg={4}>
                <Field
                  variant='outlined'
                  id='outlined_service_order'
                  name='outlined_service_order'
                  size='small'
                  component={Text}
                  label='Ordem de Serviço'
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={5} md={4} lg={4}>
                <Field
                  variant='outlined'
                  component={Text}
                  id='outlined_forn'
                  name='outlined_forn'
                  size='small'
                  label='Fornecedor'
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={2} md={4} lg={4}>
                <Field
                  variant='outlined'
                  id='outlined_day'
                  name='outlined_day'
                  component={Text}
                  size='small'
                  label='Dias'
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
