import React, { memo } from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';

export const RowsLoader = memo(({ columnsCount }) => {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell className={classes.cell} align='center' colSpan={columnsCount}>
        <LinearProgress />
        <Typography className={classes.text}>Carregando...</Typography>
      </TableCell>
    </TableRow>
  );
});

RowsLoader.displayName = 'RowsLoader';
