import React, { useEffect, useRef } from 'react';

import Grid from '@material-ui/core/Grid';
// import Field from '@material-ui/core/Field';
import Typography from '@material-ui/core/Typography';

import { Text } from 'components';
import { Formik, Form, Field } from 'formik';
//import { makeStyles } from '@material-ui/core';

//import { Field } from '@mui/material';

export const Base = ({ baseFormData }) => {
  const formRef = useRef();
  useEffect(() => {
    // console.log(formRef);
    if (!!formRef?.current.setFieldValue) {
      // console.log(baseFormData);
      formRef.current.setFieldValue('outlined_service_order', baseFormData.numeroos);
      formRef.current.setFieldValue('outlined_serial', baseFormData.serial);
      formRef.current.setFieldValue('outlined_cliente', baseFormData.cliente);
      formRef.current.setFieldValue('outlined_type_process', baseFormData.ProcessType);
      formRef.current.setFieldValue('outlined_tag', baseFormData.tag);
      formRef.current.setFieldValue('outlined_dtAbert', baseFormData.dtAbertura);
      formRef.current.setFieldValue('outlined_dtNf3', baseFormData.dt_nfSaida);
      formRef.current.setFieldValue('outlined_dtEntrCMP', baseFormData.dt_entrada_cmp);
      formRef.current.setFieldValue('outlined_dtSaiCMP', baseFormData.dtFinal_CMP);
      formRef.current.setFieldValue('outlined_repairTime', baseFormData.repairtimeReal);
      formRef.current.setFieldValue('outlined_diasCongAprovLaudo', baseFormData.diasCongAprovLaudo);
      formRef.current.setFieldValue('outlined_diasCongClienteOpv', baseFormData.diasCongClienteOpv);
      formRef.current.setFieldValue(
        'outlined_diasCongClienteProduto',
        baseFormData.diasCongClienteProduto,
      );
      formRef.current.setFieldValue('outlined_diasCongCmp', baseFormData.diasCongCmp);
      formRef.current.setFieldValue('outlined_diasCongNota', baseFormData.diasCongNota);
      formRef.current.setFieldValue('outlined_diasCongNotaProd', baseFormData.diasCongNotaProd);
      formRef.current.setFieldValue('outlined_diasCongProd', baseFormData.diasCongProd);
      formRef.current.setFieldValue('outlined_diasCongSerial', baseFormData.diasCongSerial);
      formRef.current.setFieldValue('outlined_diasPenIngenico', baseFormData.diasPenIngenico);
      formRef.current.setFieldValue('outlined_diasConsolid', baseFormData.diasConsolid);
      formRef.current.setFieldValue(
        'outlined_diasCongAvaliacaoPersonalizacao',
        baseFormData.diasCongAvaliacaoPersonalizacao,
      );
      formRef.current.setFieldValue('outlined_diasCongBuffer', baseFormData.diasCongBuffer);
      formRef.current.setFieldValue('outlined_diasCongVD', baseFormData.diasCongVD);
      formRef.current.setFieldValue('outlined_TAT', baseFormData.TAT);
    }
  }, [baseFormData]);
  const handleSubmit = (values, { setFieldValue }) => {
    console.log(values);
  };

  return (
    <>
      <Typography variant='h6' gutterBottom>
        Informações da ordem de serviço
      </Typography>
      <Formik
        innerRef={(f) => (formRef.current = f)}
        onSubmit={handleSubmit}
        initialValues={{
          outlined_service_order: '',
          outlined_serial: '',
          outlined_cliente: '',
          outlined_type_process: '',
          outlined_tag: '',
          outlined_dtAbert: '',
          outlined_dtNf3: '',
          outlined_dtEntrCMP: '',
          outlined_dtSaiCMP: '',
          outlined_repairTime: '',
          outlined_diasCongAprovLaudo: '',
          outlined_diasCongClienteOpv: '',
          outlined_diasCongClienteProduto: '',
          outlined_diasCongCmp: '',
          outlined_diasCongNota: '',
          outlined_diasCongNotaProd: '',
          outlined_diasCongProd: '',
          outlined_diasCongSerial: '',
          outlined_diasPenIngenico: '',
          outlined_diasConsolid: '',
          outlined_diasCongAvaliacaoPersonalizacao: '',
          outlined_diasCongBuffer: '',
          outlined_diasCongVD: '',
          outlined_TAT: '',
        }}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={6} md={4} lg={2}>
                <Field
                  component={Text}
                  name='outlined_service_order'
                  id='outlined_service_order'
                  label='Ordem de Serviço'
                  InputProps={{
                    readOnly: true,
                  }}
                  variant='outlined'
                  fullWidth
                  size='small'
                />
              </Grid>
              <Grid item xs={6} md={4} lg={3}>
                <Field
                  component={Text}
                  id='outlined_serial'
                  name='outlined_serial'
                  label='Serial'
                  inputProps={{
                    readOnly: true,
                  }}
                  variant='outlined'
                  fullWidth
                  size='small'
                />
              </Grid>
              <Grid item xs={6} md={4} lg={3}>
                <Field
                  component={Text}
                  id='outlined_cliente'
                  name='outlined_cliente'
                  label='Cliente'
                  // defaultValue='BANRISUL/TEFTI'
                  inputProps={{
                    readOnly: true,
                  }}
                  variant='outlined'
                  fullWidth
                  size='small'
                />
              </Grid>
              <Grid item xs={6} md={4} lg={2}>
                <Field
                  component={Text}
                  id='outlined_type_process'
                  name='outlined_type_process'
                  label='Tipo processo'
                  // defaultValue='Reparo de POS Ingenico'
                  inputProps={{
                    readOnly: true,
                  }}
                  variant='outlined'
                  fullWidth
                  size='small'
                />
              </Grid>
              <Grid item xs={6} md={4} lg={2}>
                <Field
                  component={Text}
                  id='outlined_tag'
                  name='outlined_tag'
                  label='TAG'
                  // defaultValue='INGPOSMUSOGFAB'
                  inputProps={{
                    readOnly: true,
                  }}
                  variant='outlined'
                  fullWidth
                  size='small'
                />
              </Grid>
              <Grid item xs={6} md={4} lg={2}>
                <Field
                  component={Text}
                  id='outlined_dtAbert'
                  name='outlined_dtAbert'
                  label='dtAbertura'
                  // defaultValue='12-02-2024 08:03:24'
                  inputProps={{
                    readOnly: true,
                  }}
                  variant='outlined'
                  fullWidth
                  size='small'
                />
              </Grid>
              <Grid item xs={6} md={4} lg={3}>
                <Field
                  component={Text}
                  id='outlined_dtNf3'
                  name='outlined_dtNf3'
                  label='dtNfSaida'
                  // defaultValue='14-05-2024 06:24:16'
                  inputProps={{
                    readOnly: true,
                  }}
                  variant='outlined'
                  fullWidth
                  size='small'
                />
              </Grid>
              <Grid item xs={6} md={4} lg={3}>
                <Field
                  component={Text}
                  id='outlined_dtEntrCMP'
                  name='outlined_dtEntrCMP'
                  label='dtEntradaCMP'
                  // defaultValue='14-05-2024 03:37:48'
                  inputProps={{
                    readOnly: true,
                  }}
                  variant='outlined'
                  fullWidth
                  size='small'
                />
              </Grid>
              <Grid item xs={6} md={4} lg={2}>
                <Field
                  component={Text}
                  id='outlined_dtSaiCMP'
                  name='outlined_dtSaiCMP'
                  label='dtSaidaCMP'
                  // defaultValue='14-05-2024 09:00:12'
                  inputProps={{
                    readOnly: true,
                  }}
                  variant='outlined'
                  size='small'
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} md={4} lg={2}>
                <Field
                  component={Text}
                  id='outlined_repairTime'
                  name='outlined_repairTime'
                  label='Repair Time Real'
                  // defaultValue='65'
                  inputProps={{
                    readOnly: true,
                  }}
                  variant='outlined'
                  size='small'
                  fullWidth
                />
              </Grid>
            </Grid>
            <Typography variant='h6' gutterBottom>
              Dias de Congelamento da Ordem de Serviço
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6} md={4} lg={1}>
                <Field
                  component={Text}
                  id='outlined_diasCongAprovLaudo'
                  name='outlined_diasCongAprovLaudo'
                  label='AprovLaudo'
                  // defaultValue='0'
                  inputProps={{
                    readOnly: true,
                  }}
                  variant='outlined'
                  size='small'
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} md={4} lg={1}>
                <Field
                  component={Text}
                  id='outlined_diasCongClienteOpv'
                  name='outlined_diasCongClienteOpv'
                  label='ClienteOpv'
                  // defaultValue='0'
                  inputProps={{
                    readOnly: true,
                  }}
                  variant='outlined'
                  size='small'
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} md={4} lg={1}>
                <Field
                  component={Text}
                  id='outlined_diasCongClienteProduto'
                  name='outlined_diasCongClienteProduto'
                  label='ClienteProduto'
                  // defaultValue='0'
                  inputProps={{
                    readOnly: true,
                  }}
                  variant='outlined'
                  size='small'
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} md={4} lg={1}>
                <Field
                  component={Text}
                  id='outlined_diasCongCmp'
                  name='outlined_diasCongCmp'
                  label='CMP'
                  // defaultValue='0'
                  inputProps={{
                    readOnly: true,
                  }}
                  variant='outlined'
                  size='small'
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} md={4} lg={1}>
                <Field
                  component={Text}
                  id='outlined_diasCongNota'
                  name='outlined_diasCongNota'
                  label='Nota'
                  // defaultValue='0'
                  inputProps={{
                    readOnly: true,
                  }}
                  variant='outlined'
                  size='small'
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} md={4} lg={1}>
                <Field
                  component={Text}
                  id='outlined_diasCongNotaProd'
                  name='outlined_diasCongNotaProd'
                  label='Nota Produto'
                  // defaultValue='0'
                  inputProps={{
                    readOnly: true,
                  }}
                  variant='outlined'
                  size='small'
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} md={4} lg={1}>
                <Field
                  component={Text}
                  id='outlined_diasCongProd'
                  name='outlined_diasCongProd'
                  label='Produto'
                  // defaultValue='0'
                  inputProps={{
                    readOnly: true,
                  }}
                  variant='outlined'
                  size='small'
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} md={4} lg={1}>
                <Field
                  component={Text}
                  id='outlined_diasCongSerial'
                  name='outlined_diasCongSerial'
                  label='Serial'
                  // defaultValue='0'
                  inputProps={{
                    readOnly: true,
                  }}
                  variant='outlined'
                  size='small'
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} md={4} lg={1}>
                <Field
                  component={Text}
                  id='outlined_diasPenIngenico'
                  name='outlined_diasPenIngenico'
                  label='Pendencia Ing'
                  // defaultValue='0'
                  inputProps={{
                    readOnly: true,
                  }}
                  variant='outlined'
                  size='small'
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} md={4} lg={1}>
                <Field
                  component={Text}
                  id='outlined_diasConsolid'
                  name='outlined_diasConsolid'
                  label='Consolidado'
                  // defaultValue='0'
                  inputProps={{
                    readOnly: true,
                  }}
                  variant='outlined'
                  size='small'
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} md={4} lg={1}>
                <Field
                  component={Text}
                  id='outlined_diasCongAvaliacaoPersonalizacao'
                  name='outlined_diasCongAvaliacaoPersonalizacao'
                  label='Av Personalizacao'
                  // defaultValue='0'
                  inputProps={{
                    readOnly: true,
                  }}
                  variant='outlined'
                  size='small'
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} md={4} lg={1}>
                <Field
                  component={Text}
                  id='outlined_diasCongBuffer'
                  name='outlined_diasCongBuffer'
                  label='Buffer'
                  // defaultValue='0'
                  inputProps={{
                    readOnly: true,
                  }}
                  variant='outlined'
                  size='small'
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} md={4} lg={1}>
                <Field
                  component={Text}
                  id='outlined_diasCongVD'
                  name='outlined_diasCongVD'
                  label='Venda Direta'
                  // defaultValue='0'
                  inputProps={{
                    readOnly: true,
                  }}
                  variant='outlined'
                  size='small'
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} md={4} lg={1}>
                <Field
                  component={Text}
                  id='outlined_TAT'
                  name='outlined_TAT'
                  label='TAT'
                  // defaultValue='0'
                  inputProps={{
                    readOnly: true,
                  }}
                  variant='outlined'
                  size='small'
                  fullWidth
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
