import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
  return {
    customInput: {
      '& .MuiInputBase-input': {
        height: '0.5em !important',
      },
      '& .MuiFormLabel-root': {
        lineHeight: '0.3 !important',
      },
    },
  };
});
