import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { Grid } from '@mui/material';
import { Button, DatePicker, Select, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { downloadXlsx } from 'helpers/downloadXLSX';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providerService } from 'services';

const selectType = [
  {
    value: 0,
    label: 'Pendência ingenico',
  },
  {
    value: 1,
    label: 'Por id congelamento',
  },
  {
    value: 2,
    label: 'Por Data',
  },
];

export const ReportFreezingPendin = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    setIsLoading(true);
    setSubmitting(true);

    try {
      const temp = {
        filter: values.filter || null,
        input_data: values.filter === 2 ? values.input_data : null,
        output_data: values.filter === 2 ? values.output_data : null,
        idChamado: values.filter === 1 ? values.idChamado : null,
      };
      const data = await providerService.getIngenicoReportFreezingPending(temp);
      downloadXlsx('ingenico_relatorio_cong_pending', data);
      toast.success('Relatório gerado com sucesso');
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setSubmitting(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Relatório Congelamento Pendência Ingenico</PageTitle>
      <Formik
        initialValues={{
          filter: 0,
          input_data: new Date(new Date().setDate(new Date().getDate() - 7)),
          output_data: new Date(),
          idChamado: '',
        }}
        onSubmit={handleSubmit}
      >
        {({ values, resetForm }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3} lg={3}>
                <Field
                  size='small'
                  loading={isLoading}
                  name='filter'
                  variant='outlined'
                  fullWidth
                  inputVariant='outlined'
                  label='Filtro'
                  options={selectType}
                  component={Select}
                />
              </Grid>
              {values.filter !== 0 ? (
                values.filter === 1 ? (
                  <Grid item xs={12} md={3} lg={3}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='idChamado'
                      variant='outlined'
                      fullWidth
                      inputVariant='outlined'
                      label='ID'
                      component={Text}
                    />
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={12} md={3} lg={3}>
                      <Field
                        size='small'
                        loading={isLoading}
                        name='input_data'
                        variant='outlined'
                        fullWidth
                        inputVariant='outlined'
                        label='Data inicial'
                        component={DatePicker}
                      />
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                      <Field
                        size='small'
                        loading={isLoading}
                        name='output_data'
                        variant='outlined'
                        fullWidth
                        inputVariant='outlined'
                        label='Data final'
                        component={DatePicker}
                      />
                    </Grid>
                  </>
                )
              ) : null}
              <Grid item xs={12} md={3} lg={3}>
                <Button fullWidth type='submit' loading={isLoading}>
                  Exportar
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
