import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Radio from '@mui/material/Radio';
import { Dialog, Button, Table } from 'components';
import { Form, Formik } from 'formik';
import { downloadcsv } from 'helpers/downloadcsv';
import { formatDate } from 'helpers/formatData';
import { AuthContext } from 'hooks/useAuth';
import { launchPrivider } from 'services';
import { useContextSelector } from 'use-context-selector';

import { DialogUpdateRequest } from './DialogDialogUpdateRequest';

const columns = [
  { label: 'Aprovar Congelamento' },
  { label: 'Reprovar Congelamento' },
  { label: 'Numero Os' },
  { label: 'Serial' },
  { label: 'Status Congelamento' },
  { label: 'Status Aprovação' },
  { label: 'Data Aprovação' },
  { label: 'Aprovado Por' },
  { label: 'Data Congelamento' },
  { label: 'User Congelamento' },
  { label: 'Data Descongelado' },
  { label: 'UserDescongelamento' },
  { label: 'Motivo Congelamento' },
  { label: 'Motivo Congelamento IR' },
  { label: 'ID_Chamado' },
];

export const DialogChangeStatus = ({ idCadastro, openDialogStatus, handleCloseDialogStatus }) => {
  if (!DialogChangeStatus) return null;
  const user = useContextSelector(AuthContext, (context) => context.user);
  const [isLoading, setIsLoading] = useState(false);
  const now = new Date();
  const [DataTableApprove, setDataTableApprove] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState([]);
  const [openDialogUpdateRequest, setOpenDialogUpdateRequest] = useState(false);
  const [idCadastroUpdateRequest, setIdCadastroUpdateRequest] = useState(0);

  const handleRadioChange = (index, value, item) => {
    const newPrevState = [...selectedRadio];
    newPrevState[index] = {
      idCongelarPendenciaIngenico: item.idCongelarPendenciaIngenico,
      idChamado: item.ID_Chamado,
      status: value,
    };
    setSelectedRadio(newPrevState);
  };

  const getDados = useCallback(async (idCadastro) => {
    const { data } = await launchPrivider.getDataTableApproveFreezing(idCadastro);
    setDataTableApprove(data);
  }, []);

  useEffect(() => {
    getDados(idCadastro);
  }, [idCadastro, getDados]);

  const handleCheckedAllApprove = () => {
    const newSelectedRadio = [];
    DataTableApprove.forEach((item, index) => {
      newSelectedRadio[index] = {
        idCongelarPendenciaIngenico: item.idCongelarPendenciaIngenico,
        idChamado: item.ID_Chamado,
        status: 'aprovar',
      };
    });
    setSelectedRadio(newSelectedRadio);
  };
  const handleCheckedAllReprove = () => {
    const newSelectedRadio = [];
    DataTableApprove.forEach((item, index) => {
      newSelectedRadio[index] = {
        idCongelarPendenciaIngenico: item.idCongelarPendenciaIngenico,
        idChamado: item.ID_Chamado,
        status: 'reprovar',
      };
    });
    setSelectedRadio(newSelectedRadio);
  };

  const handleClose = () => {
    setSelectedRadio([]);
    handleCloseDialogStatus();
  };

  const handleConfirmData = async () => {
    const value = { radioValue: selectedRadio, user: user.name, idCadastro: idCadastro };
    const { data } = await launchPrivider.updateStatusFreezing(value);

    if (data.msg == 'Aprovação realizada com sucesso') {
      toast.success('Aprovação realizada com sucesso!');
      setSelectedRadio([]);
      handleCloseDialogStatus();
      if (selectedRadio.some((item) => item.status === 'reprovar')) {
        handleOpenDialogUpdateRequest();
      }
    } else {
      toast.error('Erro ao aprovar');
    }
  };

  const handleExportReport = () => {
    setIsLoading(true);
    toast.info('Aguardando relatório');
    try {
      const data = DataTableApprove;
      const date = formatDate(now);
      downloadcsv(`Relatorio_de_Aprovação_Congelamento${date}`, data);
      toast.success('Relatório Gerado com sucesso');
    } catch (error) {
      console.error(error);
      toast.error('Falha ao gerar relatório');
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenDialogUpdateRequest = (id_cadastro) => {
    setIdCadastroUpdateRequest(id_cadastro);
    setOpenDialogUpdateRequest(true);
  };

  const handleCloseDialogUpdateRequest = () => {
    setOpenDialogUpdateRequest(false);
  };

  return (
    <>
      <DialogUpdateRequest
        idCadastroUpdateRequest={idCadastro}
        handleCloseDialogUpdateRequest={handleCloseDialogUpdateRequest}
        openDialogUpdateRequest={openDialogUpdateRequest}
      ></DialogUpdateRequest>
      <Dialog maxWidth={'lg'} fullScreen open={openDialogStatus}>
        <Dialog.Title onClose={handleClose}>Congelamento Aprovação</Dialog.Title>
        <Dialog.Content>
          <Formik>
            {(props) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={3}>
                    <Button onClick={handleCheckedAllApprove} fullWidth>
                      Aprovar Todos
                    </Button>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Button onClick={handleCheckedAllReprove} color='secondary' fullWidth>
                      Reprovar Todos
                    </Button>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Button isLoading={isLoading} onClick={handleExportReport} fullWidth>
                      Extrair Relatório
                    </Button>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Button fullWidth onClick={handleConfirmData}>
                      Confirmar
                    </Button>
                  </Grid>
                </Grid>
                <Box mt={2}>
                  <Grid item xs={12}>
                    <Table name='Table' headers={columns} maxHeight disableNumeration>
                      {DataTableApprove &&
                        DataTableApprove.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <Radio
                                size='small'
                                checked={selectedRadio[index]?.status === 'aprovar'}
                                onChange={() => handleRadioChange(index, 'aprovar', item)}
                                value='aprovar'
                                name={`radioGroup${index}`}
                              />
                            </TableCell>
                            <TableCell>
                              <Radio
                                size='small'
                                checked={selectedRadio[index]?.status === 'reprovar'}
                                onChange={() => handleRadioChange(index, 'reprovar', item)}
                                value='reprovar'
                                name={`radioGroup${index}`}
                              />
                            </TableCell>
                            <TableCell>{item.numeroOS}</TableCell>
                            <TableCell>{item.Serial}</TableCell>
                            <TableCell>{item.StatusCongelamento}</TableCell>
                            <TableCell>{item.StatusAprovacao}</TableCell>
                            <TableCell>{item.DataAprovacao}</TableCell>
                            <TableCell>{item.AprovadoPor}</TableCell>
                            <TableCell>{item.DataCongelamento}</TableCell>
                            <TableCell>{item.UserCongelamento}</TableCell>
                            <TableCell>{item.DataDescongelado}</TableCell>
                            <TableCell>{item.UserDescongelamento}</TableCell>
                            <TableCell>{item.MotivoCongelamento}</TableCell>
                            <TableCell>{item.MotivoCongelamentoIR}</TableCell>
                            <TableCell>{item.ID_Chamado}</TableCell>
                          </TableRow>
                        ))}
                    </Table>
                  </Grid>
                </Box>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
