import React from 'react';

import { Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';

import { TableTabs } from './TableTabs';

export const ConsultQuoteTransactions = () => {
  return (
    <>
      <PageTitle>Consultar Cotação de Seguros</PageTitle>
      <Formik initialValues={{}}>
        <TableTabs />
      </Formik>
    </>
  );
};
