import React from 'react';

import { useQuery } from 'hooks/useQuery';
import { ResponsiveContainer, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import { serviceOrderService } from 'services';

export const GraphiRepairInspector = () => {
  const [budgetWeekTransactionsData, , loadingbudgetWeekTransactionsData] = useQuery(
    () => serviceOrderService.listRepairInspector(),
    [],
  );

  function formatarData(data) {
    const dataObj = new Date(data);
    const dia = dataObj.getUTCDate().toString().padStart(2, '0');
    const mes = (dataObj.getUTCMonth() + 1).toString().padStart(2, '0');

    return `${dia}/${mes}`;
  }

  const data = (budgetWeekTransactionsData || []).map((datas) => ({
    name: formatarData(datas.date),
    pv: Number(datas.total),
    //fill: COLORS[index % COLORS.length],
  }));
  return (
    <ResponsiveContainer width='100%' height='90%'>
      <BarChart
        width={80}
        height={30}
        data={data}
        margin={{
          left: -25,
          bottom: -5,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' />
        <YAxis />
        <Tooltip />

        <Bar barSize={30} dataKey='pv' stackId='a' fill='#7355bd' />
      </BarChart>
    </ResponsiveContainer>
  );
};
