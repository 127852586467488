import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog } from 'components';
export const ErrorDialog = ({ openSubErrorDialog, handleCloseSubModal, errorDialog }) => {
  return (
    <Dialog open={openSubErrorDialog}>
      <Dialog.Title onClose={handleCloseSubModal}>Mensagem</Dialog.Title>
      <Dialog.Content>
        <Grid container spacing={2} justifyContent='center'>
          <Grid item xs={12} lg={12}>
            <Typography variant='h5' align='center' style={{ paddingBottom: '2rem' }}>
              {errorDialog}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Button fullWidth color='secondary' onClick={handleCloseSubModal}>
              fechar
            </Button>
          </Grid>
        </Grid>
      </Dialog.Content>
    </Dialog>
  );
};
