import React from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { browserHistory } from 'routes/browserHistory';
import { api } from 'services/api';
import { useContextSelector } from 'use-context-selector';
import * as Yup from 'yup';

import { useStyles } from './styles';

const validationSchema = Yup.object().shape({});

export const CodeAcess = () => {
  const signIn = useContextSelector(AuthContext, (context) => context.signIn);

  const {
    state: { login, password },
  } = useLocation();

  const classes = useStyles();

  const onSubmitHandler = async (values, { setSubmitting }) => {
    try {
      const { acess_code } = values;

      const authenticateCode = await api.post('/password/pre-authenticate-code', { acess_code });

      if (authenticateCode) {
        await signIn({
          login: login,
          password: password,
        });
      }

      setSubmitting(true);

      toast.success('Usuário validado com sucesso!');

      browserHistory.push('/acess_code');
    } catch (err) {
      toast.error('Erro ao validar o código do usuário.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{ acess_code: '' }}
      onSubmit={onSubmitHandler}
      validationSchema={validationSchema}
    >
      <Form>
        <Field
          name='acess_code'
          variant='outlined'
          size='small'
          label='Código especial de acesso'
          type='text'
          fullWidth
          className={classes.input}
          component={Text}
        />
        <Button type='submit' size='large' fullWidth>
          Validar código
        </Button>
      </Form>
    </Formik>
  );
};
