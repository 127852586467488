import deepPurple from '@material-ui/core/colors/deepPurple';
import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: deepPurple[700],
      light: '#895BF5',
    },
    text: {
      primary: '#2d3747',
    },
  },
  overrides: {
    MuiFormHelperText: {
      root: {
        fontSize: 12,
        color: '#ed6c02',
      },
      contained: {
        marginLeft: 2,
        marginRight: 0,
      },
    },
    MuiTableRow: {
      root: {
        '&$hover': {
          '&:hover': {
            backgroundColor: '#fafafa',
          },
        },
      },
    },
    MuiButton: {
      root: {
        height: 40,
      },
    },
    MuiTabs: {
      root: {
        borderBottom: '1px solid #e2e8f0',
      },
    },
    MuiTab: {
      textColorPrimary: {
        fontWeight: 400,

        '&$selected': {
          color: '#2d3748',
        },
      },
    },
  },
});
