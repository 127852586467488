import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

export const Detailed = ({ detailedFormData }) => {
  return (
    <>
      <TableContainer>
        <Table
          size='medium'
          arial-label='a dense table'
          stripped
          emptyMassage='Nenhum Registro encontrado'
        >
          <TableHead>
            <TableRow>
              <TableCell>Ordem de Serviço</TableCell>
              <TableCell>Cliente</TableCell>
              <TableCell>Motivo</TableCell>
              <TableCell>Ticket HP</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Responsável Ing</TableCell>
              <TableCell>dtCongelado</TableCell>
              <TableCell>dtDescongelado</TableCell>
              <TableCell>dtAbertura</TableCell>
              <TableCell>dtNF3</TableCell>
              <TableCell>Dias Úteis</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {detailedFormData.map((element, index) => {
              return (
                <TableRow key={index}>
                  <TableCell> {element?.numeroOs} </TableCell>
                  <TableCell> {element.cliente}</TableCell>
                  <TableCell> {element.motivo}</TableCell>
                  <TableCell> {element.TiketHP}</TableCell>
                  <TableCell> {element.tipo}</TableCell>
                  <TableCell> {element.responsavelIngenico}</TableCell>
                  <TableCell> {element.dtCongelado}</TableCell>
                  <TableCell> {element.dtDescongelado}</TableCell>
                  <TableCell> {element.dtAbertura}</TableCell>
                  <TableCell> {element.dtNfs}</TableCell>
                  <TableCell> {element.DiasUteis}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
