import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import Box from '@mui/material/Box';
import { Button, DatePicker, Dialog, Text, Time, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useContextSelector } from 'use-context-selector';
import * as Yup from 'yup';

import { laboratoryService } from '../../../../../services/laboratory';

const typeAreaAfetada = [
  { label: 'Extract Files', value: 'Extract Files' },
  { label: 'Limpeza', value: 'Limpeza' },
  { label: 'Laudo', value: 'Laudo' },
  { label: 'TEW1', value: 'TEW1' },
  { label: 'Reparo N2', value: 'Reparo N2' },
  { label: 'Reparo Expresso', value: 'Reparo Expresso' },
  { label: 'TEW2', value: 'TEW2' },
  { label: 'Personalização', value: 'Personalização' },
  { label: 'Módulo', value: 'Módulo' },
  { label: 'Controle de Qualidade 1', value: 'Controle de Qualidade 1' },
  { label: 'controle de Qualidade 2', value: 'Controle de Qualidade 2' },
  { label: 'Laboratório', value: 'Laboratório' },
  { label: 'Expedição', value: 'Expedição' },
  { label: 'Recebimento', value: 'Recebimento' },
];

const typeParada = [
  { label: 'Total', value: 'Total' },
  { label: 'Parcial', value: 'Parcial' },
];

const typeStatus = [
  { label: 'Aberto', value: 'Aberto' },
  { label: 'Em processo', value: 'Em Processo' },
  { label: 'Solucionado', value: 'Solucionado' },
];

const optionAreaAfetada = [
  { label: 'Manuntenção', value: 'Manuntenção' },
  { label: 'TI', value: 'TI' },
  { label: 'Engenharia', value: 'Engenharia' },
  { label: 'Estoque', value: 'Estoque' },
  { label: 'Outros', value: 'Outros' },
];

const validationSchema = Yup.object().shape({
  problem: Yup.string().required('O campo Problemas/Necessidade é obrigatório'),
  type_parada: Yup.string().required('O campo Tipo de Parada é obrigatório'),
});

export const DialogCadastro = ({ openDialogCadastro, handleCloseDialogCadastro }) => {
  const [isLoading, setIsLoading] = useState(false);
  const user = useContextSelector(AuthContext, (context) => context.user);
  const userName = user.name;

  const handleProcessDowntime = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await laboratoryService.processDowntime({
        select_area: values.select_area,
        type_parada: values.type_parada,
        responsible_user: userName,
        problem: values.problem,
        select_status: values.select_status,
        time: values.time,
        responsible_area: values.responsible_area,
        input_data_occurrence: values.input_data_occurrence,
        input_obs: values.input_obs,
      });
      toast.success('Enviado com sucesso!');
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
      await handleCloseDialogCadastro();
      window.location.reload();
    }
  };
  return (
    <Dialog maxWidth='lg' open={openDialogCadastro}>
      <Dialog.Title onClose={handleCloseDialogCadastro}>Cadastro DownTime</Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{
            select_area: '',
            type_parada: '',
            responsible_user: userName,
            problem: '',
            select_status: '',
            time: '00:00',
            responsible_area: '',
            input_data_occurrence: new Date(),
            input_obs: '',
          }}
          onSubmit={handleProcessDowntime}
          validationSchema={validationSchema}
        >
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Field
                  size='small'
                  loading={isLoading}
                  name='select_area'
                  variant='outlined'
                  fullWidth
                  inputVariant='outlined'
                  label='Área afetada'
                  options={typeAreaAfetada}
                  component={Select}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Field
                  size='small'
                  loading={isLoading}
                  name='type_parada'
                  variant='outlined'
                  fullWidth
                  inputVariant='outlined'
                  label='Tipo de Parada'
                  options={typeParada}
                  component={Select}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={4}>
                <Field
                  size='small'
                  loading={isLoading}
                  name='responsible_user'
                  variant='outlined'
                  fullWidth
                  disabled
                  label='Responsável pelo preenchimento'
                  component={Text}
                />
              </Grid>
            </Grid>
            <Box mt={2}>
              <Grid item xs={12} md={9} lg={12}>
                <Field
                  size='small'
                  loading={isLoading}
                  name='problem'
                  variant='outlined'
                  fullWidth
                  inputVariant='outlined'
                  label='Problemas/Necessidade'
                  component={Text}
                  multiline
                  minRows={3}
                />
              </Grid>
            </Box>
            <Box mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <Field
                    size='small'
                    loading={isLoading}
                    name='select_status'
                    variant='outlined'
                    fullWidth
                    inputVariant='outlined'
                    label='Status'
                    options={typeStatus}
                    component={Select}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Field
                    size='small'
                    disabled={isLoading}
                    name='input_data_occurrence'
                    variant='inline'
                    fullWidth
                    inputVariant='outlined'
                    label='Data Ocorrencia'
                    component={DatePicker}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Field
                    size='small'
                    loading={isLoading}
                    name='time'
                    variant='outlined'
                    fullWidth
                    inputVariant='outlined'
                    label='Hora de Inicio'
                    component={Time}
                    defaultValue='00:00'
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Field
                    size='small'
                    loading={isLoading}
                    name='responsible_area'
                    variant='outlined'
                    fullWidth
                    inputVariant='outlined'
                    label='Área resposável'
                    options={optionAreaAfetada}
                    component={Select}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mt={2}>
              <Grid item xs={12} md={9} lg={12}>
                <Field
                  size='small'
                  loading={isLoading}
                  name='input_obs'
                  variant='outlined'
                  fullWidth
                  inputVariant='outlined'
                  label='Observação'
                  component={Text}
                  multiline
                  minRows={3}
                />
              </Grid>
            </Box>
            <Box mt={2}>
              <Grid item xs={12} md={2} lg={2}>
                <Button fullWidth type='submit' loading={isLoading}>
                  Enviar
                </Button>
              </Grid>
            </Box>
          </Form>
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
