import React from 'react';
import { useRouteMatch } from 'react-router';

import { AutoCheckCQ } from 'pages/Reports/AutoCheckCQReport';
import { CosmeticScreeningReport } from 'pages/Reports/CosmeticScreeningReport';
import { ExpressRepair } from 'pages/Reports/ExpressRepairReport';
import { LaunchParts } from 'pages/Reports/LaunchPartsReport';
import { ProductionDepartmentReport } from 'pages/Reports/ProductionDepartmentReport';
import { QuantityLaudoDay } from 'pages/Reports/QuantityLaudoDayReport';
import { ViewLaudo } from 'pages/Reports/ViewLaudosReport';

import { Route } from '../Route';

export const ReportLabRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${path}/autoCheck-cq`} component={AutoCheckCQ} />
      <Route
        isPrivate
        path={`${path}/cosmetic-screening-report`}
        component={CosmeticScreeningReport}
      />
      <Route isPrivate path={`${path}/express-repair`} component={ExpressRepair} />
      <Route isPrivate path={`${path}/launch-parts`} component={LaunchParts} />
      <Route
        isPrivate
        path={`${path}/report-production-department`}
        component={ProductionDepartmentReport}
      />
      <Route isPrivate path={`${path}/quantity-laudo-day`} component={QuantityLaudoDay} />
      <Route isPrivate path={`${path}/view-laudos`} component={ViewLaudo} />
    </>
  );
};
