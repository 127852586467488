import { api } from 'services/api';

export class LaunchPrivider {
  //  LAUNCH

  async getListClient(grupo) {
    return api.get('/xpcellIngenicoLaunch/launch/list-client?grupo=' + grupo);
  }

  async getListCondition() {
    return api.get('/xpcellIngenicoLaunch/launch/list-condition');
  }

  async getDataTable(data) {
    return api.post('/xpcellIngenicoLaunch/launch/getDataTable', data);
  }

  async getDataTableApproveFreezing(data) {
    console.log(data);
    return api.post('/xpcellIngenicoLaunch/launch/getDataTableApproveFreezing', {
      id_cadastro: data,
    });
  }

  async getDataTableUpdateRequest(data) {
    return api.post('/xpcellIngenicoLaunch/launch/getDataTableUpdateRequest', {
      id_call: data,
    });
  }
  async newRequestFreezing(data) {
    console.log('providetr', data);
    return api.post('/xpcellIngenicoLaunch/launch/newRequestFreezing', data);
  }

  async updateStatusFreezing(data) {
    return api.post('/xpcellIngenicoLaunch/launch/updateStatusFreezing', data);
  }

  async resendEmail(data) {
    return api.post('/xpcellIngenicoLaunch/launch/resendEmail', data);
  }

  async UptadeRequestStatus(data) {
    return api.post('/xpcellIngenicoLaunch/launch/UptadeRequestStatus', data);
  }

  async generateCompressedReport(data) {
    return api.post('/xpcellIngenicoLaunch/launch/generate-compressed-report', data, {
      responseType: 'blob',
    });
  }

  async registerBlockOs(data) {
    return api.post('/xpcellIngenicoLaunch/launch/registerBlockOs', data);
  }

  async getTableValuesBlocks(data) {
    return api.post('/xpcellIngenicoLaunch/launch/getTableValuesBlocks', data);
  }

  async unlockBlockOs(data) {
    return api.post('/xpcellIngenicoLaunch/launch/unlockBlockOs', data);
  }

  async getBase64(data) {
    return api.post('/xpcellIngenicoLaunch/launch/getBase64', data);
  }

  async findClients() {
    return api.get('/xpcellIngenicoLaunch/launch/findClients');
  }

  async blockMultipleSeriais(data) {
    return api.post('/xpcellIngenicoLaunch/launch/blockMultipleSeriais', data);
  }

  async unlockMultipleSeriais(data) {
    return api.post('/xpcellIngenicoLaunch/launch/unlockMultipleSeriais', data);
  }
  //HELPERS
  async getTypeFreezing() {
    return api.get(`/xpcellIngenicoLaunch/helpers/getTypeFreezing`);
  }

  async getstatusFreezing() {
    return api.get(`/xpcellIngenicoLaunch/helpers/getstatusFreezing`);
  }

  async reportsServiceOrdem(data) {
    return api.post(`/xpcellIngenicoLaunch/launch/reportsServiceOrdem`, data);
  }

  async limitRegistreImport(data) {
    const jsonObject = JSON.parse(localStorage.getItem('@Gaia:user'));
    return api.post(`/xpcellIngenicoLaunch/launch/limiteRegistreImport?id=${jsonObject.id}`, data);
  }

  async limiteRegistreExport(data) {
    return api.get(`/xpcellIngenicoLaunch/launch/limiteRegistreExport`, data);
  }
}

export const launchPrivider = new LaunchPrivider();
