import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import { Button, Table, Select, DateTimePicker } from 'components';
import { Field, Form, Formik } from 'formik';
import { downloadcsv } from 'helpers/downloadcsv';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providerService } from 'services/providers';

import { DialogAtribuido } from './Dialog/DialogAtribuido';

const columns = [
  { label: 'Etapa De' },
  { label: 'Etapa Para' },
  { label: 'Dt. Cadastro' },
  { label: 'Turno' },
  { label: 'Total OSs' },
  { label: 'Atribuido' },
];

export const ProductionDepartmentReport = () => {
  const [isLoading, setIsLoading] = useState(0);
  const [etapa, setEtapa] = useState([]);
  const [dataReport, setDataReport] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [line, setLine] = useState([]);
  const [formattedInputDate, setFormattedInputDate] = useState('');
  const [formattedOutputDate, setFormattedOutputDate] = useState('');

  const handleExportReport = async (values) => {
    toast.info('Aguarde, relatório sendo gerado.');
    setIsLoading(1);

    const formattedInputDate = values.input_data
      .toLocaleString('pt-BR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false, // Para formato 24 horas
      })
      .replace(',', '') // Remove a vírgula entre a data e a hora
      .replace(/\//g, '-'); // Substitui as barras por hífens

    const formattedOutputDate = values.output_data
      .toLocaleString('pt-BR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false, // Para formato 24 horas
      })
      .replace(',', '') // Remove a vírgula entre a data e a hora
      .replace(/\//g, '-'); // Substitui as barras por hífens

    try {
      const { data } = await providerService.getProductionDepartmentReport(
        formattedInputDate,
        formattedOutputDate,
        values.type_data,
        values.etapa,
      );
      downloadcsv('ingenico_producao_por_setor', data);
      toast.success('Relatório gerado com sucesso');
    } catch (error) {
      console.log(error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(0);
    }
  };

  const selectRangeOptions = [
    {
      value: '0',
      label: 'Resumido',
    },
    {
      value: '1',
      label: 'Detalhado',
    },
  ];

  const getEtapas = useCallback(async () => {
    const data = await providerService.getEtapas();
    setEtapa(data);
  }, []);

  const handleCreateTable = async (values) => {
    toast.info('Aguarde, tabela está gerando.');
    setIsLoading(1);

    const formattedInputDate = values.input_data
      .toLocaleString('pt-BR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false, // Para formato 24 horas
      })
      .replace(',', '') // Remove a vírgula entre a data e a hora
      .replace(/\//g, '-'); // Substitui as barras por hífens

    const formattedOutputDate = values.output_data
      .toLocaleString('pt-BR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false, // Para formato 24 horas
      })
      .replace(',', '') // Remove a vírgula entre a data e a hora
      .replace(/\//g, '-'); // Substitui as barras por hífens

    setFormattedInputDate(formattedInputDate);
    setFormattedOutputDate(formattedOutputDate);
    try {
      const { data } = await providerService.getProductionDepartmentReport(
        formattedInputDate,
        formattedOutputDate,
        values.type_data,
        values.etapa,
      );
      setDataReport(data);
      toast.success('Relatório gerado com sucesso');
    } catch (error) {
      console.log(error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(0);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenDialog = (item) => {
    setOpenDialog(true);
    setLine(item);
  };

  const handleCloseDialo = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    getEtapas();
  }, [getEtapas]);

  return (
    <>
      <DialogAtribuido
        openDialog={openDialog}
        handleCloseDialo={handleCloseDialo}
        line={line}
        inputDate={formattedInputDate}
        outputDate={formattedOutputDate}
      />
      <PageTitle>Relatório Produção por Setor</PageTitle>
      <Formik
        initialValues={{
          input_data: new Date(new Date().setHours(0, 0, 0, 0)), // Define a hora para 00:00:00
          output_data: new Date(new Date().setHours(23, 59, 59, 999)), // Define a hora para 23:59:59
          type_data: '0',
          etapa: 0,
        }}
        onSubmit={handleExportReport}
      >
        {({ values }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3} lg={3}>
                <Field
                  size='small'
                  name='input_data'
                  variant='outlined'
                  fullWidth
                  inputVariant='outlined'
                  label='Data inicial'
                  component={DateTimePicker}
                  disabled={isLoading == 1 ? true : false}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <Field
                  size='small'
                  name='output_data'
                  variant='outlined'
                  fullWidth
                  inputVariant='outlined'
                  label='Data final'
                  component={DateTimePicker}
                  disabled={isLoading == 1 ? true : false}
                />
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <Field
                  size='small'
                  name='type_data'
                  label='Tipo'
                  variant='outlined'
                  options={selectRangeOptions}
                  component={Select}
                  disabled={isLoading == 1 ? true : false}
                />
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <Field
                  size='small'
                  name='etapa'
                  label='Etapa'
                  variant='outlined'
                  options={etapa}
                  component={Select}
                  disabled={isLoading == 1 ? true : false}
                />
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <Button
                  fullWidth
                  type='submit'
                  disabled={isLoading == 1 ? true : false}
                  loading={isLoading == 1 ? true : false}
                >
                  Exportar relatório
                </Button>
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <Button
                  fullWidth
                  onClick={() => handleCreateTable(values)}
                  disabled={isLoading == 1 || values.type_data === '1'}
                  loading={isLoading == 1}
                >
                  Relatório
                </Button>
              </Grid>
            </Grid>
            <Box mt={2}>
              <Grid container>
                <Grid xs={12} md={12} lg={12}>
                  <Table name='Table' headers={columns} maxHeight disableNumeration>
                    {dataReport
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{item.Etapa_DE}</TableCell>
                          <TableCell>{item.Etapa_Para}</TableCell>
                          <TableCell>{item.Dt}</TableCell>
                          <TableCell>{item.turno}</TableCell>
                          <TableCell>{item.Total_OS}</TableCell>
                          <TableCell>
                            <InfoIcon
                              style={{ color: '#512da8', cursor: 'pointer' }}
                              onClick={() => handleOpenDialog(item)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </Table>
                  <TablePagination
                    component='div'
                    count={dataReport.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage='Itens por página'
                  />
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
