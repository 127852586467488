import { api } from 'services/api';

export class ReceivementService {
  async getNF(data) {
    return api.post('/receivement/getNF', data);
  }

  async processCorrectionNF(data) {
    return api.post('/receivement/processCorrectionNF', data);
  }
  async findProccess() {
    return api.get('/receivement/findProccess');
  }
  async findPartner() {
    return api.get('/receivement/findPartner');
  }
  async findClient(data) {
    return api.post('/receivement/findClient', data);
  }
  async findTable(data) {
    return api.post('/receivement/findTable', data);
  }
  async preReceivementImport(data) {
    return api.post('/receivement/preReceivementImport', data);
  }

  async getProcess() {
    return api.get('/receivement/getProcess');
  }

  async getParceiro() {
    return api.get('/receivement/getParceiro');
  }

  async getClient() {
    return api.get('/receivement/getClient');
  }

  async getClientParceiro(data) {
    return api.post('/receivement/getClientParceiro', data);
  }

  async updateNF(data) {
    return api.post('/receivement/updateNF', data);
  }

  async processRegisterPreReivement(data) {
    return api.post('/receivement/processRegisterPreReivement', data);
  }
}

export const receivementService = new ReceivementService();
