import React, { memo } from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { AuthContext } from 'hooks/useAuth';
import { useContextSelector } from 'use-context-selector';

import { useStyles } from './styles';

const MenuItemToggleComponent = ({ id, label, icon, isOpen, onClick, roles }) => {
  const classes = useStyles();

  const rolePermissionsMain = useContextSelector(
    AuthContext,
    (context) => context.user?.role_permissions_main_id,
  );

  const userAdditionalPermissionsMain = useContextSelector(
    AuthContext,
    (context) => context.user?.additional_permissions_main_id,
  );

  const allMainPermissions = rolePermissionsMain?.concat(userAdditionalPermissionsMain);

  const rolesThatAccess = roles;
  const userRolesMatch = roles != null ? allMainPermissions?.includes(rolesThatAccess) : true;

  const handleClick = () => onClick(id);

  if (!userRolesMatch) {
    return null;
  }

  return (
    <ListItem
      classes={{
        root: classes.listItem,
      }}
      button
      onClick={handleClick}
    >
      <ListItemIcon className={classes.listIcon}>{icon}</ListItemIcon>
      <ListItemText
        classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }}
        primary={label}
      />
      <ExpandMore classes={{ root: classes.expandMoreIcon }} className={isOpen ? 'open' : ''} />
    </ListItem>
  );
};

const MenuItemToggle = memo(MenuItemToggleComponent);
export { MenuItemToggle };
