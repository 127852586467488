import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';

import { Button, Text, Table } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providerService } from 'services';

import { downloadcsv } from '../../../helpers/downloadcsv';

const columns = [
  { label: 'CNPJ' },
  { label: 'Cliente' },
  { label: 'Processo' },
  { label: 'Status' },
  { label: 'obsStatus' },
  { label: 'Obs' },
  { label: 'dtCadastro' },
  { label: 'Usuario' },
  { label: 'Tipo' },
];

export const StatusLogNfReport = () => {
  const [dataTable, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const handleGetTableValues = async (values) => {
    const { data } = await providerService.getLogNfTable(values);
    console.log(data);
    const dataPages = Object.values(data); // Converter o objeto em um array de arrays
    setData(dataPages);
  };

  const handleDownloadCsv = async () => {
    toast.info('Aguarde, Relatório sendo gerado!');
    setIsLoading(true);

    try {
      downloadcsv(`Relatorio_StatusLogf`, dataTable.flat()); // Utilizar flat() para converter para um array plano
      toast.success('Relatório gerado com sucesso');
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <PageTitle>Status LOG NF</PageTitle>
      <Formik
        initialValues={{
          num_nf: '0',
        }}
        onSubmit={handleGetTableValues}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='num_nf'
                  label='NF Cliente'
                  type='number'
                  variant='outlined'
                  value={props.values.num_Nf}
                  component={Text}
                  onChange={(e) => {
                    if (e.target.value < 0) {
                      props.setFieldValue('num_nf', 0);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={1}>
                <Button type='submit' fullWidth>
                  <SearchIcon />
                </Button>
              </Grid>
              <Grid item xs={12} lg={3}>
                <Button
                  fullWidth
                  color='secondary'
                  onClick={handleDownloadCsv}
                  disabled={dataTable.length <= 0 || isLoading}
                >
                  Extrair Planilha
                </Button>
              </Grid>
              <Grid item xs={12}>
                {dataTable.length > 0 && (
                  <>
                    <Table name='Table' headers={columns} maxHeight disableNumeration>
                      {dataTable[page] &&
                        dataTable[page].map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>{item.cnpj}</TableCell>
                            <TableCell>{item.Cliente}</TableCell>
                            <TableCell>{item.Processo}</TableCell>
                            <TableCell>{item.Status}</TableCell>
                            <TableCell>{item.obsStatus}</TableCell>
                            <TableCell>{item.Obs}</TableCell>
                            <TableCell>{item.dtCadastro}</TableCell>
                            <TableCell>{item.Usuario}</TableCell>
                            <TableCell>{item.Tipo}</TableCell>
                          </TableRow>
                        ))}
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component='div'
                      count={dataTable.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </>
                )}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
