import { makeStyles } from '@material-ui/core/styles';

import drawerBackgroundImg from 'assets/images/drawer-background.jpg';

export const DRAWER_WIDTH = 280;

const useStyles = makeStyles((theme) => {
  return {
    drawer: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
    paper: {
      backgroundImage: `
        linear-gradient(rgba(24, 27, 35, 0.9), rgba(24, 27, 35, 0.9)),
        url(${drawerBackgroundImg})`,
      width: DRAWER_WIDTH,
      borderRight: 0,
      fontWeight: 500,
      color: 'rgba(255, 255, 255, .9)',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    list: {
      padding: `0 ${theme.spacing(2)}px`,
      // width: 'auto',
      // position: 'relative',
      // overflow: 'auto',
    },
    gaia: {
      fontSize: 26,
      textAlign: 'center',
      fontWeight: 'bold',
      lineHeight: '64px',
      '& > span': {
        color: theme.palette.primary.light,
      },
    },

    version: {
      marginTop: 'auto',
      height: 45,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      opacity: 0.8,
      color: '#fff',
      textDecoration: 'none',
    },
  };
});

export default useStyles;
