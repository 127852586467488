import React, { useEffect, useState } from 'react';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { useMediaQuery, useTheme } from '@mui/material';
import { Button, FormatMoney, Mask, Radio, Spacer } from 'components';
import { Field, useFormikContext } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { quoteService } from 'services';

import { AccidentalBrokenDialog } from './AccidentalBrokenDialog';
import { AccidentalBrokenTheftDialog } from './AccidentalBrokenTheftDialog';
import { AllThingsDialog } from './AllThingsDialog';
import { TheftDialog } from './TheftDialog';

export const Result = () => {
  const { values, setFieldValue } = useFormikContext();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isBetweenMdAndLg = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  // const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  // const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const [openAccidentalBrokenTheftDialog, setOpenAccidentalBrokenTheftDialog] = useState(false);
  const [openAccidentalBrokenDialog, setOpenAccidentalBrokenDialog] = useState(false);
  const [openAllThingsDialog, setOpeAllThingstDialog] = useState(false);
  const [openTheftDialog, setOpenTheftDialog] = useState(false);

  const [smartphoneBrand, , loadingSmartphoneBrand] = useQuery(
    () => quoteService.getSmartphoneByBrandId(values?.smartphone_brand_id),
    [values?.smartphone_brand_id],
  );

  const [smartphoneModel, , loadingSmartphoneModel] = useQuery(
    () => quoteService.getSmartphoneByModelId(values?.smartphone_model_id),
    [values?.smartphone_model_id],
  );

  const [smartphoneUseTime, , loadingSmartphoneUseTime] = useQuery(
    () => quoteService.getSmartphoneByUseTime(values?.smartphone_use_time_id),
    [values?.smartphone_use_time_id],
  );

  console.log('smartphoneModel: ', smartphoneModel);

  const handleDialogCloseAccidentalBrokenTheft = () => {
    setOpenAccidentalBrokenTheftDialog(false);
  };

  const handleDialogOpenAccidentalBrokenTheft = () => {
    setOpenAccidentalBrokenTheftDialog(true);
  };

  const handleDialogCloseAccidentalBroken = () => {
    setOpenAccidentalBrokenDialog(false);
  };

  const handleDialogOpenAccidentalBroken = () => {
    setOpenAccidentalBrokenDialog(true);
  };

  const handleDialogClosAllThings = () => {
    setOpeAllThingstDialog(false);
  };

  const handleDialogOpenAllThings = () => {
    setOpeAllThingstDialog(true);
  };

  const handleDialogCloseTheft = () => {
    setOpenTheftDialog(false);
  };

  const handleDialogOpenTheft = () => {
    setOpenTheftDialog(true);
  };

  const formatValue = (value) => parseFloat(value).toFixed(2);

  useEffect(() => {
    switch (Number(values?.insurance_type)) {
      case 1:
        setFieldValue('insurance_value', formatValue(0.15 * smartphoneModel?.market_value));
        break;
      case 2:
        setFieldValue('insurance_value', formatValue(0.15 * smartphoneModel?.market_value));
        break;
      case 3:
        setFieldValue('insurance_value', formatValue(0.19 * smartphoneModel?.market_value));
        break;
      case 4:
        setFieldValue('insurance_value', formatValue(0.25 * smartphoneModel?.market_value));
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.insurance_type]);

  return (
    <>
      <Grid container style={{ width: '100%', padding: '10px' }} spacing={2}>
        <Grid item xs={12} lg={12}>
          <Paper
            style={{ padding: '12px', background: '#F8F8F8', height: '100%' }}
            variant='outlined'
          >
            <Typography variant='h6'>Dados do Cliente</Typography>
            <Spacer size={12} />
            <Grid container alignItems='center' spacing={2}>
              <Grid item xs={6} lg={3}>
                <Typography variant='body2'>CPF</Typography>
                <Typography variant='subtitle2'>
                  <Mask type='document'>{values?.cgc}</Mask>
                </Typography>
              </Grid>
              <Grid item xs={6} lg={3}>
                <Typography variant='body2'>Nome</Typography>
                <Typography variant='subtitle2'>{values?.name}</Typography>
              </Grid>
              <Grid item xs={6} lg={3}>
                <Typography variant='body2'>Celular</Typography>
                <Typography variant='subtitle2'>
                  <Mask type='phone'>{values?.phone_number}</Mask>
                </Typography>
              </Grid>
              <Grid item xs={6} lg={3}>
                <Typography variant='body2'>E-mail</Typography>
                <Typography variant='subtitle2'>{values?.email}</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Grid container style={{ width: '100%', padding: '10px' }} spacing={2}>
        <Grid item xs={12} lg={12}>
          <Paper variant='outlined' style={{ padding: '12px' }}>
            <Grid container alignItems='flex-start' spacing={2}>
              <Grid item xs={12} lg={12}>
                <Typography variant='subtitle2'>Aparelho (principal)</Typography>
              </Grid>
              <Grid item xs={3} md={3} lg={3}>
                <Typography variant='body2'>{smartphoneBrand?.description}</Typography>
                <Typography variant='subtitle2'>{smartphoneModel?.description}</Typography>
              </Grid>
              <Grid item xs={3} md={3} lg={3}>
                <Typography variant='body2'>Tempo de uso do aparelho</Typography>
                <Typography variant='subtitle2'>{smartphoneUseTime?.description}</Typography>
              </Grid>
              <Grid item xs={3} md={3} lg={3}>
                <Typography variant='body2'>Valor atual de mercado</Typography>
                <Typography variant='subtitle2'>
                  <FormatMoney>{smartphoneModel?.market_value}</FormatMoney>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider variant='fullWidth' />
              </Grid>
            </Grid>

            <Grid container alignItems='stretch' spacing={2}>
              <Grid item xs={12} md={3} lg={3}>
                <Paper
                  style={{
                    padding: '12px',
                    background: '#F8F8F8',
                    borderRadius: 12,
                    height: isSmallScreen ? 390 : isBetweenMdAndLg ? 390 : 250,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  variant='outlined'
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Field align='left' name='insurance_type' value={1} component={Radio} />
                    <Typography variant='h6' align='left' style={{ marginLeft: '12px' }}>
                      Quebra Acidental
                    </Typography>
                  </div>
                  <Typography variant='body1' style={{ color: '#28874B' }}>
                    por 12x <FormatMoney>{(0.15 * smartphoneModel?.market_value) / 12}</FormatMoney>{' '}
                    sem juros
                  </Typography>
                  <Typography variant='body2'>
                    ou à vista por:{' '}
                    <FormatMoney>{0.15 * smartphoneModel?.market_value}</FormatMoney>
                  </Typography>

                  <Spacer size={24} />

                  <Typography variant='body2'>. Quebra Acidental</Typography>

                  <Typography align='right' style={{ marginTop: 'auto' }}>
                    <Button
                      color='primary'
                      align='right'
                      onClick={handleDialogOpenAccidentalBroken}
                    >
                      Ver detalhes
                    </Button>
                  </Typography>
                </Paper>

                <AccidentalBrokenDialog
                  open={openAccidentalBrokenDialog}
                  onClose={handleDialogCloseAccidentalBroken}
                />
              </Grid>

              <Grid item xs={12} md={3} lg={3}>
                <Paper
                  style={{
                    padding: '12px',
                    background: '#F8F8F8',
                    borderRadius: 12,
                    height: isSmallScreen ? 390 : isBetweenMdAndLg ? 390 : 250,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  variant='outlined'
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Field align='left' name='insurance_type' value={2} component={Radio} />
                    <Typography variant='h6' align='left' style={{ marginLeft: '12px' }}>
                      Roubo
                    </Typography>
                  </div>
                  <Typography variant='body1' style={{ color: '#28874B' }}>
                    por 12x <FormatMoney>{(0.15 * smartphoneModel?.market_value) / 12}</FormatMoney>{' '}
                    sem juros
                  </Typography>
                  <Typography variant='body2'>
                    ou à vista por:{' '}
                    <FormatMoney>{0.15 * smartphoneModel?.market_value}</FormatMoney>
                  </Typography>

                  <Spacer size={24} />

                  <Typography variant='body2'>. Roubo e Furto Mediante Arrombamento</Typography>

                  <Typography align='right' style={{ marginTop: 'auto' }}>
                    <Button color='primary' align='right' onClick={handleDialogOpenTheft}>
                      Ver detalhes
                    </Button>
                  </Typography>
                </Paper>

                <TheftDialog open={openTheftDialog} onClose={handleDialogCloseTheft} />
              </Grid>

              <Grid item xs={12} md={3} lg={3}>
                <Paper
                  style={{
                    padding: '12px',
                    background: '#F8F8F8',
                    borderRadius: 12,
                    height: isSmallScreen ? 390 : isBetweenMdAndLg ? 390 : 250,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  variant='outlined'
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Field align='left' name='insurance_type' value={3} component={Radio} />
                    <Typography variant='h6' align='left' style={{ marginLeft: '12px' }}>
                      Quebra + Roubo
                    </Typography>
                  </div>
                  <Typography variant='body1' style={{ color: '#28874B' }}>
                    por 12x <FormatMoney>{(0.19 * smartphoneModel?.market_value) / 12}</FormatMoney>{' '}
                    sem juros
                  </Typography>
                  <Typography variant='body2'>
                    ou à vista por:{' '}
                    <FormatMoney>{0.19 * smartphoneModel?.market_value}</FormatMoney>
                  </Typography>

                  <Spacer size={24} />

                  <Typography variant='body2'>. Quebra Acidental</Typography>
                  <Typography variant='body2'>. Roubo e Furto Mediante Arrombamento</Typography>

                  <Typography align='right' style={{ marginTop: 'auto' }}>
                    <Button
                      color='primary'
                      align='right'
                      onClick={handleDialogOpenAccidentalBrokenTheft}
                    >
                      Ver detalhes
                    </Button>
                  </Typography>
                </Paper>

                <AccidentalBrokenTheftDialog
                  open={openAccidentalBrokenTheftDialog}
                  onClose={handleDialogCloseAccidentalBrokenTheft}
                />
              </Grid>

              <Grid item xs={12} md={3} lg={3}>
                <Paper
                  style={{
                    padding: '12px',
                    background: '#F8F8F8',
                    borderRadius: 12,
                    height: isSmallScreen ? 390 : isBetweenMdAndLg ? 390 : 250,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  variant='outlined'
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Field align='left' name='insurance_type' value={4} component={Radio} />
                    <Typography variant='h6' align='left' style={{ marginLeft: '12px' }}>
                      Quebra + Roubo + Furto Simples
                    </Typography>
                  </div>
                  <Typography variant='body1' style={{ color: '#28874B' }}>
                    por 12x <FormatMoney>{(0.25 * smartphoneModel?.market_value) / 12}</FormatMoney>{' '}
                    sem juros
                  </Typography>
                  <Typography variant='body2'>
                    ou à vista por:{' '}
                    <FormatMoney>{0.25 * smartphoneModel?.market_value}</FormatMoney>
                  </Typography>
                  <Spacer size={24} />
                  <Typography variant='body2'>. Furto Simples</Typography>
                  <Typography variant='body2'>. Quebra Acidental</Typography>
                  <Typography variant='body2'>. Roubo e Furto Mediante Arrombamento</Typography>

                  <Typography align='right' style={{ marginTop: 'auto' }}>
                    <Button color='primary' align='right' onClick={handleDialogOpenAllThings}>
                      Ver detalhes
                    </Button>
                  </Typography>
                </Paper>

                <AllThingsDialog open={openAllThingsDialog} onClose={handleDialogClosAllThings} />
              </Grid>

              <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='h6' align='right' style={{ marginTop: 'auto' }}>
                  Valor Total: <FormatMoney>{values?.insurance_value ?? 0}</FormatMoney>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
