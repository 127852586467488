import React from 'react';

import Grid from '@material-ui/core/Grid';

import { Text } from 'components';
import { Field } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { api } from 'services/api';

export const SecurityQuestion = () => {
  const refresh_token = window.location.href.split('/')[5];

  const datas = useQuery(() => api.get(`/password/reset/${refresh_token}`), []);

  const question = datas[0]?.quiz?.question;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <Field
            variant='outlined'
            name='question'
            size='small'
            value={question}
            component={Text}
            disabled
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <Field
            variant='outlined'
            size='small'
            name='answer'
            label='Resposta de segurança'
            component={Text}
          />
        </Grid>
      </Grid>
    </>
  );
};
