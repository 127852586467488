import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: 700,
  },
  button: {
    borderRadius: '50%',
    backgroundColor: 'transparent',
  },
  currentAvatar: {
    display: 'block',
    borderRadius: '50%',
    width: '100%',
    maxWidth: 125,
    margin: `0 auto ${theme.spacing(2)}px`,
  },
  smallAvatar: {
    width: '100%',
    borderRadius: '50%',
    border: `2px solid transparent`,
  },
  avatarSelected: {
    borderColor: theme.palette.primary.main,
  },
}));
