import { api } from 'services/api';
//
export class ConferenceService {
  async getDataTable() {
    return api.post(`/xpcell-ingenico-conference/conference/conference-beeping`);
  }
  async getDataTableModeloConferenceBeeping(selectedNote, selectedIdRegistro) {
    if (!selectedNote || !selectedIdRegistro) return;
    return api.get(
      `/xpcell-ingenico-conference/conference/conference-beeping-model/${selectedNote}/${selectedIdRegistro}`,
    );
  }
  async getModelBeeping(data) {
    return api.post(`/xpcell-ingenico-conference/conference/conference-model-pn`, data);
  }
  async vincModelBeeping(data) {
    return api.post(`/xpcell-ingenico-conference/conference/conference-model-vinc`, data);
  }
  async getModelSerialBeeping(data) {
    return api.post(`/xpcell-ingenico-conference/conference/conference-model-serial`, data);
  }
  async processSerialBeeping(data) {
    return api.post(`/xpcell-ingenico-conference/conference/conference-process-serial`, data);
  }
  async resetCollection(data) {
    return api.post(`/xpcell-ingenico-conference/conference/conference-reset-collection`, data);
  }
  async getFinishNF(data) {
    return api.post(`/xpcell-ingenico-conference/conference/conference-finish-nf`, data);
  }
  async getDataCSV(data) {
    return api.post('/xpcell-ingenico-conference/conference/conference-upload-csv', data);
  }
  async automaticConference(data) {
    return api.post('/xpcell-ingenico-conference/conference/automatic-conference', data);
  }
  async validationNfs(data) {
    return api.post('/xpcell-ingenico-conference/conference/validationNfs', data);
  }
  async confirmNF3xNF4(data) {
    return api.post('/xpcell-ingenico-conference/conference/confirmNF3xNF4', data);
  }
}

export const conferenceService = new ConferenceService();
