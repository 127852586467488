import { useState, useCallback } from 'react';

export const useBoolean = (defaultValue) => {
  const [state, setState] = useState(!!defaultValue);

  const toFalse = useCallback(() => setState(false), []);
  const toTrue = useCallback(() => setState(true), []);
  const toggle = useCallback(() => setState((prevState) => !prevState), []);

  return [
    state,
    {
      toFalse,
      toTrue,
      toggle,
    },
  ];
};
