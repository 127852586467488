import React from 'react';

import PropTypes from 'prop-types';

const Content = () => null;

Content.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.node.isRequired,
  icon: PropTypes.node,
  disabled: PropTypes.bool,
  disablePadding: PropTypes.bool,
};

export default React.memo(Content);
