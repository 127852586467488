import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, Text } from 'components';
import { Formik, Form, Field } from 'formik';
import { downloadXlsx } from 'helpers/downloadXLSX';
import { parseXmlToJson } from 'helpers/readXml';
import { PageTitle } from 'pages/Layout/PageTitle';
import { conferenceService } from 'services';

import { ConfirmDialog } from './dialogConfirmation';

export const Nf3xNf4 = () => {
  const fileInputRefNf3 = useRef(null);
  const fileInputRefNf4 = useRef(null);
  const [fileInputRefNf3Value, setFileInputRefNf3Value] = useState('');
  const [fileInputRefNf4Value, setFileInputRefNf4Value] = useState('');
  const [fileNf3, setFileNf3] = useState(null);
  const [fileNf4, setFileNf4] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [dataInsert, setDataInsert] = useState({});

  const handleGetNf3 = () => {
    fileInputRefNf3.current.value = ''; // Limpa a seleção anterior
    fileInputRefNf3.current.click(); // Abre o seletor de arquivos
  };

  const handleGetNf4 = () => {
    fileInputRefNf4.current.value = ''; // Limpa a seleção anterior
    fileInputRefNf4.current.click(); // Abre o seletor de arquivos
  };

  const handleFileChangeNf3 = () => {
    const file = fileInputRefNf3.current.files[0];
    if (file) {
      setFileInputRefNf3Value(file.name);
      setFileNf3(file); // Armazena o arquivo selecionado no estado
    }
  };

  const handleFileChangeNf4 = () => {
    const file = fileInputRefNf4.current.files[0];
    if (file) {
      setFileInputRefNf4Value(file.name);
      setFileNf4(file); // Armazena o arquivo selecionado no estado
    }
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
    setFileInputRefNf3Value('');
    setFileInputRefNf4Value('');
    fileInputRefNf3.current.value = '';
    fileInputRefNf4.current.value = '';
  };

  const handleSubmit = async (vales) => {
    const formData = { fileNf3: '', fileNf4: '', setXml3: '', setXml4: '' };
    toast.info('Aguarde...');
    try {
      if (fileNf3) {
        const xmlTextNf3 = await fileNf3.text();
        const nf3Object = parseXmlToJson(xmlTextNf3);
        formData.fileNf3 = nf3Object;
        formData.setXml3 = xmlTextNf3;
      }
      if (fileNf4) {
        const xmlTextNf4 = await fileNf4.text();
        const nf4Object = parseXmlToJson(xmlTextNf4);
        formData.fileNf4 = nf4Object;
        formData.setXml4 = xmlTextNf4;
      }
      const response = await conferenceService.validationNfs(formData);
      setDataInsert({ ...response.data, user: localStorage.getItem('@Gaia:user') });
      toast.success('validação feita com sucesso.');
      console.log('Resposta do servidor:', response.data);
      setIsOpenModal(true);
    } catch (error) {
      try {
        const errorData = await JSON.parse(error?.response?.data?.message);
        // Função para montar o array com base nos dados disponíveis
        const resultArray = [];

        if (errorData.items) {
          Object.entries(errorData.items).forEach(([key, value]) => {
            resultArray.push({
              error: `tag<items> <${key}>: nf3Value: ${value.nf3Value} x nf4Value: ${value.nf4Value}`,
            });
          });
        }

        // Verificando se total está presente
        if (errorData.total) {
          Object.entries(errorData.total).forEach(([key, value]) => {
            resultArray.push({
              error: `tag<total> <${key}>: nf3Value: ${value.nf3Value} x nf4Value: ${value.nf4Value}`,
            });
          });
        }

        // Verificando se transp está presente
        if (errorData.transp) {
          Object.entries(errorData.transp).forEach(([key, value]) => {
            resultArray.push({
              error: `tag<transp> <${key}>: nf3Value: ${value.nf3Value} x nf4Value: ${value.nf4Value}`,
            });
          });
        }
        toast.info('Gerando relatório de divergencia.');
        downloadXlsx('DivergenciasNF3xNF4', resultArray);
      } catch (e) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  return (
    <>
      <ConfirmDialog
        isOpenModal={isOpenModal}
        handleCloseModal={handleCloseModal}
        dataInsert={dataInsert}
      />
      <input
        type='file'
        ref={fileInputRefNf3}
        style={{ display: 'none' }}
        accept='.xml'
        onChange={handleFileChangeNf3}
      />
      <input
        type='file'
        ref={fileInputRefNf4}
        style={{ display: 'none' }}
        accept='.xml'
        onChange={handleFileChangeNf4}
      />
      <PageTitle>NF3 X NF4</PageTitle>
      <Formik
        initialValues={{
          nf3: fileInputRefNf3Value,
          nf4: fileInputRefNf4Value,
        }}
        onSubmit={handleSubmit}
      >
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} xl={6}>
              <Field
                as={Text}
                size='small'
                fullWidth
                name='nf3'
                label='NF3 XML'
                variant='outlined'
                value={fileInputRefNf3Value}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={3} xl={3}>
              <Button fullWidth onClick={handleGetNf3}>
                Upload NF3 XML
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} xl={6}>
              <Field
                as={Text}
                size='small'
                fullWidth
                name='nf4'
                label='NF4 XML'
                variant='outlined'
                value={fileInputRefNf4Value}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={3} xl={3}>
              <Button fullWidth onClick={handleGetNf4}>
                Upload NF4 XML
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3} xl={3}>
              <Button fullWidth type='submit'>
                Enviar
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </>
  );
};
