import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

// import Checkbox from '@material-ui/core/Checkbox';
// import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Grid } from '@mui/material';
import { Button, CustomAutocomplete, DatePicker, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { downloadXlsx } from 'helpers/downloadXLSX';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providerService } from 'services';
import * as Yup from 'yup';

const typeFiler = [
  { label: 'Entrada', value: 'entrada' },
  { label: 'Saida', value: 'saida' },
];

const typeDt = [
  { label: 'Dt. Recebimento', value: 'dtRecebimento' },
  { label: 'Dt. Abertura', value: 'dtAbertura' },
];

const typeDtSaida = [
  { label: 'Dt. NF Center Cell', value: 'dtNFCenterCell' },
  { label: 'Dt. Solicitação NF Ingenico', value: 'dtSolicitacaoNFIngenico' },
];

const validationSchema = Yup.object({
  select_client: Yup.object().nullable().required('Cliente é obrigatório'),
  select_filter: Yup.string().nullable().required('Tipo é obrigatório'),
  select_dt: Yup.string()
    .nullable()
    .when('select_filter', {
      is: 'entrada', // Condição para validar select_dt
      then: Yup.string().nullable().required('Tipo Data Entrada é obrigatório'), // Corrigido para Yup.string()
      otherwise: Yup.string().nullable(), // Sempre Yup.string(), mesmo quando não é obrigatório
    }),
  select_dt_saida: Yup.string()
    .nullable()
    .when('select_filter', {
      is: 'saida', // Condição para validar select_dt_saida
      then: Yup.string().nullable().required('Tipo Data Saida é obrigatório'), // Corrigido para Yup.string()
      otherwise: Yup.string().nullable(), // Sempre Yup.string(), mesmo quando não é obrigatório
    }),
});

export const InOutExpedition = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isListClient, setIsListClient] = useState([{ label: '', value: '' }]);

  const handleClient = useCallback(async () => {
    try {
      const { data } = await providerService.getListClient();
      const allClient = [{ label: 'TODOS', value: 'todos' }, ...data];
      setIsListClient(allClient);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }, []);

  useEffect(() => {
    handleClient();
  }, [handleClient]);

  const handleSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    try {
      const { data } = await providerService.getInOutExpedition({
        select_filter: values.select_filter,
        select_dt: values.select_dt,
        select_dt_saida: values.select_dt_saida,
        selectClient: values.select_client,
        input_data: values.input_data,
        output_data: values.output_data,
      });

      if (data.length > 0) {
        downloadXlsx('ingenico_IN_OUT', data);
        toast.success('Relatório gerado com sucesso');
      } else {
        toast.error('Não há dados para esse relatório.');
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
      resetForm();
    }
  };

  return (
    <>
      <PageTitle>Relatório IN OUT (expedição)</PageTitle>

      <Formik
        initialValues={{
          select_filter: null,
          select_dt: null,
          select_dt_saida: null,
          input_data: new Date(),
          output_data: new Date(),
          select_client: null, // Certifique-se de inicializar corretamente
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ values, errors, touched, resetForm }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={3} md={3} lg={2}>
                <Field
                  size='small'
                  loading={isLoading}
                  name='select_filter'
                  variant='outlined'
                  fullWidth
                  inputVariant='outlined'
                  label='Tipo'
                  options={typeFiler}
                  component={Select}
                />
              </Grid>
              {values?.select_filter === 'entrada' && (
                <>
                  <Grid item xs={3} md={3} lg={2}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='select_dt'
                      variant='outlined'
                      fullWidth
                      inputVariant='outlined'
                      label='Tipo Data Entrada'
                      options={typeDt}
                      component={Select}
                    />
                  </Grid>
                </>
              )}
              {values?.select_filter === 'saida' && (
                <>
                  <Grid item xs={3} md={3} lg={2}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='select_dt_saida'
                      variant='outlined'
                      fullWidth
                      inputVariant='outlined'
                      label='Tipo Data Saida'
                      options={typeDtSaida}
                      component={Select}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={3} md={3} lg={2}>
                <Field
                  name='select_client'
                  label='Cliente'
                  filteredValues={isListClient}
                  disabled={isLoading}
                  component={CustomAutocomplete}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='input_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data inicial'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='output_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data final'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={3} md={3} lg={3}>
                <Button fullWidth type='submit' loading={isLoading}>
                  Exportar
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
