import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Grid } from '@mui/material';
import { Button, DatePicker } from 'components';
import { Field, Form, Formik } from 'formik';
import { downloadcsv } from 'helpers/downloadcsv';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providerService } from 'services';

export const ExpeditionReport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isExpedidoSelected, setIsExpedidoSelected] = useState(false);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    if (name === 'Expedido Ingenico') {
      setIsExpedidoSelected(checked);
      if (checked) {
        setSelectedOptions([name]);
      } else {
        setSelectedOptions((prev) => prev.filter((option) => option !== name));
      }
    } else {
      setSelectedOptions((prev) =>
        checked ? [...prev, name] : prev.filter((option) => option !== name),
      );
    }
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      console.log({ selectedOptions });
      const { data } = await providerService.getExpeditionReport({
        selectedOptions: selectedOptions,
        input_data: values.input_data,
        output_data: values.output_data,
      });
      if (data.length > 0) {
        downloadcsv('ingenico_expedicao', data);
        toast.success('Relatório gerado com sucesso');
      } else {
        toast.error('Não há dados para esse relatório.');
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Relatório Expedição</PageTitle>

      <Formik
        initialValues={{ input_data: new Date(), output_data: new Date() }}
        onSubmit={handleSubmit}
      >
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={3} md={3} lg={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='1'
                    onChange={handleCheckboxChange}
                    color='primary'
                    disabled={isExpedidoSelected}
                    checked={selectedOptions.includes('1')}
                  />
                }
                label='Aguardando NF Ingenico'
              />
            </Grid>
            <Grid item xs={3} md={3} lg={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='2'
                    onChange={handleCheckboxChange}
                    color='primary'
                    disabled={isExpedidoSelected}
                    checked={selectedOptions.includes('2')}
                  />
                }
                label='Aguardando coleta'
              />
            </Grid>
            <Grid item xs={3} md={3} lg={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='3'
                    onChange={handleCheckboxChange}
                    color='primary'
                    disabled={isExpedidoSelected}
                    checked={selectedOptions.includes('3')}
                  />
                }
                label='Aguardando site operador'
              />
            </Grid>
            <Grid item xs={3} md={3} lg={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='Expedido Ingenico'
                    onChange={handleCheckboxChange}
                    color='primary'
                    checked={isExpedidoSelected}
                  />
                }
                label='Expedido Ingenico'
              />
            </Grid>

            {selectedOptions.includes('Expedido Ingenico') && (
              <>
                <Grid item xs={12} lg={2}>
                  <Field
                    size='small'
                    disabled={isLoading}
                    name='input_data'
                    variant='inline'
                    fullWidth
                    inputVariant='outlined'
                    label='Data inicial'
                    component={DatePicker}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Field
                    size='small'
                    disabled={isLoading}
                    name='output_data'
                    variant='inline'
                    fullWidth
                    inputVariant='outlined'
                    label='Data final'
                    component={DatePicker}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3} md={3} lg={3}>
              <Button fullWidth type='submit' loading={isLoading}>
                Exportar
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </>
  );
};
