import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    background: '#00',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    marginBottom: theme.spacing(4),
    textAlign: 'center',

    [theme.breakpoints.down('xs')]: {
      fontSize: 28,
    },
  },
  image: {
    display: 'block',
    maxWidth: 600,
    width: '100%',
    margin: `${theme.spacing(2)}px auto`,
  },
  backToHome: {
    fontSize: 20,
    color: '#000',
    marginTop: theme.spacing(4),
    textDecoration: 'underline',
  },
}));
