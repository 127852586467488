import React from 'react';

import DialogActions from '@material-ui/core/DialogActions';

import { useStyles } from './styles';

export const Actions = ({ children, classes }) => {
  const styles = useStyles();

  return (
    <DialogActions className={styles.root} classes={classes}>
      {children}
    </DialogActions>
  );
};
