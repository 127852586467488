import React from 'react';
import { useRouteMatch } from 'react-router';

import { BlacklistQuery } from 'pages/Reports/BlacklistQuery';
import { DoaBounceReport } from 'pages/Reports/DoaBounceReport';
import { OSPartReleaseControlReport } from 'pages/Reports/OSPartReleaseControlReport';
import { RefuseUploadReport } from 'pages/Reports/RefuseUploadReport';
import { ReportFreezingPendin } from 'pages/Reports/ReportFreezingPendin';
import { validateInfoRecuse } from 'pages/Reports/validateInfoRecuse';
import { WhereUsedReport } from 'pages/Reports/whereUsedReport';

import { Route } from '../Route';

export const ReportEngineeringRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${path}/blacklist-query`} component={BlacklistQuery} />
      <Route isPrivate path={`${path}/doaBounce-Report`} component={DoaBounceReport} />
      <Route
        isPrivate
        path={`${path}/oS-Part-Release-Control-Report`}
        component={OSPartReleaseControlReport}
      />
      <Route isPrivate path={`${path}/report-freezing-pendin`} component={ReportFreezingPendin} />
      <Route isPrivate path={`${path}/validate-info-recuse`} component={validateInfoRecuse} />
      <Route isPrivate path={`${path}/where-used`} component={WhereUsedReport} />
      <Route isPrivate path={`${path}/refuse-upload`} component={RefuseUploadReport} />
    </>
  );
};
