import React, { useState } from 'react';
import { toast } from 'react-toastify';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { Button, DatePicker, Table } from 'components';
import { Field, Form, Formik } from 'formik';
import { downloadXlsx } from 'helpers/downloadXLSX';
import { PageTitle } from 'pages/Layout/PageTitle';

import { laboratoryService } from '../../../../services/laboratory';
import { DialogAtualizar } from './Dialog/DialogAtualizar';
import { DialogCadastro } from './Dialog/DialogCadastro';

const columns = [
  { label: 'Área Afetada' },
  { label: 'Tipo Parada' },
  { label: 'Resp. Preenchimento' },
  { label: 'Problemas/Necessidades' },
  { label: 'DownTime Inicial' },
  { label: 'DownTime Final' },
  { label: 'Tempo de Parada' },
  { label: 'Status' },
  { label: 'Obs' },
  { label: 'Área Responável' },
  { label: 'Ação' },
  { label: 'Responável' },
  { label: 'Atualizar' },
];

const convertMinutesToHours = (minutos) => {
  const horas = Math.floor(minutos / 60);
  const remainingMinutes = minutos % 60;
  return `${horas}h ${remainingMinutes}min`;
};

export const Downtime = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [openDialogCadastro, setOpenDialogCadastro] = useState(false);
  const [openDialogAtualizar, setOpenDialogAtualizar] = useState(false);
  const [info, setInfo] = useState([]);

  const handleReport = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await laboratoryService.getDowntimeReport({
        input_data: values.input_data,
        output_data: values.output_data,
      });
      if (data.length > 0) {
        downloadXlsx('ingenico_downtime', data);
        toast.success('Relatório gerado com sucesso');
      } else {
        toast.error('Não há dados para esse relatório.');
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTable = async (props) => {
    setIsLoading(true);
    try {
      const { data } = await laboratoryService.getDowntimeReport({
        input_data: props.values.input_data,
        output_data: props.values.output_data,
      });
      if (Array.isArray(data)) {
        setInfo(data);
        console.log('tabela', data);
      } else {
        setInfo([]);
      }
      if (data.length <= 0) {
        toast.error('Não há dados!');
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenDialogCadastro = () => {
    setOpenDialogCadastro(true);
  };

  const handleCloseDialogCadastro = () => {
    setOpenDialogCadastro(false);
  };

  const handleOpenDialogAtualizar = (item) => {
    setInfo(item);
    setOpenDialogAtualizar(true);
  };

  const handleCloseDialogAtualizar = () => {
    setOpenDialogAtualizar(false);
  };

  return (
    <>
      <DialogCadastro
        handleCloseDialogCadastro={handleCloseDialogCadastro}
        openDialogCadastro={openDialogCadastro}
      />
      <DialogAtualizar
        handleCloseDialogAtualizar={handleCloseDialogAtualizar}
        openDialogAtualizar={openDialogAtualizar}
        info={info}
      />
      <PageTitle>Controle Downtime</PageTitle>

      <Formik
        initialValues={{
          input_data: new Date(),
          output_data: new Date(),
        }}
        onSubmit={handleReport}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='input_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data inicial'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='output_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data final'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <Button fullWidth type='submit' loading={isLoading}>
                  Exportar
                </Button>
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <Button fullWidth onClick={handleOpenDialogCadastro} loading={isLoading}>
                  Cadastrar
                </Button>
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <Button fullWidth onClick={() => handleTable(props)} loading={isLoading}>
                  Atualizar
                </Button>
              </Grid>
            </Grid>
            <Box mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12} md={12}>
                  <Table name='Table' headers={columns} maxHeight disableNumeration>
                    {Array.isArray(info) && info.length > 0 ? (
                      info.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{item.area_afetada}</TableCell>
                          <TableCell>{item.tipo_parada}</TableCell>
                          <TableCell>{item.responsavel}</TableCell>
                          <TableCell>{item.problemas}</TableCell>
                          <TableCell>{item.downtime_inicial}</TableCell>
                          <TableCell>{item.downtime_final}</TableCell>
                          <TableCell>{convertMinutesToHours(item.minutos)}</TableCell>
                          <TableCell>{item.status}</TableCell>
                          <TableCell>{item.obs}</TableCell>
                          <TableCell>{item.area_responsavel}</TableCell>
                          <TableCell>{item.acao}</TableCell>
                          <TableCell>{item.responsavel_resuolucao}</TableCell>
                          <TableCell>
                            <Button
                              fullWidth
                              onClick={() => handleOpenDialogAtualizar(item)}
                              loading={isLoading}
                            >
                              Editar
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={columns.length} align='center'>
                          Não há dados para mostrar.
                        </TableCell>
                      </TableRow>
                    )}
                  </Table>
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
