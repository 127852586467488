import React from 'react';

import { Grid } from '@mui/material';
import { Button, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';

export const LabelReading = ({ orderServ }) => {
  const handleSubmite = (value) => {
    console.log(value);
  };

  return (
    <>
      <PageTitle>Terminal</PageTitle>
      <Formik initialValues={{ orderServ: orderServ }} onSubmit={handleSubmite}>
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={2}>
                <Field
                  size='small'
                  name='orderServ'
                  label='Ordem de Serviço'
                  variant='outlined'
                  component={Text}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6} lg={2}>
                <Button onClick={() => handleSubmite()} fullWidth>
                  Pesquisar
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
