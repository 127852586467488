import React from 'react';
import { useRouteMatch } from 'react-router';

import { AgingFreezingReport } from 'pages/Reports/AgingFreezingReport';
import { BipingConferenceReport } from 'pages/Reports/BipingConferenceReport';
import { ConferenceProduction } from 'pages/Reports/ConferenceProduction';
import { OpenOsReport } from 'pages/Reports/OpenOsReport';

import { Route } from '../Route';

export const ReportConferenceRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${path}/open-os-report`} component={OpenOsReport} />
      <Route isPrivate path={`${path}/aging-freezing-report`} component={AgingFreezingReport} />
      <Route isPrivate path={`${path}/conference-production`} component={ConferenceProduction} />
      <Route
        isPrivate
        path={`${path}/biping-conference-report`}
        component={BipingConferenceReport}
      />
    </>
  );
};
