import React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Table, Text } from 'components';
import { Formik, Field, Form } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { screeningService } from 'services/screening';

import { DialogCancellationLaudo } from './Dialog/DialogCancellationLaudo';

const columns = [
  { label: 'Numero OS' },
  { label: 'Data Laudo' },
  { label: 'User Laudo' },
  { label: 'Destino' },
  { label: 'Serial' },
  { label: 'PartNumber' },
  { label: 'Modelo' },
  { label: 'Tipo Reparo' },
  { label: 'Cancelar Laudo' },
];

export const CancellationLaudo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [openDialog, setDialog] = useState(false);
  const [infoOs, setInfoOs] = useState([]);

  const handleDowloadTable = async (values) => {
    setIsLoading(true);

    try {
      const { data } = await screeningService.getOsLaudo({
        input_os: values.input_os,
      });

      if (data.status === 404 || data.status === 400) {
        toast.error(data.msg);
      } else {
        toast.success(data.msg);
        setTableData(data.laudo);
      }
    } catch (error) {
      console.error('Erro ao gerar a tabela: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenDialog = (item) => {
    setDialog(true);
    setInfoOs(item);
  };

  const handleCloseDialog = () => {
    setTableData([]);
    setDialog(false);
  };

  return (
    <>
      <DialogCancellationLaudo
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        infoOs={infoOs}
      />
      <PageTitle>Cancelamento de Laudo Triagem</PageTitle>

      <Formik initialValues={{ input_os: ' ' }} onSubmit={handleDowloadTable}>
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={2}>
              <Field
                size='small'
                name='input_os'
                label='Ordem de Serviço'
                variant='outlined'
                component={Text}
              />
            </Grid>
            <Grid item xs={2} lg={2}>
              <Button type='submit' fullWidth loading={isLoading}>
                Pesquisar
              </Button>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12} md={12}>
                <Table name='Table' headers={columns} maxHeight disableNumeration>
                  {tableData.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.numeroOs}</TableCell>
                      <TableCell>{item.dtLaudo}</TableCell>
                      <TableCell>{item.userLaudo}</TableCell>
                      <TableCell>{item.destino}</TableCell>
                      <TableCell>{item.serial}</TableCell>
                      <TableCell>{item.PartNumber}</TableCell>
                      <TableCell>{item.modelo}</TableCell>
                      <TableCell>{item.tipoReparo}</TableCell>
                      <TableCell>
                        <DeleteIcon
                          style={{ color: '#f50057', cursor: 'pointer' }}
                          onClick={() => handleOpenDialog(item)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </>
  );
};
