import React from 'react';
import { useRouteMatch } from 'react-router';

import { CancellationLaudo } from 'pages/Private/Screening/CancellationLaudo';
import { Clear } from 'pages/Private/Screening/Clear';
import { CosmeticScreening } from 'pages/Private/Screening/CosmeticScreening';
import { DataRecovery } from 'pages/Private/Screening/DataRecovery';

import { Route } from '../Route';

export const ScreeningRoutes = () => {
  const { url } = useRouteMatch();
  return (
    <>
      <Route isPrivate path={`${url}/screening-clear`} component={Clear} />
      <Route isPrivate path={`${url}/cancellation-laudo`} component={CancellationLaudo} />
      <Route isPrivate path={`${url}/cosmetic-screening`} component={CosmeticScreening} />
      <Route isPrivate path={`${url}/screening-data-recovery`} component={DataRecovery} />
    </>
  );
};
