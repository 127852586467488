import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  footer: {
    marginTop: 'auto',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  emailGen: {
    display: 'flex',
    justifyContent: 'left',
  },
}));
