import React, { useState, useEffect, useCallback } from 'react';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import AddIcon from '@mui/icons-material/Add';
import Pagination from '@mui/material/Pagination';
import { Text, Button, Table } from 'components';
import { Dialog } from 'components';
import { Field, Form, Formik } from 'formik';

import { pcpService } from '../../../../../services/pcp';

const columns = [
  { label: 'Técnico' },
  { label: 'Nome' },
  { label: 'Turno' },
  { label: 'QtdKit' },
  { label: 'Marca' },
  { label: '' },
];

export const DialogConsultTec = ({ openConsultTec, handleCloseConsultTec, getNumTec }) => {
  if (!openConsultTec) return null;

  const [dataTable, setDataTable] = useState([]);
  const [filteredTec, setFilteredTec] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  const handleFilter = (searchValue) => {
    const lowercasedFilter = searchValue.toLowerCase();
    const filteredData = dataTable.filter((item) => {
      return Object.keys(item).some((key) =>
        item[key]?.toString().toLowerCase().includes(lowercasedFilter),
      );
    });
    setFilteredTec(filteredData);
  };

  const getDados = useCallback(async () => {
    try {
      const { data } = await pcpService.getTableTecs();
      setDataTable(data.data);
      setFilteredTec(data.data);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    }
  }, []);

  useEffect(() => {
    if (openConsultTec) {
      getDados();
    }
  }, [openConsultTec, getDados]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  // Get current rows
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredTec.slice(indexOfFirstRow, indexOfLastRow);

  return (
    <>
      <Dialog maxWidth='md' open={openConsultTec}>
        <Dialog.Title onClose={handleCloseConsultTec}>Consultar Técnico</Dialog.Title>
        <Dialog.Content>
          <Formik initialValues={{ search: '' }} onSubmit={() => {}}>
            {(props) => {
              useEffect(() => {
                handleFilter(props.values.search);
                setCurrentPage(1); // Reset to first page on search
              }, [props.values.search]);

              return (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        variant='outlined'
                        name='search'
                        label='Pesquisar'
                        size='small'
                        component={Text}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Table name='table' headers={columns} disableNumeration>
                        {currentRows.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>{item.TECNICO}</TableCell>
                            <TableCell>{item.NOME}</TableCell>
                            <TableCell>{item.TURNO}</TableCell>
                            <TableCell>{item.Qtd}</TableCell>
                            <TableCell>{item.marca}</TableCell>
                            <TableCell>
                              <Grid item xs={3} lg={3}>
                                <Button onClick={() => getNumTec(item.TECNICO)}>
                                  <AddIcon />
                                </Button>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        ))}
                      </Table>
                    </Grid>
                    <Grid item xs={12}>
                      <Pagination
                        count={Math.ceil(filteredTec.length / rowsPerPage)}
                        page={currentPage}
                        onChange={handleChangePage}
                      />
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
