import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button } from 'components';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providerService } from 'services/providers';

import { downloadcsv } from '../../../helpers/downloadcsv';

export const AgingFreezingReport = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadAgingFreezingReport = async () => {
    setIsLoading(true);
    toast.info('Aguardando relatório');

    try {
      const { data } = await providerService.getAgingFreezingReport();

      await downloadcsv(`ingenico_congelamento_aging`, data);
      console.log(data);
      toast.success('Relatório Gerado com sucesso');
    } catch (error) {
      console.error(error);
      toast.error('Falha ao gerar relatório');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Aging Congelamento</PageTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <Button fullWidth onClick={handleDownloadAgingFreezingReport} loading={isLoading}>
            exportar relatório
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
