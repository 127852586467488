import React from 'react';

import Typography from '@material-ui/core/Typography';

import { Button, Dialog, Spacer } from 'components';

export const AccidentalBrokenDialog = ({ open, onClose }) => {
  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth='sm'>
        <Dialog.Title onClose={onClose}>Ver Detalhes</Dialog.Title>
        <Dialog.Content>
          <Typography variant='h6'>Quebra Acidental</Typography>
          <Spacer size={24} />
          <Typography variant='subtitle2'>
            Quebra por queda acidental:
            <Typography variant='body2'>
              Imagine que você tropeçou e o equipamento caiu da sua mão, isso é um exemplo de quebra
              acidental.
            </Typography>
            <Spacer size={12} />
          </Typography>
          <Typography variant='subtitle2'>
            Derramamento de líquidos ou oxidação:
            <Typography variant='body2'>
              Por um acidente, você caiu na piscina e o smartphone estava junto, neste exemplo pode
              acionar esse plano.
            </Typography>
            <Spacer size={12} />
          </Typography>

          <Typography variant='subtitle2'>
            Danos elétricos durante a carga
            <Typography variant='body2'>
              Enquanto o equipamento estava sendo recarregado, houve oscilação de energia e causou o
              dano. Nesse exemplo, seu equipamento tem amparo.
            </Typography>
            <Spacer size={12} />
          </Typography>

          <Typography variant='subtitle2'>
            Garantia Internacional
            <Typography variant='body2'>
              Viaje com tranquilidade, pois esse benefício estende as coberturas do plano contratado
              para qualquer lugar do mundo.
            </Typography>
            <Spacer size={12} />
          </Typography>
        </Dialog.Content>
        <Dialog.Actions>
          <Button onClick={onClose} variant='text' color='secondary'>
            Fechar
          </Button>
        </Dialog.Actions>
      </Dialog>
    </>
  );
};
