import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog } from 'components';
import { AuthContext } from 'hooks/useAuth';
import { useContextSelector } from 'use-context-selector';

import { laboratoryService } from '../../../../../services/laboratory';

export const DialogConfirm = ({ handleCloseDialog, openDialog, row }) => {
  const [isLoading, setIsLoading] = useState(false);
  const user = useContextSelector(AuthContext, (context) => context.user);
  const processLotsCustomization = async () => {
    setIsLoading(true);
    const keys = Object.keys(row);
    let newArray = [];
    for (let i = 0; i < keys.length; i++) {
      newArray.push(row[keys[i]]);
    }

    try {
      const { data } = await laboratoryService.processLotsCustomization({
        values: newArray,
        user: user,
      });
      if (data.status === '400') {
        data.listOfErrors.forEach((error) => {
          toast.error(`OS ${error.OS}: ${error.msg}`);
        });
      } else if (data.status === '200') {
        toast.success('Alterações realizadas com sucesso!');
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setIsLoading(false);
      await handleCloseDialog();
      window.location.reload();
    }
  };

  return (
    <>
      <Dialog open={openDialog}>
        <Dialog.Title onClose={handleCloseDialog}>Confirmação</Dialog.Title>
        <Typography align='center' variant='h6' style={{ paddingBottom: '2rem' }}>
          Você deseja realmente salvar as alterações?
        </Typography>
        <Dialog.Content>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Button fullWidth color='secondary' onClick={handleCloseDialog}>
                Não
              </Button>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Button
                fullWidth
                color='primary'
                loading={isLoading}
                onClick={() => processLotsCustomization()}
              >
                Sim
              </Button>
            </Grid>
          </Grid>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
