import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button } from 'components';
import { downloadXLSXSheets } from 'helpers/downloadXLSXSheets';
import { readCsv } from 'helpers/readCSV';
import { PageTitle } from 'pages/Layout/PageTitle';
import { launchPrivider } from 'services';

export const LimiteRegistre = () => {
  const [isLoading, setIsLoading] = useState(false);
  const inputFileRef = useRef(null);

  const handleFileChange = async (event) => {
    setIsLoading(true);
    toast.info('Aguarde até os dados serem importados');
    try {
      const data_csv = await readCsv(event);
      await launchPrivider.limitRegistreImport(data_csv);
      toast.success('Dados importado com sucesso');
    } catch (error) {
      toast.error('não foi possivel importar dados');
    } finally {
      setIsLoading(false);
    }
  };

  const handleImport = () => {
    inputFileRef.current.value = '';
    inputFileRef.current.click();
  };

  const handleDownloadLayout = async () => {
    setIsLoading(true);
    toast.info('Aguarde ...');
    try {
      const { data } = await launchPrivider.limiteRegistreExport();
      toast.success('Montando xlsx ...');
      downloadXLSXSheets('ingenico_cadastro_teto', data);
    } catch (error) {
      toast.error('Não foi possivel pegar dados.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <input
        ref={inputFileRef}
        type='file'
        name='csv'
        onChange={handleFileChange}
        accept='text/csv'
        hidden
      />

      <PageTitle>Cadastrar TETO</PageTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} xl={3}>
          <Button fullWidth disabled={isLoading} onClick={handleImport}>
            importar
          </Button>
        </Grid>
        <Grid item xs={12} md={3} xl={3}>
          <Button fullWidth disabled={isLoading} onClick={handleDownloadLayout}>
            baixar layout
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
