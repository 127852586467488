import React from 'react';

import { Tabs } from 'components';

import { Base } from './Base/index';
import { Detailed } from './Detailed/index';
import { IngFat } from './IngFat/index';

const TabsCong = ({ baseFormData, ingFatFormData, detailedFormData }) => {
  return (
    <Tabs gutterBottom>
      <Tabs.Content label='Base' disablePadding>
        <Base baseFormData={baseFormData} />
      </Tabs.Content>
      <Tabs.Content label='Detalhado' disablePadding>
        <Detailed detailedFormData={detailedFormData} />
      </Tabs.Content>
      <Tabs.Content label='IngFat' disablePadding>
        <IngFat ingFatFormData={ingFatFormData} />
      </Tabs.Content>
    </Tabs>
  );
};

export { TabsCong };
