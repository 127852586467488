import * as XLSX from 'xlsx';

export const readXlsxFile = async (file) => {
  try {
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data, { type: 'array' });
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
    const jsonData = XLSX.utils.sheet_to_json(sheet);

    return jsonData; // Retorna os dados como um array de objetos JSON
  } catch (error) {
    console.error('Erro ao ler o arquivo XLSX:', error);
    throw error; // Lança o erro para que o componente possa tratá-lo
  }
};
