import { api } from 'services/api';

export class LaboratoryService {
  async getLotsCustomization() {
    return api.get('/laboratory/lots-customization');
  }

  async getLotsCustomizationReport() {
    return api.get('/laboratory/lots-customization-report');
  }

  async processLotsCustomization(data) {
    return api.post('/laboratory/process-lots', data);
  }

  async getDowntimeReport(data) {
    return api.post('/laboratory/getDowntimeReport', data);
  }

  async processDowntime(data) {
    return api.post('/laboratory/processDowntime', data);
  }

  async updateDowntime(data) {
    return api.post('/laboratory/updateDowntime', data);
  }
}

export const laboratoryService = new LaboratoryService();
