import React from 'react';

import Typography from '@material-ui/core/Typography';

import { Grid } from '@mui/material';
import { Button, Dialog } from 'components';

export const DialogInfoErros = ({
  openDialogInfoErros,
  handleCloseDialogInfoErros,
  arrayErros,
}) => {
  if (!openDialogInfoErros) return null;
  console.log(arrayErros);
  return (
    <>
      <Dialog maxWidth={'md'} open={openDialogInfoErros}>
        <Dialog.Title onClose={handleCloseDialogInfoErros}>Alerta - Ação Parcial</Dialog.Title>
        <Dialog.Content>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography
                variant='h1'
                style={{ fontSize: '25px', fontWeight: 'bold', textAlign: 'center' }}
              >
                {arrayErros}
              </Typography>
            </Grid>
          </Grid>
        </Dialog.Content>
        <Dialog.Actions>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Button onClick={handleCloseDialogInfoErros} fullWidth>
              Entendi
            </Button>
          </Grid>
        </Dialog.Actions>
      </Dialog>
    </>
  );
};
