import { useEffect, useState, useCallback } from 'react';

export const useQuery = (apiCb, dependenciesList) => {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cb = useCallback(apiCb, dependenciesList);

  const fetchData = useCallback(async () => {
    setData();
    setError();
    setLoading(true);

    try {
      const { data } = await cb();

      setData(data);
    } catch (err) {
      console.log(err);
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [cb]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [data, error, loading, fetchData];
};
