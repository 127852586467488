import React from 'react';
import { Route as ReactRoute, Redirect } from 'react-router-dom';

import { AuthContext } from 'hooks/useAuth';
import { NotFound } from 'pages/Private/NotFound';
import { useContextSelector } from 'use-context-selector';

const Route = ({ isPrivate = false, component: Component, roles = [], ...rest }) => {
  const isSignedIn = useContextSelector(AuthContext, (context) => context.isAuthenticated);
  const userRole = useContextSelector(AuthContext, (context) => context.user?.role?.alias);

  const rolesThatAccess = ['admin', ...roles];

  const userRolesMatch = roles.length ? rolesThatAccess.includes(userRole) : true;
  const userCanAccess = isPrivate === isSignedIn;

  return (
    <ReactRoute
      {...rest}
      render={({ location }) => {
        if (userCanAccess && userRolesMatch) {
          return <Component />;
        }

        if (userCanAccess && !userRolesMatch) {
          return <NotFound />;
        }

        return (
          <Redirect
            to={{
              pathname: isPrivate ? '/' : '/home',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export { Route };
