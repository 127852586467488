import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Text } from 'components';
import { Formik, Form, Field } from 'formik';

export const ShowQrCode = ({
  dataMain,
  selectedNote,
  validationSerial,
  handleProcess,
  validationPn,
  user,
}) => {
  const [processing, setProcessing] = useState(false);
  let count = 0;

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const handleSubmit = async (values, setFieldValue, resetForm) => {
    console.log(values);
    if (!processing) {
      setProcessing(true);
      if (values.qrcode === '') {
        toast.error('Não foi bipado nenhum qrcode');
        return;
      }
      const qrCodeData = values.qrcode.split('\n');
      const partNumber = qrCodeData[0];
      const datapn = await validationPn(partNumber, dataMain?.[0]?.idCabec);
      if (!datapn) {
        toast.error('Partnumber não encontrado ou invalido');
        resetForm();
        setProcessing(false);
        return;
      }
      const ClienteOPV = selectedNote?.ClienteOPV.substring(0, 4).trim();
      let serial;
      if (qrCodeData[1].length === 24) {
        serial = qrCodeData[1].substring(4);
      } else {
        serial = qrCodeData[1];
      }
      const dataserial = await validationSerial(
        dataMain?.[0]?.idCabec,
        ClienteOPV,
        serial,
        qrCodeData[1].substring(4),
      );
      if (!dataserial) {
        toast.error('Serial não encontrado ou invalido');
        resetForm();
        setProcessing(false);
        return;
      }
      setProcessing(false);
      const payload = {
        idCabec: dataMain?.[0]?.idCabec,
        idProdModelo: datapn?.[0]?.idProdModelo || dataMain?.[0]?.idProdModelo,
        idModelo: datapn?.[0]?.idModelo || dataMain?.[0]?.idModelo,
        serial: serial,
        usuario: user.name,
        faltaPeca: values.faltaPeca,
        IdStatusReincidenciaGaia: dataserial.response[0].IdStatusReincidencia,
        imei: '',
        mac: '',
        ativo: '',
        patrimonio: '',
      };
      handleProcess(payload);
      resetForm();
    }
  };
  return (
    <Formik initialValues={{ qrcode: '', faltaPeca: false }} onSubmit={handleSubmit}>
      {({ setFieldValue, resetForm, values }) => (
        <Form>
          <Box mt={5}>
            <Grid container spacing={2} justifyContent='center' alignItems='center'>
              <label style={{ fontSize: '18px' }}>
                <Field type='checkbox' name='faltaPeca' style={{ width: '20px', height: '20px' }} />
                Falta de Peças
              </label>
            </Grid>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12} md={12}>
              <Typography variant='h6' style={{ paddingTop: '2rem' }}>
                Coleta de Dados
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
              <Field
                size='small'
                name='qrcode'
                label='QR Code'
                variant='outlined'
                component={Text}
                multiline
                minRows={8}
                disabled={dataMain?.[0]?.Processados === dataMain?.[0]?.qtdNf}
                onKeyDown={async (e) => {
                  if (e.key === 'Enter') {
                    count += 1;
                    if (count === 4) {
                      count = 0;
                      await sleep(1000);
                      document.querySelector('[name="btn_processar_qrcode"]').click();
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4} md={4} sm={4}>
              <Button fullWidth name='btn_processar' disabled={true}>
                processar
              </Button>
            </Grid>
            <Grid item xs={12} lg={3} md={3} sm={4}>
              <Button
                fullWidth
                name='btn_processar_qrcode'
                onClick={() => handleSubmit(values, setFieldValue, resetForm)}
                style={{ display: 'none' }} //invisivel
              >
                processar invisivel
              </Button>
            </Grid>
            <Grid item xs={12} lg={3} md={3} sm={4}>
              <Button
                fullWidth
                name='btn_clear_qrcode'
                disabled={false}
                onClick={() => {
                  resetForm();
                }}
                style={{ display: 'none' }} //invisivel
              >
                clear
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
