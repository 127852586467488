import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, DatePicker, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { downloadXlsx } from 'helpers/downloadXLSX';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providerService } from 'services/providers';

export const OpenOsReport = () => {
  const [inputData, setInputData] = useState(new Date());
  const [outputData, setOutputData] = useState(new Date());
  const [Type, setType] = useState('0');
  const [isLoading, setIsLoading] = useState(0);

  const handleExportOpenOsReport = async () => {
    toast.info('Aguarde, relatório sendo gerado.');
    setIsLoading(1);
    const formattedInputDate = inputData.toLocaleDateString().replace('/', '-').replace('/', '-');
    const formattedOutputDate = outputData.toLocaleDateString().replace('/', '-').replace('/', '-');
    try {
      const { data } = await providerService.getOpenOsReport(
        formattedInputDate,
        formattedOutputDate,
        Type,
      );

      downloadXlsx('ingenico_abertura_os', data);
      toast.success('Relatório gerado com sucesso');
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    } finally {
      setIsLoading(0);
    }
  };

  const selectType = [
    {
      value: '0',
      label: 'Resumido',
    },
    {
      value: '1',
      label: 'Detalhado',
    },
  ];

  return (
    <>
      <PageTitle>Relatório de abertura de OS</PageTitle>
      <Formik
        initialValues={{
          input_data: inputData,
          output_data: outputData,
          type: Type,
        }}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='type'
                  label='Tipo'
                  variant='outlined'
                  options={selectType}
                  component={Select}
                  disabled={isLoading === 1}
                  // onChange={(e) => setTypeData(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='input_data'
                  variant='outlined'
                  fullWidth
                  inputVariant='outlined'
                  label='Data inicial'
                  component={DatePicker}
                  disabled={isLoading == 1}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='output_data'
                  variant='outlined'
                  fullWidth
                  inputVariant='outlined'
                  label='Data final'
                  component={DatePicker}
                  disabled={isLoading == 1}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Button
                  fullWidth
                  onClick={handleExportOpenOsReport}
                  disabled={isLoading == 1}
                  loading={isLoading == 1}
                >
                  Exportar relatório
                </Button>
              </Grid>
            </Grid>
            {useEffect(() => {
              setInputData(props.values.input_data);
              setOutputData(props.values.output_data);
              setType(props.values.type);
            }, [props])}
          </Form>
        )}
      </Formik>
    </>
  );
};
