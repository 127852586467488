import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { Grid } from '@mui/material';
import { Button, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providerService } from 'services';
import { useContextSelector } from 'use-context-selector';

export const RemoveBlackList = () => {
  const user = useContextSelector(AuthContext, (context) => context.user);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    setIsLoading(true);
    setSubmitting(true);

    try {
      const response = await providerService.removeBlackListGaia(values);
      if (response[0].status === 200) toast.success('removido com sucesso');
      else toast.error('Não foi encontrado serial');
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      resetForm();
      setSubmitting(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Remover Serial Blacklist</PageTitle>
      <Formik
        initialValues={{
          serial: '',
          user: user,
        }}
        onSubmit={handleSubmit}
      >
        {({ values, resetForm }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3} lg={3}>
                <Field
                  size='small'
                  loading={isLoading}
                  name='serial'
                  variant='outlined'
                  fullWidth
                  inputVariant='outlined'
                  label='Serial'
                  component={Text}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <Button fullWidth type='submit' loading={isLoading}>
                  Enviar
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
