import { api } from 'services/api';

export class ClientService {
  async createClient(data) {
    return api.post('/service-orders/clients', data);
  }

  async listClients() {
    return api.get('/service-orders/clients');
  }

  async updateClient(data) {
    return api.put('/service-orders/clients', data);
  }
}

export const clientService = new ClientService();
