import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { Dialog, Table } from 'components';
import { providerService } from 'services/providers';

const columns = [{ label: 'Colaborador' }, { label: 'Turno' }, { label: 'Total OS' }];

export const DialogAtribuido = ({ openDialog, handleCloseDialo, line, inputDate, outputDate }) => {
  if (!openDialog) return null;
  const [attributionData, setAttributionData] = useState([]);

  useEffect(() => {
    const handleAtribuido = async () => {
      try {
        const { data } = await providerService.getAtribuido({
          etapa_DE: line.idEtapaAnt,
          etapa_Para: line.idEtapaPos,
          id_turno: line.id_turno,
          inputDate: inputDate,
          outputDate: outputDate,
        });
        setAttributionData(data);
      } catch (error) {
        toast.error(error?.response?.data?.message);
        console.log(error?.response?.data?.message);
      }
    };

    handleAtribuido();
  }, [openDialog, line, inputDate, outputDate]);
  return (
    <>
      <Dialog open={openDialog}>
        <Dialog.Title onClose={handleCloseDialo}>Atribuição</Dialog.Title>
        <Dialog.Content>
          <Grid container>
            <Grid xs={12} md={12} lg={12}>
              <Table name='Table' headers={columns} maxHeight disableNumeration>
                {attributionData.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.Colaborador}</TableCell>
                    <TableCell>{item.Turno}</TableCell>
                    <TableCell>{item.Total_OS}</TableCell>
                  </TableRow>
                ))}
              </Table>
            </Grid>
          </Grid>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
