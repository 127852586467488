import React from 'react';
import { useRouteMatch } from 'react-router';

import { ScheduleOpenServiceOrder } from 'pages/Private/ServiceOrder/ScheduleOpenServiceOrder';

import { Route } from '../Route';

export const ServiceOrderRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Route
        isPrivate
        path={`${path}/schedule-open-service-order`}
        component={ScheduleOpenServiceOrder}
      />
    </>
  );
};
