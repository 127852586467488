import React from 'react';
import { useState } from 'react';

import Grid from '@material-ui/core/Grid';

import { Button, Dialog, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';

import { DialogConfirmCancel } from './DialogConfirmCancel';

const validationSchema = yup.object().shape({
  input_motivo: yup.string().required('Motivo é obrigatório'),
});

export const DialogCancellationLaudo = ({ openDialog, handleCloseDialog, infoOs }) => {
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const [dataMotivo, setMotivo] = useState('');

  const handleOpenDialog = async (props) => {
    setMotivo(props.values.input_motivo);
    setOpenDialogConfirm(true);
  };

  const handleCloseDialogConfirm = () => {
    handleCloseDialog();
    setOpenDialogConfirm(false);
  };

  return (
    <>
      <DialogConfirmCancel
        openDialogConfirm={openDialogConfirm}
        handleCloseDialogConfirm={handleCloseDialogConfirm}
        infoOs={infoOs}
        dataMotivo={dataMotivo}
      />
      <Dialog open={openDialog}>
        <Dialog.Title onClose={handleCloseDialog}>Motivo do Cancelamento Laudo</Dialog.Title>
        <Dialog.Content>
          <Formik initialValues={{ input_motivo: '' }} validationSchema={validationSchema}>
            {(props) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12}>
                    <Field
                      size='small'
                      name='input_motivo'
                      variant='outlined'
                      label='Motivo'
                      component={Text}
                      multiline
                      minRows={8}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Button fullWidth color='secondary' onClick={() => handleOpenDialog(props)}>
                      Cancelar Laudo
                    </Button>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Button fullWidth color='primary' onClick={handleCloseDialog}>
                      Fechar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
