import React from 'react';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { FormatMoney, Mask, Spacer } from 'components';
import { useFormikContext } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { quoteService } from 'services';

const paymentMethodsOptions = {
  1: 'Boleto',
  2: 'Link de pagamento crédito',
  3: 'Link de pagamento débito',
  6: 'PIX',
  10: 'Cartão de débito',
  11: 'Dinheiro',
  12: 'Cartão de Crédito',
  13: 'Pagamento direto ao Caixa',
};

export const Verify = () => {
  const { values, setFieldValue } = useFormikContext();

  const [smartphoneBrand, , loadingSmartphoneBrand] = useQuery(
    () => quoteService.getSmartphoneByBrandId(values?.smartphone_brand_id),
    [values?.smartphone_brand_id],
  );

  const [smartphoneModel, , loadingSmartphoneModel] = useQuery(
    () => quoteService.getSmartphoneByModelId(values?.smartphone_model_id),
    [values?.smartphone_model_id],
  );

  const [smartphoneUseTime, , loadingSmartphoneUseTime] = useQuery(
    () => quoteService.getSmartphoneByUseTime(values?.smartphone_use_time_id),
    [values?.smartphone_use_time_id],
  );

  return (
    <>
      <Grid container style={{ width: '100%', padding: '10px' }} spacing={2}>
        <Grid item xs={6} md={6} lg={6}>
          <Paper
            style={{ padding: '12px', background: '#F8F8F8', height: '100%' }}
            variant='outlined'
          >
            <Typography variant='h5'>Dados do Cliente</Typography>
            <Spacer size={12} />
            <Grid container alignItems='center' spacing={2}>
              <Grid item xs={6} lg={3}>
                <Typography variant='body2'>CPF</Typography>
                <Typography variant='subtitle2'>
                  <Mask type='document'>{values?.cgc}</Mask>
                </Typography>
              </Grid>
              <Grid item xs={6} lg={3}>
                <Typography variant='body2'>Nome</Typography>
                <Typography variant='subtitle2'>{values?.name}</Typography>
              </Grid>
              <Grid item xs={6} lg={3}>
                <Typography variant='body2'>Celular</Typography>
                <Typography variant='subtitle2'>
                  <Mask type='phone'>{values?.phone_number}</Mask>
                </Typography>
              </Grid>
              <Grid item xs={6} lg={3}>
                <Typography variant='body2'>E-mail</Typography>
                <Typography variant='subtitle2'>{values?.email}</Typography>
              </Grid>
            </Grid>

            <Spacer size={24} />

            <Typography variant='h6'>Dados residenciais do segurado</Typography>

            <Grid container alignItems='center' spacing={2}>
              <Grid item xs={12} lg={12}>
                <Typography variant='body2'>CEP</Typography>
                <Typography variant='subtitle2'>
                  <Mask type='zipcode'>{values?.zipcode}</Mask>
                </Typography>
              </Grid>
              <Grid item xs={6} lg={6}>
                <Typography variant='body2'>Endereço</Typography>
                <Typography variant='subtitle2'>{values?.street}</Typography>
              </Grid>
              <Grid item xs={6} lg={3}>
                <Typography variant='body2'>Número</Typography>
                <Typography variant='subtitle2'>{values?.street_number}</Typography>
              </Grid>
              <Grid item xs={6} lg={2}>
                <Typography variant='body2'>Bairro</Typography>
                <Typography variant='subtitle2'>{values?.district}</Typography>
              </Grid>
              <Grid item xs={6} lg={2}>
                <Typography variant='body2'>Estado</Typography>
                <Typography variant='subtitle2'>{values?.state}</Typography>
              </Grid>
              <Grid item xs={6} lg={2}>
                <Typography variant='body2'>Cidade</Typography>
                <Typography variant='subtitle2'>{values?.city}</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={6} md={6} lg={6}>
          <Paper
            style={{ padding: '12px', background: '#F8F8F8', height: '100%' }}
            variant='outlined'
          >
            <Typography variant='h5'>Seguro do Celular</Typography>

            <Spacer size={12} />

            <Typography variant='h6'>Quebra Acidental</Typography>
            <Spacer size={12} />
            <Grid container alignItems='center' spacing={2}>
              <Grid item xs={6} md={6} lg={6}>
                <Typography variant='body2'>Modelo do Aparelho</Typography>
                <Typography variant='subtitle2'>{smartphoneModel?.description}</Typography>
              </Grid>
              <Grid item xs={6} md={6} lg={6}>
                <Typography variant='body2'>Tempo de uso do aparelho</Typography>
                <Typography variant='subtitle2'>{smartphoneUseTime?.description}</Typography>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Typography variant='body2'>Valor de mercado</Typography>
                <Typography variant='subtitle2'>
                  <FormatMoney>{smartphoneModel?.market_value}</FormatMoney>
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Typography variant='body2'>
                  Até 12x de <FormatMoney>{0.15 * smartphoneModel?.market_value}</FormatMoney> sem
                  juros, <FormatMoney>{(0.15 * smartphoneModel?.market_value) / 12}</FormatMoney> à
                  vista
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Divider variant='fullWidth' />
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Typography variant='body2' align='right'>
                  Subtotal: <FormatMoney>{0.15 * smartphoneModel?.market_value}</FormatMoney>
                </Typography>

                <Typography variant='body2' align='right'>
                  Desconto: <FormatMoney>{0}</FormatMoney>
                </Typography>

                <Typography variant='subtitle2' align='right'>
                  Valor total: <FormatMoney>{0.15 * smartphoneModel?.market_value}</FormatMoney>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={6} md={6} lg={6}>
          <Paper
            style={{ padding: '12px', background: '#F8F8F8', height: '100%' }}
            variant='outlined'
          >
            <Typography variant='h6'>
              Dados do Aparelho - {smartphoneBrand?.description} - {smartphoneModel?.description}
            </Typography>
            <Spacer size={12} />
            <Grid container alignItems='center' spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <Typography variant='body2'>Tempo de uso do aparelho</Typography>
                <Typography variant='overline'>{smartphoneUseTime?.description}</Typography>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Typography variant='body2'>Valor de mercado</Typography>
                <Typography variant='overline'>
                  <FormatMoney>{Number(smartphoneModel?.market_value)}</FormatMoney>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={6} md={6} lg={6}>
          <Paper
            style={{ padding: '12px', background: '#F8F8F8', height: '100%' }}
            variant='outlined'
          >
            <Typography variant='h6'>Pagamento(s)</Typography>
            <Spacer size={12} />
            <Grid container alignItems='center' spacing={2}>
              <Grid item xs={4} md={4} lg={4}>
                <Typography variant='body2'>Método de Pagamento 1</Typography>
                <Typography variant='overline'>
                  {paymentMethodsOptions?.[values?.payment_method]}
                </Typography>
              </Grid>
              <Grid item xs={4} md={4} lg={4}>
                <Typography variant='body2'>Nº de Parcelas</Typography>
                <Typography variant='overline'>{values?.installments}</Typography>
              </Grid>
              <Grid item xs={4} md={4} lg={4}>
                <Typography variant='body2'>Valor por parcela</Typography>
                <Typography variant='overline'>
                  <FormatMoney>{values?.installment_price}</FormatMoney>
                </Typography>
              </Grid>
            </Grid>

            <Grid container alignItems='center' spacing={2}>
              <Grid item xs={4} md={4} lg={4}>
                <Typography variant='body2'>Método de Pagamento 2</Typography>
                <Typography variant='overline'>
                  {paymentMethodsOptions?.[values?.payment_method2] ?? '-'}
                </Typography>
              </Grid>
              <Grid item xs={4} md={4} lg={4}>
                <Typography variant='body2'>Nº de Parcelas</Typography>
                <Typography variant='overline'>{values?.installments2 ?? '0'}</Typography>
              </Grid>
              <Grid item xs={4} md={4} lg={4}>
                <Typography variant='body2'>Valor por parcela</Typography>
                <Typography variant='overline'>
                  <FormatMoney>{values?.installment_price2 ?? 0}</FormatMoney>
                </Typography>
              </Grid>
            </Grid>

            {console.log('Final Values: ', values)}

            <Grid container alignItems='center' spacing={2}>
              <Grid item xs={4} md={4} lg={4}>
                <Typography variant='body2'>Método de Pagamento 3</Typography>
                <Typography variant='overline'>
                  {paymentMethodsOptions?.[values?.payment_method3] ?? '-'}
                </Typography>
              </Grid>
              <Grid item xs={4} md={4} lg={4}>
                <Typography variant='body2'>Nº de Parcelas</Typography>
                <Typography variant='overline'>{values?.installments3 ?? '0'}</Typography>
              </Grid>
              <Grid item xs={4} md={4} lg={4}>
                <Typography variant='body2'>Valor por parcela</Typography>
                <Typography variant='overline'>
                  <FormatMoney>{values?.installment_price3 ?? 0}</FormatMoney>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
