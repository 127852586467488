import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TreeItem from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import { Dialog } from 'components';
import { Form, Formik } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { accountService } from 'services';

import { useStyles } from './styles';

export const RoleDialog = ({ open, handleClose, role = {}, reloadRoles, reloadUsers }) => {
  if (!open) return null;

  const classes = useStyles();

  const [accessData, , loadingAccessData, refetchAccessData] = useQuery(
    () => accountService.listUsersAccessActive(),
    [],
  );

  const [roles, , loadingRolesData, refetchRolesData] = useQuery(
    () => accountService.getRoleById(role?.id),
    [role?.id],
  );

  const allPermissions = roles?.role_permissions_main_id?.concat(roles?.role_permissions_sub_id);

  const [selected, setSelected] = useState([]);

  useEffect(() => {
    setSelected(allPermissions);
    refetchAccessData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles]);

  const onSubmitHandler = async () => {
    try {
      await accountService.updateUserRolePermissions(roles?.id, selected);

      refetchAccessData();
      refetchRolesData();

      reloadRoles();
      reloadUsers();
      toast.success('Permissões de usuário alteradas com sucesso.');
    } catch (err) {
      toast.error(
        err?.response?.data?.message || 'Não foi possível alterar as permissões do usuário.',
      );
    } finally {
      handleClose();
    }
  };

  function getChildById(node, id) {
    let array = [];

    function getAllChild(nodes) {
      if (nodes === null) return [];
      array.push(nodes.id);
      if (Array.isArray(nodes.accessControl)) {
        nodes.accessControl.forEach((node) => {
          array = [...array, ...getAllChild(node)];
          array = array.filter((v, i) => array.indexOf(v) === i);
        });
      }
      return array;
    }

    function getNodeById(nodes, id) {
      if (nodes.id === id) {
        return nodes;
      } else if (Array.isArray(nodes.accessControl)) {
        let result = null;
        nodes.accessControl.forEach((node) => {
          if (!!getNodeById(node, id)) {
            result = getNodeById(node, id);
          }
        });

        return result;
      }

      return null;
    }

    return getAllChild(getNodeById(node, id));
  }

  function getOnChange(checked, nodes) {
    const allNode = getChildById(nodes, nodes.id);
    let array = checked
      ? [...selected, ...allNode]
      : selected.filter((value) => !allNode.includes(value));

    array = array.filter((v, i) => array.indexOf(v) === i);

    setSelected(array);
  }

  const renderTree = (nodes) =>
    Array.isArray(nodes)
      ? nodes?.map((access) => {
          return access?.id != 1 ? (
            <TreeItem
              key={access?.description}
              nodeId={access?.description}
              label={
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selected?.some((item) => item === access?.id)}
                      onChange={(event) => getOnChange(event.currentTarget.checked, access)}
                      onClick={(e) => e.stopPropagation()}
                    />
                  }
                  label={<>{access?.description}</>}
                  key={access?.id}
                />
              }
            >
              {Array.isArray(access?.accessControl)
                ? access?.accessControl?.map((subAccess) => {
                    return (
                      <TreeItem
                        key={subAccess?.id}
                        nodeId={subAccess?.description}
                        label={
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selected?.some((item) => item === subAccess?.id)}
                                onChange={(event) =>
                                  getOnChange(event.currentTarget.checked, subAccess)
                                }
                                onClick={(e) => e.stopPropagation()}
                              />
                            }
                            label={<>{subAccess?.description}</>}
                            key={subAccess?.id}
                          />
                        }
                      ></TreeItem>
                    );
                  })
                : null}
            </TreeItem>
          ) : null;
        })
      : null;

  return (
    <Dialog maxWidth='md' onClose={handleClose} open={open}>
      <Dialog.Title onClose={handleClose}>
        Permissões de grupo de usuários {roles?.description}:
      </Dialog.Title>
      <Dialog.Content>
        <Formik>
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Grid container>
                  <Grid item xs={12} md={12} lg={6}>
                    <TreeView
                      defaultCollapseIcon={<ExpandMoreIcon />}
                      defaultExpandIcon={<ChevronRightIcon />}
                    >
                      {renderTree(accessData)}
                    </TreeView>
                  </Grid>
                  <Grid className={classes.buttonsContainer} container>
                    <Button onClick={handleClose} color='primary'>
                      Fechar
                    </Button>
                    <Button
                      color='primary'
                      disabled={allPermissions?.toString() === selected?.toString()}
                      onClick={onSubmitHandler}
                    >
                      Confirmar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
