import { makeStyles } from '@material-ui/core/styles';

import { DRAWER_WIDTH } from '../Drawer/styles';

export const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
    },
    headerLogoGaia: {
      width: 100,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      background: '#181b23',
      opacity: '0.95',

      [theme.breakpoints.up('3000px')]: {
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        marginLeft: DRAWER_WIDTH,
      },
      boxShadow: 'none',
    },
    toolBar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    right: {
      marginLeft: 'auto',
      display: 'flex',
      alignItems: 'center',
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('3000px')]: {
        display: 'none',
      },
    },
  };
});
