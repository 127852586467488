export const versions = [
  // {
  //   // id: '0.1.1',
  //   // description: 'Essa mudança foi feito somente para testes no dia 07/07/2021 às 10:30.',
  //   // changes: [
  //   //   'Adicionado tela X para clientes Y.',
  //   //   'Distribuindo novas ordens de serviço.',
  //   //   'Exibindo versão do Gaia no fim do Menu.',
  //   //   'Criado changelog para exibição dos detalhes de versão e versões passadas.',
  //   // ],
  // },
  {
    MARK_NAME: 'Ingenico',
    id: '1.0.0',
    description: 'Mudança realizada no dia 02/03/2022',
    changes: [
      'Implementação de gráficos em Dashboard. ',
      'Exibição de relatórios.',
      'Venda de acessórios.',
      'Requisição de encomenda.',
      'Validação de garantia.',
      'Implementação de avatares.',
      'Autenticação de senha segura.',
      'Demais mudanças contactar nosso suporte por meio do nosso e-mail: Suporte@gaiaicfcomercio.com.br',
    ],
  },
];
