import React from 'react';
import { VscSettingsGear } from 'react-icons/vsc';

import Chip from '@material-ui/core/Chip';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import GppMaybeIcon from '@mui/icons-material/GppMaybe';

import { useStyles } from './styles';

export const PageTitle = ({ children, dev = false, att = false }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const classes = useStyles();

  return (
    <h1 className={classes.title}>
      {children}{' '}
      {dev && (
        <Chip
          className={classes.devText}
          icon={<VscSettingsGear />}
          label={isMobile ? 'DEV' : 'EM DESENVOLVIMENTO'}
          variant='outlined'
          textColor
        />
      )}
      {att && (
        <Chip
          className={classes.devText}
          icon={<GppMaybeIcon color='warning' />}
          label={isMobile ? 'ATT' : 'ATENÇÃO'}
          variant='outlined'
          textColor
        />
      )}
    </h1>
  );
};
