import { api } from 'services/api';

export class StockService {
  async findBlockAllocations() {
    return api.get('/stock/getBlockAllocation');
  }

  async blockAllocation(data) {
    return api.post('/stock/blockAllocation', data);
  }

  async unlockAllocation(data) {
    return api.post('/stock/unlockAllocation', data);
  }

  async checkedPartnumber(data) {
    return api.post('/stock/checkedPartnumber', data);
  }

  async sendFileEmail(data) {
    return api.post('/stock/sendFileEmail', data);
  }
}

export const stockService = new StockService();
