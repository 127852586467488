import React from 'react';
import { useRouteMatch } from 'react-router';

import { ArchivesDownload } from '../../pages/Private/Receipt/ArchivesDownload';
import { PreReceivementImport } from '../../pages/Private/Receipt/PreReceivementImport';
import { CorrectionNF } from '../../pages/Private/Receivement/CorrectionNF';
import { Route } from '../Route';

export const ReceivementRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${path}/correction-nf`} component={CorrectionNF} />
      <Route isPrivate path={`${path}/archives-download`} component={ArchivesDownload} />
      <Route isPrivate path={`${path}/pre-receivement-import`} component={PreReceivementImport} />
    </>
  );
};
