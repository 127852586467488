import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';

import { Spacer, Button, DatePicker } from 'components';
import { Field, Form, Formik } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { salesService } from 'services/sales';
import XLSX from 'xlsx';

export const SalesReport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const today = new Date();

  const [inputData, setInputData] = useState(new Date(new Date().setDate(today.getDate() - 30)));
  const [outputData, setOutputData] = useState(new Date());

  const [componentData, setComponents] = useState([]);

  const [components, , loadingPartsRequisitions, refetchPartsDataDate] = useQuery(
    () => salesService.listSalesReport(inputData, outputData),
    [inputData, outputData],
  );

  useEffect(() => {
    setComponents(components);
  }, [components]);

  const handleDownloadGeneralStockRelatory = async () => {
    setIsLoading(true);
    const now = new Date();
    const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(componentData));
    const workbook = (XLSX.WorkBook = {
      Sheets: {
        vendas: worksheet,
      },
      SheetNames: ['vendas'],
    });
    XLSX.writeFile(workbook, `RelatorioVenda${now}.xlsx`);

    refetchPartsDataDate();
    setIsLoading(false);
  };

  return (
    <>
      <PageTitle>Relatório Venda</PageTitle>
      <Spacer size={15} />
      <Formik
        initialValues={{
          input_data: inputData,
          output_data: outputData,
        }}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <Field
                  size='small'
                  name='input_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data de entrada'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Field
                  size='small'
                  name='output_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data de saída'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Button
                  fullWidth
                  disabled={isLoading}
                  loading={isLoading}
                  onClick={handleDownloadGeneralStockRelatory}
                >
                  exportar relatório
                </Button>
              </Grid>
            </Grid>
            {useEffect(() => {
              setInputData(props.values.input_data);
              setOutputData(props.values.output_data);
            }, [props])}
          </Form>
        )}
      </Formik>
    </>
  );
};
