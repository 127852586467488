import { api } from 'services/api';

export class QuoteService {
  async listQuoteTransaction() {
    return api.get('/quote/smartphone-transaction');
  }

  async createSmartphoneTransaction(data) {
    return api.post('/quote/smartphone-transaction', data);
  }

  async listSmartphoneBrands() {
    return api.get('/quote/smartphone-brand');
  }

  async createSmartphoneBrand(data) {
    return api.post('/quote/smartphone-brand', data);
  }

  async updateSmartphoneBrand(id, description) {
    return api.put('/quote/smartphone-brand', { id, description });
  }

  async listSmartphoneModels() {
    return api.get(`/quote/smartphone-model`);
  }

  async createSmartphoneModel(data) {
    return api.post(`/quote/smartphone-model`, data);
  }

  async updateSmartphoneModel(id, description, market_value, smartphone_brand_id) {
    return api.post(`/quote/smartphone-model`, {
      id,
      description,
      market_value,
      smartphone_brand_id,
    });
  }

  async listSmartphoneUseTime() {
    return api.get('/quote/smartphone-use-time');
  }

  async createSmartphoneUseTime(data) {
    return api.post('/quote/smartphone-use-time', data);
  }

  async updateSmartphoneUseTime(id, description) {
    return api.put('/quote/smartphone-use-time', { id, description });
  }

  async getSmartphoneByUseTime(id) {
    return api.get(`/quote/smartphone-use-time/${id}`);
  }

  async listSmartphoneInsuranceType() {
    return api.get(`/quote/smartphone-insurance-type`);
  }

  async createSmartphoneInsuranceType(data) {
    return api.post(`/quote/smartphone-insurance-type`, data);
  }

  async updateSmartphoneInsuranceType(id, description) {
    return api.put('/quote/smartphone-insurance-type', { id, description });
  }

  async getSmartphoneByBrandId(id) {
    return api.get(`/quote/smartphone-brand/${id}`);
  }

  async getSmartphoneByModelId(id) {
    return api.get(`/quote/smartphone-model/id/${id}`);
  }

  async listSmartphoneModelsByBrand(smartphone_brand_id) {
    return api.get(`/quote/smartphone-models/${smartphone_brand_id}`);
  }
}

export const quoteService = new QuoteService();
