import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: ({ maxHeight }) => ({
    width: '100%',
    maxHeight: maxHeight || 'none',
    marginBottom: theme.spacing(2),
  }),
  tableRowRoot: {
    '&:nth-of-type(even)': {
      backgroundColor: '#fafafa',
    },
  },
  head: {
    backgroundColor: '#fafafa',
  },
}));
