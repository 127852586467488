import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Spacer, Button, DatePicker } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providerService } from 'services';
import XLSX from 'xlsx';

export const IncontrolReport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const today = new Date();

  const [inputData, setInputData] = useState(new Date(new Date().setDate(today.getDate() - 1)));
  const [outputData, setOutputData] = useState(new Date());

  const handleDownloadGeneralStockRelatory = async () => {
    try {
      toast.info('Gerando relatório...');
      setIsLoading(true);
      const { data } = await providerService.getIncontrolReport(inputData, outputData);
      const now = new Date();
      const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(data));
      const workbook = (XLSX.WorkBook = {
        Sheets: {
          logs: worksheet,
        },
        SheetNames: ['logs'],
      });
      XLSX.writeFile(workbook, `RelatorioLogs${now}.xlsx`);

      toast.success('Relatório gerado com sucesso');
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Erro ao gerar o relatório');
    } finally {
      setIsLoading(false);
    }

    setIsLoading(false);
  };

  return (
    <>
      <PageTitle>Relatório Logs Catracas</PageTitle>
      <Spacer size={15} />
      <Formik
        initialValues={{
          input_data: inputData,
          output_data: outputData,
        }}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='input_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data de entrada'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='output_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data de saída'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Button
                  fullWidth
                  disabled={isLoading}
                  loading={isLoading}
                  onClick={() => handleDownloadGeneralStockRelatory()}
                >
                  exportar relatório
                </Button>
              </Grid>
            </Grid>
            {useEffect(() => {
              setInputData(props.values.input_data);
              setOutputData(props.values.output_data);
            }, [props])}
          </Form>
        )}
      </Formik>
    </>
  );
};
