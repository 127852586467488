import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, DatePicker, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { downloadXlsx } from 'helpers/downloadXLSX';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providerService } from 'services/providers';

export const ReceiptReport = () => {
  const [inputData, setInputData] = useState(new Date());
  const [outputData, setOutputData] = useState(new Date());
  const [typeData, setTypeData] = useState('0');
  const [typeReceipt, setTypeReceipt] = useState('0');
  const [isLoading, setIsLoading] = useState(0);

  const handleExportReport = async () => {
    toast.info('Aguarde, relatório sendo gerado.');
    setIsLoading(1);
    const formattedInputDate = inputData.toLocaleDateString().replace('/', '-').replace('/', '-');
    const formattedOutputDate = outputData.toLocaleDateString().replace('/', '-').replace('/', '-');
    const now = new Date().toLocaleDateString();

    try {
      const { data } = await providerService.getReceiptReport(
        formattedInputDate,
        formattedOutputDate,
        typeData,
        typeReceipt,
      );
      downloadXlsx('ingenico_relatorio_recebimento', data);
      toast.success('Relatório gerado com sucesso');
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    } finally {
      setIsLoading(0);
    }
  };
  const selectTypeReceipt = [
    {
      value: '0',
      label: 'Apenas recusadas',
    },
    {
      value: '1',
      label: 'Apenas recusas terceiros',
    },
    {
      value: '2',
      label: 'Todas',
    },
  ];
  const selectTypeData = [
    {
      value: '0',
      label: 'Completo',
    },
    {
      value: '1',
      label: 'Por data',
    },
  ];

  return (
    <>
      <PageTitle>Relatório de Recebimentos</PageTitle>
      <Formik
        initialValues={{
          input_data: inputData,
          output_data: outputData,
          type_data: typeData,
          type_receipt: typeReceipt,
        }}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='type_data'
                  label='Tipo'
                  variant='outlined'
                  options={selectTypeData}
                  component={Select}
                  disabled={isLoading === 1}
                  // onChange={(e) => setTypeData(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='type_receipt'
                  label='Tipo'
                  variant='outlined'
                  options={selectTypeReceipt}
                  component={Select}
                  disabled={isLoading === 1 || props.values.type_data !== '1'}
                  // onChange={(e) => setTypeReceipt(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='input_data'
                  variant='outlined'
                  fullWidth
                  inputVariant='outlined'
                  label='Data inicial'
                  component={DatePicker}
                  disabled={isLoading == 1 || props.values.type_data !== '1'}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='output_data'
                  variant='outlined'
                  fullWidth
                  inputVariant='outlined'
                  label='Data final'
                  component={DatePicker}
                  disabled={isLoading == 1 || props.values.type_data !== '1'}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Button
                  fullWidth
                  onClick={handleExportReport}
                  disabled={isLoading == 1}
                  loading={isLoading == 1}
                >
                  Exportar relatório
                </Button>
              </Grid>
            </Grid>
            {useEffect(() => {
              setInputData(props.values.input_data);
              setOutputData(props.values.output_data);
              setTypeData(props.values.type_data);
              setTypeReceipt(props.values.type_receipt);
            }, [props])}
          </Form>
        )}
      </Formik>
    </>
  );
};
