import { api } from 'services/api';

export class PreBudgetService {
  async listPreBudgetsNewSalesComponents() {
    return api.get('/budgets/pre-budgets-sales-price');
  }

  async listPreBudgetsNewSalesComponentsByStatusId() {
    return api.get('/budgets/pre-budgets-sales-price-status-id');
  }

  async getPreBudgetByServiceOrderNumber(number) {
    return api.get(`/budgets/pre-budgets/${number}`);
  }

  async createPreBudget(data) {
    return api.post('/budgets/pre-budgets', data);
  }

  async cancelPreBudgetById(id) {
    return api.put('/budgets/cancel-pre-budget', { id: id });
  }

  // async listPreBudgetsSalesComponents(part_numbers, selling_process_type_id) {
  //   return api.get('/budgets/sales-component', {
  //     params: {
  //       partNumbers: part_numbers,
  //       selling_process_type_id: selling_process_type_id,
  //     },
  //   });
  // }

  async listPreBudgetStatuses() {
    return api.get('/budgets/pre-budget-statuses');
  }

  async listGsnpPreBudgets(number) {
    return api.post('/budgets/gspn-pre-budgets', { number });
  }

  async listGsnpPreBudgetsLocal() {
    return api.get('/budgets/gspn-pre-budgets-local');
  }
}

export const preBudgetService = new PreBudgetService();
