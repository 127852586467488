import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog } from 'components';
export const ConfirmationDialog = ({
  isModalOpenConfirmation,
  handleCloseSubModal,
  handleConfirmDialog,
  textMessage,
}) => {
  return (
    <Dialog open={isModalOpenConfirmation} onClose={handleCloseSubModal} title='Finalizar NF'>
      <Dialog.Title onClose={handleCloseSubModal}>Finalizar NF</Dialog.Title>
      <Dialog.Content>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <Typography variant='h5' align='center' style={{ paddingBottom: '2rem' }}>
              {textMessage}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Button fullWidth color='primary' onClick={handleConfirmDialog}>
              Sim
            </Button>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Button fullWidth color='secondary' onClick={handleCloseSubModal}>
              Não
            </Button>
          </Grid>
        </Grid>
      </Dialog.Content>
    </Dialog>
  );
};
