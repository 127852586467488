import React from 'react';

export const LayoutContext = React.createContext({});
export const LayoutContextProvider = LayoutContext.Provider;

export function useLayoutContext() {
  const context = React.useContext(LayoutContext);
  return context;
}

export default LayoutContextProvider;
