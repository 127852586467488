import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { DatePicker, Select, Button } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providerService } from 'services/providers';

import { downloadcsv } from '../../../helpers/downloadcsv';
import { ModelosMulti } from '../../../helpers/getModelosMulti';

export const PartsXModelBorReport = () => {
  const [typeModelos, setTypeModelos] = useState([{ label: '', value: '' }]);
  const [isLoading, setIsLoading] = useState(false);

  const getDados = useCallback(async () => {
    const dataModelos = await ModelosMulti();
    const firstValue = [{ label: 'TODOS', value: '0' }, ...dataModelos];
    setTypeModelos(firstValue);
  }, []);

  useEffect(() => {
    getDados();
  }, [getDados]);

  const handleSubmit = async (values) => {
    toast.info('Aguarde, Relatório sendo gerado!');
    setIsLoading(true);

    try {
      const { data } = await providerService.PartsXModelBorReport(values);

      if (data.length === 0 || null) {
        toast.error('Não há dados para gerar o relatório');
        return;
      }

      await downloadcsv(`ngenico_BOR`, data);
      toast.success('Relatório gerado com sucesso');
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Peças X Modelos (BOR)</PageTitle>

      <Formik
        initialValues={{
          type_models: '0',
          input_data: new Date(),
          output_data: new Date(),
        }}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='input_data'
                  variant='outlined'
                  fullWidth
                  inputVariant='outlined'
                  label='Data Inicial'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='output_data'
                  variant='outlined'
                  fullWidth
                  inputVariant='outlined'
                  label='Data Final'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='type_models'
                  label='Modelos'
                  variant='outlined'
                  options={typeModelos}
                  component={Select}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Button fullWidth type='submit' disabled={isLoading}>
                  Extrair Relatório
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
