import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { Dialog, Text } from 'components';
import { Form, Field, Formik } from 'formik';
import { accountService } from 'services';
import * as yup from 'yup';

import { useStyles } from './styles';

const validationSchema = yup.object().shape({
  alias: yup.string().required(),
  description: yup.string().required(),
});

export const AlterRoleDialog = ({ open, handleClose, role = {}, reloadRoles }) => {
  const classes = useStyles();

  useEffect(() => {}, []);

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      await accountService.updateRole(values);

      reloadRoles();
      toast.success('Grupo de usuário alterado com sucesso.');
      resetForm();
    } catch (err) {
      toast.error(err.response?.data?.message || 'Não foi possível alterar o grupo de usuário');
    } finally {
      handleClose();
      setSubmitting(false);
    }
  };

  return (
    <Dialog maxWidth='xs' onClose={handleClose} open={open}>
      <Dialog.Title onClose={handleClose}>Grupo: {role?.description} </Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{ id: role?.id, alias: role?.alias, description: role?.description }}
          onSubmit={onSubmitHandler}
          validationSchema={validationSchema}
        >
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Grid container>
                  <Grid spacing={2} container>
                    <Grid item xs={12} lg={12}>
                      <Field
                        variant='outlined'
                        name='alias'
                        label='Alterar a sigla do grupo'
                        size='small'
                        component={Text}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        variant='outlined'
                        name='description'
                        label='Alterar a descrição do grupo'
                        size='small'
                        component={Text}
                      />
                    </Grid>
                  </Grid>
                  <Grid className={classes.buttonsContainer} container>
                    <Button onClick={handleClose} color='primary'>
                      Fechar
                    </Button>
                    <Button
                      disabled={!props.dirty || !props.isValid}
                      variant='contained'
                      color='primary'
                      type='submit'
                    >
                      Confirmar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
