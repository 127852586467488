import React, { useCallback } from 'react';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
import { getFieldError } from 'helpers/formik';

export const DatePicker = ({
  field,
  name,
  form,
  label,
  margin = 'none',
  format = 'dd/MM/yyyy',
  disabled,
  value,
  loading,
  fullWidth,
  errorMessage: errorMessageProp,
  ...props
}) => {
  const onChangeHandler = useCallback(
    (date) => {
      return form?.setFieldValue(field?.name, date);
    },
    [form, field],
  );

  const onBlurHandler = useCallback(() => {
    form?.setFieldTouched(field?.name, true);
  }, [form, field]);

  const errorMessage = errorMessageProp || getFieldError(field?.name, form);
  const hasError = !!errorMessage;

  value = field?.value ?? value;

  return (
    <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        fullWidth={fullWidth ?? true}
        format={format}
        margin={margin}
        label={label}
        value={value}
        name={name}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        disabled={form?.isSubmitting || disabled || loading}
        {...props}
        error={hasError}
        invalidDateMessage='Formato de data inválido'
      />
    </MuiPickersUtilsProvider>
  );
};
