import React from 'react';
import { useRouteMatch } from 'react-router';

import { ConferenceBeeping_new } from 'pages/Private/Conference/ConferenceBeeping/new_index';
import { Nf3xNf4 } from 'pages/Private/Conference/Nf3xNf4';

import { Route } from '../Route';

export const ConferenceRoutes = () => {
  const { url } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${url}/conference-beeping`} component={ConferenceBeeping_new} />
      <Route isPrivate path={`${url}/nf3vsnf4`} component={Nf3xNf4} />
    </>
  );
};
