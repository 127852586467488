import React from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { Button, Text, Select } from 'components';
import { Formik, Field } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useQuery } from 'hooks/useQuery';
import { accountService } from 'services/account';
import { api } from 'services/api';
import { useContextSelector } from 'use-context-selector';
import * as Yup from 'yup';

import { useStyles } from './styles';

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(9, 'A senha deve ter no mínimo 9 caracteres')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{9,}$/,
      'A senha deve conter uma letra minúscula, uma maiúscula, um número e um caractere especial',
    )
    .required(),
  confirm_password: Yup.string()
    .required()
    .oneOf([Yup.ref('password')], 'As senhas devem coincidir.'),
});

export const UserInformation = () => {
  const [values, setValues] = React.useState({
    password: false,
    confirm_password: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [confirmPassword, setConfirmPassword] = React.useState({
    password: false,
    confirm_password: false,
  });

  const handleChangeRepeatPassword = (prop) => (event) => {
    setConfirmPassword({ ...confirmPassword, [prop]: event.target.value });
  };

  const handleClickShowPasswordConfirm = () => {
    setConfirmPassword({
      ...confirmPassword,
      showPasswordConfirm: !confirmPassword.showPasswordConfirm,
    });
  };

  const handleMouseDownPasswordConfirm = (event) => {
    event.preventDefault();
  };

  const userName = useContextSelector(AuthContext, (context) => context.user.name);
  const userEmail = useContextSelector(AuthContext, (context) => context.user.email);
  const userPhone = useContextSelector(AuthContext, (context) => context.user.phone);
  const userRole = useContextSelector(AuthContext, (context) => context.user?.role?.description);
  const signOut = useContextSelector(AuthContext, (context) => context.signOut);

  const [quiz, , loadingQuiz] = useQuery(() => api.get('/users/quiz'), []);

  const onSubmitHandler = async (
    { email, password, phone, quiz_id, answer },
    { setSubmitting, resetForm },
  ) => {
    try {
      setSubmitting(true);

      await accountService.updateUserPassword(email, password, phone, quiz_id, answer);

      toast.success('Informações alteradas com sucesso!');
      resetForm();
      signOut();
    } catch (err) {
      yarn;
      console.error(err);
      toast.error(
        err.response?.data?.message ||
          'Erro ao alterar as informações da conta, se for a primeira alteração, preencha também a pergunta e resposta de segurança.',
      );
    } finally {
      setSubmitting(false);
    }
  };

  const quizOptions = (quiz || []).map((quiz) => ({
    value: quiz.id,
    label: quiz.question,
  }));

  const classes = useStyles();

  console.log('Quiz Options: ', quizOptions);

  return (
    <>
      <Typography className={classes.title} variant='h5'>
        Seus dados
      </Typography>
      <Formik
        initialValues={{
          fullname: userName,
          email: userEmail,
          phone: userPhone,
          oldpassword: '*******************',
          password: '',
          confirm_password: '',
          quiz_id: '',
          answer: '',
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <Field
                  label='Nome Completo'
                  name='fullname'
                  variant='outlined'
                  size='small'
                  component={Text}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Field
                  label='Email'
                  name='email'
                  variant='outlined'
                  size='small'
                  component={Text}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Text
                  label='Grupo'
                  name='role'
                  variant='outlined'
                  size='small'
                  value={userRole}
                  disabled
                />
              </Grid>
              {!!userPhone && (
                <Grid item xs={12} lg={6}>
                  <Field
                    label='Telefone'
                    name='phone'
                    mask='phone'
                    variant='outlined'
                    size='small'
                    component={Text}
                  />
                </Grid>
              )}
              <Grid item xs={12} lg={6}>
                <Field
                  size='small'
                  fullWidth
                  name='quiz_id'
                  variant='outlined'
                  label='Pergunta de Segurança'
                  loading={loadingQuiz}
                  options={quizOptions}
                  component={Select}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Field
                  name='answer'
                  variant='outlined'
                  size='small'
                  label='Resposta de segurança'
                  fullWidth
                  component={Text}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Field
                  label='Senha atual'
                  name='oldpassword'
                  variant='outlined'
                  size='small'
                  component={Text}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Field
                  name='password'
                  variant='outlined'
                  size='small'
                  label='Nova senha'
                  inputProps={{ minLength: 3 }}
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.password}
                  onChange={handleChange('password')}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  fullWidth
                  component={Text}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Field
                  name='confirm_password'
                  variant='outlined'
                  size='small'
                  label='Confirmar senha'
                  inputProps={{ minLength: 3 }}
                  type={confirmPassword.showPasswordConfirm ? 'text' : 'password'}
                  value={confirmPassword.confirm_password}
                  onChange={handleChangeRepeatPassword('password')}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPasswordConfirm}
                        onMouseDown={handleMouseDownPasswordConfirm}
                        edge='end'
                      >
                        {confirmPassword.showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  fullWidth
                  component={Text}
                />
              </Grid>
              <Grid item xs={6} className={classes.gridUpdateBtn}>
                <Button
                  fullWidth
                  color='primary'
                  loading={props.isSubmitting}
                  onClick={props.handleSubmit}
                  disabled={!props.dirty || !props.isValid}
                >
                  Atualizar
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Formik>
    </>
  );
};
