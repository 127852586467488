import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import Checkbox from '@mui/material/Checkbox';
import { Button, Dialog, Text, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useContextSelector } from 'use-context-selector';
import * as Yup from 'yup';

import { receivementService } from '../../../../../services/receivement';

const validationSchema = Yup.object().shape({
  input_nf: Yup.string().required('O campo NF é obrigatório'),
  input_qtd: Yup.string().required('O campo Quantidade é obrigatório'),
  input_processo: Yup.string().required('O campo Processo é obrigatório'),
  input_parceiro: Yup.string().required('O campo Parceiro é obrigatório'),
  input_cliente: Yup.string().required('O campo Cliente é obrigatório'),
});

export const DialogCorrigir = ({
  openDialogCorrigir,
  handleCloseDialogCorrigir,
  selectedRowData,
}) => {
  if (!openDialogCorrigir) return null;

  const [isLoading, setIsLoading] = useState(false);
  const [processOptions, setProcessOptions] = useState([{ label: '', value: '' }]);
  const [parceiroOptions, setParceiroOptions] = useState([{ label: '', value: '' }]);
  const [clientOptions, setClientOptions] = useState([{ label: '', value: '' }]);
  const user = useContextSelector(AuthContext, (context) => context.user);
  const userName = user.name;

  const handleProcess = useCallback(async () => {
    try {
      const { data } = await receivementService.getProcess();
      setProcessOptions(data);
    } catch (error) {
      toast.error('Erro ao carregar processo:', error?.response?.data?.message);
    }
  }, []);

  const handleParceiro = useCallback(async () => {
    try {
      const { data } = await receivementService.getParceiro();
      setParceiroOptions(data);
    } catch (error) {
      toast.error('Erro ao carregar parceiro:', error?.response?.data?.message);
    }
  }, []);

  const handleGetClient = useCallback(
    async (selectedValues) => {
      try {
        const { data } = await receivementService.getClientParceiro({
          idClienteParceiro:
            selectedValues?.idClienteParceiro || selectedRowData?.idClienteParceiro,
          idProcesso: selectedValues?.idTipoProcesso || selectedRowData?.idTipoProcesso,
        });
        setClientOptions(data);
      } catch (error) {
        toast.error('Erro ao carregar cliente:', error?.response?.data?.message);
      }
    },
    [selectedRowData],
  );

  console.log('selectedRowData', selectedRowData.tipoProcesso);
  const processCorrecaoNF = async (values) => {
    setIsLoading(true);
    try {
      const selectedProcessLabel = processOptions.find(
        (option) => option.value === values.input_processo,
      )?.label;

      const selectedClientLabel = clientOptions.find(
        (option) => option.value === values.input_cliente,
      )?.label;

      const { data } = await receivementService.updateNF({
        idCabec: selectedRowData.idCadastro,
        qtd: values.input_qtd,
        idTipoProcesso: values.input_processo,
        idClienteOPV: values.input_parceiro,
        idCliente: values.input_cliente,
        objeto: values.input_objetos,
        obs: values.input_obs,
        restricao: values.analiseRestricao,
        reincidencia: values.reincidencia,
        user: userName,
        nomeProcesso: selectedProcessLabel,
        nomeCliente: selectedClientLabel,
      });

      if (data.status === 400) {
        toast.error(data.msg);
        setIsLoading(false);
      } else {
        toast.success('NF atualizada com sucesso!');
        setIsLoading(false);
        await handleCloseDialogCorrigir();
        window.location.reload();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleOneTime = async () => {
      if (openDialogCorrigir) {
        await handleProcess();
        await handleParceiro();
        await handleGetClient({
          idClienteParceiro: selectedRowData?.idClienteParceiro,
          idProcesso: selectedRowData?.idTipoProcesso,
        });
      }
    };
    handleOneTime();
  }, [openDialogCorrigir, handleGetClient, handleParceiro, handleProcess, selectedRowData]);

  return (
    <Dialog maxWidth={'md'} open={openDialogCorrigir}>
      <Dialog.Title onClose={handleCloseDialogCorrigir}>
        NF Cliente: {selectedRowData.numNF}
      </Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{
            input_nf: selectedRowData.numNF,
            input_qtd: selectedRowData.qtdTotal,
            input_processo: selectedRowData?.idTipoProcesso,
            input_parceiro: selectedRowData?.idClienteParceiro,
            input_cliente: '',
            input_objetos: '',
            input_obs: '',
            analiseRestricao: false,
            reincidencia: false,
          }}
          enableReinitialize
          onSubmit={processCorrecaoNF}
          validationSchema={validationSchema}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={6} lg={6}>
                  <Field
                    size='small'
                    loading={isLoading}
                    name='input_nf'
                    variant='outlined'
                    fullWidth
                    label='NF'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={6} lg={6}>
                  <Field
                    size='small'
                    loading={isLoading}
                    name='input_qtd'
                    variant='outlined'
                    fullWidth
                    label='QTD'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={6} lg={6}>
                  <Field
                    size='small'
                    loading={isLoading}
                    name='input_processo'
                    variant='outlined'
                    fullWidth
                    label='Processo'
                    component={Select}
                    options={processOptions}
                    onChange={(value) => {
                      const selectedProcess = value;
                      setFieldValue('input_processo', selectedProcess);
                      handleGetClient({
                        idClienteParceiro: values.input_parceiro,
                        idTipoProcesso: selectedProcess,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={6} lg={6}>
                  <Field
                    size='small'
                    loading={isLoading}
                    name='input_parceiro'
                    variant='outlined'
                    fullWidth
                    label='Parceiro'
                    component={Select}
                    options={parceiroOptions}
                    onChange={(value) => {
                      const selectedParceiro = value;
                      setFieldValue('input_parceiro', selectedParceiro);
                      handleGetClient({
                        idClienteParceiro: selectedParceiro,
                        idTipoProcesso: values.input_processo,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={6} lg={6}>
                  <Field
                    size='small'
                    loading={isLoading}
                    name='input_cliente'
                    variant='outlined'
                    fullWidth
                    label='Cliente'
                    component={Select}
                    options={clientOptions}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Field
                    size='small'
                    loading={isLoading}
                    name='input_objetos'
                    variant='outlined'
                    fullWidth
                    label='Objetos Correios'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Field
                    size='small'
                    loading={isLoading}
                    name='input_obs'
                    variant='outlined'
                    fullWidth
                    label='Observação'
                    component={Text}
                    multiline
                    minRows={3}
                  />
                </Grid>
                <Grid item xs={6} lg={4}>
                  <Field
                    type='checkbox'
                    as={Checkbox}
                    name='analiseRestricao'
                    style={{ color: '#512da8' }}
                  />
                  <label>Análise de restrição</label>
                </Grid>
                <Grid item xs={6} lg={4}>
                  <Field
                    type='checkbox'
                    as={Checkbox}
                    name='reincidencia'
                    style={{ color: '#512da8' }}
                  />
                  <label>Reincidência</label>
                </Grid>
                <Grid item xs={3} md={3} lg={4}>
                  <Button fullWidth type='submit' loading={isLoading}>
                    Gravar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
