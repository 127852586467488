/* eslint-disable prettier/prettier */
import React, { memo } from 'react';
import { useLocation } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import clsx from 'clsx';
import { AuthContext } from 'hooks/useAuth';
import { ModalUpdatePassword } from 'pages/Layout/ModalUpdatePassword';
import { useContextSelector } from 'use-context-selector';

import { useStyles } from './styles';

const MenuItemRouteComponent = ({
  fullPath,
  path,
  label,
  icon,
  isNested,
  onClick,
  roles = [],
  icon2,
}) => {
  const rolePermissionsSub = useContextSelector(
    AuthContext,
    (context) => context.user?.role_permissions_sub_id,
  );

  // console.log('rolePermissionsSub: ', rolePermissionsSub);

  const userAdditionalPermissionsSub = useContextSelector(
    AuthContext,
    (context) => context.user?.additional_permissions_sub_id,
  );

  const allSubPermissions = rolePermissionsSub?.concat(userAdditionalPermissionsSub);

  const rolesThatAccess = roles;
  const userRolesMatch = roles != null ? allSubPermissions?.includes(rolesThatAccess) : true;

  const classes = useStyles();

  const location = useLocation();

  const isActive = location.pathname === fullPath || location.pathname === path;

  const handleClick = () => onClick(fullPath || path);

  if (!userRolesMatch) {
    return null;
  }

  return (
    <Grid container alignItems='center'>
      <Grid item xs={10}>
        <ListItem
          className={clsx(
            classes.listItem,
            isNested && classes.listItemNested,
            isActive && classes.listItemActive,
          )}
          onClick={handleClick}
          button
        >
          <ListItemIcon className={classes.listIcon}>{icon}</ListItemIcon>
          <ListItemText
            classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }}
            primary={label}
          />
        </ListItem>
      </Grid>
      {label === 'Página inicial' ? (
        <></>
      ) : (
        <>
            <Grid item xs={2}>
              {icon2}
            </Grid>
        </>
      )}
      {window.location.href !==
      window.location.href.split('/')[0] +
        window.location.href.split('/')[1] +
        '//' +
        window.location.href.split('/')[2] +
        '/profile' ? (
        <ModalUpdatePassword />
      ) : (
        <>{}</>
      )}
    </Grid>
  );
};

const MenuItemRoute = memo(MenuItemRouteComponent);
export { MenuItemRoute };
