import { api } from 'services/api';

export class SalesService {
  async listRequest(data) {
    return api.post('/sales/listRequest', data);
  }

  async updateRequest(request_number, supply_id) {
    return api.post('/sales/updateRequest', { request_number, supply_id });
  }

  async sendSerialNumber(request_number, serial_number, serial_chip) {
    return api.post('/sales/sendSerialNumber', { request_number, serial_number, serial_chip });
  }

  async createListRequestMinute(requestSales) {
    return api.post(
      `/sales/listRequest/minute`,
      { requestSales },
      {
        responseType: 'arraybuffer',
      },
    );
  }

  async listRequestByRequestNumber(request_number) {
    return api.get(`/sales/listRequest/requestNumber/${request_number}`);
  }

  async listRequestByOlderSerialNumber() {
    return api.get(`/sales/listRequestItens/serial`);
  }

  async listMinuteReport() {
    return api.get(`/sales/list/listMinuteReport`);
  }

  async listSalesReport(input_date, output_date) {
    return api.get(`/sales/list/listSalesReport/${input_date}/${output_date}`);
  }

  async getListRequestTag(request_number, invoice_number, serial_number) {
    return api.post(
      `/sales/getListRequest/${request_number}`,
      { invoice_number, serial_number },
      {
        responseType: 'arraybuffer',
      },
    );
  }

  async getListRequestBySerialNumber(serial_number) {
    return api.get(`/sales/listRequest/serialNumber/${serial_number}`);
  }

  async getListRequestOnlySerial(request_number) {
    return api.get(`/sales/listRequest/requestNumber/serialNumber/${request_number}`);
  }
}

export const salesService = new SalesService();
