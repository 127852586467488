import * as React from 'react';

import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';

const LabelBase = ({ hasError, label, description, errorMessage }) => {
  if (label && typeof label !== 'string') {
    return <>{label}</>;
  }

  return (
    <>
      {label && <Typography>{label}</Typography>}

      {description && (
        <Typography size='x-small' fontWeight='regular'>
          {description}
        </Typography>
      )}

      {hasError && <FormHelperText error={true}>{errorMessage}</FormHelperText>}
    </>
  );
};

export default React.memo(LabelBase);
