import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { Grid } from '@mui/material';
import { Button } from 'components';
import { Form, Formik } from 'formik';
import { downloadcsv } from 'helpers/downloadcsv';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providerService } from 'services';

export const MinimumStock = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const { data } = await providerService.getMinimumStock();
      if (data.length > 0) {
        downloadcsv('ingenico_estoque_minimo', data);
        toast.success('Relatório gerado com sucesso');
      } else {
        toast.error('Não há dados para esse relatório.');
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Relatório Estoque Mínimo</PageTitle>

      <Formik initialValues={{}} onSubmit={handleSubmit}>
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3} lg={3}>
              <Button fullWidth type='submit' loading={isLoading}>
                Exportar
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </>
  );
};
