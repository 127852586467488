import React from 'react';
import { NavLink } from 'react-router-dom';

import { ReactComponent as ChangeLogImage } from 'assets/images/change-log.svg';
import { AuthContext } from 'hooks/useAuth';
import { useContextSelector } from 'use-context-selector';

import { useStyles } from './styles';
import { versions } from './versions';

export const Changelog = () => {
  const isSignedIn = useContextSelector(AuthContext, (context) => context.isAuthenticated);

  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        <NavLink className={classes.backLink} to={isSignedIn ? '/home' : '/'}>
          Voltar para o Gaia-System
        </NavLink>
        <h1 className={classes.title}>Changelog</h1>
        <ChangeLogImage className={classes.changeLogImage} />
        {versions.map(({ id, description, changes }) => (
          <div key={id} className={classes.version}>
            <h2 className={classes.versionId}>
              v<span>{id}</span>
            </h2>
            {description && <h3 className={classes.description}>{description}</h3>}
            <ul className={classes.changes}>
              {changes.map((change) => (
                <li key={change}>• {change}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </>
  );
};
