import React from 'react';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LanguageIcon from '@material-ui/icons/Language';

// import avatarImg from 'assets/images/logoAvatar.png';
import logoGaia from 'assets/images/logoGaia.svg';

import { LoginForm } from './Form';
import useStyles from './styles';

const Login = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div>
        <h1 className={classes.title}>Gaia-System</h1>
        <p className={classes.subtitle}>Sistema de gerenciamento</p>
        <p className={classes.subtitleFlai}>
          Desenvolvido por <strong style={{ color: '#fc6d26' }}>FLAI</strong>
        </p>
      </div>

      <div className={classes.right} elevation={4}>
        {/* <img className={classes.logoAvatar} src={avatarImg} alt='avatar' /> */}
        <img className={classes.logoGaia} src={logoGaia} alt='avatar' />

        <div className={classes.icons}>
          <FacebookIcon className={classes.icon} fill='#3b5998' />
          <InstagramIcon className={classes.icon} />
          <LanguageIcon className={classes.icon} />
        </div>

        <LoginForm />
      </div>
    </div>
  );
};

export default Login;
