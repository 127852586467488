import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button } from 'components';
import { downloadXlsx } from 'helpers/downloadXLSX';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providerService } from 'services/providers';

export const AgingReport = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadAgingReport = async () => {
    setIsLoading(true);
    toast.info('Aguardando relatório');

    try {
      const { data } = await providerService.getAgingReport();
      downloadXlsx(`ingenico_aging2`, data);
      toast.success('Relatório Gerado com sucesso');
      setIsLoading(false);
    } catch (error) {
      console.error(error.response.data.message);
      toast.error(
        error.response.data.message || 'Erro ao trazer dados para o relatório aging com peças.',
      );
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Relatório Aging</PageTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <Button fullWidth onClick={handleDownloadAgingReport} loading={isLoading}>
            exportar relatório
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
