import React from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { Button, Select, Text } from 'components';
import { Formik, Form, Field } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { browserHistory } from 'routes/browserHistory';
import { api } from 'services/api';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string().email().required(),
  phone: Yup.string().required(),
  password: Yup.string()
    .min(9, 'A senha deve ter no mínimo 9 caracteres')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'A senha deve conter uma letra minúscula, uma maiúscula, um número e um caractere especial',
    )
    .required(),
  confirm_password: Yup.string()
    .required()
    .oneOf([Yup.ref('password')], 'As senhas devem coincidir.'),
  role_id: Yup.number().required(),
  quiz_id: Yup.number().required(),
  answer: Yup.string().required(),
});

export const CreateAccountForm = () => {
  const [progress, setProgress] = React.useState(0);

  const [confirmPassword, setConfirmPassword] = React.useState({
    password: false,
    confirm_password: false,
  });

  const [values, setValues] = React.useState({
    password: false,
    confirm_password: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleChangeRepeatPassword = (prop) => (event) => {
    setConfirmPassword({ ...confirmPassword, [prop]: event.target.value });
  };

  const handleClickShowPasswordConfirm = () => {
    setConfirmPassword({
      ...confirmPassword,
      showPasswordConfirm: !confirmPassword.showPasswordConfirm,
    });
  };

  const handleMouseDownPasswordConfirm = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [roles, , loadingRoles] = useQuery(() => api.get('/users/roles'), []);
  const [quiz, , loadingQuiz] = useQuery(() => api.get('/users/quiz'), []);

  const initialValues = {
    name: '',
    email: '',
    phone: '',
    password: '',
    confirm_password: '',
    role_id: '',
    quiz_id: '',
    answer: '',
  };

  const onSubmitHandler = async (
    { name, email, password, phone, role_id, quiz_id, answer },
    { setSubmitting, resetForm },
  ) => {
    try {
      setSubmitting(true);

      await api.post('/users', {
        name,
        email,
        password,
        phone,
        role_id,
        quiz_id,
        answer,
      });

      toast.success('Conta criada com sucesso!');

      setTimeout(() => {
        browserHistory.push('/');
      }, 500);

      resetForm();
    } catch (err) {
      console.error(err);
      toast.error('Erro ao criar conta');
    } finally {
      setSubmitting(false);
    }
  };

  const rolesOptions = (roles || []).map((role) => ({
    value: role.id,
    label: role.description,
  }));

  const quizOptions = (quiz || []).map((quiz) => ({
    value: quiz.id,
    label: quiz.question,
  }));

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmitHandler}
    >
      {(props) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Field
                name='name'
                variant='outlined'
                size='small'
                label='Nome'
                fullWidth
                component={Text}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Field
                name='email'
                variant='outlined'
                size='small'
                label='Email'
                fullWidth
                component={Text}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                mask='phone'
                name='phone'
                variant='outlined'
                size='small'
                label='Celular / Telefone'
                fullWidth
                component={Text}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                size='small'
                fullWidth
                name='role_id'
                variant='outlined'
                label='Grupo'
                loading={loadingRoles}
                options={rolesOptions}
                component={Select}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                size='small'
                fullWidth
                name='quiz_id'
                variant='outlined'
                label='Pergunta de Segurança'
                loading={loadingQuiz}
                options={quizOptions}
                component={Select}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name='answer'
                variant='outlined'
                size='small'
                label='Resposta de segurança'
                fullWidth
                component={Text}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name='password'
                variant='outlined'
                size='small'
                label='Nova senha'
                inputProps={{ minLength: 3 }}
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                onChange={handleChange('password')}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                fullWidth
                component={Text}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name='confirm_password'
                variant='outlined'
                size='small'
                label='Confirmar senha'
                inputProps={{ minLength: 3 }}
                type={confirmPassword.showPasswordConfirm ? 'text' : 'password'}
                value={confirmPassword.confirm_password}
                onChange={handleChangeRepeatPassword('password')}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPasswordConfirm}
                      onMouseDown={handleMouseDownPasswordConfirm}
                      edge='end'
                    >
                      {confirmPassword.showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                fullWidth
                component={Text}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                size='large'
                fullWidth
                loading={props.isSubmitting}
                onClick={props.handleSubmit}
                disabled={!props.isValid || !props.dirty}
              >
                Cadastrar
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
