import React from 'react';

import Typography from '@material-ui/core/Typography';

import { Button, Dialog } from 'components';
import { AuthContext } from 'hooks/useAuth';
import { browserHistory } from 'routes/browserHistory';
import { useContextSelector } from 'use-context-selector';

export const ModalUpdatePassword = () => {
  const user = useContextSelector(AuthContext, (context) => context.user);
  const signOut = useContextSelector(AuthContext, (context) => context.signOut);

  let validation = null;
  const date1 = new Date(user?.update_password_quarterly);
  const date2 = new Date();
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  // console.log('User: ', user);

  if (
    (user.active_directory === false && user.loginValidate === false) ||
    (user.active_directory === false && user.loginValidate === null) ||
    (user.active_directory === false && diffDays > 90)
  ) {
    validation = true;
  } else {
    validation = false;
  }

  const navigateToProfileLocation = () => {
    browserHistory.push('/profile');
  };

  return (
    <>
      <Dialog open={validation} onClose={navigateToProfileLocation} maxWidth='xs'>
        <Dialog.Title onClose={navigateToProfileLocation}>Aviso</Dialog.Title>
        <Dialog.Content>
          {user.loginValidate === false || user.loginValidate === null ? (
            <>
              <Typography>
                Bem-vindo {user.name}, o Gaia-System solicita a troca de sua senha de acesso na
                primeira validação de login por medidas de segurança.
              </Typography>
              <br />
              <Typography>
                Clique no botão de CONFIRMAR para ser redirecionado à tela de alteração de senha.
              </Typography>
            </>
          ) : (
            <>
              <Typography>
                Olá {user.name}, o Gaia System solicita a troca de sua senha de acesso a cada
                trimestre por medidas de segurança.
              </Typography>
              <br />
              <Typography>
                Clique no botão de CONFIRMAR para ser redirecionado à tela de alteração de senha.
              </Typography>
            </>
          )}
        </Dialog.Content>
        <Dialog.Actions>
          <Button onClick={signOut}>Logout</Button>
          <Button onClick={navigateToProfileLocation}>Confirmar</Button>
        </Dialog.Actions>
      </Dialog>
    </>
  );
};
