export const exemplo = {
  sheetName: 'exemplo',
  data: [
    {
      COLUNA: 'BEMFACIL DIGITAL',
      PARCEIRO: '30699142000150',
      PROCESSOS: 'DEVOLUÇÃO SEM REPARO',
      CLIENTE: '276194',
    },
    {
      COLUNA: 'CEPAV DO BRASIL',
      PARCEIRO: '01026219000122',
      PROCESSOS: 'MASTER RPA',
      CLIENTE: 'A&S ESTACIONAMENTO',
    },
    {
      COLUNA: 'CERTO SERVICOS',
      PARCEIRO: '09248858000144',
      PROCESSOS: 'MASTER RPT',
      CLIENTE: 'A&S ESTACIONAMENTOS LTDA',
    },
    {
      COLUNA: 'Econ  Servicos Admi',
      PARCEIRO: '11253814000154',
      PROCESSOS: 'MAU USO',
      CLIENTE: 'ACWARE COM EQUIPAMENTOS AUTOMAC COM EIRELI',
    },
    {
      COLUNA: 'ESMERALDA SERVICOS',
      PARCEIRO: '17768068000118',
      PROCESSOS: 'PERSONALIZAÇÃO',
      CLIENTE: 'ADAPTIVE TECNOLOGIA DA INFORMACAO LTDA',
    },
    {
      COLUNA: 'FEDEX CAJAMAR',
      PARCEIRO: '10970887014588',
      PROCESSOS: 'PERSONALIZAÇÃO REPARO',
      CLIENTE: 'ADMINISTRADORA GERAL DE ESTACIONAMENTOS',
    },
    {
      COLUNA: 'FEDEX GUARULHOS',
      PARCEIRO: '10970887000285',
      PROCESSOS: 'RECUPERACAO',
      CLIENTE: 'AEP ADMINISTRADORA DE ESTACIONAMENTOS LTDA EPP',
    },
    {
      COLUNA: 'FLEXTRONICS INTERNAT',
      PARCEIRO: '74404229000390',
      PROCESSOS: 'RECUPERADO',
      CLIENTE: 'ANA PAULA MARTINS BERTOLDI GATO - ME (INFOTECH INFORMATICA)',
    },
    {
      COLUNA: 'GETNET SEQUOIA',
      PARCEIRO: '01599101000193',
      PROCESSOS: 'REFURBISH',
      CLIENTE: 'ANDRE DA ROCHA OLIVEIRA',
    },
    {
      COLUNA: 'GETNET SPFLY',
      PARCEIRO: '28985079000176',
      PROCESSOS: 'REFURBISH PLACAS',
      CLIENTE: 'APPLE-RJ',
    },
    {
      COLUNA: 'GSURF',
      PARCEIRO: '05643319000159',
      PROCESSOS: 'REP. PROD STK',
      CLIENTE: 'APPLE-SP',
    },
    {
      COLUNA: 'INGENICO DO BRASIL LTDA',
      PARCEIRO: '03616814000810',
      PROCESSOS: 'REPARO DE PLACAS',
      CLIENTE: 'ARCAR CENTRO OESTE',
    },
    {
      COLUNA: 'INGENICO DO BRASIL LTDA',
      PARCEIRO: '03616814000143',
      PROCESSOS: 'REPARO DE POS INGENICO',
      CLIENTE: 'ARIS TECNOLOGIA',
    },
    {
      COLUNA: 'INGENICO DO BRASIL LTDA',
      PARCEIRO: '03616814000577',
      PROCESSOS: 'TRIAGEM OPL',
      CLIENTE: 'ARP EMPREENDIMENTOS IMOBILIARIOS LTDA',
    },
    {
      COLUNA: 'INGENICO DO BRASIL LTDA',
      PARCEIRO: '03616814000658',
      PROCESSOS: 'VENDA DIRETA',
      CLIENTE: 'ASTEMAC AUTOMACAO COMERCIAL LTDA  ME',
    },
    {
      COLUNA: 'IT Technology Ltda',
      PARCEIRO: '24157252000197',
      PROCESSOS: 'VENDA DIRETA BUFFER',
      CLIENTE: 'ATM INFORMATICA LTDA EPP',
    },
    {
      COLUNA: 'JAXTECH',
      PARCEIRO: '22932937000138',
      PROCESSOS: '',
      CLIENTE: 'AVANCO INFORMATICA LTDA',
    },
    {
      COLUNA: 'Mandacaru Administra',
      PARCEIRO: '09370323000141',
      PROCESSOS: '',
      CLIENTE: 'B.FACIL SERVICOS E TECNOLOGIA LTDA',
    },
    {
      COLUNA: 'MERCADO PAGO',
      PARCEIRO: '10573521000434',
      PROCESSOS: '',
      CLIENTE: 'B4NK SERVIÇO DE TECNOLOGIA LTDA',
    },
    { COLUNA: 'MERCADO PAGO', PARCEIRO: '10573521000272', PROCESSOS: '', CLIENTE: 'BAM COMERCIO' },
    {
      COLUNA: 'ML SOLUCOES DE VAREJ',
      PARCEIRO: '33650301000209',
      PROCESSOS: '',
      CLIENTE: 'BANCO ITAÚ',
    },
    {
      COLUNA: 'MR AUTOMAÇÃO',
      PARCEIRO: '18280114000106',
      PROCESSOS: '',
      CLIENTE: 'BANCO SAFRA /SEQUOIA',
    },
    {
      COLUNA: 'NCR',
      PARCEIRO: '33033440001176',
      PROCESSOS: '',
      CLIENTE: 'BANCO SAFRA/ SEQUOIA EMBU',
    },
    { COLUNA: 'NCR BRASIL', PARCEIRO: '32766379003157', PROCESSOS: '', CLIENTE: 'BANRISUL' },
    {
      COLUNA: 'NETSET DO BRASIL',
      PARCEIRO: '68950385000515',
      PROCESSOS: '',
      CLIENTE: 'BANRISUL/TEFTI',
    },
    {
      COLUNA: 'OGEA SERVICOS LTDA',
      PARCEIRO: '31287199000457',
      PROCESSOS: '',
      CLIENTE: 'BMB MATERIAL DE CONSTRUCAO S.A.',
    },
    { COLUNA: 'PAYTEC', PARCEIRO: '04184712000240', PROCESSOS: '', CLIENTE: 'CAIXA_VENDA_DIRETA' },
    {
      COLUNA: 'Quero 2 Pay soluçoes',
      PARCEIRO: '36382680000100',
      PROCESSOS: '',
      CLIENTE: 'CAPPTA',
    },
    { COLUNA: 'REDE COMODATO', PARCEIRO: '01425787000104', PROCESSOS: '', CLIENTE: 'CAPTA' },
    {
      COLUNA: 'SCAN SOURCE  PR',
      PARCEIRO: '05607657000135',
      PROCESSOS: '',
      CLIENTE: 'CAPTA SERVICOS, CONSULTORIA E INTERMEDIAÇÃO LTDA',
    },
    {
      COLUNA: 'SCAN SOURCE SP',
      PARCEIRO: '05607657001107',
      PROCESSOS: '',
      CLIENTE: 'CAR AS A SERVICE LTDA',
    },
    { COLUNA: 'SOFTWARE EXPRESS', PARCEIRO: '55649404000100', PROCESSOS: '', CLIENTE: 'CEOPAG' },
    { COLUNA: 'STARTTECH INFORMATIC', PARCEIRO: '30634002000101', PROCESSOS: '', CLIENTE: 'CEPAV' },
    {
      COLUNA: 'STONE LOGISTICA LTDA',
      PARCEIRO: '16810540000170',
      PROCESSOS: '',
      CLIENTE: 'CEPAV / C&A',
    },
    { COLUNA: 'SYSDATA', PARCEIRO: '05406237000352', PROCESSOS: '', CLIENTE: 'CEPAV / SANTANDER' },
    {
      COLUNA: 'TCA COMERCIO LTDA',
      PARCEIRO: '59870436000184',
      PROCESSOS: '',
      CLIENTE: 'CERACA COOP.DE INFRA.E DESEN.VALE',
    },
    {
      COLUNA: 'TECH CENTER',
      PARCEIRO: '07771362000125',
      PROCESSOS: '',
      CLIENTE: 'CERTO / BORBANET',
    },
    {
      COLUNA: 'TEFTI TECNOLOGIAES',
      PARCEIRO: '07697245000169',
      PROCESSOS: '',
      CLIENTE: 'CERTO / DA CASA',
    },
    {
      COLUNA: 'TRIPAGTEFTI',
      PARCEIRO: '31287199000104',
      PROCESSOS: '',
      CLIENTE: 'CERTO / DATA PROM',
    },
    {
      COLUNA: 'Unidade Minas Gerais',
      PARCEIRO: '03616814000305',
      PROCESSOS: '',
      CLIENTE: 'CERTO / EXTRACARD',
    },
    {
      COLUNA: 'VERIFONE',
      PARCEIRO: '01394860000381',
      PROCESSOS: '',
      CLIENTE: 'CERTO / NACIONAL P',
    },
    {
      COLUNA: 'VIA EXPRESSA LOGISTI',
      PARCEIRO: '21197824000173',
      PROCESSOS: '',
      CLIENTE: 'CERTO / NACIONAL T',
    },
    {
      COLUNA: 'WPS BRASIL LTDA',
      PARCEIRO: '04225761000100',
      PROCESSOS: '',
      CLIENTE: 'CERTO / REDE FLEX',
    },
    { COLUNA: 'YOURPAY', PARCEIRO: '27997260000130', PROCESSOS: '', CLIENTE: 'CERTO / TELECOM' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'CERTO / TENDENCIA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'CERTO / TENDENCIA N2' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'CERTO SERV.ELETRON' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'CERTO YUP INGRESSOS' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'CERTO/CONEKTA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'CERTO/CREDALIMENTAÇÃO' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'CERTO/PONTO CERTO' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'CERTO/RV' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'CERTO/SAUDE SERVICE' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'CERTO/VALLE CARD' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'CH BOHRER E CIA (POISON SURF SHOP)' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'CIA SISTEMA SUPORTE TECNICO LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'CIELO INTEC' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'CIELO RAPIDÃO' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'CIELO RAPIDÃO / M4U' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'CIELO SEQUOIA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'CIELO SEQUOIA / M4U' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'CIELO SEQUOIA INT' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'CIELO_FEDEX CAJAMAR' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'CIELO_FLEXTRONICS' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'CIELO_VENDA_DIRETA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'COMPUS INFORMATICA LTDA  E' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'CONEKTA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'CR SOFTWARE EXPRESS' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'CRUZEIRO EQUIPAMENTOS' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'DCM AUTOMACAO E SERVICOS EIRELI' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'DDTOTAL GFI ARRANJOS DE PAGAMENTO S' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'DIGIMAQ REGISTRADORAS SISTEMAS' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'DIGITAL EAZY PARTICIPAÇÕES LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'DIMAQSUL INFORMATICA LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'DIRECAO ESTACIONAMENTOS S/A' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'DW SERVICOS DE ESTACIONAMENTO LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'ECON SERVICOS ADMINISTRATIVOS LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'ELAVON' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'ELAVON / NETSET' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'ELAVON / PAYTEC' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'ELAVON / SEQUOIA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'ELAVON INTEC' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'ELOG SERVICE' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'ELOG/ EVOLUSERVICE' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'ELOG/ PONTO CERTO' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'ELOG/CONEKTA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'ELOG/CONEKTATEFTI' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'ELOG/FOODTEAM' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'ELOG/NACIONAL' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'ELOG/NACIONALPAYTEC' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'ELOG/NACIONALTEFTI' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'ELOG/REDEPAY' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'ELOG/TELECOM' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'ELOG/TENDENCIA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'ELOGSERVICE/CUSTOMERS' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'ELOGSERVICE/LECARD' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'ELOGSERVICE/LIBERCARD' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'ELOGSERVICE/SAMAR' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'ELOGSERVICE/SAUDE' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'ELOGSERVICE/TW2' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'EMB & EDSOFT INFOR. LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'EMP. BRASILEIRA DE TECNOL. E SERV. LTDA' },
    {
      COLUNA: '',
      PARCEIRO: '',
      PROCESSOS: '',
      CLIENTE: 'EMPRESA BRASILEIRA DE TECNOLOGIA E SERV LTDA',
    },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'ESFERA 5 TECNOLOGIA E PAGAMENTOS S.A.' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'ESMERALDA SERVICOS DIGITAIS LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'ESTACIONAMENTO DE VEICULOS SHOPPING' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'FABIO PORTO DOS SANTOS' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'FACIL PAY' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'FACIL PAY /TEFIT' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'FACIL PAY /TRANRED' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'FACILITA / REDEFLEX' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'FACILITA LOG / REDEFLEX' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'FEDERAL PARKING' },
    {
      COLUNA: '',
      PARCEIRO: '',
      PROCESSOS: '',
      CLIENTE: 'FEDEX BRASIL LOGISTICA E TRANSPORTE LTDA',
    },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'FEDEX GLOBAL/REDSYS' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'FIRST DATA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'FIRST DATA - ADQ_BIN' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'FIRST DATA - ADQ_BIN_FITCARD' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'FIRST DATA - ADQ_FITCARD' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'FIRST DATA - ADQ_SIPAG' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'FIRST DATA - ADQ_SIPAG_FITCARD' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'FIRST DATA - CEF' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'FIRST DATA - CEF_FITCARD' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'FIRST DATA - CNB' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'FIRST DATA - CNB_FITCARD' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'FIRST DATA - CREFISA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'FIRST DATA - CREFISA_FITCARD' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'FIRST DATA - SICRED' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'FIRST DATA - SICRED_FITCARD' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'FIRST DATA CAIXA/SEQUOIA_FITCARD' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'FIRST DATA CAIXA_FITCARD' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'FIRST DATA CREDICARD' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'FIRST DATA CREDICARD_FITCARD' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'FIRST DATA INSTITUIÇÃO' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'FIRST DATA INSTITUIÇÃO_FITCARD' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'FIRST DATA SOROCRED' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'FIRST DATA SOROCRED_FITCARD' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GATE GOURMET LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GATE RETAIL' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GC ESTACIONAMENTOS LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GENERICO' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET CHECKOUT / FEDEX' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET CHECKOUT / NETSET' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET CHECKOUT / PAYTEC' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET CHECKOUT / VERIFONE' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET FEDEX' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET LINK_BLUETOOTH / FEDEX' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET LINK_BLUETOOTH / NETSET' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET LINK_BLUETOOTH / PAYTEC' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET LINK_WIFI / FEDEX' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET LINK_WIFI / NETSET' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET LINK_WIFI / PAYTEC' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET NETSET' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET PAYTEC' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET PAYTEC-VENDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET SEQUOIA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET SPFLY' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET VERIFONE' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET_CHECKOUT / PAYTEC' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET_CHECKOUT / SEQUOIA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET_CHECKOUT / SPFLY' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET_CHECKOUT/PAYTEC-VENDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET_LINK WIFI PLUS_ VERIFONE' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET_LINK WIFI PLUS_FEDEX' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET_LINK WIFI PLUS_NETSET' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET_LINK WIFIPLUS_PAYTEC' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET_LINK WIFIPLUS_PAYTEC-VENDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET_LINK WIFIPLUS_SEQUOIA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET_LINK WIFIPLUS_SPFLY' },
    {
      COLUNA: '',
      PARCEIRO: '',
      PROCESSOS: '',
      CLIENTE: 'GETNET_PINPAD LINK BLUETOOH_PAYTEC-VENDA',
    },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET_PINPAD LINK BLUETOOTH_ VERIFONE' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET_PINPAD LINK BLUETOOTH_FEDEX' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET_PINPAD LINK BLUETOOTH_NETSET' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET_PINPAD LINK BLUETOOTH_PAYTEC' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET_PINPAD LINK BLUETOOTH_SEQUOIA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET_PINPAD LINK BLUETOOTH_SPFLY' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET_VD_SEQUOIA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET_VD_SPFLY' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GETNET_VENDA_DIRETA' },
    {
      COLUNA: '',
      PARCEIRO: '',
      PROCESSOS: '',
      CLIENTE: 'GILSON RAFAEL SANTIAGO DE MELO INFORMATICA ME',
    },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GLOBAL FEDEX' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GLOBAL PAYTEC' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GLOBAL SABANCO' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GLOBAL/ SEQUOIA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GLOBAL/TEFTI' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GSOFT SOLUÇÕES TECNOLÓGICAS EIRELI' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GSURF' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'GULA MANIA MINIMERCADO EIRELI' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'HIPER CARNES COMERCIAL LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'HOME TECH COMERCIO E INDUSTRIA LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'HUB PAGAMENTOS S.A (MAGALU)' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'INFOMAQ' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'ING_FIRSTDATA_SICRED_FITCARD' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'INGENICO' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'INGENICO DO BRASIL LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'INGENICO SBT' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'INGENICO_APPLE' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'INGENICO_CIELO_VENDA_DIRETA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'INGENICO_FIRSTDATA_VENDA_DIRETA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'INGENICO_GET_VENDA_DIRETA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'INGENICO_GETNET_VENDA_DIRETA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'INGENICO_MEGAPOP_VENDA_DIRETA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'INGENICO_PASSAÍ' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'INGENICO_PASSAÍ_VENDA_DIRETA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'INGENICO_SICRED_VENDA_DIRETA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'INGENICO_STELO' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'INGENICO_STELO_VENDA_DIRETA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'INOVE AUTOMAÇAO E SERVIÇOS' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'IT TECHNOLOGY LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'ITAU CREDICARD' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'ITAU FIC' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'ITAU HIPERCARD' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'ITAU LUIZA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'ITAU/PROXXI' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'ITAU/SPREAD' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'J.M. GASPAR & CIA LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'JAXTECH' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'JD. DE SOUZA PREPRARP DE ALIMENTOS LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'JORDANA MILACH' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'KCMS' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'KREDIT PAGAMENTOS S/A' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'LINKB COMERCIO E SERVICOS LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'LINKC COMERCIO E SERVICOS LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'LISTO' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'LSR ESTACIONAMENTO' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'LUCRE SOLUCOES INTELIGENTES' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'LUIZ CARLOS PALMA & CIA LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'M. A. A. DA SILVA INFORMATICA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'MACSUL AUTOMACAO COMERCIAL' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'MANDACARU ADMINISTRADORA DE CARTOES S/A' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'MAQMOL AUTOMAÇÃO COMERCIAL LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'MARINGA TELECOM LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'MEGAPOP_VENDA_DIRETA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'MERCADO NAVARRO LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'MERCADO PAGO' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'MERCEARIA ROMA LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'ML SOLUCOES DE VAREJO S.A (PROPIG)' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'MOOZ / FEDEX (BAU)' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'MOOZ SOLUÇÕES FINANCEIRAS LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'MOOZ SOLUÇÕES FINANCEIRAS LTDA (TAAS)' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'MR AUTOMACAO' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'MULTIDISPLAY COMERCIO E SERVICOS (M4U)' },
    {
      COLUNA: '',
      PARCEIRO: '',
      PROCESSOS: '',
      CLIENTE: 'MULTIMAC MAQUINAS E EQUIPAMENTOS BAURU EIRELI',
    },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'NCR BRASIL' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'NEW EQUIPAMENTOS E SERVIÇOS' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'NORTE GAS COM E DIST DE GAS LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'NOVA PANIFICADORA 24 HS' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'OGEA SERVICOS LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'OKI' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'OKI / CONSOLAÇÃO' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'OKI BRASIL/BELA VISTA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'OKI/BELA VISTA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'OKI/JUNDIAÍ' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'OKI/ORIGAMI/CONSOLAÇÃO' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'OKI/ORIGAMI/DIADEMA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'OKI/ORIGAMI/JUNDIAI' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'ONCLICK SISTEMAS DE INFORMACAO LIMITADA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'OPV' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'OTECH' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'OTECH COM SERV DE INFO LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'PAG TODOS' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'PAGSEGURO - OSASCO' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'PAGSEGURO SBC' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'PASSAÍ' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'PASSAÍ_VENDA_DIRETA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'PAYTEC' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'PAYTEC GLOBAL/NEXXPAGO' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'PAYTEC GLOBAL/REDSYS' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'PAYTEC VALE CARD' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'PAYTEC/PONTO CERTO' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'PAYTEC/SOLANIR' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'PAYTEC/STONE' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'PAYTEC/TELECOM' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'PEREIRA, COLONHEZI & CIA. LTDA. ME' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'PLAZA CARAPICUIBA EMPREENDIMENTO' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'POUPE SUPERMERCADOS LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'PUNTO' },
    {
      COLUNA: '',
      PARCEIRO: '',
      PROCESSOS: '',
      CLIENTE: 'QUERO 2 PAY SOLUÇOES E MEIOS PAGAMENTO LTDA',
    },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'R.L.S. COMERCIO DE COMBUSTIVEIS LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'RC AUTOMACAO COMERCIAL LTDA ME' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'REBOUCAS SUPERMERCADO' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'REDE FD CURITIBA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'REDE FD GUARULHOS' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'REDE FD RECIFE' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'REDE FD SUMARÉ' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'REDE MEGA BA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'REDE MEGA MG' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'REDE NETSET' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'REDE NETSET / IPIRANGA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'REDE POE/ CURITIBA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'REDE POE/ GUARULHOS' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'REDE POE/ RECIFE' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'REDE RAPIDÃO' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'REDE RAPIDÃO / IPIRANGA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'REDE SABANCO' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'REDE SABANCO / IPIRANGA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'REDE/VIA EXPRESSA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'REDE/VIA EXPRESSA POE' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'REDE/VIA EXPRESSA/CUSTOM' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'REDECARD_VENDA_DIRETA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'REDEFLEX' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'RESPONDE FACIL' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'RIO ECF AUTOMACAO COMERCIAL LTDA ME' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'RP TECNOLOGIA EM CAPTURAS' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'RV TECNOLOGIA MG' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'RV TECNOLOGIA RJ' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'RV TECNOLOGIA SP' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'S.E COMERCIO E SERVICO DE ELETRONICOS' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'SABANCO GLOBAL/ REDSYS' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'SALA INFORMATICA EIRELLI' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'SCAN SOURCE PR' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'SCAN SOURCE SP' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'SCANSOURCE - NATURA' },
    {
      COLUNA: '',
      PARCEIRO: '',
      PROCESSOS: '',
      CLIENTE: 'SCANSOURCE BRASIL DISTRIBUIDORA DE TECNOLOGIAS LTDA',
    },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'SERVDADOS SOLUÇÕES' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'SETIS AUTOMACAO E SISTEMAS LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'SICRED_VENDA_DIRETA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'SINDICATO DO COMERCIO DE TEOFILO' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'SKIDATA SOLUCOES E SERVICOS EM TECN' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'SMARTNET LOCACAO E SERVICOS LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'SOFTWARE EXPRESS' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'STARTTECH INFORMATICA LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'STELO_VENDA_DIRETA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'STONE' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'STONE NETSET' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'STONE PAYTEC / PAGPOP' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'STONE REDEPAY / PAYTEC' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'STONE SEQUOIA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'STONE/PAYTEC' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'SUPERMERCADO CURY QUEIROZ LTDA - EPP' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'SUPERMERCADO MAIRINQUE LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'SUPERMERCADOS PONTIN E PONTIN LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'SUPERNET LOCACOES LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'SYSDATA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'SYSDATA / TICKETCAR' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'SYSDATA TICKET CAR/ PA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'SYSDATA TICKET CAR/ SP' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'TCA/COMERCIO' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'TCA/SANTANDER' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'TECNOLOGIA BANCARIA S.A' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'TEFTI' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'TENDENCIA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'TENDENCIA/CERTO' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'TERMINALTEC SOLUCOES LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'TIME WARE AUTOMACAO' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'TRIPAG / OGEA (FILIAL)' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'TRIPAG TEFTI' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'UNITEC C/ CAIXAS REGIS. LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'UNIVERSO AUTOMACAO' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'UZIAS DA COSTA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'VALE NET' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'VITALCRED/ PAGPOP' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'VS SOLUTIONS AUTOMACAO' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'WARI SISTEMAS LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'WGA AUTOMACAO LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'WPS BRASIL LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'WS PARK LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'YOURPAY - TECNOLOGIA EM PAGAMENTOS' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'YOURPAY - TECNOLOGIA EM PAGAMENTOS LTDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'YOURPAY_2M' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'YOURPAY_AKIREDE' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'YOURPAY_AREATEC' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'YOURPAY_ASG' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'YOURPAY_CREDTAG' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'YOURPAY_CUSTOM' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'YOURPAY_EUROPAG' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'YOURPAY_GERESTAR' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'YOURPAY_MERCATTUM' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'YOURPAY_SERBET' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'YOURPAY_SINALVIDA' },
    { COLUNA: '', PARCEIRO: '', PROCESSOS: '', CLIENTE: 'YOURPAY_UP' },
  ],
};
