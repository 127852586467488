import * as React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';

export const RowsEmpty = ({ emptyMessage, columnsCount }) => {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell align='center' colSpan={columnsCount}>
        <Typography className={classes.text}>{emptyMessage}</Typography>
      </TableCell>
    </TableRow>
  );
};
