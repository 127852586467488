import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Text } from 'components';
import { Field, Form, Formik } from 'formik';

export const ShowBeeping = ({
  dataMain,
  selectedNote,
  validationSerial,
  handleProcess,
  validationPn,
  user,
}) => {
  const [selectStatusReincidencia, setStatusReincidencia] = useState('');
  const [serialDisabled, setSerialDisabled] = useState(true);
  const [client, setClient] = useState([]);

  // Validação de PN
  const handleValidationPn = async (event, setFieldValue, resetForm) => {
    if (event.key === 'Enter') {
      const serialField = document.querySelector('[name="input_serial"]');
      const data = await validationPn(event.target.value, dataMain?.[0]?.idCabec);

      if (!data) {
        resetForm();
        return;
      }

      setClient(data);
      setFieldValue('input_vincModelo', data?.[0]?.modelo);
      setSerialDisabled(false);
      serialField.focus();
    }
  };

  // Validação de Serial
  const handleValidationSerial = async (event, setFieldValue, resetForm) => {
    if (event.key === 'Enter') {
      const serialProcessButton = document.querySelector('[name="btn_processar_beeping"]');
      const inputPn = document.querySelector('[name="input_pn"]');
      const serial = event.target.value;
      const serial20dig = serial.length === 24 ? serial.substring(4, 24) : serial;
      const ClienteOPV = selectedNote?.ClienteOPV.substring(0, 4).trim();
      const idCabec = dataMain?.[0]?.idCabec;

      const data = await validationSerial(idCabec, ClienteOPV, serial, serial20dig);
      console.log('retorno validationSerial', data);
      if (data.status === 200) {
        setStatusReincidencia(data.response[0].IdStatusReincidencia);
        console.log(serialProcessButton);
        if (serialProcessButton) {
          serialProcessButton?.click();
        }
      } else {
        serialProcessButton.disabled = true;
        setSerialDisabled(true);
        resetForm();
        inputPn?.focus();
      }
    }
  };

  // Envio de dados ao processar
  const handleSubmit = async (values, setFieldValue, resetForm) => {
    const payload = {
      idCabec: dataMain?.[0]?.idCabec,
      idProdModelo: client?.[0]?.idProdModelo || dataMain?.[0]?.idProdModelo,
      idModelo: client?.[0]?.idModelo || dataMain?.[0]?.idModelo,
      serial: values.input_serial,
      usuario: user.name,
      faltaPeca: values.faltaPeca,
      IdStatusReincidenciaGaia: selectStatusReincidencia,
      imei: '',
      mac: '',
      ativo: '',
      patrimonio: '',
    };
    await handleProcess(payload);
    resetForm();
  };

  return (
    <Formik
      initialValues={{ input_pn: '', input_serial: '', faltaPeca: false, input_vincModelo: '' }}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, resetForm, values }) => (
        <Form>
          <Box mt={5}>
            <Grid container spacing={2} justifyContent='center' alignItems='center'>
              <label style={{ fontSize: '18px' }}>
                <Field type='checkbox' name='faltaPeca' style={{ width: '20px', height: '20px' }} />
                Falta de Peças
              </label>
            </Grid>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h6' style={{ paddingTop: '2rem' }}>
                Coleta de Dados
              </Typography>
            </Grid>

            <Grid item xs={12} lg={4}>
              <Field
                size='small'
                name='input_pn'
                label='PN'
                variant='outlined'
                component={Text}
                autoComplete='off'
                // disabled={dataMain?.[0]?.Processados === dataMain?.[0]?.qtdNf}
                onClick={() => setFieldValue('input_pn', '')}
                onKeyDown={(event) => handleValidationPn(event, setFieldValue, resetForm)}
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <Field
                size='small'
                name='input_vincModelo'
                variant='outlined'
                component={Text}
                disabled
                autoComplete='off'
                value=''
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <Field
                size='small'
                name='input_serial'
                label='Serial'
                variant='outlined'
                component={Text}
                autoComplete='off'
                disabled={serialDisabled}
                onKeyDown={(event) => handleValidationSerial(event, setFieldValue, resetForm)}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <Button fullWidth name='btn_processar' disabled>
                Processar
              </Button>
            </Grid>

            <Grid item xs={12} lg={3}>
              <Button
                fullWidth
                name='btn_processar_beeping'
                onClick={() => handleSubmit(values, setFieldValue, resetForm)}
                disabled={false}
                style={{ display: 'none' }} //invisivel
                inert
              >
                Processar Invisível
              </Button>
            </Grid>

            <Grid item xs={12} lg={3}>
              <Button
                fullWidth
                name='btn_clear_beeping'
                onClick={() => {
                  resetForm();
                  setSerialDisabled(true);
                }}
                style={{ display: 'none' }}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
