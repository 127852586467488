import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Grid } from '@mui/material';
import { Button } from 'components';
import { Form, Formik } from 'formik';
import { downloadXlsx } from 'helpers/downloadXLSX';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providerService } from 'services';

export const WfpWithPn = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAging, setIsAging] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      console.log({ isAging });
      const { data } = await providerService.getWfpWithPn({ isAging: isAging });
      if (data.length > 0) {
        downloadXlsx('ingenico_wfp_com_pn', data);
        toast.success('Relatório gerado com sucesso');
      } else {
        toast.error('Não há dados para esse relatório.');
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <PageTitle>Relatório WFP com PN</PageTitle>

      <Formik initialValues={{}} onSubmit={handleSubmit}>
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={3} md={3} lg={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAging}
                    onChange={(e) => setIsAging(e.target.checked)}
                    color='primary'
                  />
                }
                label='OSs em Aging'
              />
            </Grid>
            <Grid item xs={3} md={3} lg={3}>
              <Button fullWidth type='submit' loading={isLoading}>
                Exportar
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </>
  );
};
