import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Button, DatePicker, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providerService } from 'services';
import * as XLSX from 'xlsx';

const typeDate = [
  { label: 'TODOS', value: 0 },
  { label: 'Saída', value: 1 },
];

export const SignageReport = () => {
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await providerService.findClientsExped();
        const allClients = [{ label: 'TODOS', value: 0 }, ...data];
        setClients(allClients);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await providerService.getSignageRepostExpedition({
        clients: values.client,
        input_date: values.input_date,
        output_date: values.output_date,
        type_date: values.type_date,
      });
      console.log(data);
      exportToExcel(data.result, data.result2);
      setIsLoading(false);
      toast.success('Relatório gerado com sucesso!');
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      toast.error(error?.response?.data?.message || 'Erro ao baixar relatório');
    }
  };

  const exportToExcel = (result, result2) => {
    const workbook = XLSX.utils.book_new();

    const worksheet1 = XLSX.utils.json_to_sheet(result);
    const worksheet2 = XLSX.utils.json_to_sheet(result2);

    XLSX.utils.book_append_sheet(workbook, worksheet1, 'DADOS');
    XLSX.utils.book_append_sheet(workbook, worksheet2, 'RESUMO_NFS');

    XLSX.writeFile(workbook, 'relatorio_sinalizacao.xlsx');
  };
  return (
    <>
      <PageTitle>Relatório de Sinalização (Expedição)</PageTitle>

      <Formik
        initialValues={{
          type_date: 0,
          input_date: new Date(),
          output_date: new Date(),
          client: [0],
        }}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Field
                  name='client'
                  label='Cliente'
                  component={Select}
                  variant='outlined'
                  fullWidth
                  size='small'
                  options={clients.map((client) => ({
                    ...client,
                    disabled: props.values.client.includes(0) && client.value !== 0,
                  }))}
                  multiple
                />
              </Grid>
              <Grid item xs={2}>
                <Field
                  name='type_date'
                  label='Tipo de Data'
                  component={Select}
                  variant='outlined'
                  fullWidth
                  size='small'
                  options={typeDate}
                />
              </Grid>
              {props.values.type_date !== 0 && (
                <>
                  <Grid item xs={2}>
                    <Field
                      name='input_date'
                      label='Data'
                      component={DatePicker}
                      variant='inline'
                      fullWidth
                      inputVariant='outlined'
                      size='small'
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Field
                      name='output_date'
                      label='Data'
                      component={DatePicker}
                      variant='inline'
                      fullWidth
                      inputVariant='outlined'
                      size='small'
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={2}>
                <Button
                  type='submit'
                  fullWidth
                  startIcon={<FileDownloadIcon />}
                  loading={isLoading}
                >
                  Exportar
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
