import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  listItem: {
    borderRadius: 4,
    transition: 'background .2s',
    background: 'rgba(0, 0, 0, 0.5)',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
    padding: '10px 16px',
    marginBottom: theme.spacing(1),
  },
  listIcon: {
    color: 'rgba(255, 255, 255, .9)',

    minWidth: 'unset',
    '& > svg': {
      width: 18,
      height: 18,
    },
  },
  listItemTextRoot: {
    marginBottom: 0,
  },
  listItemText: {
    paddingLeft: 16,
    fontSize: 14,
    fontWeight: 500,
  },
  expandMoreIcon: {
    transition: 'transform .4s',
    transform: 'rotateZ(-90deg)',

    '&.open': {
      transform: 'rotateZ(0)',
    },
  },
}));
