import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { Button, DatePicker, Select, Table } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';

import { receivementService } from '../../../../services/receivement';

const initialValues = { partner: 0, process: 0, input_date: new Date(), output_date: new Date() };

const columns = [
  { label: 'NF' },
  { label: 'Data Cadastro' },
  { label: 'Cliente' },
  // { label: 'Sub Cliente' },
  { label: 'Processo' },
  { label: 'Danfe' },
  // { label: 'CCE' },
  { label: 'Excel' },
  { label: 'Danfe Ingênico' },
  // { label: 'XML Ingênico' },
  // { label: 'Irregularidade' },
  // { label: 'Laudo Multiplo' },
];

export const ArchivesDownload = () => {
  const [proccess, setProccess] = useState([]);
  const [partner, setPartner] = useState([]);
  const [clients, setClients] = useState([]);
  const [propsProcess, setPropsProccess] = useState();
  const [propsPartner, setPropsPartner] = useState();
  const [table, setTable] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    const { data: proccess } = await receivementService.findProccess();
    const { data: partner } = await receivementService.findPartner();
    const allProccess = [{ label: 'TODOS', value: 0 }, ...proccess];
    const allPartner = [{ label: 'TODOS', value: 0 }, ...partner];
    setPartner(allPartner);
    setProccess(allProccess);
  };

  const getClients = async () => {
    const { data: clients } = await receivementService.findClient({
      process: propsProcess ? propsProcess : 0,
      partner: propsPartner ? propsPartner : 0,
    });
    setClients(clients);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    getClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propsProcess, propsPartner]);

  const handleGetTable = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await receivementService.findTable({
        proccess: values.process,
        partner: values.partner,
        client: values.client,
        input_date: values.input_date,
        output_date: values.output_date,
      });
      setIsLoading(false);
      setTable(data);
    } catch (error) {
      setIsLoading(false);
      console.error(error?.response?.data?.message);
    }
  };
  return (
    <>
      <PageTitle>Download de Arquivos</PageTitle>

      <Formik initialValues={initialValues} onSubmit={handleGetTable}>
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={3} sm={3} md={3} lg={2}>
                <Field
                  name='process'
                  label='Processo'
                  options={proccess}
                  component={Select}
                  variant='outlined'
                  size='small'
                  fullWidth
                  onChange={(e) => setPropsProccess(e)}
                />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={2}>
                <Field
                  name='partner'
                  label='Parceiro'
                  options={partner}
                  component={Select}
                  variant='outlined'
                  size='small'
                  fullWidth
                  onChange={(e) => setPropsPartner(e)}
                />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={2}>
                <Field
                  name='client'
                  label='Cliente'
                  options={clients}
                  component={Select}
                  variant='outlined'
                  size='small'
                  fullWidth
                />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={2}>
                <Field
                  name='input_date'
                  label='Cliente'
                  component={DatePicker}
                  variant='inline'
                  fullWidth
                  size='small'
                  inputVariant='outlined'
                />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={2}>
                <Field
                  name='output_date'
                  label='Cliente'
                  component={DatePicker}
                  variant='inline'
                  fullWidth
                  size='small'
                  inputVariant='outlined'
                />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={2}>
                <Button type='submit' fullWidth loading={isLoading}>
                  Extrair
                </Button>
              </Grid>
            </Grid>
            <Box mt={1}>
              {table.length > 0 && (
                <Table name='Table' headers={columns} disableNumeration>
                  {table &&
                    table.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.numNF}</TableCell>
                        <TableCell>{item.dtCadastro}</TableCell>
                        <TableCell>{item.Cliente}</TableCell>
                        <TableCell>{item.Processo}</TableCell>
                        <TableCell>{item.DANFE}</TableCell>
                        <TableCell>{item.EXCEL}</TableCell>
                        <TableCell>{item.DANFE_ING}</TableCell>
                      </TableRow>
                    ))}
                </Table>
              )}
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
