import { api } from 'services/api';

export class BudgetService {
  async listBudgets() {
    return api.get('/budgets');
  }

  async listBudgetsById() {
    return api.get('/budgets/negociation-status');
  }

  async listBudgetsByNotNegociationId() {
    return api.get('/budgets/all-status');
  }

  async listBudgetsByFinished() {
    return api.get('/budgets/finished-status');
  }

  async listBudgetsServices(number) {
    return api.get(`/budgets/service/number/${number}`);
  }

  async listBudgetsServicesByServiceId(id) {
    return api.get(`/budgets/service/${id}`);
  }

  async listBudgetsServicesByServiceIdToAplicatedComponents(id) {
    return api.get(`/budgets/findaplicatedcomponents/${id}`);
  }

  async listStatuses() {
    return api.get('/budgets/statuses');
  }

  async availableToCancel() {
    return api.get('/budgets/cancel/available');
  }

  async createBudget(data) {
    return api.post('/budgets', data);
  }

  async createLink(data) {
    return api.post('/link-create', data);
  }

  async createPreBudgetTec(data) {
    return api.post('/budgets/pre-budgets-tec', data);
  }

  async listPreBudgetTec() {
    return api.get('/budgets/pre-budgets-tec');
  }

  async createFinalBudget(data) {
    return api.post('/budgets/final', data);
  }
  //
  async listAdditionalServiceProducts() {
    return api.get('/budgets/additional-service-products');
  }

  async listRefuseMotivation() {
    return api.get('/budgets/listRefuse-motivation');
  }

  async listProductsPagination(page) {
    return api.get(`/budgets/additional-service-products-pagination=${page}`);
  }

  async createAdditionalServiceProduct(data) {
    return api.post('/budgets/additional-service-products', data);
  }

  async cancelLinkSafe2Pay(values) {
    return api.post('/budgets/cancel-linksafe2pay', values);
  }

  async getByServiceOrderNumber(number) {
    return api.get(`/budgets/service/${number}`);
  }

  async addBudgetNegociation(budgetId, values) {
    return api.put(`/budgets/${budgetId}/negociation`, values);
  }

  async getServiceOrderType() {
    return api.get('/budgets/awaiting-withdrawal');
  }

  async getServiceOrderTypeInhome() {
    return api.get('/budgets/awaiting-finishInhome');
  }

  async listBudgetWithVoucher() {
    return api.get('/budgets/listVoucherByBudget');
  }

  async listBudgetRetail() {
    return api.get('/budgets/listBudgetRetail');
  }
  async listBudgetRetailByName(client_name) {
    return api.get(`/budgets/listBudgetRetailname/${client_name}`);
  }

  async listServiceInhomeNoGspn() {
    return api.get('/budgets/finished-inhomenogspn');
  }

  async setServiceOrderType(number) {
    return api.put(`/budgets/awaiting-withdrawal`, { number });
  }

  async setServiceOrderTypeInhome(number) {
    return api.put(`/budgets/awaiting-finishinhome`, { number });
  }

  async issueInvoiceBudget(data) {
    return api.post('/budgets/issue-invoice-budget', data);
  }

  async updateBudgetStatus(
    id,
    status_id,
    contactAttemptOne,
    contactAttemptTwo,
    contactAttemptThree,
  ) {
    return api.put(`budgets/status`, {
      id,
      status_id,
      contactAttemptOne,
      contactAttemptTwo,
      contactAttemptThree,
    });
  }

  async updateProduct(id, description) {
    return api.put(`/budgets/additional-service-products`, { id, description });
  }

  async listPreBudgetsAnalyzedFromTechnicianInPcp() {
    return api.get('/budgets/analyzed-in-pcp');
  }

  async listPreBudgetsAnalyzedFromTechnicianInPcpDate(input_date, output_date) {
    return api.get(`/budgets/listPreBudgetAnalyzedFromDate/${input_date}/${output_date}`);
  }

  async listPreBudgetsAnalyzedFromTechnicianInPcpDateSeg(input_date, output_date) {
    return api.get(`/budgets/listPreBudgetAnalyzedFromDateAllSeg/${input_date}/${output_date}`);
  }

  async listPreBudgetsAnalyzedFromTechnicianInPcpDateAll(input_date, output_date) {
    return api.get(`/budgets/listPreBudgetAnalyzedFromDateAll/${input_date}/${output_date}`);
  }

  async listPartsQuantity(budget_id) {
    return api.get(`/budgets/listPartsQuantity/${budget_id}`);
  }

  async getBudgetById(id) {
    return api.get(`/budgets/${id}`);
  }

  async updateSalesComponentePrice(id, sell_price, is_sales_active, min_price) {
    return api.put(`/budgets/sales-component`, { id, sell_price, is_sales_active, min_price });
  }

  async listPreBudgetsSalesComponents(part_numbers, process_type_id) {
    return api.get('/budgets/sales-component', {
      params: {
        partNumbers: part_numbers,
        process_type_id: Number(process_type_id),
      },
    });
  }

  async CreateSalesComponent(data) {
    return api.post('/budgets/sales-component', data);
  }

  async CreateSalesComponentFile(data) {
    return api.post('/budgets/sales-component-file', data);
  }

  async updateBudgetStatusDescription(id, description) {
    return api.put(`/budgets/statuses-update`, { id, description });
  }

  async listBudgetPaymentStatuses() {
    return api.get(`/budgets/payment-statuses`);
  }

  async listBudgetPaymentByStatusesWhereNotGenerateLink() {
    return api.get(`/budgets/payment-statuses-not-generate-link`);
  }

  async listAdditionalServices() {
    return api.get(`/budgets/additional-services`);
  }

  async listSwapPending() {
    return api.get(`/budgets/list-swap-pending`);
  }

  async listSwapFinalized() {
    return api.get(`/budgets/list-swap-finalized`);
  }

  async createSwap() {
    return api.post(`/budgets/create-swap`);
  }

  async listAdditionalServicesByProductType(additional_service_product_id) {
    return api.get(`/budgets/additional-services-by-type`, { additional_service_product_id });
  }

  async cancelBudget(data) {
    return api.put('/budgets/cancel-budget', data);
  }

  async getDanfe(service_order_number) {
    return api.get(`/budgets/reprint-danfe/${service_order_number}`, {
      responseType: 'arraybuffer',
    });
  }

  async getPostCard(service_order_number) {
    return api.get(`/budgets/reprint-post-card/${service_order_number}`, {
      responseType: 'arraybuffer',
    });
  }

  async getDanfeLocal(service_order_number) {
    return api.get(`/budgets/reprint-danfe-local/${service_order_number}`, {
      responseType: 'arraybuffer',
    });
  }

  async getPostCardLocal(service_order_number) {
    return api.get(`/budgets/reprint-post-card-local/${service_order_number}`, {
      responseType: 'arraybuffer',
    });
  }

  async getBudgetsApproved() {
    return api.get(`/budgets/approved`);
  }

  async getBudgetsReproved() {
    return api.get(`/budgets/reproved`);
  }

  async listBudgetSalesComponentsByBudgetId(budget_id) {
    return api.get(`/budgets/budget-sales-components/${budget_id}`);
  }
}

export const budgetService = new BudgetService();
