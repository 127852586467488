import React from 'react';
import { useRouteMatch } from 'react-router';

import { InventoryLock } from 'pages/Private/Stock/InventoryLock';

import { Route } from '../Route';

export const StockRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${path}/inventory-lock`} component={InventoryLock} />
    </>
  );
};
