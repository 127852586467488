import React from 'react';
import { useRouteMatch } from 'react-router';

import { FreezeApproval } from 'pages/Reports/FreezeApprovalReport';

import { Route } from '../Route';

export const ReportReleasesRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${path}/freeze-approval`} component={FreezeApproval} />
    </>
  );
};
