import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  cell: {
    padding: 0,
  },
  text: {
    opacity: 0.8,
    fontStyle: 'italic',
    padding: theme.spacing(4),
  },
}));
