import { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button } from 'components';
import { Form, Formik } from 'formik';
import { downloadXlsx } from 'helpers/downloadXLSX';
import { readXlsxFile } from 'helpers/readXLSX';
import { AuthContext } from 'hooks/useAuth';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providerService } from 'services';
import { useContextSelector } from 'use-context-selector';

export const RefuseUploadReport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const inputFileRef = useRef(null);
  const user = useContextSelector(AuthContext, (context) => context.user);

  const handleImportClick = () => {
    inputFileRef.current.value = '';
    inputFileRef.current.click();
  };

  function excelDateToJSDate(excelDate) {
    const excelEpoch = new Date(1900, 0, 1);
    const daysSinceEpoch = excelDate - 1;
    excelEpoch.setDate(excelEpoch.getDate() + daysSinceEpoch);
    return excelEpoch.toISOString().split('T')[0];
  }

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsLoading(true);
      try {
        const data = await readXlsxFile(file);

        data.forEach((row) => {
          if (row.ABERTURA && typeof row.ABERTURA === 'number') {
            row.ABERTURA = excelDateToJSDate(row.ABERTURA);
          }
        });

        const response = await providerService.getRefuseUpload({ json: data, user_gaia: user.id });
        downloadXlsx('upload_recusa', response.data);
        toast.success('Arquivo importado com sucesso!');
      } catch (error) {
        console.log(error?.response?.data?.message);
        toast.error(error?.response?.data?.message);
      } finally {
        setIsLoading(false);
        inputFileRef.current.value = '';
      }
    }
  };

  const handleDownloadLayout = () => {
    const data = [
      {
        CLIENTE: '',
        OPERADOR: '',
        TIPO: '',
        'CENTRO REPARO': '',
        NF1: '',
        QTD: '',
        ABERTURA: '',
        MODELO: '',
        SERIAL: '',
        'RECLAMACAO CLIENTE': '',
        'COD SINTOMA CLIENTE': '',
        'FAMILIA SINTOMA CLIENTE': '',
        'TIPO FALHA': '',
        'DENTRO FORA ESTADO': '',
        'SINALIZADO DENTRO PRAZO': '',
      },
    ];

    downloadXlsx('layout', data);
  };

  return (
    <>
      <input
        ref={inputFileRef}
        type='file'
        name='xlsx'
        onChange={handleFileChange}
        accept='text/xlsx'
        hidden
      />
      <PageTitle>Recusa por Upload</PageTitle>

      <Formik initialValues={{}}>
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3}>
                <Button fullWidth onClick={handleImportClick} loading={isLoading}>
                  Importar XLSX
                </Button>
              </Grid>
              <Grid item xs={12} lg={3}>
                <Button
                  color='secondary'
                  fullWidth
                  onClick={handleDownloadLayout}
                  loading={isLoading}
                >
                  Baixar Layout
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
