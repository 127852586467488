import * as React from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioMUI from '@material-ui/core/Radio';

import LabelBase from '../LabelBase';

const RadioComp = ({
  field,
  label,
  name,
  description,
  checked,
  form,
  errorMessage: errorMessageProp,
  onChange,
  margin,
  value,
}) => {
  const isChecked = React.useMemo(
    () => (form ? String(form.values[field?.name]) === String(value) : checked),
    [checked, form, field, value],
  );

  const handleChange = React.useCallback(
    (e, checked) => {
      const stringBooleans = ['true', 'false'];
      if (stringBooleans.includes(e.target.value)) {
        form?.setFieldValue(field?.name, e.target.value === 'true');
      } else {
        form?.setFieldValue(field?.name, e.target.value);
      }
      onChange && onChange(e, checked);
    },
    [form, field, onChange],
  );

  const errorMessage = errorMessageProp ?? form?.errors[field?.name];
  const hasError = !!errorMessage;

  return (
    <FormControlLabel
      control={
        <RadioMUI
          classes={{ root: margin === 'none' }}
          checked={isChecked}
          onChange={handleChange}
          name={name}
          color='primary'
          value={value}
        />
      }
      label={
        <LabelBase
          hasError={hasError}
          errorMessage={errorMessage}
          label={label}
          description={description}
        />
      }
    />
  );
};

export const Radio = React.memo(RadioComp);
