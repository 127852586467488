import React from 'react';

import Grid from '@material-ui/core/Grid';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { Text } from 'components';
import { Field } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { api } from 'services';
import * as Yup from 'yup';

import { useStyles } from './styles';

const validationSchema = Yup.object().shape({
  new_password: Yup.string()
    .min(9, 'A senha deve ter no mínimo 9 caracteres')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'A senha deve conter uma letra minúscula, uma maiúscula, um número e um caractere especial',
    )
    .required(),
  repeat_new_password: Yup.string()
    .required()
    .oneOf([Yup.ref('new_password')], 'As senhas devem coincidir.'),
});

export const NewPassword = () => {
  const refresh_token = window.location.href.split('/')[5];

  const data = useQuery(() => api.get(`/password/reset/${refresh_token}`), []);

  const email = data[0]?.email;

  const classes = useStyles();

  const [values, setValues] = React.useState({
    new_password: false,
    repeat_new_password: false,
  });

  const [confirmPassword, setConfirmPassword] = React.useState({
    new_password: false,
    repeat_new_password: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChangeRepeatPassword = (prop) => (event) => {
    setConfirmPassword({ ...confirmPassword, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPasswordConfirm = () => {
    setConfirmPassword({
      ...confirmPassword,
      showPasswordConfirm: !confirmPassword.showPasswordConfirm,
    });
  };

  const handleMouseDownPasswordConfirm = (event) => {
    event.preventDefault();
  };

  // const initialValues = {
  //   new_password: '',
  //   repeat_new_password: '',
  // };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <Field
            variant='outlined'
            name='email'
            type='email'
            size='small'
            value={email}
            component={Text}
            disabled
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <Field
            name='new_password'
            variant='outlined'
            size='small'
            label='Nova senha'
            inputProps={{ minLength: 3 }}
            type={values.showPassword ? 'text' : 'password'}
            value={values.new_password}
            onChange={handleChange('password')}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge='end'
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            fullWidth
            component={Text}
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <Field
            name='repeat_new_password'
            variant='outlined'
            size='small'
            label='Confirmar senha'
            inputProps={{ minLength: 3 }}
            type={confirmPassword.showPasswordConfirm ? 'text' : 'password'}
            value={confirmPassword.repeat_new_password}
            onChange={handleChangeRepeatPassword('password')}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPasswordConfirm}
                  onMouseDown={handleMouseDownPasswordConfirm}
                  edge='end'
                >
                  {confirmPassword.showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            fullWidth
            component={Text}
          />
        </Grid>
      </Grid>
    </>
  );
};
