import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, Dialog, Text } from 'components';
import { Form, Formik, Field } from 'formik';
import { screeningService } from 'services/screening';

import { DialogAccessoryScreening } from '../DialogAccessory/DialogAccessoryScreening';
import { DialogReprintScreening } from '../DialogReprintEtiqueta/DialogReprintScreening';

export const DialogCollectScreening = ({
  openDialogCollect,
  handleCloseDialogCollect,
  info,
  setInfoQRCODE,
}) => {
  const [openDialogAcessory, setOpenDialogAcessory] = useState(false);
  const [openDialogEtiqueta, setOpenDialogEtiqueta] = useState(false);
  const [qrCodeValue, setQrCodeValue] = useState([]);
  const [infoFuctionOs, setInfoFuctionOs] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const inputQRCodeRef = useRef(null);
  const coletaOkButtonRef = useRef(null);

  useEffect(() => {
    if (openDialogCollect) {
      setTimeout(() => {
        inputQRCodeRef.current && inputQRCodeRef.current.focus();
      }, 100);
    }
  }, [openDialogCollect]);

  const handleOpenDialogAcessory = () => {
    setOpenDialogAcessory(true);
  };

  const handleCloseDialogAcessory = () => {
    setOpenDialogAcessory(false);
  };

  const handleCloseDialog = () => {
    toast.error('Triagem Cancelada');
    handleCloseDialogCollect('', false);
  };

  const handleOpenDialogReprintEtiqueta = () => {
    setOpenDialogEtiqueta(true);
  };

  const handleCloseDialogReprintEtiqueta = () => {
    setOpenDialogEtiqueta(false);
  };

  const processCollect = async (props) => {
    setIsLoading(true);
    const QRCode = props.values.input_qrcode.split('\n');

    try {
      const { data } = await screeningService.processCollect({ QRCode: QRCode });

      toast.success('Coleta realizada com Sucesso');
      handleOpenDialogAcessory();
      setInfoQRCODE((prevInfo) => ({
        ...prevInfo,
        data,
      }));
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyDown = (e, props) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      coletaOkButtonRef.current && coletaOkButtonRef.current.focus();
      processCollect(props);
    }
  };

  const processQrCodeEtiqueta = async (props) => {
    setIsLoading(true);
    const QRCode = props.values.input_qrcode.split('\n');
    const temp = {
      data: {
        pn: QRCode?.[0] || null,
        serial: QRCode?.[1] || null,
        imei: QRCode?.[2] || null,
        bt: QRCode?.[3] || null,
        mac: QRCode?.[4] || null,
        imei2: QRCode?.[5] || null,
      },
    };
    setQrCodeValue(temp);

    const response = await dataFunctionOS(info);
    setInfoFuctionOs(response.data);

    try {
      const { data } = await screeningService.processCollect({ QRCode: QRCode });
      handleOpenDialogReprintEtiqueta();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const dataFunctionOS = async (info) => {
    try {
      return await screeningService.getDataFunctionOS({ numOs: info.os.numeroOs });
    } catch (error) {
      console.log('Erro ao realizar: ' + error);
    }
  };

  return (
    <>
      <DialogAccessoryScreening
        openDialogAcessory={openDialogAcessory}
        handleCloseDialogAcessory={handleCloseDialogAcessory}
        handleCloseDialogCollect={handleCloseDialogCollect}
        info={info}
        setInfoQRCODE={setInfoQRCODE}
      />
      <DialogReprintScreening
        openDialogEtiqueta={openDialogEtiqueta}
        handleCloseDialogReprintEtiqueta={handleCloseDialogReprintEtiqueta}
        qrCodeValue={qrCodeValue}
        infoFuctionOs={infoFuctionOs}
        handleCloseDialog={handleCloseDialog}
      />
      <Dialog maxWidth={'md'} open={openDialogCollect}>
        <Dialog.Title onClose={() => handleCloseDialog('')}>Coleta de Dados</Dialog.Title>
        <Dialog.Content>
          <Formik initialValues={{ input_qrcode: '' }}>
            {(props) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12}>
                    <Field
                      size='small'
                      name='input_qrcode'
                      variant='outlined'
                      component={Text}
                      multiline
                      minRows={8}
                      inputRef={inputQRCodeRef}
                      onKeyDown={(e) => handleKeyDown(e, props)}
                    />
                  </Grid>
                  <Grid item xs={4} lg={4}>
                    <Button fullWidth color='secondary' onClick={handleCloseDialog}>
                      Fechar
                    </Button>
                  </Grid>
                  <Grid item xs={4} lg={4}>
                    <Button
                      fullWidth
                      color='primary'
                      loading={isLoading}
                      onClick={() => processCollect(props)}
                      ref={coletaOkButtonRef}
                    >
                      Coleta OK
                    </Button>
                  </Grid>
                  <Grid item xs={4} lg={4}>
                    <Button
                      fullWidth
                      color='primary'
                      loading={isLoading}
                      onClick={() => processQrCodeEtiqueta(props)}
                    >
                      Reprint Etiqueta
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
