import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import { Box } from '@mui/material';
import { Button, Table } from 'components';
import { Formik, Form } from 'formik';
import { downloadcsv } from 'helpers/downloadcsv';
import { PageTitle } from 'pages/Layout/PageTitle';

import { laboratoryService } from '../../../../services/laboratory';
import { DialogConfirm } from './Dialog/DialogConfirm';

const columns = [
  { label: 'OS' },
  { label: 'Modelo' },
  { label: 'PN' },
  { label: 'Serial Out' },
  { label: 'Cliente OPV' },
  { label: 'Ativo Cliente' },
  { label: 'NF Cliente' },
  { label: 'Falha Personalização' },
  { label: 'Data das Falhas' },
  { label: 'Prosseguir com reparo' },
  { label: 'Devolver sem reparo' },
  { label: 'Não Avaliado' },
];

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });
};

export const LotsCustomization = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [checkboxStates, setCheckboxStates] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [row, setRow] = useState({});

  const handleTable = async () => {
    try {
      const { data } = await laboratoryService.getLotsCustomization();

      const isNaoAvaliadoChecked = data.length > 0 && data[0].status === 'N';

      const formattedData = data.map((item) => ({
        ...item,
        'Data das Falhas': formatDate(item['Data das Falhas']),
      }));

      const initialCheckboxStates = formattedData.reduce((acc, item) => {
        acc[item.OS] = {
          OS: item.OS,
          prosseguirReparo: false,
          devolverSemReparo: false,
          naoAvaliado: isNaoAvaliadoChecked,
        };
        return acc;
      }, {});

      setData(formattedData);
      setCheckboxStates(initialCheckboxStates);

      if (formattedData.length <= 0) {
        toast.error('Não existem lotes disponíveis para avaliação no momento');
      }
    } catch (error) {
      toast.error(error.message || 'Ocorreu um erro ao buscar os dados');
    }
  };

  useEffect(() => {
    handleTable();
  }, []);

  useEffect(() => {}, [checkboxStates]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCheckboxChange = (id, checkbox) => (event) => {
    setCheckboxStates((prevStates) => {
      const newState = {
        ...prevStates,
        [id]: {
          OS: id,
          prosseguirReparo: false,
          devolverSemReparo: false,
          naoAvaliado: false,
          [checkbox]: event.target.checked,
        },
      };
      setRow(newState);
      return newState;
    });
  };
  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const { data } = await laboratoryService.getLotsCustomizationReport();

      if (data.length > 0) {
        downloadcsv('ingenico_aprovacao_personalizacao', data);
        toast.success('Relatório gerado com sucesso');
      } else {
        toast.error('Não há dados para esse relatório.');
      }
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenDialog = (item) => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <DialogConfirm handleCloseDialog={handleCloseDialog} openDialog={openDialog} row={row} />
      <PageTitle>Lotes de Personalização</PageTitle>

      <Formik initialValues={{}} onSubmit={handleSubmit}>
        {(props) => (
          <Form>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={3} md={3} lg={3}>
                  <Button fullWidth type='submit' loading={isLoading}>
                    Exportar
                  </Button>
                </Grid>
                <Grid item xs={3} md={3} lg={3}>
                  <Button
                    fullWidth
                    onClick={() => handleOpenDialog(props)}
                    color='secondary'
                    loading={isLoading}
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Grid container spacing={2} style={{ paddingTop: '1rem' }}>
              <Grid item xs={12} lg={12} md={12}>
                <Table name='Table' headers={columns} maxHeight disableNumeration>
                  {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                    <TableRow key={item.OS}>
                      <TableCell>{item.OS}</TableCell>
                      <TableCell>{item.Modelo}</TableCell>
                      <TableCell>{item.PN}</TableCell>
                      <TableCell>{item['Serial Out']}</TableCell>
                      <TableCell>{item['Cliente OPV']}</TableCell>
                      <TableCell>{item['Ativo Cliente']}</TableCell>
                      <TableCell>{item['NF Cliente']}</TableCell>
                      <TableCell>{item['Falha Personalização']}</TableCell>
                      <TableCell>{item['Data das Falhas']}</TableCell>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color='primary'
                              checked={checkboxStates[item.OS]?.prosseguirReparo || false}
                              onChange={handleCheckboxChange(item.OS, 'prosseguirReparo')}
                            />
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color='primary'
                              checked={checkboxStates[item.OS]?.devolverSemReparo || false}
                              onChange={handleCheckboxChange(item.OS, 'devolverSemReparo')}
                            />
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color='primary'
                              checked={checkboxStates[item.OS]?.naoAvaliado || false}
                              onChange={handleCheckboxChange(item.OS, 'naoAvaliado')}
                            />
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
                <TablePagination
                  component='div'
                  count={data.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
