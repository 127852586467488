import React, { createContext, useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { withStyles } from '@material-ui/core/styles';

import { Formik, Form } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { quoteService } from 'services';

import { Actions } from './Actions';
import { ClientInfoNew } from './ClientInfoNew';
import { validationSchema } from './formik';
import { Payment } from './Payment';
import { Purpose } from './Purpose';
import { Result } from './Result';
import { SmartphoneModel } from './SmartphoneModel';
import { StepIconComponent } from './StepIcon';
import useStyles from './styles';
import { Verify } from './Verify';

const steps = [
  {
    label: 'Dados Iniciais',
  },
  {
    label: 'Modelo',
  },
  {
    label: 'Resultado',
  },
  {
    label: 'Proposta',
  },
  {
    label: 'Pagamento',
  },
  {
    label: 'Verificação',
  },
];

const Connector = withStyles((theme) => ({
  alternativeLabel: {
    top: 24,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  completed: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  line: {
    borderColor: '#c8c8cc',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))(StepConnector);

export const ClientContext = createContext();

export const QuoteSmartphone = () => {
  const classes = useStyles();

  const [clientExists, setClientExists] = useState(false);
  const [hasClient, setHasClient] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const {
        payment_method,
        payment_method2,
        payment_method3,
        installment_price,
        installment_price2,
        installment_price3,
        installments,
        installments2,
        installments3,
        insurance_type,
        smartphone_brand_id,
        smartphone_model_id,
        smartphone_use_time_id,
        total,
        client_id,
        discount,
        insurance_value,
        money_change,
        money_received,
      } = values;

      await quoteService.createSmartphoneTransaction({
        payment_method,
        payment_method2,
        payment_method3,
        installment_price,
        installment_price2,
        installment_price3,
        installments,
        installments2,
        installments3,
        insurance_type,
        smartphone_brand_id,
        smartphone_model_id,
        smartphone_use_time_id,
        total,
        client_id,
        discount,
        insurance_value,
        money_change,
        money_received,
      });

      toast.success('Cotação de seguro gerado com sucesso.');

      resetForm();
      setActiveStep(0);
    } catch (err) {
      toast.error(err.response?.data?.message);
    } finally {
      setSubmitting(false);
    }
  };

  const getStepContent = useCallback((activeStep, formProps) => {
    const steps = {
      0: <ClientInfoNew FormProps={formProps} />,
      1: <SmartphoneModel />,
      2: <Result />,
      3: <Purpose />,
      4: <Payment />,
      5: <Verify />,
    };

    return steps[activeStep];
  }, []);

  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  return (
    <>
      <PageTitle>Cotação de Seguro - Smartphone</PageTitle>

      <Grid className={classes.root}>
        <Stepper activeStep={activeStep} alternativeLabel connector={<Connector />}>
          {steps.map((step) => (
            <Step key={step.label}>
              <StepLabel StepIconComponent={StepIconComponent}>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Formik
          initialValues={{
            cgc: '',
            street: '',
            street_number: '',
            zipcode: '',
            name: '',
            residential_phone_number: '',
            phone_number: '',
            city: '',
            state: '',
            email: '',
            district: '',
            complement: '',
            payment_type: 1,
            payment_method: 1,
          }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              <ClientContext.Provider
                value={{ clientExists, setClientExists, setHasClient, hasClient }}
              >
                {getStepContent(activeStep, props)}
                <Actions
                  activeStep={activeStep}
                  handleNext={handleNext}
                  handleBack={handleBack}
                  stepsSize={steps.length}
                />
              </ClientContext.Provider>
            </Form>
          )}
        </Formik>
      </Grid>
    </>
  );
};
