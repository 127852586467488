import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { Button, Table, Text, Select } from 'components';
import { Formik, Field, Form } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { PageTitle } from 'pages/Layout/PageTitle';
import { screeningService } from 'services/screening';
import { useContextSelector } from 'use-context-selector';

import { DialogCollectScreening } from './DialogCollect/DialogCollectScreening';
import { DialogConfirmScreening } from './DialogConfirm/DialogConfirmScreening';

const typeCollect = [
  { label: 'OS', value: '1' },
  { label: 'Serial', value: '2' },
];

const columns = [{ label: 'Defeito' }, { label: 'Check' }];

export const CosmeticScreening = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [openDialogCollect, setDialogCollect] = useState(false);
  const [info, setInfo] = useState([]);
  const [infoQRCODE, setInfoQRCODE] = useState([]);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [isIrreparable, setIsIrreparable] = useState(false);
  const [isIrreparableDisabled, setIsIrreparableDisabled] = useState(true);
  const [typeCollectValue, setTypeCollectValue] = useState(' ');
  const [selectedAccessories, setSelectedAccessories] = useState([]);
  const [openDialogConfirm, setDialogConfirm] = useState(false);
  const user = useContextSelector(AuthContext, (context) => context.user);
  const [msgBack, setMsgBack] = useState([]);
  const [newInfo, setNewInfo] = useState([]);
  const [isCheckIrreparavel, setIsCheckIrreparavel] = useState([]);

  const handleDownloadTable = async (validation, shouldFetchData = true) => {
    setIsCheckIrreparavel(validation);
    if (!shouldFetchData) {
      return;
    }
    setIsLoading(true);
    try {
      const { data } = await screeningService.getDefects({
        laudo: validation,
        idReparo: info.idReparo,
        type_collect: typeCollectValue,
      });
      toast.success('Dados Carregados com Sucesso');
      setTableData(data);
      setIsButtonEnabled(true);
      setIsIrreparableDisabled(false);
    } catch (error) {
      console.error('Erro ao gerar a tabela: ' + error.message);
      setIsButtonEnabled(false);
    } finally {
      setIsLoading(false);
    }
  };

  const checkOsOrSerial = async (props) => {
    setIsLoading(true);
    try {
      const { data } = await screeningService.checkOsOrSerial({
        type_collect: props.values.type_collect,
        numOs: props.values.input_os,
        serial: props.values.input_serial,
      });

      setTypeCollectValue(props.values.type_collect);
      if (data.status === 400 || data.status === 404) {
        toast.error(data.msg);
      } else {
        toast.success(data.msg);
        handleOpenDialogCollect();
        setInfo(data);
      }
    } catch (error) {
      console.error('Erro ao fazer verificação: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCheckboxChange = (idCadastro, laudoDtFab, excFuncional) => {
    setSelectedAccessories((prevSelected) =>
      prevSelected.some((obj) => obj.idCadastro === idCadastro)
        ? prevSelected.filter((obj) => obj.idCadastro !== idCadastro)
        : [...prevSelected, { idCadastro, laudoDtFab, excFuncional }],
    );
  };

  const handleOpenDialogCollect = () => {
    setDialogCollect(true);
  };

  const handleCloseDialogCollect = (shouldFetchData = true) => {
    setDialogCollect(false);
    setIsIrreparable(false);
    handleDownloadTable(false, shouldFetchData);
  };

  const handleChecked = async () => {
    const inverte = !isIrreparable;
    setIsIrreparable(inverte);
    await handleDownloadTable(inverte);
  };

  const checksIsSerialBlackListWifi = async () => {
    try {
      const { data } = await screeningService.getSerialBlackListWifi({
        serial: infoQRCODE.data.serial,
      });
    } catch (error) {
      console.log('Erro' + error);
    }
  };

  const ScreeningSemDefeito = async (info) => {
    setIsLoading(true);
    if (selectedAccessories.length > 0) {
      toast.error(
        'Não é possível enviar esta Os como NFF, pois houve a seleção de pelo menos um defeito',
      );
    } else {
      handleDialogConfirm();
    }
    const response = await dataFunctionOS(info);
    setNewInfo(response.data);
    checksIsSerialBlackListWifi();

    try {
      const { data } = await screeningService.insertSemDefeito({
        qrcode: infoQRCODE,
        info: response.data,
        usuario: user.name,
      });
      if (data.status === 400) {
        toast.error(data.msg);
      } else if (data.status === 201) {
        setMsgBack(data);
      }
    } catch (error) {
      console.log('Erro ao realizar: ' + error);
    } finally {
      setIsLoading(false);
    }
  };

  const ScreeningTransferir = async (info) => {
    setIsLoading(true);
    if (selectedAccessories.length === 0) {
      toast.error('Selecione ao menos um defeito');
    } else {
      handleDialogConfirm();
    }

    const response = await dataFunctionOS(info);
    setNewInfo(response.data);

    try {
      const { data } = await screeningService.insertTransferirComDefeito({
        qrcode: infoQRCODE,
        info: response.data,
        usuario: user.name,
        selectedAccessories: selectedAccessories,
        checkIrreparavel: isIrreparable,
      });
      if (data.status === 400) {
        toast.error(data.msg);
      } else if (data.status === 201) {
        setMsgBack(data);
      }
    } catch (error) {
      console.log('Erro ao realizar: ' + error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDialogConfirm = () => {
    setDialogConfirm(true);
  };

  const handleCloseDialogConfirm = () => {
    setDialogConfirm(false);
  };

  const dataFunctionOS = async (info) => {
    try {
      return await screeningService.getDataFunctionOS({ numOs: info.os.numeroOs });
    } catch (error) {
      console.log('Erro ao realizar: ' + error);
    }
  };

  const paginatedData = tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
      <DialogCollectScreening
        openDialogCollect={openDialogCollect}
        handleCloseDialogCollect={handleCloseDialogCollect}
        info={info}
        setInfoQRCODE={setInfoQRCODE}
      />
      <DialogConfirmScreening
        handleCloseDialogConfirm={handleCloseDialogConfirm}
        openDialogConfirm={openDialogConfirm}
        selectedAccessories={selectedAccessories}
        msgBack={msgBack}
        newInfo={newInfo}
        infoQRCODE={infoQRCODE}
        isCheckIrreparavel={isCheckIrreparavel}
      />
      <PageTitle>Triagem Cosmética</PageTitle>

      <Formik initialValues={{ type_collect: ' ', input_serial: '', input_os: '' }}>
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={2}>
                <Field
                  size='small'
                  name='type_collect'
                  label='Tipo Coleta'
                  variant='outlined'
                  options={typeCollect}
                  component={Select}
                />
              </Grid>
              {props.values.type_collect === '1' && (
                <Grid item xs={12} md={6} lg={2}>
                  <Field
                    size='small'
                    name='input_os'
                    label='Ordem de Serviço'
                    variant='outlined'
                    component={Text}
                  />
                </Grid>
              )}
              {props.values.type_collect === '2' && (
                <Grid item xs={12} md={6} lg={2}>
                  <Field
                    size='small'
                    name='input_serial'
                    label='Serial'
                    variant='outlined'
                    component={Text}
                  />
                </Grid>
              )}
              <Grid item xs={2} lg={1}>
                <Button
                  type='submit'
                  fullWidth
                  loading={isLoading}
                  onClick={() => checkOsOrSerial(props)}
                >
                  Coletar
                </Button>
              </Grid>
              <Grid item xs={1} lg={1}>
                <Button
                  type='submit'
                  fullWidth
                  loading={isLoading}
                  disabled={!isButtonEnabled}
                  onClick={() => ScreeningTransferir(info)}
                >
                  Transferir
                </Button>
              </Grid>
              <Grid item xs={2} lg={2}>
                <Button
                  type='submit'
                  fullWidth
                  loading={isLoading}
                  disabled={!isButtonEnabled}
                  onClick={() => ScreeningSemDefeito(info)}
                >
                  Sem Defeito Cosmético
                </Button>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={1}>
                  <Typography align='center' style={{ paddingTop: '1rem' }}>
                    Dados QR code:
                  </Typography>
                </Grid>
                <Grid item md={2}>
                  <Typography align='center' style={{ paddingTop: '1rem' }}>
                    IMEI: {infoQRCODE?.data?.imei ?? 'N/A'}
                  </Typography>
                </Grid>
                <Grid item md={2}>
                  <Typography align='center' style={{ paddingTop: '1rem' }}>
                    MAC: {infoQRCODE?.data?.mac ?? 'N/A'}
                  </Typography>
                </Grid>
                <Grid item md={2}>
                  <Typography align='center' style={{ paddingTop: '1rem' }}>
                    Bluetooth: {infoQRCODE?.data?.bt ?? 'N/A'}
                  </Typography>
                </Grid>
                <Grid item md={2}>
                  <Typography align='center' style={{ paddingTop: '1rem' }}>
                    IMEI 2: {infoQRCODE?.data?.imei2 ?? 'N/A'}
                  </Typography>
                </Grid>
                <Grid item md={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isIrreparable}
                        onChange={handleChecked}
                        disabled={isIrreparableDisabled}
                        style={{ color: '#512da8' }}
                      />
                    }
                    label='Laudo Irreparável'
                    style={{ paddingTop: '0.5rem' }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ paddingTop: '1rem' }}>
                <Grid item xs={12} lg={12} md={12}>
                  <Table name='Table' headers={columns} maxHeight disableNumeration>
                    {paginatedData.map((item, index) => {
                      const actualIndex = page * rowsPerPage + index;
                      return (
                        <TableRow key={actualIndex}>
                          <TableCell>{item.descricao}</TableCell>
                          <TableCell>
                            <Checkbox
                              checked={selectedAccessories.some(
                                (obj) => obj.idCadastro === item.idCadastro,
                              )}
                              onChange={() =>
                                handleCheckboxChange(
                                  item.idCadastro,
                                  item.laudoDtFab,
                                  item.excFuncional,
                                )
                              }
                              style={{ color: '#512da8' }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component='div'
                    count={tableData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
