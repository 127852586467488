import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';

import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { Form, Formik } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { browserHistory } from 'routes/browserHistory';
import { api } from 'services';
import * as Yup from 'yup';

import { Actions } from './Actions';
import { NewPassword } from './NewPassword';
import { SecurityQuestion } from './SecurityQuestion';
import { StepIconComponent } from './StepIcon';
import { useStyles } from './styles';

const steps = [
  {
    label: 'Etapa 1',
  },
  {
    label: 'Etapa 2',
  },
];

const Connector = withStyles((theme) => ({
  alternativeLabel: {
    top: 24,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  completed: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  line: {
    borderColor: '#c8c8cc',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))(StepConnector);

const validationSchema = Yup.object().shape({
  question: Yup.string(),
  answer: Yup.string().required(),
  email: Yup.string(),
  new_password: Yup.string()
    .min(9, 'A senha deve ter no mínimo 9 caracteres')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'A senha deve conter uma letra minúscula, uma maiúscula, um número e um caractere especial',
    )
    .required(),
  repeat_new_password: Yup.string()
    .required()
    .oneOf([Yup.ref('new_password')], 'As senhas devem coincidir.'),
});

export const RedefinePassword = () => {
  const refresh_token = window.location.href.split('/')[5];
  const data = useQuery(() => api.get(`/password/reset/${refresh_token}`), []);
  const answerData = data[0]?.answer?.answer;
  const email = data[0]?.email;

  const classes = useStyles();

  const [activeStep, setActiveStep] = useState(0);

  const getStepContent = (activeStep) => {
    const steps = {
      0: <SecurityQuestion />,
      1: <NewPassword />,
    };

    return steps[activeStep];
  };

  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const new_password = values.new_password;
      if (values.new_password === values.repeat_new_password) {
        await api.patch(`/password/reset`, { email: email, password: new_password });

        toast.success('Senha alterada com sucesso.');
        resetForm();

        browserHistory.replace('/');
      }
    } catch (err) {
      toast.error('Erro ao atualizar a senha.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.container} elevation={4}>
        <NavLink className={classes.goBack} to='/'>
          <ArrowBackIcon className={classes.backIcon} />
          Voltar para login
        </NavLink>
        <div>
          <Stepper activeStep={activeStep} alternativeLabel connector={<Connector />}>
            {steps.map((step) => (
              <Step key={step.label}>
                <StepLabel StepIconComponent={StepIconComponent}>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <Formik
          initialValues={{ answer: '', email: email, new_password: '', repeat_new_password: '' }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              {getStepContent(activeStep)}
              <Actions
                activeStep={activeStep}
                handleNext={handleNext}
                handleBack={handleBack}
                stepsSize={steps.length}
                AnswerIsCorrect={answerData === props.values.answer ? true : false}
              />
            </Form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};
