import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { Grid } from '@mui/material';
import { Button, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { downloadXlsx } from 'helpers/downloadXLSX';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providerService } from 'services';

export const SerialBaseQuery = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await providerService.getSerialBaseQuery({ serial: values.serial });
      if (data.length > 0) {
        downloadXlsx('ingenico_consulta_base_seriais', data);
        toast.success('Relatório gerado com sucesso');
      } else {
        toast.error('Não há dados para esse relatório.');
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Consulta a base de Seriais</PageTitle>

      <Formik
        initialValues={{
          serial: '',
        }}
        onSubmit={handleSubmit}
      >
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3} lg={3}>
              <Field
                size='small'
                loading={isLoading}
                name='serial'
                variant='outlined'
                fullWidth
                inputVariant='outlined'
                label='Serial'
                component={Text}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <Button fullWidth type='submit' loading={isLoading}>
                Exportar
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </>
  );
};
