import React from 'react';
import { NavLink } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { PasswordRecoverForm } from './Form';
import { useStyles } from './styles';

export const PasswordRecover = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.container} elevation={4}>
        <NavLink className={classes.goBack} to='/'>
          <ArrowBackIcon className={classes.backIcon} />
          Voltar para o link
        </NavLink>

        <h1 className={classes.title}>Esqueceu sua senha?</h1>
        <p className={classes.subtitle}>
          Insira seu endereço de e-mail para receber o link de recuperar senha.
        </p>

        <PasswordRecoverForm />
      </Paper>
    </div>
  );
};
