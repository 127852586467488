import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog } from 'components';
import { AuthContext } from 'hooks/useAuth';
import { pcpService } from 'services';
import { useContextSelector } from 'use-context-selector';

export const DialogDeleteOsAssign = ({
  openDialogDeleteOsAssign,
  handleCloseDialogDeleteOsAssign,
  lineOsTec,
}) => {
  if (!openDialogDeleteOsAssign) return null;

  const user = useContextSelector(AuthContext, (context) => context.user);

  const [isLoading, setIsLoading] = useState(false);

  const getMessage = () => {
    if (lineOsTec.idEtapa === 6) {
      return 'OS está aguardando componente, realize a confirmação da movimentação e a desatribuição ocorrerá automaticamente';
    } else {
      return `Realmente deseja desatribuir a OS ${lineOsTec.numeroos} do técnico ${lineOsTec.numTecnico}?`;
    }
  };

  const handleDeleteOsAssign = async () => {
    setIsLoading(true);
    try {
      const { data } = await pcpService.deleteOsAssign({
        numOs: lineOsTec.numeroos,
        numTec: lineOsTec.numTecnico,
        user: user.id,
      });

      if (data.status === 500) {
        toast.error(data.msg);
        setIsLoading(false);
      }
      if (data.status === 200) {
        toast.success(data.msg);
        handleCloseDialogDeleteOsAssign();
        setIsLoading(false);
      }
    } catch {
      toast.error('Falha ao desatribuir OS.');
      setIsLoading(false);
    }
  };

  return (
    <>
      <Dialog open={openDialogDeleteOsAssign}>
        <Dialog.Title onClose={handleCloseDialogDeleteOsAssign}>Alerta</Dialog.Title>
        <Dialog.Content>
          <Typography
            align='center'
            style={{ paddingTop: '0.5rem', fontSize: '20px', fontWeight: 'bold' }}
          >
            {getMessage()}
          </Typography>
        </Dialog.Content>
        <Dialog.Actions>
          <Grid container spacing={2}>
            <Grid item xs={3}></Grid>
            <Grid item xs={6} lg={3}>
              <Button
                fullWidth
                color='secondary'
                onClick={handleCloseDialogDeleteOsAssign}
                loading={isLoading}
              >
                Fechar
              </Button>
            </Grid>
            <Grid item xs={6} lg={3}>
              <Button fullWidth onClick={() => handleDeleteOsAssign()} loading={isLoading}>
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Dialog.Actions>
      </Dialog>
    </>
  );
};
