import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(1.5),

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  fileList: {
    listStyle: 'none',
    height: '100%',
    maxHeight: 236,
    marginBottom: theme.spacing(1.5),
    overflow: 'auto',

    '&::-webkit-scrollbar': {
      background: 'rgba(0, 0, 0, .1)',
      width: 4,
      borderRadius: 4,
    },

    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(0, 0, 0, .2)',
      width: 4,
      borderRadius: 4,
    },
  },
  fileItem: {
    fontSize: 16,
    wordBreak: 'break-all',

    display: 'flex',
    alignItems: 'center',
  },
  fileIcon: {
    fontSize: 36,
    marginRight: theme.spacing(0.5),
  },
  fileActionBtn: {
    marginLeft: 'auto',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

    '& > button + button': {
      marginLeft: theme.spacing(1),
    },
  },
}));
