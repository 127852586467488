/* eslint-disable prettier/prettier */
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import { Grid } from '@mui/material';
import { Button, DatePicker, Select, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { downloadXlsx } from 'helpers/downloadXLSX';
import { AuthContext } from 'hooks/useAuth';
import { PageTitle } from 'pages/Layout/PageTitle';
import { launchPrivider } from 'services';
import { useContextSelector } from 'use-context-selector';
import * as XLSX from 'xlsx';

import { DialogInfoErros } from './Dialogs/DialogInfoErros';
import { DialogUnlockBlock } from './Dialogs/DialogUnlockBlock';

const columns = [
  { label: 'idBloqueio' },
  { label: 'Valor Bloqueado' },
  { label: 'desc Tipo Bloqueio' },
  { label: 'desc Log Tipo Bloqueio' },
  { label: 'dtBloqueio' },
  { label: 'Bloqueado Por' },
  { label: 'obs Bloqueio' },
  { label: 'Cliente' },
  { label: '' },
];

export const OsBlock = () => {
  const user = useContextSelector(AuthContext, (context) => context.user);

  const typeActions = [
    { label: 'Cadastrar Bloqueio', value: 0 },
    { label: 'Consultar', value: 1 },
    { label: 'Desbloquear OS', value: 2 },
  ];

  const typeBlock = [
    { label: 'IMEI/SERIAL', value: 0 },
    { label: 'NOTA FISCAL', value: 1 },
    { label: 'UPLOAD SERIAIS', value: 2 },
  ];

  const [clients, setClients] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await launchPrivider.findClients();
      setClients(data);
    };
    fetchData();
  }, []);

  const [table, setDataTable] = useState([]);
  const [openDialogUnlock, setOpenDialogUnlock] = useState(false);
  const [openDialogInfoErros, setOpenDialogInfoErros] = useState(false);
  const [infoBlock, setInfoBlock] = useState();
  const [arrayErros, setArrayErros] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [importing, setImporting] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleGetTableBlocks = async (props) => {
    const values = props.values;
    try {
      const { data } = await launchPrivider.getTableValuesBlocks({ values });

      if (data.status === 200) {
        setDataTable(data.consult);
      } else {
        toast.error(data.msg);
        setDataTable([]);
      }
    } catch (error) {
      console.error(error.message);
      toast.error('Erro ao buscar valores bloqueados');
    }
  };

  const hadleRegisterBlock = async (values) => {
    toast.info('Realizando bloqueio...');
    setIsLoading(true);
    try {
      const { data } = await launchPrivider.registerBlockOs({ values, user: user.name });
      if (data.status === 200) {
        toast.success(data.msg);
        setIsLoading(false);
      } else {
        toast.error(data.msg);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error.message);
      toast.error('Erro ao registar novo bloqueio');
      setIsLoading(false);
    }
  };

  const inputFileRef = useRef(null);

  const handleImportClick = () => {
    inputFileRef.current.value = '';
    inputFileRef.current.click();
  };

  const handleRegisterByUpload = async (e) => {
    setImporting(true);
    try {
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = async (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Converte a planilha em um array de objetos
        const jsonData = XLSX.utils.sheet_to_json(sheet);

        // Verifica se a planilha está vazia
        if (jsonData.length === 0) {
          toast.error('A planilha está vazia.');
          setImporting(false);
          return;
        }

        let hasSerial = false;
        let hasError = false;
        let errorMessage = '';

        jsonData.forEach((obj, index) => {
          const serial = obj['Serial'];
          const obs = obj['Obs'];

          // Verifica se há pelo menos um Serial não vazio
          if (serial) {
            hasSerial = true;

            // Verifica se o Obs correspondente está vazio
            if (!obs) {
              hasError = true;
              errorMessage += `A célula "Obs" está vazia na linha ${index + 2
                } onde há um "Serial".\n`;
            }
          }
        });

        // Se não houver Serial, exibe um erro
        if (!hasSerial) {
          toast.error('Deve haver pelo menos um "Serial*".');
          setImporting(false);
          return;
        }

        // Se houver erros, exibe um erro com a mensagem
        if (hasError) {
          toast.error(errorMessage);
          setImporting(false);
          return;
        }

        // Filtra os dados válidos
        const filterDados = jsonData.map((obj) => ({
          Serial: obj['Serial'],
          Obs: obj['Obs'],
        }));

        toast.info('Bloqueando seriais ...');

        const { data: upload } = await launchPrivider.blockMultipleSeriais({
          filterDados,
          user: user.name,
        });

        if (upload.status === 200) {
          toast.success(upload.msg);
          if (upload.arrayErros.length > 0) {
            const msgErro = upload.msgErro;
            handleOpenDialogInfoErros(msgErro);
          }
        } else {
          toast.error(upload.msg);
        }

        setImporting(false);
      };

      reader.readAsArrayBuffer(file);
    } catch (err) {
      console.log(err);
      toast.error(err.message || 'Erro ao importar, tente novamente!');
      setImporting(false);
    }
  };

  const inputFileRefUnlock = useRef(null);

  const handleImportClickUnlock = () => {
    inputFileRefUnlock.current.value = '';
    inputFileRefUnlock.current.click();
  };

  const handleUnlockByUpload = async (e) => {
    setImporting(true);
    try {
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = async (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Converte a planilha em um array de objetos
        const jsonData = XLSX.utils.sheet_to_json(sheet);

        // Verifica se a planilha está vazia
        if (jsonData.length === 0) {
          toast.error('A planilha está vazia.');
          setImporting(false);
          return;
        }

        let hasSerial = false;
        let hasError = false;
        let errorMessage = '';

        jsonData.forEach((obj, index) => {
          const serial = obj['Serial'];
          const obs = obj['Obs'];

          // Verifica se há pelo menos um Serial não vazio
          if (serial) {
            hasSerial = true;

            // Verifica se o Obs correspondente está vazio
            if (!obs) {
              hasError = true;
              errorMessage += `A célula "Obs" está vazia na linha ${index + 2
                } onde há um "Serial".\n`;
            }
          }
        });

        // Se não houver Serial, exibe um erro
        if (!hasSerial) {
          toast.error('Deve haver pelo menos um "Serial*".');
          setImporting(false);
          return;
        }

        // Se houver erros, exibe um erro com a mensagem
        if (hasError) {
          toast.error(errorMessage);
          setImporting(false);
          return;
        }

        // Filtra os dados válidos
        const filterDados = jsonData.map((obj) => ({
          Serial: obj['Serial'],
          Obs: obj['Obs'],
        }));

        toast.info('Desbloqueando seriais ...');

        const { data: upload } = await launchPrivider.unlockMultipleSeriais({
          filterDados,
          user: user.name,
        });

        if (upload.status === 200) {
          toast.success(upload.msg);
          if (upload.arrayErros.length > 0) {
            const msgErro = upload.msgErro;
            handleOpenDialogInfoErros(msgErro);
          }
        } else {
          toast.error(upload.msg);
        }

        setImporting(false);
      };

      reader.readAsArrayBuffer(file);
    } catch (err) {
      console.log(err);
      toast.error(err.message || 'Erro ao importar, tente novamente!');
      setImporting(false);
    }
  };

  const handleOpenDialogUnlock = (item) => {
    setOpenDialogUnlock(true);
    setInfoBlock(item);
  };
  const handleCloseDialogUnlock = () => {
    setOpenDialogUnlock(false);
  };

  const handleOpenDialogInfoErros = (msgErro) => {
    setArrayErros(msgErro);
    setOpenDialogInfoErros(true);
  };
  const handleCloseDialogInfoErros = () => {
    setOpenDialogInfoErros(false);
  };

  const handleResetField = (props) => {
    props.setFieldValue('imei_serial_block', '');
    props.setFieldValue('observation', '');
    props.setFieldValue('nf', '');
    props.setFieldValue('nf_1', '');
    props.setFieldValue('client', '');
  };

  const handleDownloadLayout = async () => {
    const data = [{ Serial: '', Obs: '' }];
    downloadXlsx('ingenico_bloqueio_os_', data);
  };

  const formatDate = (date) => {
    const validDate = new Date(date);

    if (isNaN(validDate.getTime())) {
      return '';
    }

    const day = String(validDate.getDate()).padStart(2, '0');
    const month = String(validDate.getMonth() + 1).padStart(2, '0');
    const year = validDate.getFullYear();

    return `${day}/${month}/${year}`;
  };

  return (
    <>
      <input
        ref={inputFileRef}
        type='file'
        name='xlsx'
        onChange={handleRegisterByUpload}
        accept='text/xslx'
        hidden
      />
      <input
        ref={inputFileRefUnlock}
        type='file'
        name='xlsx'
        onChange={handleUnlockByUpload}
        accept='text/xslx'
        hidden
      />
      <DialogUnlockBlock
        openDialogUnlock={openDialogUnlock}
        handleCloseDialogUnlock={handleCloseDialogUnlock}
        infoBlock={infoBlock}
      />
      <DialogInfoErros
        openDialogInfoErros={openDialogInfoErros}
        handleCloseDialogInfoErros={handleCloseDialogInfoErros}
        arrayErros={arrayErros}
      />
      <PageTitle>Controle de Bloqueios</PageTitle>

      <Formik
        initialValues={{
          type_action: 0,
          type_block: 0,
          imei_serial_block: '',
          imei_serial_consult: '',
          input_data: new Date(),
          output_data: new Date(),
          observation: '',
          nf: '',
          nf_1: '',
          client: '',
        }}
        onSubmit={hadleRegisterBlock}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={4} lg={2}>
                <Field
                  size='small'
                  name='type_action'
                  label='Ações'
                  component={Select}
                  options={typeActions}
                  variant='outlined'
                />
              </Grid>
              {props.values.type_action === 0 && (
                <>
                  <Grid item xs={12} sm={4} md={4} lg={2}>
                    <Field
                      size='small'
                      name='type_block'
                      label='Tipo de Bloqueio'
                      component={Select}
                      options={typeBlock}
                      variant='outlined'
                      onChange={() => handleResetField(props)}
                    />
                  </Grid>
                  {props.values.type_block === 0 && (
                    <>
                      <Grid item xs={12} sm={4} md={4} lg={2}>
                        <Field
                          size='small'
                          name='imei_serial_block'
                          label='Digite o IMEI/SERIAL'
                          component={Text}
                          variant='outlined'
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={2}>
                        <Field
                          size='small'
                          name='observation'
                          label='Observação'
                          component={Text}
                          variant='outlined'
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={2}>
                        <Button type='submit' fullWidth loading={isLoading}>
                          Registrar Bloqueio
                        </Button>
                      </Grid>
                    </>
                  )}
                  {props.values.type_block === 1 && (
                    <>
                      <Grid item xs={12} sm={4} md={4} lg={2}>
                        <Field
                          size='small'
                          name='nf'
                          label='NOTA FISCAL'
                          component={Text}
                          variant='outlined'
                          type='number'
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={2}>
                        <Field
                          size='small'
                          name='nf_1'
                          label='EX: 150'
                          component={Text}
                          variant='outlined'
                          type='number'
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={2}>
                        <Field
                          size='small'
                          name='client'
                          label='Cliente'
                          component={Select}
                          options={clients}
                          variant='outlined'
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={2}>
                        <Field
                          size='small'
                          name='observation'
                          label='Observação'
                          component={Text}
                          variant='outlined'
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={2}>
                        <Button type='submit' fullWidth loading={isLoading}>
                          Registrar Bloqueio
                        </Button>
                      </Grid>
                    </>
                  )}
                </>
              )}
              {props.values.type_block === 2 && props.values.type_action === 0 && (
                <>
                  <Grid item xs={12} sm={4} md={4} lg={2}>
                    <Button fullWidth onClick={handleImportClick} loading={importing}>
                      Upload Seriais P/Bloqueio
                    </Button>
                  </Grid>
                </>
              )}
              {props.values.type_action === 1 && (
                <>
                  <Grid item xs={12} sm={4} md={4} lg={2}>
                    <Field
                      size='small'
                      name='imei_serial_consult'
                      label='Digite o IMEI/SERIAL'
                      component={Text}
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={2}>
                    <Field
                      size='small'
                      name='input_data'
                      variant='inline'
                      fullWidth
                      inputVariant='outlined'
                      label='Data de entrada'
                      component={DatePicker}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={2}>
                    <Field
                      size='small'
                      name='output_data'
                      variant='inline'
                      fullWidth
                      inputVariant='outlined'
                      label='Data de saída'
                      component={DatePicker}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={2}>
                    <Button onClick={() => handleGetTableBlocks(props)} fullWidth>
                      Filtrar
                    </Button>
                  </Grid>
                  {table.length > 0 && (
                    <>
                      <Grid item xs={12} sm={4} md={12} lg={12}>
                        <Table name='Table' headers={columns} disableNumeration>
                          {table
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((item, index) => (
                              <TableRow key={index}>
                                <TableCell>{item.idBloqueio}</TableCell>
                                <TableCell>{item.valorBloqueado}</TableCell>
                                <TableCell>{item.descTipoBloqueio}</TableCell>
                                <TableCell>{item.descTipoLogBloqueio}</TableCell>
                                <TableCell>{formatDate(item.dtLogBloqueio)}</TableCell>
                                <TableCell>{item.bloqueadoPor}</TableCell>
                                <TableCell>{item.obsBloqueio}</TableCell>
                                <TableCell>{item.Cliente}</TableCell>
                                <TableCell>
                                  <Button onClick={() => handleOpenDialogUnlock(item)}>
                                    Desbloquear
                                  </Button>
                                </TableCell>
                              </TableRow>
                            ))}
                        </Table>
                        <TablePagination
                          component='div'
                          count={table.length}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Grid>
                    </>
                  )}
                </>
              )}
              {props.values.type_action === 2 && (
                <>
                  <Grid item xs={12} sm={4} md={4} lg={2}>
                    <Button onClick={handleImportClickUnlock} fullWidth>
                      Uploads Seriais P/Desbloqueio
                    </Button>
                  </Grid>
                </>
              )}
              {((props.values.type_action === 0 && props.values.type_block === 2) ||
                props.values.type_action === 2) && (
                  <Grid item xs={12} sm={4} md={4} lg={2}>
                    <Button color='secondary' onClick={handleDownloadLayout} fullWidth>
                      Baixar Layout Upload
                    </Button>
                  </Grid>
                )}
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
