import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import { Text, Button, Table, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { PageTitle } from 'pages/Layout/PageTitle';
import { useContextSelector } from 'use-context-selector';

import { pcpService } from '../../../../services/pcp';
import { DialogConfirmNextTecNoRequest } from './Dialogs/DialogConfirmNextTecNoRequest';
import { DialogConsultTec } from './Dialogs/DialogConsultTec';
import { DialogDeleteAllOs } from './Dialogs/DialogDeleteAllOs';
import { DialogDeleteOsAssign } from './Dialogs/DialogDeleteOsAssign';

export const OsAssignment = () => {
  const user = useContextSelector(AuthContext, (context) => context.user);

  const resquests = [
    {
      label: 'OS',
      value: 0,
    },
    {
      label: 'SERIAL',
      value: 1,
    },
  ];

  const [dataTableOs, setOsTable] = useState([]);
  const [dataTec, setDataTec] = useState([]);
  const [focused, setFocused] = useState(false);

  const [openConsultTec, setOpenConsultTec] = useState(false);
  const [openDialogConfirmNextTecNoRequest, setOpenDialogConfirmNextTecNoRequest] = useState(false);
  const [openDialogDeleteOsAssign, setOpenDialogDeleteOsAssign] = useState(false);
  const [openDialogDeleteAllOs, setOpenDialogDeleteAllOs] = useState(false);

  const [field, setField] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [totalSLaudo, setTotalSLaudo] = useState(0);
  const [totalCLaudo, setTotalCLaudo] = useState(0);
  const [numOsCmp, setNumOsCmp] = useState({});
  const [lineOsTec, setLineOsTec] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [numTecData, setNumTecData] = useState('');

  const handleGetTec = async (props, numTec) => {
    // console.log('props', props, 'numTec', numTec);
    if (!props?.values?.numtec && !numTec) {
      toast.error('Por favor inserir o número do técnico.');
      setIsLoading(false);
      return;
    }
    setNumTecData(props?.values.numtec ?? numTec);
    const { data } = await pcpService.getOsTec({
      numeroTec: props?.values.numtec ? props?.values.numtec : numTec,
      numTec: numTec ? numTec : '',
    });

    const { data: dataTecnico } = await pcpService.getInfoTec({
      numeroTec: props?.values.numtec ? props?.values.numtec : numTec,
      numTec: numTec ? numTec : '',
    });

    setDataTec(dataTecnico.data);
    if (data.status === 200) {
      setOsTable(data.data);
      setPage(0);
      getQtdLaudos(data.data);
      const osSerialField = document.querySelector('[name="os_serial"]');
      if (osSerialField) {
        osSerialField.focus();
      }
    } else {
      toast.error(data.msg);
      setOsTable([]);
      const osSerialField = document.querySelector('[name="os_serial"]');
      if (osSerialField) {
        osSerialField.focus();
      }
    }
  };

  useEffect(() => {
    if (dataTableOs.length > 0 && !focused) {
      const serialField = document.querySelector('[name="os_serial"]');
      if (serialField) {
        serialField.focus();
        setFocused(true);
      }
    }
  }, [dataTableOs, focused]);

  //Init Dialogs
  const handleOpenConsultTec = (props) => {
    setField(props);
    setOpenConsultTec(true);
  };
  const handleCloseConsultTec = () => {
    setOpenConsultTec(false);
  };

  const handleOpenDialogConfirmNextTecNoRequest = () => {
    setOpenDialogConfirmNextTecNoRequest(true);
  };
  const handleCloseDialogConfirmNextTecNoRequest = () => {
    setOpenDialogConfirmNextTecNoRequest(false);
  };

  const handleOpenDialogDeleteOsAssign = (item) => {
    setLineOsTec(item);
    setOpenDialogDeleteOsAssign(true);
  };
  const handleCloseDialogDeleteOsAssign = () => {
    setOpenDialogDeleteOsAssign(false);
    handleGetTec(null, numTecData);
  };

  const handleOpenDialogDeleteAllOs = () => {
    setOpenDialogDeleteAllOs(true);
  };
  const handleCloseDialogDeleteAllOs = () => {
    setOpenDialogDeleteAllOs(false);
    setOsTable([]);
  };

  // end Dialogs

  const getNumTec = (numTec) => {
    handleCloseConsultTec();
    field.setFieldValue('numtec', numTec);
    handleGetTec(null, numTec);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Soma dos laudos
  const getQtdLaudos = (data) => {
    let totalS = 0;
    let totalC = 0;
    for (let i = 0; i < data.length; i++) {
      if (data[i].laudo === 'SIM') {
        totalC += 1;
      } else {
        totalS += 1;
      }
    }
    setTotalSLaudo(totalS);
    setTotalCLaudo(totalC);
  };

  const total = totalCLaudo + totalSLaudo;

  // Botão próximo técnico
  const handleNextTec = (props) => {
    props.setFieldValue('numtec', '');
    props.setFieldValue('request_type', 0);
    setTotalCLaudo(0);
    setTotalSLaudo(0);
    setOsTable([]);
    setDataTec([]);

    const numtecField = document.querySelector('[name="numtec"]');
    numtecField.focus();
  };

  // Atribuir OS
  const handleAssignOs = async (props) => {
    setIsLoading(true);
    try {
      if (props.values.os_serial === '') {
        toast.error('Porfavor inserir uma os ou serial.');
        setIsLoading(false);
        return;
      }
      const { data } = await pcpService.assignOs({
        numOs: props.values.os_serial,
        request_type: props.values.request_type,
        numTec: props.values.numtec,
        user: user.id,
      });
      if (data.status === 500) {
        toast.error(data.msg);
        setIsLoading(false);
      }
      if (data.status === 501) {
        setNumOsCmp(data);
        handleOpenDialogConfirmNextTecNoRequest();
      }
      if (data.status === 200) {
        toast.success(data.msg);
        setIsLoading(false);
        handleGetTec(null, props.values.numtec);
      }
    } catch {
      console.log('teste');
      setIsLoading(false);
      toast.error('Falha ao atribuir OS');
    }
    props.setFieldValue('os_serial', '');
  };

  const colunas = [
    { label: 'OS' },
    { label: 'PN' },
    { label: 'Status' },
    { label: 'Servico' },
    { label: 'Aging Real' },
    { label: 'Modelo' },
    { label: 'Garantia' },
    { label: 'Laudo' },
    {
      label: (
        <IconButton onClick={() => handleOpenDialogDeleteAllOs()}>
          <DeleteIcon style={{ color: '#F50057' }} />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <DialogDeleteAllOs
        openDialogDeleteAllOs={openDialogDeleteAllOs}
        handleCloseDialogDeleteAllOs={handleCloseDialogDeleteAllOs}
        numTecData={numTecData}
        setNumTecData={setNumTecData}
      />
      <DialogDeleteOsAssign
        openDialogDeleteOsAssign={openDialogDeleteOsAssign}
        handleCloseDialogDeleteOsAssign={handleCloseDialogDeleteOsAssign}
        lineOsTec={lineOsTec}
      />
      <DialogConfirmNextTecNoRequest
        openDialogConfirmNextTecNoRequest={openDialogConfirmNextTecNoRequest}
        handleOpenDialogConfirmNextTecNoRequest={handleCloseDialogConfirmNextTecNoRequest}
        numOsCmp={numOsCmp}
      />
      <DialogConsultTec
        openConsultTec={openConsultTec}
        handleCloseConsultTec={handleCloseConsultTec}
        getNumTec={getNumTec}
      />
      <PageTitle>Atribuição de OS</PageTitle>

      <Formik initialValues={{ numtec: '', os_serial: '', request_type: 0 }}>
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={2}>
                <Field
                  variant='outlined'
                  name='numtec'
                  label='Técnico'
                  size='small'
                  component={Text}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      handleGetTec(props, null);
                    }
                  }}
                  disabled={dataTableOs.length > 0}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Button fullWidth onClick={() => handleNextTec(props)} loading={isLoading}>
                  Próximo Técnico
                </Button>
              </Grid>
              <Grid item xs={12} lg={6}></Grid>
              <Grid item xs={12} lg={2}>
                <Button
                  fullWidth
                  onClick={() => handleOpenConsultTec(props)}
                  startIcon={<SearchIcon />}
                  loading={isLoading}
                >
                  Consultar Técnicos
                </Button>
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12} lg={2}>
                <Field
                  variant='outlined'
                  name='request_type'
                  label='Tipo de Coleta'
                  size='small'
                  component={Select}
                  options={resquests}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Field
                  variant='outlined'
                  name='os_serial'
                  label='OS / Serial'
                  size='small'
                  component={Text}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      handleAssignOs(props);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Button fullWidth onClick={() => handleAssignOs(props)} loading={isLoading}>
                  Atribuir OS
                </Button>
              </Grid>
              <Grid item xs={12} lg={6}></Grid>

              <Grid item xs={12} lg={3}>
                <Typography
                  variant='h1'
                  style={{ paddingTop: '0.5rem', fontSize: '20px', fontWeight: 'bold' }}
                >
                  OS`s Atribuídas
                </Typography>
              </Grid>
              <Box
                component={Paper}
                display='flex'
                flexDirection='row'
                sx={{
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
                  width: '100vw',
                  height: '5vh',
                  boxSizing: 'border-box',
                }}
              >
                <Grid item xs={12} lg={2}>
                  <Typography style={{ paddingTop: '0.5rem' }} align='center'>
                    Técnico: {dataTec?.[0]?.NOME}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Typography style={{ paddingTop: '0.5rem' }} align='center'>
                    Limite placas: {dataTec?.[0]?.placas_max}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Typography style={{ paddingTop: '0.5rem' }} align='center'>
                    Total S/ Laudo: {totalSLaudo}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Typography style={{ paddingTop: '0.5rem' }} align='center'>
                    Total C/ Laudo: {totalCLaudo}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Typography style={{ paddingTop: '0.5rem' }} align='center'>
                    Total: {total}
                  </Typography>
                </Grid>
              </Box>
              {dataTableOs.length > 0 && (
                <>
                  <Box
                    mt={1}
                    component={Paper}
                    sx={{
                      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
                      width: '100vw',
                      boxSizing: 'border-box',
                    }}
                  >
                    <Grid item xs={12}>
                      <Table name='table' headers={colunas} disableNumeration>
                        {dataTableOs
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((item, index) => (
                            <TableRow key={index}>
                              <TableCell>{item.numeroos}</TableCell>
                              <TableCell>{item.modelo}</TableCell>
                              <TableCell>{item.status}</TableCell>
                              <TableCell>{item.aparelho}</TableCell>
                              <TableCell>{item.agingReal}</TableCell>
                              <TableCell>{item.md}</TableCell>
                              <TableCell>
                                <Checkbox
                                  style={{ color: '#512da8' }}
                                  checked={item.garantia === 'S'}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  style={{ color: '#512da8' }}
                                  checked={item.laudo === 'SIM'}
                                />
                              </TableCell>
                              <TableCell>
                                <IconButton>
                                  <DeleteIcon
                                    style={{ color: '#F50057' }}
                                    onClick={() => handleOpenDialogDeleteOsAssign(item)}
                                  />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                      </Table>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={dataTableOs.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Grid>
                  </Box>
                </>
              )}
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
