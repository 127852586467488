import React from 'react';

import { AccessControlRoutes } from './AccessControl';
import { CollaboratorsRoutes } from './CollaboratorsRoutes';
import { ConferenceRoutes } from './ConferenceRoutes';
import { LaunchRoutes } from './LaunchRoutes';
import { PcpRoutes } from './PcpRoutes';
import { ReceivementRoutes } from './ReceivementRoutes';
import { ReportConferenceRoutes } from './ReportConference';
import { ReportEngineeringRoutes } from './ReportEngineering';
import { ReportLabRoutes } from './ReportLab';
import { ReportReleasesRoutes } from './ReportReleases';
import { ReportRoutes } from './ReportRoutes';
import { ReportsReceiptsRoutes } from './ReportsReceipts';
import { ReportsShippingRoutes } from './ReportsShipping';
import { ReportStockRoutes } from './ReportStock';
import { ScreeningRoutes } from './ScreeningRoutes';
import { ServiceOrderRoutes } from './ServiceOrderRoutes';
import { StockRoutes } from './StockRoutes';
import { ToQuoteRoutes } from './ToQuote';

export const FavoritesRoutes = () => {
  return (
    <>
      <AccessControlRoutes />
      <ReportRoutes />
      <CollaboratorsRoutes />
      <ConferenceRoutes />
      <LaunchRoutes />
      <PcpRoutes />
      <ScreeningRoutes />
      <StockRoutes />
      <ServiceOrderRoutes />
      <ToQuoteRoutes />
      <ReceivementRoutes />
      <ReportConferenceRoutes />
      <ReportEngineeringRoutes />
      <ReportStockRoutes />
      <ReportLabRoutes />
      <ReportsShippingRoutes />
      <ReportReleasesRoutes />
      <ReportsReceiptsRoutes />
    </>
  );
};
