import React from 'react';
import { FixedSizeList } from 'react-window';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import NotificationsIcon from '@material-ui/icons/Notifications';
const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(1),
  },
  popOver: {
    marginTop: 10,
  },
  root: {
    width: '100%',
    height: 400,
    maxWidth: 300,
    backgroundColor: theme.palette.background.paper,
  },
}));

function renderRow(props) {
  const { index, style } = props;

  return (
    <ListItem button style={style} key={index}>
      <ListItemText primary={`Informações do sistema:`} />
    </ListItem>
  );
}

export const PopOverNotifications = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <NotificationsIcon
        aria-describedby={id}
        variant='contained'
        color='inherit'
        onClick={handleClick}
      >
        Open Popover
      </NotificationsIcon>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className={classes.popOver}
      >
        <div className={classes.root}>
          <FixedSizeList height={400} width={250} itemSize={46} itemCount={1}>
            {renderRow}
          </FixedSizeList>
        </div>
      </Popover>
    </div>
  );
};
