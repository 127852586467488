import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, Select, Text, DatePicker } from 'components';
import { Field, Form, Formik } from 'formik';
import { downloadXlsx } from 'helpers/downloadXLSX';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providerService } from 'services/providers';

export const BipingConferenceReport = () => {
  const typeOptions = [
    { label: 'Por NF', value: '0' },
    { label: 'Por Data', value: '1' },
  ];

  const [isLoading, setIsLoading] = useState(0);

  const handleExportReport = async (values) => {
    toast.info('Aguarde, relatório sendo gerado.');
    setIsLoading(1);

    try {
      const { data } = await providerService.getBipingConferenceReport(values);
      console.log(data);
      await downloadXlsx(`ingenico_relatorio_conferencia`, data);
      toast.success('Relatório gerado com sucesso');
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    } finally {
      setIsLoading(0);
    }
  };

  return (
    <>
      <PageTitle>Relatório da Conferência por Bipagem</PageTitle>

      <Formik
        initialValues={{
          type_report: '0',
          num_nf: '',
          input_data: new Date(),
          output_data: new Date(),
        }}
        onSubmit={handleExportReport}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='type_report'
                  label='Tipo de relatório'
                  variant='outlined'
                  options={typeOptions}
                  component={Select}
                ></Field>
              </Grid>
              {props.values.type_report == '0' && (
                <>
                  <Grid item xs={12} lg={3}>
                    <Field
                      size='small'
                      name='num_nf'
                      label='Digite a NF1'
                      type='number'
                      variant='outlined'
                      value={props.values.num_Nf}
                      component={Text}
                      onChange={(e) => {
                        if (e.target.value < 0) {
                          props.setFieldValue('num_Nf', 0);
                        }
                      }}
                    />
                  </Grid>
                </>
              )}
              {props.values.type_report == '1' && (
                <>
                  <Grid item xs={12} lg={3}>
                    <Field
                      size='small'
                      name='input_data'
                      variant='outlined'
                      fullWidth
                      inputVariant='outlined'
                      label='Data Inicial'
                      component={DatePicker}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Field
                      size='small'
                      name='output_data'
                      variant='outlined'
                      fullWidth
                      inputVariant='outlined'
                      label='Data Final'
                      component={DatePicker}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} lg={3}>
                <Button type='submit' fullWidth disabled={isLoading == 1}>
                  Exportar relatório
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
