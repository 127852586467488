import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: '100vh',
    backgroundColor: '#fdfdfd',
    backgroundPositionY: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `url(${wavesImg})`,
  },
  container: {
    width: '100%',
    maxWidth: 420,
    padding: `${theme.spacing(6)}px ${theme.spacing(5)}px`,
    backgroundColor: '#fff',

    display: 'flex',
    flexDirection: 'column',
  },
  alreadyRegistered: {
    fontSize: 16,
    textDecoration: 'none',
    color: '#000',

    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-start',

    '&:hover': {
      '& > svg': {
        transform: 'translateX(-5px)',
      },
    },
  },
  title: {
    fontSize: 32,
    margin: `${theme.spacing(2)}px 0`,
    color: theme.palette.text.primary,
  },
  backIcon: {
    fontSize: 24,
    transition: 'transform .4s',
    marginRight: 4,
  },
}));
