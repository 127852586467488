import { api } from 'services/api';

export class ScreeningService {
  async getDataTable(data) {
    return api.post(`/xpcell-ingenico-screening/screening-clear`, data);
  }

  async getOsLaudo(data) {
    return api.post(`/xpcell-ingenico-screening/getOsLaudo`, data);
  }

  async insertCancellation(data) {
    return api.post(`/xpcell-ingenico-screening/insertCancellation`, data);
  }

  async getDefects(data) {
    return api.post(`/xpcell-ingenico-screening/getDefects`, data);
  }

  async checkOsOrSerial(data) {
    return api.post(`/xpcell-ingenico-screening/checkOsOrSerial`, data);
  }

  async processCollect(data) {
    return api.post(`/xpcell-ingenico-screening/processCollect`, data);
  }

  async getAccessory(data) {
    return api.post(`/xpcell-ingenico-screening/getAccessory`, data);
  }

  async insertAccesory(data) {
    return api.post(`/xpcell-ingenico-screening/insertAccesory`, data);
  }

  async insertSemDefeito(data) {
    return api.post(`/xpcell-ingenico-screening/insertSemDefeito`, data);
  }

  async getSerialBlackListWifi(data) {
    return api.post(`/xpcell-ingenico-screening/getSerialBlackListWifi`, data);
  }

  async getDataFunctionOS(data) {
    return api.post(`/xpcell-ingenico-screening/getDataFunctionOS`, data);
  }

  async insertTransferirComDefeito(data) {
    return api.post(`/xpcell-ingenico-screening/insertTransferirComDefeito`, data);
  }

  async semDefeito(data) {
    return api.post(`/xpcell-ingenico-screening/semDefeito`, data);
  }

  async comDefeito(data) {
    return api.post(`/xpcell-ingenico-screening/comDefeito`, data);
  }

  async reprintEtiquetaCollect(data) {
    return api.post(`/xpcell-ingenico-screening/reprintEtiquetaCollect`, data);
  }

  async getEtiqueta(data) {
    return api.post(`/xpcell-ingenico-screening/getEtiqueta`, data, {
      responseType: 'blob',
    });
  }

  async getInfDataRecovery(data) {
    return api.post(`/xpcell-ingenico-screening/screening-dataRecovery`, data);
  }

  async getEnviaDados(valuesID, orderServ) {
    return api.post(`xpcell-ingenico-screening/screening-requestIng`, {
      valuesID,
      orderServ,
      userGaia,
    });
  }
}

export const screeningService = new ScreeningService();
