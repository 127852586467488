import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 320,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    maxWidth: 350,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    marginBottom: theme.spacing(1),
  },
  linksContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px`,
  },
  linksDivider: {
    margin: `0 ${theme.spacing(1)}px`,
    color: 'rgba(0, 0, 0, .4)',
  },
  link: {
    textDecoration: 'none',
    color: '#000',
    opacity: 0.5,
    transition: 'opacity .4s',
    '&:hover': {
      opacity: 1,
    },
  },
}));
