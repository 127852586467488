import React, { useContext } from 'react';

import { Button, Spacer } from 'components';
import { useFormikContext } from 'formik';

import { ClientContext } from '../';
import { registerClient } from '../ClientInfoNew';
import { useStyles } from './styles';

export const Actions = ({ activeStep, handleBack, handleNext, stepsSize }) => {
  const { handleSubmit, isValid, dirty, isSubmitting, values, resetForm } = useFormikContext();

  const { clientExists, setClientExists, hasClient, setHasClient } = useContext(ClientContext);

  const classes = useStyles();

  const isFirstStep = activeStep === 0;

  return (
    <div className={classes.footer}>
      <Spacer size={24} />
      <div className={classes.actions}>
        <Button disabled={isFirstStep} onClick={handleBack} className={classes.backButton}>
          Voltar
        </Button>
        {/* {activeStep === stepsSize - 1 &&
          (clientExists ? (
            <Button
              color='primary'
              loading={isSubmitting}
              onClick={() => registerClient(values, setClientExists, setHasClient, resetForm)}
            >
              Cadastrar cliente
            </Button>
          ) : (
            <Button
              type='submit'
              color='primary'
              onClick={handleSubmit}
              disabled={!isValid || !dirty}
              loading={isSubmitting}
            >
              Enviar
            </Button>
          ))} */}
        {/* {activeStep !== stepsSize - 1 && (
          <Button
            color='primary'
            disabled={!isFirstStep ? values.service_order_found : isSubmitting}
            loading={isSubmitting}
            onClick={handleNext}
          >
            {console.log('Values: ', values)}
            Próxima Página
          </Button>
        )} */}

        {(() => {
          switch (activeStep) {
            case 0:
              return clientExists ? (
                <Button
                  color='primary'
                  loading={isSubmitting}
                  onClick={() => registerClient(values, setClientExists, setHasClient, resetForm)}
                >
                  Cadastrar cliente
                </Button>
              ) : (
                <Button
                  color='primary'
                  disabled={
                    isSubmitting ||
                    !values?.name ||
                    !values?.city ||
                    !values?.email ||
                    !values?.state ||
                    !values?.district ||
                    !values?.zipcode ||
                    !values?.phone_number ||
                    !values?.cgc
                  }
                  loading={isSubmitting}
                  onClick={handleNext}
                >
                  {console.log('Values: ', values)}
                  Próxima Página
                </Button>
              );
            case 1:
              return (
                <Button
                  color='primary'
                  disabled={
                    isSubmitting ||
                    !values?.smartphone_brand_id ||
                    !values?.smartphone_model_id ||
                    !values?.smartphone_use_time_id
                  }
                  loading={isSubmitting}
                  onClick={handleNext}
                >
                  {console.log('Values: ', values)}
                  Próxima Página
                </Button>
              );
            case 2:
              return (
                <Button
                  color='primary'
                  disabled={isSubmitting || !values?.insurance_type}
                  loading={isSubmitting}
                  onClick={handleNext}
                >
                  {console.log('Values: ', values)}
                  Próxima Página
                </Button>
              );
            case 3:
              return (
                <Button
                  color='primary'
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  onClick={handleNext}
                >
                  {console.log('Values: ', values)}
                  Próxima Página
                </Button>
              );
            case 4:
              return (
                <Button
                  color='primary'
                  disabled={isSubmitting || !values?.payment_type || !values?.payment_method}
                  loading={isSubmitting}
                  onClick={handleNext}
                >
                  Próxima Página
                </Button>
              );
            case 5:
              return (
                <Button
                  // type='submit'
                  color='primary'
                  onClick={handleSubmit}
                  disabled={!isValid || !dirty}
                  loading={isSubmitting}
                >
                  Enviar
                </Button>
              );
            default:
              return <h1>Preenchar todos os campos para habilitar a confirmação</h1>;
          }
        })()}
      </div>
    </div>
  );
};
