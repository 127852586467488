import React, { useRef, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { Button, Text, Table } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providerService } from 'services';
import XLSX from 'xlsx';

import { readCsvSemCabec } from '../../../helpers/readCSV.js';

const columns = [{ label: 'Serial' }, { label: 'PartNumber' }];

export const SerialbyPartnumberReport = () => {
  const [importing, setImporting] = useState(false);
  const [isLoading, setIsLoading] = useState(0);
  const [serial, setSerial] = useState('');
  const [serialList, setSerialList] = useState([]);

  const inputFileRef = useRef(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAddSerial = async (props) => {
    // Lógica para adicionar o serial à lista
    const partnumber = await hadleGetPartnumber(serial);
    console.log('partnumber', partnumber);
    setSerialList([{ serial: serial, partnumber: partnumber }, ...serialList]);
    setSerial('');
    props.setFieldValue('serial_data', '');
  };

  const handleKeyPress = async (event, props) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Evitar a ação padrão do Enter em um formulário
      await handleAddSerial(props);
    }
  };

  const hadleGetPartnumber = async (serial) => {
    const { data } = await providerService.getSerialByPartnumber([`${serial}`]);
    console.log(data);
    return data[0]?.codProd;
  };

  const handleXlsx = () => {
    const now = new Date().toLocaleDateString('pt-BR');
    toast.info('Aguarde, relatório sendo gerado.');
    setIsLoading(1);
    try {
      const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(serialList));
      const workbook = (XLSX.WorkBook = {
        Sheets: {
          pasta1: worksheet,
        },
        SheetNames: ['pasta1'],
      });
      XLSX.writeFile(workbook, `RelatorioSerialPartnumber${now}.xlsx`);
      toast.success('Relatório gerado com sucesso');
      setSerialList([]);
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    } finally {
      setIsLoading(0);
    }
  };

  const handleImportClick = () => {
    console.log('teste 1');
    inputFileRef.current.click();
  };

  const handleFileChange = async (event) => {
    setImporting(true);
    try {
      const data_csv = await readCsvSemCabec(event);
      const { data } = await providerService.getSerialByPartnumber(data_csv);
      setSerialList((prev) => {
        return [
          ...prev,
          ...data?.map((item) => ({ serial: item.serial, partnumber: item.codProd })),
        ];
      });
      toast.success('Pesquisa feita.');
      setImporting(false);
    } catch (error) {
      console.log(error);
    } finally {
      setImporting(false);
      inputFileRef.current.value = '';
    }
  };

  return (
    <>
      <input
        ref={inputFileRef}
        type='file'
        name='csv'
        onChange={handleFileChange}
        accept='text/csv'
        hidden
      />
      <PageTitle>Relatório Serial para Partnumber</PageTitle>
      <Formik
        initialValues={{
          serial_data: serial,
        }}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Field
                  size='small'
                  name='serial_data'
                  label='Serial'
                  variant='outlined'
                  component={Text}
                  loading={importing || isLoading == 1}
                  onKeyPress={(event) => handleKeyPress(event, props)}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Button
                  fullWidth
                  onClick={() => handleAddSerial(props)}
                  disabled={importing || isLoading == 1}
                >
                  Adicionar Serial
                </Button>
              </Grid>
              <Grid item xs={12} lg={2}>
                <Button
                  fullWidth
                  onClick={() => handleXlsx()}
                  disabled={isLoading == 1 || importing}
                >
                  gerar arquivo
                </Button>
              </Grid>
              <Grid item xs={12} lg={2}>
                <Button
                  color='secondary'
                  variant='contained'
                  fullWidth
                  onClick={handleImportClick}
                  disabled={importing || isLoading == 1}
                >
                  IMPORTAR PLANILHA
                </Button>
              </Grid>
              <Grid item xs={12} lg={2}>
                <Button
                  color='secondary'
                  variant='contained'
                  fullWidth
                  onClick={() => setSerialList([])}
                  disabled={importing || isLoading == 1}
                >
                  Limpar
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Table
                  headers={columns}
                  maxHeight
                  striped
                  loading={false}
                  emptyMessage={'Sem seriais registrados'}
                  data={[]}
                  title='Notas agendadas'
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  disableNumeration
                >
                  {serialList?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.serial}</TableCell>
                      <TableCell>{item.partnumber}</TableCell>
                    </TableRow>
                  ))}
                </Table>
              </Grid>
            </Grid>
            {useEffect(() => {
              setSerial(props.values.serial_data);
              // eslint-disable-next-line
            }, [props, setSerial])}
          </Form>
        )}
      </Formik>
    </>
  );
};
