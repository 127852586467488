import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  id: yup.string().required(),
  fullname: yup.string().required(),
  email: yup.string().required(),
  role_id: yup.number().required(),
  phone: yup.string().required(),
  active: yup.boolean().required(),
});
