import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, Dialog, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useContextSelector } from 'use-context-selector';
import * as Yup from 'yup';

import { receivementService } from '../../../../../services/receivement';

const validationSchema = Yup.object().shape({
  input_new_nf: Yup.string().required('O campo não pode estar vazio'),
});

export const DialogEdit = ({ openDialogEdit, handleCloseDialogEdit, selectedRowData, infoNF }) => {
  const [isLoading, setIsLoading] = useState(false);
  const user = useContextSelector(AuthContext, (context) => context.user);
  const userName = user.name;

  const processCorrectionNF = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await receivementService.processCorrectionNF({
        dataNF: selectedRowData,
        new_nf: values.input_new_nf,
        user: userName,
      });
      toast.success('NF atualizada com sucesso!');
    } catch (error) {
      console.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
      await handleCloseDialogEdit();
      window.location.reload();
    }
  };
  return (
    <>
      <Dialog open={openDialogEdit}>
        <Dialog.Title onClose={handleCloseDialogEdit}>Atualizar</Dialog.Title>
        <Dialog.Content>
          <Formik
            initialValues={{ input_new_nf: infoNF?.resultExistsNF?.[0]?.[0]?.NF }}
            validationSchema={validationSchema}
            onSubmit={processCorrectionNF}
          >
            {(props) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={12}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_new_nf'
                      variant='outlined'
                      fullWidth
                      label='NF Corrigida'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={3} md={3} lg={3}>
                    <Button fullWidth type='submit' loading={isLoading}>
                      Gravar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
