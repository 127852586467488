import React from 'react';
import { useRouteMatch } from 'react-router';

import { AgingDoca } from 'pages/Reports/AgingDocaReport';
import { InventoryReceipt } from 'pages/Reports/InventoryReceiptReport';
import { NFinput } from 'pages/Reports/NFInputReport';
import { ReceiptReport } from 'pages/Reports/ReceiptReport';
import { StatusLogNfReport } from 'pages/Reports/StatusLogNfReport';

import { Route } from '../Route';

export const ReportsReceiptsRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${path}/aging-doca`} component={AgingDoca} />
      <Route isPrivate path={`${path}/report-receipt`} component={ReceiptReport} />
      <Route isPrivate path={`${path}/nf-of-input`} component={NFinput} />
      <Route isPrivate path={`${path}/inventory-receipt`} component={InventoryReceipt} />
      <Route isPrivate path={`${path}/status-log-nf`} component={StatusLogNfReport} />
    </>
  );
};
