import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { FormatDate, FormatMoney, Table, Text } from 'components';
import { Formik, Form, Field } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { quoteService } from 'services';
const columns = [
  { label: 'Cliente' },
  { label: 'Marca' },
  { label: 'Modelo' },
  { label: 'Tipo de garantia' },
  { label: 'Tempo de uso' },
  { label: 'Sinistro' },
  { label: 'Total' },
  { label: 'Data de criação' },
  { label: <VisibilityIcon />, align: 'center' },
];

export const TableTransactionsStatus = () => {
  const [isDetailsOpen, setIsDetailsState] = useBoolean();

  const [quotes, setQuotes] = useState({});
  const [search, setSearch] = useState('');

  const [quoteTransaction, , loadingQuoteTransaction, refetchQuoteTransaction] = useQuery(
    () => quoteService.listQuoteTransaction(),
    [],
  );

  const formattedQuoteTransaction = (quoteTransaction || []).map((b) => {
    return {
      ...b,
    };
  });

  const filteredQuoteTransaction = formattedQuoteTransaction.filter((quote) => {
    const upperClientName = quote?.client?.name?.toUpperCase();
    const lowerClientName = quote?.client?.name?.toLowerCase();

    return upperClientName?.includes(search) || lowerClientName?.includes(search);
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const handleOpenInformation = (e, item) => {
    setIsDetailsState.toTrue();
    setQuotes(item);
  };

  console.log('quoteTransaction: ', quoteTransaction);

  return (
    <>
      <Formik initialValues={{ search: '' }}>
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Field
                  size='small'
                  variant='outlined'
                  name='search'
                  fullWidth
                  endAdornment={<SearchIcon />}
                  label='Pesquisar'
                  component={Text}
                  onChange={handleSearchChange}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <Table
                  headers={columns}
                  loading={loadingQuoteTransaction}
                  stripped
                  maxHeight
                  emptyMessage='Nenhum registro encontrado.'
                  page={page}
                  data={filteredQuoteTransaction}
                  rowsPerPage={rowsPerPage}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  disableNumeration
                >
                  {filteredQuoteTransaction?.map((quote) => (
                    <TableRow key={quote.id}>
                      <TableCell>{quote?.client?.name}</TableCell>
                      <TableCell>{quote?.smartphone_brand?.description}</TableCell>
                      <TableCell>{quote?.smartphone_model?.description}</TableCell>
                      <TableCell>{quote?.smartphone_insurance_type?.description}</TableCell>
                      <TableCell>{quote?.smartphone_use_time?.description}</TableCell>
                      <TableCell>{quote?.generated_sinister}</TableCell>
                      <TableCell>
                        <FormatMoney>{quote?.total}</FormatMoney>
                      </TableCell>
                      <TableCell>
                        <FormatDate format='dd/MM/yyyy HH:mm:ss'>{quote?.created_at}</FormatDate>
                      </TableCell>
                      <TableCell align='center'>
                        <IconButton
                          color='primary'
                          onClick={(e) => handleOpenInformation(e, quote)}
                        >
                          <AddCircleIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
