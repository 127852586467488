import React from 'react';

import MUITab from '@material-ui/core/Tab';
import MUITabs from '@material-ui/core/Tabs';

import { getReactChildrenProps } from 'helpers/react';

import Content from './Content';
import { useStyles } from './styles';

let incremeter = 0;

const Tabs = React.memo((props) => {
  const { value, onChange, children, gutterBottom, ...rest } = props;

  const classes = useStyles(props);

  const [position, setPosition] = React.useState(0);

  const controlled = value !== undefined;
  const currentValue = controlled ? value : position;

  const handleChange = React.useCallback(
    (_, value) => {
      if (controlled) {
        onChange && onChange(value);
        return;
      }

      setPosition(value);
    },
    [controlled, onChange],
  );

  const tabs = React.useMemo(() => {
    return getReactChildrenProps(children, Content).map((props) => ({
      ...props,
      id: `tab-${incremeter++}`,
    }));
  }, [children]);

  return (
    <>
      <MUITabs
        scrollButtons='auto'
        variant='scrollable'
        textColor='primary'
        indicatorColor='primary'
        value={currentValue}
        onChange={handleChange}
        className={gutterBottom && classes.gutterBottom}
        {...rest}
      >
        {tabs?.map((tab) => (
          <MUITab
            disableRipple
            key={tab.id}
            icon={tab.icon}
            label={tab.label}
            disabled={tab.disabled}
            id={tab.id}
          />
        ))}
      </MUITabs>

      {tabs?.map((tab, index) => (
        <div
          id={`content-tab-${tab.id}`}
          className={!tab.disablePadding ? classes.containerPadding : null}
          hidden={index !== currentValue}
          key={tab.id}
        >
          {tab.children}
        </div>
      ))}
    </>
  );
});

Tabs.Content = Content;

Tabs.displayName = 'Tabs';

export { Tabs };
