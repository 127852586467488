import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    maxWidth: 600,
    margin: '0 auto',
    padding: `0 ${theme.spacing(2)}px`,
    display: 'block',
  },
  backLink: {
    display: 'block',
    color: '#101010',
    margin: `${theme.spacing(4)}px 0`,
    textDecoration: 'none',
  },
  title: {
    paddingTop: 0,
    fontSize: 36,
    color: '#101010',
  },
  version: {
    marginTop: theme.spacing(2),
    color: '#101010',
  },
  versionId: {
    fontSize: 28,
    marginBottom: theme.spacing(1),
  },
  description: {
    fontWeight: 300,
    marginBottom: theme.spacing(1),
  },
  changes: {
    listStyle: 'none',
    paddingLeft: theme.spacing(2),

    '& > li': {
      fontSize: 14,
      fontWeight: 300,
      marginBottom: theme.spacing(0.5),
    },
  },
  changeLogImage: {
    height: 240,
    width: '100%',
    margin: '24px auto',
    display: 'block',
  },
}));
