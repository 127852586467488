import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button } from 'components';
import { downloadXlsx } from 'helpers/downloadXLSX';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providerService } from 'services/providers';

export const CarStockReport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const handleDownloadCarStockReport = async () => {
    setIsLoading(true);
    toast.info('Aguardando relatório');
    try {
      const { data } = await providerService.getCarStockReport();
      downloadXlsx('ingenico_estoque_car', data);
      toast.success('Relatório Gerado com sucesso');
    } catch (error) {
      console.log(error);
      toast.error('Falha ao gerar relatório');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Relatório de Estoque CAR</PageTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <Button loading={isLoading} fullWidth onClick={handleDownloadCarStockReport}>
            exportar relatório
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
