import React, { useCallback, useEffect, useMemo } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Text, Radio, Select } from 'components';
import { Field, useFormikContext } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { budgetService } from 'services/budget';

import { useStyles } from './styles';

const installments = [
  {
    value: 1,
    label: '1x',
  },
  {
    value: 2,
    label: '2x',
  },
  {
    value: 3,
    label: '3x',
  },
  {
    value: 4,
    label: '4x',
  },
  {
    value: 5,
    label: '5x',
  },
  {
    value: 6,
    label: '6x',
  },
  {
    value: 7,
    label: '7x',
  },
  {
    value: 8,
    label: '8x',
  },
  {
    value: 9,
    label: '9x',
  },
  {
    value: 10,
    label: '10x',
  },
  {
    value: 11,
    label: '11x',
  },
  {
    value: 12,
    label: '12x',
  },
];

export const Payment = () => {
  const classes = useStyles();

  const { values, setFieldValue } = useFormikContext();

  const [paymentStatuses, , loadingPaymentStatuses] = useQuery(
    () => budgetService.listBudgetPaymentStatuses(),
    [],
  );

  const paymentStatusesOptions = (paymentStatuses || []).map((statuses) => {
    return {
      value: statuses.id,
      label: statuses.description,
    };
  });

  const updateTotal = useCallback(() => {
    const totalAccount = values?.insurance_value;

    setFieldValue('total', formatValue(totalAccount));
  }, [setFieldValue, values?.insurance_value]);

  const memoizedUpdateTotal = useMemo(() => updateTotal, [updateTotal]);

  useEffect(() => {
    memoizedUpdateTotal();
  }, [memoizedUpdateTotal]);

  const formatValue = (value) => parseFloat(value).toFixed(2);

  const [
    installmentPrice,
    installmentPriceCard1,
    installmentPriceCard2,
    installmentPriceCard3,
    moneyReceived,
  ] = useMemo(
    () => [
      formatValue(values?.insurance_value / values?.installments),
      formatValue(values?.total_price_card / values?.installments),
      formatValue(values?.total_price_card2 / values?.installments2),
      formatValue(values?.total_price_card3 / values?.installments3),
      formatValue(values?.money_received),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      values?.installments,
      values?.total_price_card,
      values?.installments2,
      values?.total_price_card2,
      values?.installments3,
      values?.total_price_card3,
      values?.insurance_value,
      values.money_received,
    ],
  );

  useEffect(() => {
    if ([1, 4, 6, 10, 11, 13 ? true : false].includes(Number(values.payment_method))) {
      setFieldValue('installments', 1);
    }
  }, [values.payment_method, setFieldValue]);

  useEffect(() => {
    if ([1, 4, 6, 10, 11, 13].includes(Number(values.payment_method2))) {
      setFieldValue('installments2', 1);
    }
  }, [values.payment_method2, setFieldValue]);

  useEffect(() => {
    if ([1, 4, 6, 10, 11, 13].includes(Number(values.payment_method3))) {
      setFieldValue('installments3', 1);
    }
  }, [values.payment_method3, setFieldValue]);

  useEffect(() => {
    if (Number(values.payment_type) === 1) {
      setFieldValue('installment_price', installmentPrice);
      setFieldValue('payment_method2', null);
      setFieldValue('total_price_card2', 0);
      setFieldValue('installments2', 1);
      setFieldValue('installment_price2', 0);
      setFieldValue('payment_method3', null);
      setFieldValue('total_price_card3', 0);
      setFieldValue('installments3', 1);
      setFieldValue('money_received', moneyReceived);

      if (Number(values.payment_method) === 11) {
        const moneyChange = formatValue(values.money_received - installmentPrice);
        setFieldValue('money_change', moneyChange);
      } else {
        setFieldValue('money_received', formatValue(0));
        setFieldValue('money_change', formatValue(0));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.payment_type,
    values.payment_method,
    installmentPrice,
    installmentPriceCard1,
    moneyReceived,
    setFieldValue,
  ]);

  useEffect(() => {
    if (Number(values.payment_type) === 2) {
      setFieldValue('installment_price', installmentPriceCard1);
      setFieldValue('installment_price2', installmentPriceCard2);
      setFieldValue('payment_method3', null);
      setFieldValue('total_price_card3', 0);
      setFieldValue('installment_price3', 0);
      setFieldValue('installments3', 1);
      setFieldValue('money_received', moneyReceived);

      if (Number(values?.payment_method) === 11) {
        setFieldValue('money_change', formatValue(values.money_received - installmentPriceCard1));
      }

      if (Number(values?.payment_method2) === 11) {
        setFieldValue('money_change', formatValue(values.money_received - installmentPriceCard2));
      }

      if (Number(values?.payment_method) !== 11 && Number(values?.payment_method2) !== 11) {
        setFieldValue('money_received', formatValue(0));
        setFieldValue('money_change', formatValue(0));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.payment_type,
    values?.payment_method,
    values?.payment_method2,
    installmentPriceCard2,
    installmentPriceCard1,
    moneyReceived,
    setFieldValue,
  ]);

  useEffect(() => {
    if (Number(values.payment_type) === 3) {
      setFieldValue('installment_price', installmentPriceCard1);
      setFieldValue('installment_price2', installmentPriceCard2);
      setFieldValue('installment_price3', installmentPriceCard3);

      if (Number(values?.payment_method) === 11) {
        const moneyChange = formatValue(values?.money_received - installmentPriceCard1);
        setFieldValue('money_change', moneyChange);
      }

      if (Number(values?.payment_method2) === 11) {
        setFieldValue('money_change', formatValue(values.money_received - installmentPriceCard2));
      }

      if (Number(values?.payment_method) === 13) {
        const moneyChange3 = formatValue(values?.money_received - installmentPriceCard3);
        setFieldValue('money_change', moneyChange3);
      }

      if (
        ![11].includes(Number(values?.payment_method)) &&
        ![11].includes(Number(values?.payment_method2)) &&
        ![11].includes(Number(values?.payment_method3))
      ) {
        setFieldValue('money_received', 0);
        setFieldValue('money_change', 0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.payment_type,
    values?.payment_method,
    values?.payment_method2,
    values?.payment_method3,
    installmentPriceCard1,
    installmentPriceCard2,
    installmentPriceCard3,
    moneyReceived,
    setFieldValue,
  ]);

  console.log('Valorinhos: ', values);

  return (
    <>
      <Grid container style={{ width: '100%', padding: '10px' }} spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <Grid className={classes.toCloseMargin} container spacing={0}>
            <Grid item xs={12} lg={4}>
              <Field
                color='primary'
                name='payment_type'
                value={1}
                fullWidth
                component={Radio}
                label='1 Método de Pagamento'
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <Field
                color='primary'
                name='payment_type'
                value={2}
                fullWidth
                component={Radio}
                label='2 Métodos de Pagamentos'
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <Field
                color='primary'
                name='payment_type'
                value={3}
                fullWidth
                component={Radio}
                label='3 Métodos de Pagamentos'
              />
            </Grid>
          </Grid>

          {values.payment_type == 1 ? (
            <>
              <Typography variant='h6' gutterBottom>
                Método de Pagamento 1
              </Typography>

              <Grid className={classes.paymentMethod} container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Field
                    variant='outlined'
                    label='Pagamento'
                    name='payment_method'
                    size='small'
                    fullWidth
                    component={Select}
                    options={paymentStatusesOptions}
                    className={classes.paymentMethodOptions}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Field
                    variant='outlined'
                    label='Parcelas'
                    name='installments'
                    size='small'
                    fullWidth
                    component={Select}
                    options={installments}
                    className={classes.paymentMethodOptions}
                    disabled={
                      values?.payment_method == 1 ||
                      values?.payment_method == 4 ||
                      values?.payment_method == 6 ||
                      values?.payment_method == 10 ||
                      values?.payment_method == 11 ||
                      values?.payment_method == 13
                        ? true
                        : false
                    }
                  />
                </Grid>
                <Grid item xs={6} lg={4}>
                  <Field
                    variant='outlined'
                    name='installment_price'
                    label='Valor por parcela'
                    size='small'
                    mask='money'
                    component={Text}
                    disabled
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <></>
          )}

          {values.payment_type == 2 ? (
            <>
              <Grid className={classes.paymentMethod} container spacing={2} />
              <Typography variant='h6' gutterBottom>
                Método de Pagamento 1
              </Typography>

              <Grid className={classes.paymentMethod} container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Field
                    variant='outlined'
                    label='Pagamento'
                    name='payment_method'
                    size='small'
                    fullWidth
                    component={Select}
                    options={paymentStatusesOptions}
                    className={classes.paymentMethodOptions}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Field
                    variant='outlined'
                    label='Parcelas'
                    name='installments'
                    size='small'
                    fullWidth
                    component={Select}
                    options={installments}
                    className={classes.paymentMethodOptions}
                    disabled={
                      values?.payment_method == 1 ||
                      values?.payment_method == 4 ||
                      values?.payment_method == 6 ||
                      values?.payment_method == 10 ||
                      values?.payment_method == 11 ||
                      values?.payment_method == 13
                        ? true
                        : false
                    }
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    name='total_price_card'
                    label='Total das parcelas'
                    size='small'
                    mask='money'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    name='installment_price'
                    label='Valor por parcela'
                    size='small'
                    mask='money'
                    component={Text}
                    disabled
                  />
                </Grid>
              </Grid>

              <Grid className={classes.paymentMethod} container spacing={2} />
              <Typography variant='h6' gutterBottom>
                Método de Pagamento 2
              </Typography>

              <Grid className={classes.sellPerson} container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Field
                    variant='outlined'
                    label='Pagamento'
                    name='payment_method2'
                    size='small'
                    fullWidth
                    component={Select}
                    options={paymentStatusesOptions}
                    className={classes.paymentMethodOptions}
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Field
                    variant='outlined'
                    label='Parcelas'
                    name='installments2'
                    size='small'
                    fullWidth
                    component={Select}
                    options={installments}
                    className={classes.paymentMethodOptions}
                    disabled={
                      values?.payment_method2 == 1 ||
                      values?.payment_method2 == 4 ||
                      values?.payment_method2 == 6 ||
                      values?.payment_method2 == 10 ||
                      values?.payment_method2 == 11 ||
                      values?.payment_method2 == 13
                        ? true
                        : false
                    }
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    name='total_price_card2'
                    label='Total das parcelas'
                    size='small'
                    mask='money'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    name='installment_price2'
                    label='Valor por parcela'
                    size='small'
                    mask='money'
                    component={Text}
                    disabled
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <></>
          )}

          {values.payment_type == 3 ? (
            <>
              <Grid className={classes.paymentMethod} container spacing={2} />
              <Typography variant='h6' gutterBottom>
                Método de Pagamento 1
              </Typography>

              <Grid className={classes.paymentMethod} container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Field
                    variant='outlined'
                    label='Pagamento'
                    name='payment_method'
                    size='small'
                    fullWidth
                    component={Select}
                    options={paymentStatusesOptions}
                    className={classes.paymentMethodOptions}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Field
                    variant='outlined'
                    label='Parcelas'
                    name='installments'
                    size='small'
                    fullWidth
                    component={Select}
                    options={installments}
                    className={classes.paymentMethodOptions}
                    disabled={
                      values?.payment_method == 1 ||
                      values?.payment_method == 4 ||
                      values?.payment_method == 6 ||
                      values?.payment_method == 10 ||
                      values?.payment_method == 11 ||
                      values?.payment_method == 13
                        ? true
                        : false
                    }
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    name='total_price_card'
                    label='Total das parcelas'
                    size='small'
                    mask='money'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    name='installment_price'
                    label='Valor por parcela'
                    size='small'
                    mask='money'
                    component={Text}
                    disabled
                  />
                </Grid>
              </Grid>

              <Grid className={classes.paymentMethod} container spacing={2} />
              <Typography variant='h6' gutterBottom>
                Método de Pagamento 2
              </Typography>

              <Grid className={classes.sellPerson} container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Field
                    variant='outlined'
                    label='Pagamento'
                    name='payment_method2'
                    size='small'
                    fullWidth
                    component={Select}
                    options={paymentStatusesOptions}
                    className={classes.paymentMethodOptions}
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Field
                    variant='outlined'
                    label='Parcelas'
                    name='installments2'
                    size='small'
                    fullWidth
                    component={Select}
                    options={installments}
                    className={classes.paymentMethodOptions}
                    disabled={
                      values?.payment_method2 == 1 ||
                      values?.payment_method2 == 4 ||
                      values?.payment_method2 == 6 ||
                      values?.payment_method2 == 10 ||
                      values?.payment_method2 == 11 ||
                      values?.payment_method2 == 13
                        ? true
                        : false
                    }
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    name='total_price_card2'
                    label='Total das parcelas'
                    size='small'
                    mask='money'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    name='installment_price2'
                    label='Valor por parcela'
                    size='small'
                    mask='money'
                    component={Text}
                    disabled
                  />
                </Grid>
              </Grid>

              <Grid className={classes.paymentMethod} container spacing={2} />
              <Typography variant='h6' gutterBottom>
                Método de Pagamento 3
              </Typography>

              <Grid className={classes.sellPerson} container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Field
                    variant='outlined'
                    label='Pagamento'
                    name='payment_method3'
                    size='small'
                    fullWidth
                    component={Select}
                    options={paymentStatusesOptions}
                    className={classes.paymentMethodOptions}
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Field
                    variant='outlined'
                    label='Parcelas'
                    name='installments3'
                    size='small'
                    fullWidth
                    component={Select}
                    options={installments}
                    className={classes.paymentMethodOptions}
                    disabled={
                      values?.payment_method3 == 1 ||
                      values?.payment_method3 == 4 ||
                      values?.payment_method3 == 6 ||
                      values?.payment_method3 == 10 ||
                      values?.payment_method3 == 11 ||
                      values?.payment_method3 == 13
                        ? true
                        : false
                    }
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    name='total_price_card3'
                    label='Total das parcelas'
                    size='small'
                    mask='money'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    name='installment_price3'
                    label='Valor por parcela'
                    size='small'
                    mask='money'
                    component={Text}
                    disabled
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <></>
          )}

          {values.payment_method == 11 ||
          values.payment_method2 == 11 ||
          values.payment_method3 == 11 ? (
            <>
              <Typography className={classes.toCloseMargin} variant='h6' gutterBottom>
                Fluxo de Dinheiro
              </Typography>
              <Grid className={classes.toCloseMargin} container spacing={1}>
                <Grid item xs={6} lg={6}>
                  <Field
                    variant='outlined'
                    name='money_received'
                    label='Valor de Entrada'
                    size='small'
                    mask='money'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={6} lg={6}>
                  <Field
                    variant='outlined'
                    name='money_change'
                    label='Troco'
                    size='small'
                    mask='money'
                    component={Text}
                    disabled
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <></>
          )}

          <Grid className={classes.alignToTheEnd} container spacing={0}>
            <Grid item xs={6} lg={6}>
              <Field
                variant='outlined'
                name='total'
                label='TOTAL'
                size='small'
                mask='money'
                component={Text}
                disabled
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
