import React, { useCallback, useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { Text, Button, Table } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { PageTitle } from 'pages/Layout/PageTitle';
import { stockService } from 'services';
import { useContextSelector } from 'use-context-selector';

import { DialogScore } from './Dialogs/DialogScore';
import { DialogUnlockAllocation } from './Dialogs/DialogUnlockAllocation';

const columns = [{ label: 'Alocação Bloqueada' }, { label: 'Ações' }, { label: '' }];

export const InventoryLock = () => {
  const user = useContextSelector(AuthContext, (context) => context.user);

  const [openDialogUnlockAllocation, setOpenDialogUnlockAllocation] = useState(false);
  const [openDialogScore, setOpenDialogScore] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [idCadastro, setIdCadastro] = useState();
  const [searchTerm, setSearchTerm] = useState(''); // Estado para o termo de busca

  const allocationRef = useRef(null); // Referência para o campo allocation

  const getDados = useCallback(async () => {
    const { data } = await stockService.findBlockAllocations();
    setDataTable(data.data);
  }, []);

  useEffect(() => {
    getDados();
  }, [getDados]);

  const handleBlockAllocation = async (values, { resetForm }) => {
    if (values.allocation === '') {
      toast.error('Informe a alocação a ser desbloqueada');
      return;
    }
    const { data } = await stockService.blockAllocation({
      allocation: values.allocation,
      user: user.id,
    });

    if (data.status === 500) {
      toast.error(data.msg);
      resetForm(); // Limpa o formulário
      if (allocationRef.current) {
        allocationRef.current.focus(); // Coloca o foco no campo allocation
      }
    } else {
      toast.success(data.msg);
      getDados();
      resetForm(); // Limpa o formulário
      if (allocationRef.current) {
        allocationRef.current.focus(); // Coloca o foco no campo allocation
      }
    }
  };

  const handleOpenDialogUnlockAllocation = (props) => {
    setOpenDialogUnlockAllocation(true);
    setIdCadastro(props);
  };

  const handleCloseDialogUnlockAllocation = () => {
    setOpenDialogUnlockAllocation(false);
    getDados();
  };

  const handleOpenDialogScore = (props) => {
    setOpenDialogScore(true);
    setIdCadastro(props);
  };

  const handleCloseDialogScore = () => {
    setOpenDialogScore(false);
  };

  // Função para filtrar os dados com base no termo de busca
  const filteredData = dataTable?.filter((item) =>
    item.alocacao.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <>
      <DialogUnlockAllocation
        openDialogUnlockAllocation={openDialogUnlockAllocation}
        handleCloseDialogUnlockAllocation={handleCloseDialogUnlockAllocation}
        idCadastro={idCadastro}
      />
      <DialogScore
        openDialogScore={openDialogScore}
        handleCloseDialogScore={handleCloseDialogScore}
        idCadastro={idCadastro}
      />
      <PageTitle>Sistema de trava de inventário</PageTitle>

      <Formik initialValues={{ allocation: '' }} onSubmit={handleBlockAllocation}>
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Field
                  size='small'
                  fullWidth
                  name='allocation'
                  label='Alocação'
                  variant='outlined'
                  component={Text}
                  inputRef={allocationRef} // Adiciona a referência
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Button size='small' type='submit' fullWidth>
                  Bloquear
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={12} lg={12}>
                <Field
                  size='small'
                  fullWidth
                  name='search'
                  label='Pesquisar'
                  variant='outlined'
                  component={Text}
                  onChange={(e) => setSearchTerm(e.value)} // Atualiza o termo de busca
                />
              </Grid>
              <Grid item xs={12} sm={6} md={12} lg={12}>
                <Table name='Table' headers={columns} size='small' disableNumeration>
                  {filteredData?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.alocacao}</TableCell>
                      <TableCell>
                        <Button size='small' onClick={() => handleOpenDialogScore(item)}>
                          Realizar Contagem
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button size='small' onClick={() => handleOpenDialogUnlockAllocation(item)}>
                          Desbloquear
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
