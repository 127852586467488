import React from 'react';
import { NavLink } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { CreateAccountForm } from './Form';
import { useStyles } from './styles';

export const CreateAccount = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.container} elevation={4}>
        <NavLink className={classes.alreadyRegistered} to='/'>
          <ArrowBackIcon className={classes.backIcon} />
          Já sou cadastrado
        </NavLink>

        <h1 className={classes.title}>Criar conta</h1>

        <CreateAccountForm />
      </Paper>
    </div>
  );
};
