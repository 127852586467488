import React, { useState, useRef } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, Dialog, Text } from 'components';
import { Formik, Field, Form } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { PageTitle } from 'pages/Layout/PageTitle';
import { screeningService } from 'services/screening';
import { useContextSelector } from 'use-context-selector';

import { validationSchema } from './validation';

export const Clear = () => {
  const [dialogMSG, setDialogMSG] = useState('');
  const [openDialogMSG, setOpenDialogMSG] = useState(false);

  const user = useContextSelector(AuthContext, (context) => context.user);

  const orderServRef = useRef(null);
  const buttonFocus = useRef(null);

  const focusInput = () => {
    setTimeout(() => {
      if (orderServRef.current) {
        orderServRef.current.focus();
      }
    }, 0);
  };

  const handleOpenDialogMSG = () => {
    setOpenDialogMSG(true);
    setTimeout(() => {
      if (buttonFocus.current) {
        buttonFocus.current.focus();
      }
    }, 0);
  };

  const handleCloseDialogMSG = () => {
    setOpenDialogMSG(false);
    focusInput();
  };

  const consulClear = async (values, { resetForm }) => {
    try {
      const { data } = await screeningService.getDataTable(values);
      setDialogMSG(data.dialogMSG);
      handleOpenDialogMSG();
      toast.success('Enviado com Sucesso');
      resetForm(); // Reseta o formulário após a submissão bem-sucedida
    } catch (error) {
      console.error(error.response.data.message);
      toast.error(error.response.data.message);
      resetForm();
      focusInput();
    }
  };

  return (
    <>
      <PageTitle>Limpeza</PageTitle>
      <Formik
        initialValues={{ orderServ: '', userGaia: user }}
        onSubmit={consulClear}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={2}>
                <Field
                  size='small'
                  name='orderServ'
                  label='Ordem de Serviço'
                  variant='outlined'
                  component={Text}
                  inputRef={orderServRef}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={2}>
                <Button type='submit' fullWidth disabled={!props.dirty || !props.isValid}>
                  Limpeza Ok
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      <Dialog open={openDialogMSG}>
        <Dialog.Title onClose={handleCloseDialogMSG}>Mensagem</Dialog.Title>
        <Formik initialValues={{ orderServ: '', userGaia: user }} onSubmit={handleCloseDialogMSG}>
          {() => (
            <Form>
              <Grid
                style={{
                  textAlign: 'center',
                  marginBottom: '20px',
                  padding: '10px',
                  borderRadius: '4px',
                }}
              >
                <PageTitle> {dialogMSG}</PageTitle>
              </Grid>
              <Button type='submit' fullWidth innerRef={buttonFocus}>
                Fechar
              </Button>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};
