import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog } from 'components';
import { AuthContext } from 'hooks/useAuth';
import { pcpService } from 'services';
import { useContextSelector } from 'use-context-selector';

export const DialogDeleteAllOs = ({
  openDialogDeleteAllOs,
  handleCloseDialogDeleteAllOs,
  numTecData,
  setNumTecData,
}) => {
  if (!openDialogDeleteAllOs) return null;
  const user = useContextSelector(AuthContext, (context) => context.user);

  const [isLoading, setIsLoading] = useState(false);

  const handleUnsignAllOs = async () => {
    setIsLoading(true);
    try {
      const { data } = await pcpService.unsignAllOs({
        numTec: numTecData ? numTecData : '',
        user: user.id,
      });
      if (data.status === 200) {
        toast.success(data.msg);
        handleCloseDialogDeleteAllOs();
        setIsLoading(false);
      } else {
        toast.error(data.msg);
        setIsLoading(false);
      }
    } catch {
      toast.error('Erro a desvincular as OS`s');
    }
  };
  return (
    <>
      <Dialog open={openDialogDeleteAllOs}>
        <Dialog.Title>Alerta</Dialog.Title>
        <Dialog.Content>
          <Typography
            align='center'
            style={{ paddingTop: '0.5rem', fontSize: '20px', fontWeight: 'bold' }}
          >
            Realmente deseja desatribuir todas OSs do técnico
          </Typography>
        </Dialog.Content>
        <Dialog.Actions>
          <Grid container spacing={2}>
            <Grid item xs={3}></Grid>
            <Grid item xs={6} lg={3}>
              <Button
                fullWidth
                color='secondary'
                onClick={() => handleCloseDialogDeleteAllOs(setNumTecData(numTecData))}
                loading={isLoading}
              >
                Fechar
              </Button>
            </Grid>
            <Grid item xs={6} lg={3}>
              <Button fullWidth onClick={() => handleUnsignAllOs()} loading={isLoading}>
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Dialog.Actions>
      </Dialog>
    </>
  );
};
