import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: 'fit-content',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',

    '& > svg': {
      transition: 'transform .4s',
    },

    '&:hover': {
      '& > svg': {
        transform: 'translateX(-3px)',
      },
    },
  },
  arrowIcon: {
    marginRight: theme.spacing(1),
  },
  backText: {
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 500,
  },
}));
