import React from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import Typography from '@mui/material/Typography';
import { Dialog, Button } from 'components';
import { AuthContext } from 'hooks/useAuth';
import { pcpService } from 'services';
import { useContextSelector } from 'use-context-selector';

export const DialogConfirmNextTecNoRequest = ({
  openDialogConfirmNextTecNoRequest,
  handleOpenDialogConfirmNextTecNoRequest,
  numOsCmp,
}) => {
  if (!openDialogConfirmNextTecNoRequest) return null;

  const user = useContextSelector(AuthContext, (context) => context.user);

  const [isLoading, setIsLoading] = useState(false);

  const handleSendToCmp = async () => {
    setIsLoading(true);
    try {
      const { data } = await pcpService.sendOsToCmp({
        numOs: numOsCmp.numOs,
        user: user.id,
        idComponent: numOsCmp.idComponente,
      });

      if (data.status === 200) {
        toast.success(data.msg);
        setIsLoading(false);
      } else {
        toast.error(data.msg);
        setIsLoading(false);
      }
    } catch {
      toast.error('Erro ao enviar para CMP');
    }
  };
  return (
    <>
      <Dialog maxWidth={'md'} open={openDialogConfirmNextTecNoRequest}>
        <Dialog.Title onClose={handleOpenDialogConfirmNextTecNoRequest}>Alerta</Dialog.Title>
        <Dialog.Content>
          <Typography variant='h1' style={{ fontSize: '20px', fontWeight: 'bold' }}>
            Devido aos defeitos cosméticos vinculados à esta Os estarem vinculados à subcategorias
            de peças que não possuem Saldo em Estoque, a OS {numOsCmp.numOs} está sendo enviada para
            CMP.
          </Typography>
        </Dialog.Content>
        <Dialog.Actions>
          <Grid container spacing={2}>
            <Grid item xs={3}></Grid>
            <Grid item xs={6} lg={3}>
              <Button
                fullWidth
                color='secondary'
                onClick={handleOpenDialogConfirmNextTecNoRequest}
                loading={isLoading}
              >
                Fechar
              </Button>
            </Grid>
            <Grid item xs={6} lg={3}>
              <Button fullWidth onClick={() => handleSendToCmp()} loading={isLoading}>
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Dialog.Actions>
      </Dialog>
    </>
  );
};
