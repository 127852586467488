import React from 'react';

import DialogContent from '@material-ui/core/DialogContent';

import { useStyles } from './styles';

export const Content = ({ children, classes }) => {
  const styles = useStyles();

  return (
    <DialogContent className={styles.root} dividers classes={classes}>
      {children}
    </DialogContent>
  );
};
