import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { Button, DatePicker, Spacer, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providerService } from 'services';
import XLSX from 'xlsx';

import { TabsCong } from './OSData/index';

function formatDate(date) {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export const FreezingReport = () => {
  const today = new Date();
  const now = formatDate(new Date());
  const [inputData, setInputData] = useState(new Date(new Date().setDate(today.getDate() - 7)));
  const [outputData, setOutputData] = useState(new Date());
  const [baseFormData, setBaseFormData] = useState({});
  const [detailedFormData, setDetailedFormData] = useState([]);
  const [ingFatFormData, setIngFatFormData] = useState({});
  const [isFirstButtonLoading, setFirstButtonLoading] = useState(false);
  const [isSecondButtonDisabled, setSecondButtonDisabled] = useState(false);

  const handleDownloadGeneralStockRelatory = async (
    values,
    { setSubmitting, resetForm, isSubmitting },
  ) => {
    setSubmitting(true);
    setFirstButtonLoading(false);
    setSecondButtonDisabled(true);
    toast.warning('A geração do relatório leva entre 12 e 21 minutos...', {
      position: toast.POSITION.TOP_RIGHT,
    });

    try {
      const freezingShortData = await providerService.getFreezingShortReport(inputData, outputData);

      if (freezingShortData && freezingShortData.length > 1) {
        const worksheet = XLSX.utils.json_to_sheet(freezingShortData[0]);
        const worksheet3 = XLSX.utils.json_to_sheet(freezingShortData[1]);
        console.log('Retorno', freezingShortData);
        toast.info('Estamos quase acabando...', {
          position: toast.POSITION.TOP_RIGHT,
        });

        const freezingDetailedData = await providerService.getFreezingDetailedReport(
          inputData,
          outputData,
        );

        if (freezingDetailedData && freezingDetailedData.length > 0) {
          const worksheet2 = XLSX.utils.json_to_sheet(freezingDetailedData);

          const workbook = {
            Sheets: {
              BASE: worksheet,
              DETALHADO: worksheet2,
              ING_FAT: worksheet3,
            },
            SheetNames: ['BASE', 'DETALHADO', 'ING_FAT'],
          };

          await XLSX.writeFile(workbook, `RelatorioCongelamento${now}.xlsx`, {
            bookType: 'xlsx',
            type: 'array',
            compression: true,
          });
          toast.success('Relatório gerado com sucesso');
        } else {
          toast.error('Os dados detalhados de congelamento estão vazios.');
        }
      } else {
        toast.error('Os dados resumidos de congelamento estão vazios ou incompletos.');
      }
    } catch (error) {
      toast.error('Erro ao gerar o relatório de congelamento');
      console.log('error', error);
    } finally {
      setSubmitting(false);
      setFirstButtonLoading(false);
      setSecondButtonDisabled(false);
    }
  };

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      //console.log(values);
      setFirstButtonLoading(true);
      setSecondButtonDisabled(true);
      const result = await providerService.getFreezingDetailed(values);

      if (Object.keys(result.base).length > 0) {
        /// aqui eu desabilito o botão exportar
        toast.success('Consulta realizada');
        setIngFatFormData(result.ingFat);
        setDetailedFormData(result.detailed);
        setBaseFormData(result.base);
      }
      //// aqui eu habilito o botão
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setSubmitting(false);
      setFirstButtonLoading(false);
      setSecondButtonDisabled(false);
    }
  };

  return (
    <>
      <PageTitle>Relatório de Congelamento</PageTitle>

      <Formik
        initialValues={{
          input_data: inputData,
          output_data: outputData,
        }}
        onSubmit={handleDownloadGeneralStockRelatory}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Field
                  size='small'
                  name='input_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data inicial'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Field
                  size='small'
                  name='output_data'
                  variant='inline'
                  inputVariant='outlined'
                  label='Data final'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Button
                  fullWidth
                  type='submit'
                  disabled={!props.dirty || !props.isValid || isFirstButtonLoading}
                  loading={props.isSubmitting}
                >
                  exportar relatório
                </Button>
              </Grid>
            </Grid>
            {useEffect(() => {
              setInputData(props.values.input_data);
              setOutputData(props.values.output_data);
            }, [props])}
          </Form>
        )}
      </Formik>
      <Box mt={3}>
        <Spacer size={10} />
        <PageTitle>Informações da Ordem de Serviço:</PageTitle>
        <Formik initialValues={{ serviceorder_data: '' }} onSubmit={onSubmit}>
          {(props) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={2}>
                  <Field
                    size='small'
                    name='serviceorder_data'
                    label='Ordem de Serviço'
                    variant='outlined'
                    component={Text}
                    max={5}
                    inputProps={{ maxLength: 10 }}

                    // disabled={isLoading == 1 ? true : false}
                    // onKeyPress={(event) => handleKeyPress(event, props)}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                  <Button
                    type='submit'
                    fullWidth
                    disabled={!props.dirty || !props.isValid || isSecondButtonDisabled}
                    loading={props.isSubmitting}
                  >
                    Pesquisar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
      <TabsCong
        baseFormData={baseFormData}
        detailedFormData={detailedFormData}
        ingFatFormData={ingFatFormData}
      />

      {/* <SearchFreezingServiceOrder /> */}
    </>
  );
};
