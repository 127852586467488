import React from 'react';
import { useRouteMatch } from 'react-router';

import { DevolReport } from 'pages/Reports/DevolReport';
import { ExpeditionReport } from 'pages/Reports/ExpeditionReport';
import { ExpeditionServiceOrderReport } from 'pages/Reports/ExpeditionServiceOrderReport';
import { InOutExpedition } from 'pages/Reports/InOutExpeditionReport';
import { InOutNew } from 'pages/Reports/InOutNewReport';
import { InventaryExpeditionReport } from 'pages/Reports/InventaryExpeditionReport';
import { PackagingReport } from 'pages/Reports/PackagingReport';
import { SignageReport } from 'pages/Reports/SignageReportExpedition';

import { Route } from '../Route';

export const ReportsShippingRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${path}/devol-report`} component={DevolReport} />
      <Route
        isPrivate
        path={`${path}/report-os-expedition`}
        component={ExpeditionServiceOrderReport}
      />
      <Route isPrivate path={`${path}/expedition-report`} component={ExpeditionReport} />
      <Route isPrivate path={`${path}/in-out-expedition`} component={InOutExpedition} />
      <Route isPrivate path={`${path}/in-out-new`} component={InOutNew} />
      <Route
        isPrivate
        path={`${path}/inventary-expedition`}
        component={InventaryExpeditionReport}
      />
      <Route isPrivate path={`${path}/packaging-report`} component={PackagingReport} />
      <Route isPrivate path={`${path}/signage-report`} component={SignageReport} />
    </>
  );
};
