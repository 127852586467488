import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import clsx from 'clsx';
import { Button } from 'components';
import { getAvatarPath } from 'helpers/getImagePath';
import { AuthContext } from 'hooks/useAuth';
import { useQuery } from 'hooks/useQuery';
import { api } from 'services/api';
import { useContextSelector } from 'use-context-selector';

import { useStyles } from './styles';

export const UserAvatar = () => {
  const classes = useStyles();

  const [avatars, , isLoadingAvatars] = useQuery(() => api.get('/users/avatars'), []);

  const userAvatar = useContextSelector(AuthContext, (context) => context.user.avatar);
  const userAvatarId = useContextSelector(AuthContext, (context) => context.user.avatar.id);
  const setAuthData = useContextSelector(AuthContext, (context) => context.setAuthData);

  const [currentAvatar, setCurrentAvatar] = useState({});
  const [initialAvatarId, setInitialAvatarId] = useState(null);

  useEffect(() => {
    setCurrentAvatar(userAvatar);
    setInitialAvatarId(userAvatarId);
  }, [userAvatar, userAvatarId]);

  const handleAvatarChange = (avatar) => setCurrentAvatar(avatar);

  const handleAvatarUpdate = async (avatar) => {
    try {
      const { data } = await api.put('/users/avatars', { avatar_id: avatar.id });
      setInitialAvatarId(avatar.id);
      setAuthData((prevState) => {
        const user = {
          ...prevState.user,
          avatar: data.avatar,
          avatar_id: data.avatar_id,
        };

        localStorage.setItem('@Gaia:user', JSON.stringify(user));

        return {
          ...prevState,
          user,
        };
      });
      toast.success('Avatar atualizado com sucesso!', {
        position: 'bottom-right',
      });
    } catch (err) {
      console.log(err);
      toast.error('Erro ao atualizar avatar.');
    }
  };

  if (isLoadingAvatars) return null;

  const disableUpdateAvatarBtn = currentAvatar.id === initialAvatarId;

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant='h5'>
        Mude seu avatar
      </Typography>
      <div>
        <img
          className={classes.currentAvatar}
          src={getAvatarPath(currentAvatar?.filename)}
          alt='Avatar'
        />
      </div>
      <Grid container spacing={1}>
        {avatars.map((avatar) => (
          <Grid key={avatar.id} item xs={3} sm={2} md={3} lg={2}>
            <button
              className={classes.button}
              onClick={() => handleAvatarChange(avatar)}
              type='button'
            >
              <img
                className={clsx(
                  classes.smallAvatar,
                  avatar.id === currentAvatar.id && classes.avatarSelected,
                )}
                src={getAvatarPath(avatar?.filename)}
                alt='Avatar'
              />
            </button>
          </Grid>
        ))}
      </Grid>
      <Grid container justify='flex-end'>
        <Button
          fullwidth
          onClick={() => handleAvatarUpdate(currentAvatar)}
          disabled={disableUpdateAvatarBtn}
        >
          Salvar
        </Button>
      </Grid>
    </div>
  );
};
