import React from 'react';

import Typography from '@material-ui/core/Typography';

import { Button, Dialog, Spacer } from 'components';

export const TheftDialog = ({ open, onClose }) => {
  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth='sm'>
        <Dialog.Title onClose={onClose}>Ver Detalhes</Dialog.Title>
        <Dialog.Content>
          <Typography variant='h6'>Roubo e Furto Mediante Arrombamento</Typography>
          <Spacer size={24} />
          <Typography variant='subtitle2'>
            Roubo:
            <Typography variant='body2'>
              Infelizmente você foi abordado, o bandido ameaçou com revólver e levou seu smartphone
              ou a residência foi arrombada e levaram o smartphone. Fique tranquilo, pois nesses
              exemplos seu equipamento tem amparo.
            </Typography>
            <Spacer size={12} />
          </Typography>
          <Typography variant='subtitle2'>
            Garantia Internacional:
            <Typography variant='body2'>
              Viaje com tranquilidade, pois esse benefício estende as coberturas do plano contratado
              para qualquer lugar do mundo.
            </Typography>
            <Spacer size={12} />
          </Typography>
        </Dialog.Content>
        <Dialog.Actions>
          <Button onClick={onClose} variant='text' color='secondary'>
            Fechar
          </Button>
        </Dialog.Actions>
      </Dialog>
    </>
  );
};
