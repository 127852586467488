import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button } from 'components';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providerService } from 'services/providers';
import XLSX from 'xlsx';

export const TechnicalStock = () => {
  const [isLoading, setIsLoading] = useState(false);
  const handleDownloadTechnicalStock = async () => {
    setIsLoading(true);
    toast.info('Aguardando relatório');
    try {
      const { data } = await providerService.getTechnicalstock();
      const now = new Date();
      const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(data));
      const workbook = (XLSX.WorkBook = {
        Sheets: {
          EstoqueTecnico: worksheet,
        },
        SheetNames: ['EstoqueTecnico'],
      });
      XLSX.writeFile(workbook, `RelatorioEstoqueTecnico${now}.xlsx`);
      toast.success('Relatório Gerado com sucesso');
    } catch (error) {
      console.log(error);
      toast.error('Falha ao gerar relatório');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Relatório de Estoque técnico </PageTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <Button loading={isLoading} fullWidth onClick={handleDownloadTechnicalStock}>
            exportar relatório
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
