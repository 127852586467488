import React from 'react';
import { useRouteMatch } from 'react-router';

import { CompactReport } from 'pages/Private/Launch';
import { FreezingSystemSlas } from 'pages/Private/Launch/FreezingSystemSlas';
import { LimiteRegistre } from 'pages/Private/Launch/limitRegistre';
import { OsBlock } from 'pages/Private/Launch/osBlock';

import { Route } from '../Route';

export const LaunchRoutes = () => {
  const { url } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${url}/compact-report`} component={CompactReport} />
      <Route isPrivate path={`${url}/freezing-system`} component={FreezingSystemSlas} />
      <Route isPrivate path={`${url}/limit-registre`} component={LimiteRegistre} />
      <Route isPrivate path={`${url}/os-block`} component={OsBlock} />
    </>
  );
};
