import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog } from 'components';
import { Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { screeningService } from 'services/screening';
import { useContextSelector } from 'use-context-selector';

export const DialogReprintScreening = ({
  infoFuctionOs,
  qrCodeValue,
  openDialogEtiqueta,
  handleCloseDialogReprintEtiqueta,
  handleCloseDialog,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const user = useContextSelector(AuthContext, (context) => context.user);

  const ScreeningFinishedCollect = async () => {
    setIsLoading(true);

    try {
      const { data } = await screeningService.reprintEtiquetaCollect({
        user: user,
        qrcode: qrCodeValue,
        info: infoFuctionOs,
      });
      if (data.status === 200) {
        toast.success('Coleta Realizada com sucesso');

        await imprimeEtiqueta();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
      handleCloseDialogReprintEtiqueta();
      handleCloseDialog();
      window.location.reload();
    }
  };

  const imprimeEtiqueta = async () => {
    const { data } = await screeningService.getEtiqueta({ serial: infoFuctionOs[0]?.serial });

    if (data) {
      const file = new Blob([data], { type: 'application/pdf' });

      const header = `
      <style>
        @page {
          size: auto; /* Define o tamanho da página como automático para usar o tamanho real */
          margin: 0; /* Remove as margens */
          orientation: portrait; /* Define a orientação da página como retrato */
        }
        body {
          margin: 0; /* Remove as margens do corpo */
        }
      </style>
    `;
      const pdfWithHeader = new Blob([header, file], { type: 'application/pdf' });

      saveAs(pdfWithHeader, 'etiqueta.pdf');

      // Abrir automaticamente a página de impressão
      const pdfUrl = URL.createObjectURL(pdfWithHeader);
      const printWindow = window.open(pdfUrl, '_blank');
      if (printWindow) {
        printWindow.onload = () => {
          printWindow.print();
        };
      } else {
        console.error('Não foi possível abrir a janela de impressão.');
      }
      toast.success('Etiqueta gerada com sucesso');
    }
  };

  return (
    <>
      <Dialog open={openDialogEtiqueta}>
        <Dialog.Title onClose={handleCloseDialogReprintEtiqueta}>Confirmação</Dialog.Title>
        <Dialog.Content>
          <Typography align='center' variant='h6' style={{ paddingBottom: '2rem' }}>
            Você selecionou a opção de enviar a OS para a reimpressão de Etiqueta, a OS será
            transferiada para a Recuperação de Dados, confirma?
          </Typography>
          <Formik>
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Button fullWidth color='secondary' onClick={handleCloseDialogReprintEtiqueta}>
                    Não
                  </Button>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Button
                    fullWidth
                    color='primary'
                    loading={isLoading}
                    onClick={ScreeningFinishedCollect}
                  >
                    Sim
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
