import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';

import { Button } from 'components';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { salesService } from 'services/sales';
import XLSX from 'xlsx';

export const MinuteReport = () => {
  const [componentData, setComponents] = useState([]);

  const [components, , loadingPartsRequisitions, refetchPartsDataDate] = useQuery(
    () => salesService.listMinuteReport(),
    [],
  );

  useEffect(() => {
    setComponents(components);
  }, [components]);

  const handleDownloadGeneralStockRelatory = async () => {
    const now = new Date();
    const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(componentData));
    const workbook = (XLSX.WorkBook = {
      Sheets: {
        minuta: worksheet,
      },
      SheetNames: ['minuta'],
    });
    XLSX.writeFile(workbook, `RelatorioMinuta${now}.xlsx`);

    refetchPartsDataDate();
  };

  return (
    <>
      <PageTitle>Relatório de Minuta</PageTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <Button fullWidth onClick={handleDownloadGeneralStockRelatory}>
            exportar relatório
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
