/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, DatePicker, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providerService } from 'services/providers';

import { downloadcsv } from '../../../helpers/downloadcsv';

export const LogMovByLocationReport = () => {
  const [isLoading, setIsLoading] = useState(0);

  const handleOnSubmit = async (values) => {
    console.log(values);
    toast.info('Aguarde, relatório sendo gerado.');
    setIsLoading(1);

    const now = new Date().toLocaleDateString();

    try {
      const { data } = await providerService.getLogMovByLocationReport(values);
      if (data.length === 0 || null) {
        toast.error('Não há dados para gerar o relatório');
        return;
      }

      await downloadcsv(`ingenico_LOG_STK_local`, data);
      toast.success('Relatório gerado com sucesso');
    } catch (error) {
      console.log(error.response.data.message);
      toast.error(
        error.response.data.message ||
        'Erro a trazer dados para a relatório Log movimentação por local.',
      );
    } finally {
      setIsLoading(0);
    }
  };
  return (
    <>
      <PageTitle>Log Movimentação Por Local</PageTitle>
      <Formik
        initialValues={{
          local: '',
          input_data: new Date(),
          output_data: new Date(),
        }}
        onSubmit={handleOnSubmit}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='local'
                  label='Local(SM/S/L/SL):'
                  type='text'
                  variant='outlined'
                  value={props.values.local}
                  component={Text}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='input_data'
                  variant='outlined'
                  fullWidth
                  inputVariant='outlined'
                  label='Data Inicial'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='output_data'
                  variant='outlined'
                  fullWidth
                  inputVariant='outlined'
                  label='Data Final'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Button fullWidth type='submit'>
                  Extrair Relatório
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
