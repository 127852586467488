import React from 'react';
import { useRouteMatch } from 'react-router';

import { ConsultQuoteTransactions } from 'pages/Private/ToQuote/ConsultQuoteTransactions';
import { QuoteSmartphone } from 'pages/Private/ToQuote/QuoteSmartphone';

import { Route } from '../Route';

export const ToQuoteRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${path}/quote-smartphone`} component={QuoteSmartphone} />
      <Route
        isPrivate
        path={`${path}/quote-consult-transactions`}
        component={ConsultQuoteTransactions}
      />
    </>
  );
};
