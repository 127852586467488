import * as React from 'react';

export function getReactChildrenComponent(children, componentType) {
  return React.Children.map(children, (child) => {
    return isReactComponent(child, componentType) ? child : null;
  }).filter((result) => !!result);
}

export function getReactChildrenProps(children, componentType) {
  return React.Children.map(children, (child) => {
    if (!isReactComponent(child, componentType)) return null;
    return { ...child.props };
  }).filter((result) => !!result);
}

export function getReactFirstChildrenProps(children, componentType) {
  const result = getReactChildrenProps(children, componentType);
  return result && result[0];
}

export function isReactComponent(child, componentType) {
  if (componentType) {
    return child && React.isValidElement(child) && child?.type === componentType;
  }

  return child && React.isValidElement(child);
}
