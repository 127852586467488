import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';

import { useLayoutContext } from '../context';
import Desktop from './Desktop';
import Menu from './Menu';
import Mobile from './Mobile';
import { useStyles } from './styles';

export function Header() {
  const classes = useStyles();

  const { handleDrawerToggle } = useLayoutContext();

  return (
    <>
      <div className={classes.root}>
        <AppBar position='fixed' className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              edge='start'
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <div className={classes.right}>
              <Desktop />
              <Mobile />
            </div>
          </Toolbar>
        </AppBar>
      </div>
      <Menu />
    </>
  );
}
