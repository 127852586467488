import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Dialog, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { stockService } from 'services';
import { useContextSelector } from 'use-context-selector';
import * as XLSX from 'xlsx';
import * as Yup from 'yup';

const columns = [{ label: 'PartNumber' }, { label: 'Quantidade' }, { label: 'Cancelar' }];

const validationSchema = Yup.object().shape({
  input_bipagem: Yup.string().required('Campo de bipagem vazio!'),
});

export const DialogScore = ({ openDialogScore, handleCloseDialogScore, idCadastro }) => {
  const user = useContextSelector(AuthContext, (context) => context.user);

  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const inputBipagemRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = (values, { resetForm }) => {
    const [partNumber, quantidade] = values.input_bipagem.split(';');
    const newEntry = { partNumber, quantidade: parseInt(quantidade, 10) };
    setTableData([...tableData, newEntry]);
    resetForm();
    // Foco de volta para o campo input_bipagem após a inserção
    if (inputBipagemRef.current) {
      inputBipagemRef.current.focus();
    }
  };

  const handleDeleteRow = (index) => {
    const newTableData = tableData.filter((_, i) => i !== index);
    setTableData(newTableData);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const aggregateData = (data) => {
    const aggregated = data.reduce((acc, { partNumber, quantidade }) => {
      if (!acc[partNumber]) {
        acc[partNumber] = { partNumber, quantidade: 0, quantidadeCaixas: 0 };
      }
      acc[partNumber].quantidade += quantidade;
      acc[partNumber].quantidadeCaixas += 1;
      return acc;
    }, {});

    return Object.values(aggregated);
  };

  const handleDownloadReport = async () => {
    setIsLoading(true);
    try {
      toast.info('Aguarde um momento, Relatório está sendo gerado');

      const aggregatedData = aggregateData(tableData);

      const { data } = await stockService.checkedPartnumber({
        tableData: aggregatedData,
        alocacao: idCadastro.alocacao,
      });

      if (data.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = {
          Sheets: { Contagem: worksheet },
          SheetNames: ['Contagem'],
        };

        const now = new Date().toLocaleDateString('pt-BR').split('/').reverse().join('-');
        XLSX.writeFile(workbook, `Relatorio_Contagem_${now}.xlsx`);

        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'base64' });

        const { data: response } = await stockService.sendFileEmail({
          workbook: wbout,
          user: user.name,
        });

        toast.success('Contagem Realizada com sucesso!');
        setIsLoading(false);
        handleCloseDialogScore();
      } else {
        toast.error('Não há dados para gerar o relatório.');
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Erro ao gerar o relatório.', error);
      toast.error('Erro ao gerar o relatório.');
      setIsLoading(false);
    }
  };

  const currentPageData = tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  useEffect(() => {
    if (!openDialogScore) {
      setTableData([]);
      setPage(0);
      setRowsPerPage(10);
    }
  }, [openDialogScore]);

  const handleKeyDown = (event, handleSubmit) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <Dialog maxWidth={'md'} open={openDialogScore}>
      <Dialog.Title onClose={handleCloseDialogScore}>Contagem</Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{ input_bipagem: '' }}
          onSubmit={handleFormSubmit}
          validationSchema={validationSchema}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={6}>
                  <Field
                    size='small'
                    fullWidth
                    name='input_bipagem'
                    label='Bipagem Etiqueta'
                    variant='outlined'
                    component={Text}
                    onKeyDown={(event) => handleKeyDown(event, handleSubmit)}
                    inputRef={inputBipagemRef}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Button loading={isLoading} size='small' type='submit' fullWidth>
                    Inserir
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Button
                    loading={isLoading}
                    size='small'
                    fullWidth
                    color='secondary'
                    onClick={handleDownloadReport}
                  >
                    Finalizar Contagem
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Table name='Table' headers={columns} maxHeight disableNumeration>
                    {currentPageData.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.partNumber}</TableCell>
                        <TableCell>{row.quantidade}</TableCell>
                        <TableCell style={{ paddingLeft: '2rem' }}>
                          <DeleteIcon
                            style={{ cursor: 'pointer', color: '#F50057' }}
                            onClick={() => handleDeleteRow(index)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component='div'
                    count={tableData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
