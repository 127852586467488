import { api } from 'services/api';

export class PllService {
  async getAttendanceTypes() {
    return api.get('/pll/attendance-types');
  }

  async getModels() {
    return api.get('/pll/models');
  }

  async getServiceTypes() {
    return api.get('/pll/service-types');
  }

  async getColors() {
    return api.get('/pll/colors');
  }

  async createCheckin(data) {
    return api.post('/pll/checkins', data);
  }

  async listSubInsuranceCompanies() {
    return api.get('/pll/sub-insurances');
  }

  async createSubInsurance(data) {
    return api.post('/pll/create-sub-insurances', data);
  }

  async listResales() {
    return api.get('/pll/resales-pll');
  }
}

export const pllService = new PllService();
