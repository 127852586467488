import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  return {
    formContainer: {
      marginBottom: theme.spacing(2),
    },
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  };
});

export default useStyles;
