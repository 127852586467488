import React, { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { FormControl, TextField } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
import { getFieldError } from 'helpers/formik';

export const DateTimePicker = ({
  field,
  name,
  form,
  label,
  margin = 'none',
  disabled,
  value,
  loading,
  errorMessage: errorMessageProp,
  ...props
}) => {
  const onChangeHandler = useCallback(
    (date) => {
      form?.setFieldValue(field?.name, date);
    },
    [form, field],
  );

  const onBlurHandler = useCallback(() => {
    form?.setFieldTouched(field?.name, true);
  }, [form, field]);

  const errorMessage = errorMessageProp || getFieldError(field?.name, form);
  const hasError = !!errorMessage;

  // Se o value não for definido, use o valor do campo
  value = field?.value ?? value;

  // Extrair hora e minuto do valor da data
  const initialHour = value ? String(value.getHours()).padStart(2, '0') : '00';
  const initialMinute = value ? String(value.getMinutes()).padStart(2, '0') : '00';

  const handleHourChange = (event) => {
    const time = event.target.value;

    // Verifica se o formato está correto (HH:MM)
    const timePattern = /^([01]\d|2[0-3]):([0-5]\d)$/;
    if (timePattern.test(time)) {
      const combinedDate = new Date(value);
      const [hour, minute] = time.split(':');
      combinedDate.setHours(hour);
      combinedDate.setMinutes(minute);

      // Atualiza o valor do formulário
      form?.setFieldValue(field?.name, combinedDate);
    }
  };

  return (
    <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ marginRight: '16px', flex: '1' }}>
          <KeyboardDatePicker
            disableToolbar
            fullWidth
            format='dd/MM/yyyy'
            margin={margin}
            label={label}
            value={value}
            name={name}
            onChange={onChangeHandler}
            onBlur={onBlurHandler}
            disabled={form?.isSubmitting || disabled || loading}
            {...props}
            error={hasError}
            invalidDateMessage='Formato de data/hora inválido'
          />
        </div>
        <FormControl style={{ flex: '1', minWidth: '120px' }} margin={margin}>
          <TextField
            type='time'
            onChange={handleHourChange}
            label='Hora'
            value={`${initialHour}:${initialMinute}`} // Define o valor inicial da hora
            InputLabelProps={{ shrink: true }} // Faz o rótulo flutuar
            variant='outlined' // Aplica o estilo outlined
            size='small' // Mantenha o tamanho pequeno aqui
            fullWidth // Certifique-se de que o TextField ocupe toda a largura
            disabled={form?.isSubmitting || disabled || loading}
            error={hasError} // Aplica erro se existir
            helperText={hasError ? errorMessage : undefined} // Exibe a mensagem de erro
            style={{ height: '40px' }} // Ajuste a altura se necessário
          />
        </FormControl>
      </div>
    </MuiPickersUtilsProvider>
  );
};
