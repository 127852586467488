import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { Button, Dialog, Table } from 'components';
import { useQuery } from 'hooks/useQuery';
import { conferenceService } from 'services';

import { ShowBeeping } from './conferenceComponentsDiaalog/ShowBeeping';
import { ShowQrCode } from './conferenceComponentsDiaalog/ShowQrCode';
import { ConfirmationDialog } from './ConfirmationDialog';
import { ErrorDialog } from './ErrorDialog';

const columns = [
  { label: 'Modelo', align: 'center' },
  { label: 'qtdNF', align: 'center' },
  { label: 'Processados', align: 'center' },
];
//
export const ConferenceDialog = ({
  isModalOpenConference,
  handleCloseModal,
  selectedNote,
  user,
}) => {
  const [isModalOpenConfirmation, setIsModalOpenConfirmation] = useState(false);
  const [isResettingDataMain, setIsResettingDataMain] = useState(false);
  const [openSubErrorDialog, setOpenSubErrorDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState('');
  const [textMessage, setTextMessage] = useState('');
  const [typeButton, SetTypeButton] = useState('');
  const [form, setForm] = useState(false);

  const [dataMain, , loadDataMain, refreshDataMain] = useQuery(
    () => {
      if (!isResettingDataMain) {
        return conferenceService.getDataTableModeloConferenceBeeping(
          selectedNote.Nota,
          selectedNote.idRegistro,
        );
      } else {
        return undefined;
      }
    },
    [selectedNote.Nota, selectedNote.idRegistro, isResettingDataMain], //não remover o comentario
  );

  const handleCloseSubModal = async () => {
    setIsModalOpenConfirmation(false);
    setOpenSubErrorDialog(false);
    await handleCloseClear();
  };

  const handleOpenModalZerarColeta = () => {
    // Abre modal para Confirmar zerar Coleta
    setIsModalOpenConfirmation(true);
    setTextMessage('Tem certeza que deseja zerar a coleta?');
    SetTypeButton('zerar');
  };

  const handleOpenModalFinalizarNF = () => {
    setIsModalOpenConfirmation(true);
    setTextMessage('Tem certeza que deseja Finalizar a NF?');
    SetTypeButton('finalizar');
  };

  const handleConfirmDialog = () => {
    if (typeButton !== '' && typeButton === 'zerar') handleResetColletion();
    else handleFinishNF(false);
  };

  const handleResetColletion = async () => {
    if (!dataMain?.[0]?.idCabec) {
      toast.error('Erro ao pegar o informação atualize a tela e tente novamente');
      return;
    }
    const requestData = {
      ...dataMain?.[0],
      selectedNote,
      selectedIdRegistro: selectedNote.idRegistro,
    };
    try {
      await conferenceService.resetCollection(requestData);
      toast.success('Coleta Zerada com Sucesso');
      await handleCloseSubModal();
    } catch (error) {
      toast.error('Erro ao resetar a coleção.');
    }
  };

  const handleCloseClear = async () => {
    const btn_clear_beeping = document.querySelector('[name="btn_clear_beeping"]');
    const btn_clear_qrcode = document.querySelector('[name="btn_clear_qrcode"]');
    if (btn_clear_beeping) {
      btn_clear_beeping.click();
    }
    if (btn_clear_qrcode) {
      btn_clear_qrcode.click();
    }
  };

  //finalizar processo
  const handleFinishNF = async (finished) => {
    const info = {
      idCabec: dataMain?.[0]?.idCabec,
      finalizada: finished,
      selectedNote: selectedNote,
      usuario: user.name,
    };
    try {
      const { data } = await conferenceService.getFinishNF(info);
      if (data.status === 200) {
        if (data.idStatus === 3) {
          toast.error(data.msg);
        } else if (data.idStatus === 5) {
          toast.info(data.msg);
        } else {
          toast.success(data.msg);
        }
        await refreshDataMain();
        await handleCloseSubModal();
        setIsResettingDataMain(true);
        await handleCloseModal();
      } else {
        toast.error(data.msg);
        await handleCloseSubModal();
        await handleCloseModal();
        setIsResettingDataMain(true);
      }
    } catch (error) {
      toast.error('Erro ao finalizar NF.');
    }
  };

  const handleCloserSubModal = async () => {
    setOpenSubErrorDialog(false);
    const input_pn = document.querySelector('[name="input_pn"]');
    if (input_pn) {
      input_pn.focus();
    } else {
      console.log('eu tentei');
    }
  };

  const validationPn = async (partNumber, idCabec) => {
    try {
      const { data } = await conferenceService.getModelBeeping({
        idCabec: idCabec,
        partnumber: partNumber,
      });
      if (data.status === 404) {
        toast.error(data.msg);
        return false;
      } else {
        return data;
      }
    } catch (error) {
      toast.error('Erro ao buscar dados do modelo.');
      return false;
    }
  };

  const validationSerial = async (idCabec, ClienteOPV, serial, serial20dig) => {
    try {
      const { data } = await conferenceService.getModelSerialBeeping({
        idCabec,
        ClienteOPV,
        serial,
        serial20dig,
      });
      console.log('serivalValidacao:', data);
      if (data?.status === 404 || data?.status === 409) {
        toast.error(data?.msg);
        return false;
      } else {
        toast.success(data.response[0].Mensagem);
        return data;
      }
    } catch (error) {
      console.error(error);
      toast.error('Erro ao verificar serial.');
      return false;
    }
  };

  const handleProcess = async (payload) => {
    console.log('payload', payload);
    try {
      const { data } = await conferenceService.processSerialBeeping(payload);
      console.log('data process:', data?.status);
      if (data?.status === 200) {
        toast.success('Processamento realizado com sucesso');
        if (payload?.IdStatusReincidenciaGaia === 23) {
          setErrorDialog('DOA ' + payload?.serial);
          setOpenSubErrorDialog(true);
        }
        refreshDataMain();
        return true;
      } else {
        setOpenSubErrorDialog(true);
        setErrorDialog('Erro ao fazer o processo');
        return false;
      }
    } catch (error) {
      console.log(error);
      toast.error('Erro ao processar:' + error?.message + ' serial' + payload.serial);
      setOpenSubErrorDialog(true);
      setErrorDialog('Erro ao processar: ' + error.message);
      return false;
    }
  };

  // Quando o modal for fechado e o reset for ativado, redefina o isResettingDataMain
  useEffect(() => {
    if (isResettingDataMain) {
      // Após redefinir, reverter o estado
      setIsResettingDataMain(false);
    }
  }, [isResettingDataMain]);

  useEffect(() => {
    console.log('loop', dataMain, dataMain?.[0]?.Processados === dataMain?.[0]?.qtdNf);
    if (dataMain && isModalOpenConference) {
      if (dataMain?.[0]?.Processados === dataMain?.[0]?.qtdNf) {
        handleOpenModalFinalizarNF();
      }
    }
  }, [dataMain, isModalOpenConference]);

  useEffect(() => {
    if (document.querySelector('[name="input_pn"]')) {
      if (!form) document.querySelector('[name="input_pn"]').focus();
    } else if (document.querySelector('[name="qrcode"]')) {
      if (form) document.querySelector('[name="qrcode"]').focus();
    }
  }, [dataMain, openSubErrorDialog, form]);

  useEffect(() => {
    if (isModalOpenConference) {
      refreshDataMain();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpenConference, refreshDataMain]);

  return (
    <>
      <ErrorDialog
        openSubErrorDialog={openSubErrorDialog}
        handleCloseSubModal={handleCloserSubModal}
        errorDialog={errorDialog}
      />
      <ConfirmationDialog
        isModalOpenConfirmation={isModalOpenConfirmation}
        handleCloseSubModal={handleCloseSubModal}
        handleConfirmDialog={handleConfirmDialog}
        textMessage={textMessage}
      />
      <Dialog maxWidth={'md'} open={isModalOpenConference}>
        <Dialog.Title
          onClose={() => {
            setIsResettingDataMain(true);
            handleCloseModal();
          }}
        >
          Conferência
        </Dialog.Title>
        <Dialog.Content>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={12} lg={12}>
              <Typography variant='h5' align='center' style={{ paddingBottom: '1rem' }}>
                Informações da Nota
              </Typography>
              <Typography align='center'>NF: {selectedNote.Nota}</Typography>
              <Typography align='center'>Cliente: {selectedNote.ClienteOPV}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} justifyContent='center'>
            <Table
              name='Table2'
              headers={columns}
              maxHeight
              size='small'
              headerFontColor='#fff'
              disableNumeration
            >
              {dataMain?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell align='center'>{item.Modelo}</TableCell>
                  <TableCell align='center'>{item.qtdNf}</TableCell>
                  <TableCell align='center'>{item.Processados}</TableCell>
                </TableRow>
              ))}
            </Table>
          </Grid>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <Button fullWidth color='secondary' onClick={handleOpenModalZerarColeta}>
                zerar Coleta
              </Button>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <Button fullWidth onClick={handleOpenModalFinalizarNF}>
                finalizar NF
              </Button>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <Button id='clearButton' onClick={handleCloseClear} fullWidth>
                limpar
              </Button>
            </Grid>
          </Grid>
          <Grid container justifyContent='center'>
            <Typography variant='h6' style={{ paddingTop: '4rem' }}>
              Selecione o tipo de Bipagem:
            </Typography>
          </Grid>
          <Grid container justifyContent='center' alignItems='center'>
            <FormatAlignJustifyIcon
              style={{ fontSize: '5rem', cursor: 'pointer', marginRight: '2rem' }}
              onClick={() => setForm(false)}
            />
            <QrCode2Icon
              style={{ fontSize: '5rem', cursor: 'pointer' }}
              onClick={() => setForm(true)}
            />
          </Grid>
          <Grid container justifyContent='center'>
            {!form ? (
              <ShowBeeping
                dataMain={dataMain}
                selectedNote={selectedNote}
                validationSerial={validationSerial}
                handleProcess={handleProcess}
                validationPn={validationPn}
                user={user}
              />
            ) : (
              <ShowQrCode
                dataMain={dataMain}
                selectedNote={selectedNote}
                validationSerial={validationSerial}
                handleProcess={handleProcess}
                validationPn={validationPn}
                user={user}
              />
            )}
          </Grid>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
