import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { Button, Dialog, Table } from 'components';
import { Form, Formik } from 'formik';
import { screeningService } from 'services/screening';

const columns = [{ label: 'Selecione' }, { label: 'Acessorio' }];

export const DialogAccessoryScreening = ({
  openDialogAcessory,
  handleCloseDialogAcessory,
  info,
  handleCloseDialogCollect,
  setInfoQRCODE,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedAccessories, setSelectedAccessories] = useState([]);

  const handleTable = useCallback(async (info) => {
    setIsLoading(true);

    try {
      const { data } = await screeningService.getAccessory({
        idModelo: info.infoOsSerial[0].idModelo,
        numOs: info.os.numeroOs,
      });
      setTableData(data);
      if (data.status === 500) {
        toast.error(data.msg);
      } else {
        toast.success(data.msg);
      }
    } catch (error) {
      console.log('Erro ao carregar dados: ' + error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (openDialogAcessory) {
      console.log(info);
      handleTable(info);
    }
  }, [openDialogAcessory, handleTable, info]);

  const handleCheckboxChange = (idCadastro) => {
    setSelectedAccessories((prevSelected) =>
      prevSelected.includes(idCadastro)
        ? prevSelected.filter((id) => id !== idCadastro)
        : [...prevSelected, idCadastro],
    );
  };

  const handleInsert = async (info) => {
    if (selectedAccessories.length === 0) {
      toast.error('Por favor, selecione pelo menos um acessório.');
      return;
    }
    try {
      const { data } = await screeningService.insertAccesory({
        numOs: info.os.numeroOs,
        idAcc: selectedAccessories,
      });
    } catch (error) {
      console.error('Erro inserir:', error);
    } finally {
      handleCloseDialogAcessory();
      handleCloseDialogCollect();
    }
  };

  const handleCloseDialog = () => {
    toast.error('Triagem Cancelada');
    setInfoQRCODE(null);
    handleCloseDialogAcessory();
    handleCloseDialogCollect('', false);
  };

  return (
    <Dialog maxWidth={'md'} open={openDialogAcessory}>
      <Dialog.Title onClose={handleCloseDialog}>Selecione o Item/Acessório</Dialog.Title>
      <Dialog.Content>
        <Formik>
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12} md={12}>
                <Table name='Table' headers={columns} maxHeight disableNumeration>
                  {tableData.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.descricao}</TableCell>
                      <TableCell>
                        <Checkbox
                          checked={selectedAccessories.includes(item.idCadastro)}
                          onChange={() => handleCheckboxChange(item.idCadastro)}
                          style={{ color: '#512da8' }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Button fullWidth color='secondary' onClick={handleCloseDialog}>
                  Fechar
                </Button>
              </Grid>
              <Grid item xs={6} lg={6}>
                <Button
                  fullWidth
                  color='primary'
                  loading={isLoading}
                  onClick={() => handleInsert(info)}
                >
                  Gravar
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
