import { api } from 'services/api';

export class ServiceOrderService {
  // async createClient(data) {
  //   return api.post('/service-orders/clients', data);
  // }

  // async createService(data) {
  //   return api.post('/service-orders', data);
  // }

  // async updateService(data) {
  //   return api.put('/service-orders', data);
  // }

  // async getClientByCgc(cgc) {
  //   return api.get(`/service-orders/clients/${cgc}`);
  // }

  // async getObjectReceived() {
  //   return api.get(`/service-orders/objectsReceived`);
  // }

  // async getObjectRetail() {
  //   return api.get(`/service-orders/objectsRetail`);
  // }

  // async getCheckitDone() {
  //   return api.get(`/service-orders/checkitDone`);
  // }
  // async getClientById(id) {
  //   return api.get(`/service-orders/clientsid/${id}`);
  // }

  // async getServiceOrderByNumberGspn(number) {
  //   return api.get(`/service-orders/gspn/${number}`);
  // }

  // async getServiceOrderByNumberGspnFile(data) {
  //   return api.get(`/service-orders/gspn-file`, data);
  // }

  // async createCheckKit(data) {
  //   return api.post(`/service-orders/check-kit`, data);
  // }

  // async getServiceByNumberWhereIsNotCheckKit(number) {
  //   return api.get(`/service-orders/check-kit/service/${number}`);
  // }

  // async getServiceByNumberWhereIsNotQualityAssurance(number) {
  //   return api.get(`/service-orders/quality-assurance/service/${number}`);
  // }

  // async getServiceByNumberWhereIsNotSortingAssurance(number) {
  //   return api.get(`/service-orders/sorting-assurance/service/${number}`);
  // }

  // async getAssuranceDefects(number) {
  //   return api.get(`/service-orders/sorting-assurance-defects/service/${number}`);
  // }

  // async getManyQualityAssurance(number) {
  //   return api.get(`/service-orders/quality-assurance/many/service/${number}`);
  // }

  // async listProcesses() {
  //   return api.get('/service-orders/processes');
  // }

  // async getServiceOrderRh() {
  //   return api.get('/service-orders/list/rhservices');
  // }

  // async allServices() {
  //   return api.get('/service-orders/list/allServices');
  // }

  // async nonCompliceQuality() {
  //   return api.get('/service-orders/list/nonCompliceQuality');
  // }

  // async productionDepartment() {
  //   return api.get('/service-orders/list/productionDepartment');
  // }

  // async agingDate() {
  //   return api.get('/service-orders/list/agingDate');
  // }

  // async aging4h() {
  //   return api.get('/service-orders/list/aging4h');
  // }

  // async aging4hDetails() {
  //   return api.get('/service-orders/list/aging4hDetails');
  // }

  // async stepsMapping() {
  //   return api.get('/service-orders/list/stepsMapping');
  // }

  // async awaitingParts(data) {
  //   return api.post('/service-orders/awaitingParts', data);
  // }

  // async listAging() {
  //   return api.get('/service-orders/list-aging');
  // }

  // async listServiceStatuses() {
  //   return api.get('/service-orders/list-statuses');
  // }

  // async getSteps() {
  //   return api.get('/service-orders/steps');
  // }

  // async listForCurrentUser() {
  //   return api.get('/service-orders/me');
  // }

  // async listFinancialReport() {
  //   return api.get('/service-orders/list-financialreport');
  // }

  // async listPreBudgetReport() {
  //   return api.get('/service-orders/list-prebudgetreport');
  // }

  // async listOutOfWarranty() {
  //   return api.get('/service-orders/out-of-warranty');
  // }

  // async updateServiceStatusId(id) {
  //   return api.put('/service-orders/status/id', { id });
  // }

  // async updateServiceStatusIdQA(id, description, imei1_out, imei2_out) {
  //   return api.put('/service-orders/statusqa/id', { id, description, imei1_out, imei2_out });
  // }
  // async listServiceInQuantity() {
  //   return api.get('/service-orders/list/quantity');
  // }

  // async listQaInspector() {
  //   return api.get('/service-orders/list/listqaInspector');
  // }

  // async listRepairInspector() {
  //   return api.get('/service-orders/list/listrepairInspector');
  // }

  // async listRepairNonCompliance() {
  //   return api.get('/service-orders/list/listrepairNonCompliance');
  // }

  // async listTotalRepairNonCompliance() {
  //   return api.get('/service-orders/list/listqanoncompliancetotal');
  // }

  // async listRepairInspectorTotal() {
  //   return api.get('/service-orders/list/listrepairInspectorTotal');
  // }

  // async sortingReport() {
  //   return api.get('/service-orders/list/sortingReport');
  // }

  // async listSortingTotal() {
  //   return api.get('/service-orders/sortingTotal');
  // }

  // async listQaInspectorTotal() {
  //   return api.get('/service-orders/list/listqainspectortotal');
  // }

  // async listOrctotal() {
  //   return api.get('/service-orders/list/listOrcTotal');
  // }

  // async listQuantityByTotalPrice() {
  //   return api.get('/service-orders/list/quantity/byTotalPrice');
  // }

  // async listStockRequests(serviceId) {
  //   return api.get(`/service-orders/list-stock-requests/${serviceId}`);
  // }

  // async listServiceInQuantityBudget() {
  //   return api.get('/service-orders/list/quantity/byBudget');
  // }

  // async listWeekTransactions() {
  //   return api.get('/service-orders/budget/week/transactions');
  // }

  // async listServiceInQuantityFinished() {
  //   return api.get('/service-orders/list/quantity/finished');
  // }

  // async listOutOfWarrantyNoBudget() {
  //   return api.get('/service-orders/out-of-warranty/noBudget');
  // }

  // async listServiceOrdersStatusInRepair() {
  //   return api.get('/service-orders/status/in-repair');
  // }

  // async getByNumber(number) {
  //   return api.get(`/service-orders/${number}`);
  // }

  // async getByNumberWithNoRequisition(number) {
  //   return api.get(`/service-orders/nostockrequisition/${number}`);
  // }

  // async getNextStep(service_number) {
  //   return api.get(`/service-orders/status/next/${service_number}`);
  // }

  // async getServiceOrderByNumberWithGaiaAndGspnData(number) {
  //   return api.get(`/service-orders/gspn/service/${number}`);
  // }

  // async createServiceOrderAccessories(values) {
  //   return api.post('/service-orders/accessories', values);
  // }

  // async cancelServiceOrder(values) {
  //   return api.put('/service-orders/cancel', values);
  // }

  // async listServiceOrderAccessories() {
  //   return api.get('/service-orders/accessories');
  // }

  // async listBudgetTotalPriceToday() {
  //   return api.get('/service-orders/budget/today/totalPrice');
  // }

  // async listBudgetTotalAcessoryToday() {
  //   return api.get('/service-orders/budget/today/totalAcessory');
  // }

  // async listAprovedBudgetToday() {
  //   return api.get('/service-orders/budget/today/aproved');
  // }

  // async listColorsProduct() {
  //   return api.get('/service-orders/colors-products');
  // }

  // async listReprovedBudgetToday() {
  //   return api.get('/service-orders/budget/today/reproved');
  // }

  // async listAllBudgetToday() {
  //   return api.get('/service-orders/budget/today/all');
  // }

  // async listAprovedWithPartsServiceBudgetWeek() {
  //   return api.get('/service-orders/budget/week/approved/withPartsService');
  // }

  // async listAprovedWithPartLabourServiceBudgetWeek() {
  //   return api.get('/service-orders/budget/week/reproved/withPartsService');
  // }

  // async listBudgetAprovedOnly() {
  //   return api.get('/service-orders/list-aprovedonly');
  // }

  // async listBudgetsrReport() {
  //   return api.get('/service-orders/list-budgetreport');
  // }

  // async listAgingReport() {
  //   return api.get('/service-orders/list-agingreport');
  // }

  // async listEticket() {
  //   return api.get('/service-orders/list-eticketreport');
  // }

  // async listObjectReport() {
  //   return api.get('/service-orders/list-objectreport');
  // }

  // async updateAccessory(id, description) {
  //   return api.put(`/service-orders/accessories`, { id, description });
  // }

  // async updateProcess(id, initials, description) {
  //   return api.put(`service-orders/processes`, { id, initials, description });
  // }

  // async createServiceFile(data) {
  //   return api.post('/service-orders/file', data);
  // }

  // async updateServiceGspn(service_order_number) {
  //   return api.post('/service-orders/updateServiceGspn', service_order_number);
  // }

  // async listPNsFromGspnByNumber(number) {
  //   return api.get(`/service-orders/gspn/pns/${number}`);
  // }

  // async DownloadOpeningTag(service_order_number) {
  //   return api.get(`/service-orders/${service_order_number}/download-service-tag`, {
  //     responseType: 'arraybuffer',
  //   });
  // }

  // async downloadOpeningTags(services) {
  //   return api.post(`/service-orders/download-service-tags`, services, {
  //     responseType: 'arraybuffer',
  //   });
  // }

  // async listDeviceDefects() {
  //   return api.get('/service-orders/device-defect');
  // }

  // async createDeviceDefects(data) {
  //   return api.post('/service-orders/device-defect', data);
  // }

  // async listSubDeviceDefects() {
  //   return api.get('/service-orders/sub-device-defect');
  // }

  // async listSubDeviceDefectsByMainDefect(device_defect_id) {
  //   return api.get(`/service-orders/sub-device-defect/${device_defect_id}`);
  // }

  // async createSubDeviceDefects(data) {
  //   return api.post('/service-orders/sub-device-defect', data);
  // }

  // async listQualityAssuranceDefects() {
  //   return api.get('/service-orders/quality-assurance-defect');
  // }

  // async createQualityAssurance(data) {
  //   return api.post('/service-orders/quality-assurance', data);
  // }

  // async createSortingAssurance(data) {
  //   return api.post('/service-orders/sorting-assurance', data);
  // }

  // async updateQualityAssurance(id) {
  //   return api.put('/service-orders/quality-assurance', { id });
  // }

  // async listServiceOrdersByInfnfe(infnfe) {
  //   return api.post(`/service-orders/infnfe`, { infnfe });
  // }

  // async listServiceAdvancedConsult(data) {
  //   return api.post(`/service-orders/advanced/consult`, { data });
  // }

  // async listServiceAdvancedConsultInvoice(data) {
  //   return api.post(`/service-orders/advanced/consult-invoice`, { data });
  // }

  // async invoiceEmissionEntrance(service_order_number) {
  //   return api.post('/service-orders/emissions-invoice/entrance', { service_order_number });
  // }

  // async invoiceEmissionOutbound(service_order_number) {
  //   return api.post('/service-orders/emissions-invoice/outbound', { service_order_number });
  // }

  // async createServiceRemark(description, service_order_id) {
  //   return api.post(`/service-orders/serviceRemark`, { description, service_order_id });
  // }

  // async listServiceToAddReport(service_order_number) {
  //   return api.get(`/service-orders/list-report-service/${service_order_number}`);
  // }

  async getReportFile(service_number) {
    return api.get(`/service-orders/listReportFile/${service_number}`);
  }

  async scheduleOpenServiceOrder(values, user) {
    return api.get(`/service-orders/scheduleOpenServiceOrder`, {
      params: {
        values: values,
        user: user,
      },
    });
  }

  async getScheduleInvoices() {
    return api.get('/service-orders/getScheduleInvoices');
  }

  async deleteScheduleInvoice(invoice_number) {
    return api.get(`/service-orders/deleteScheduleInvoices/${invoice_number}`);
  }

  async openScheduleInvoice(invoice_numbers, user_id) {
    return api.post(`/service-orders/openScheduleInvoices`, { invoice_numbers, user_id: user_id });
  }

  async openScheduleInvoiceCsv(invoice_numbers, user_id) {
    return api.post(`/service-orders/openScheduleInvoicesCsv`, {
      invoice_numbers,
      user_id: user_id,
    });
  }
}

export const serviceOrderService = new ServiceOrderService();
