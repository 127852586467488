import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Select, Button, DatePicker } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providerService } from 'services/providers';

import { downloadcsv } from '../../../helpers/downloadcsv';

export const PartsConsumptionReport = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleOnSubmit = async (values) => {
    toast.info('Aguarde, relatório sendo gerado.');
    setIsLoading(true);
    try {
      const { data } = await providerService.getPartsConsumptionReport(values);
      console.log(data);
      if (data.length === 0 || null) {
        toast.error('Não há dados para gerar o relatório');
        return;
      }

      await downloadcsv(`ingenico_consumo_pecas`, data);
      toast.success('Relatório gerado com sucesso');
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const OptionsReport = [
    {
      label: 'Data de minuta',
      // eslint-disable-next-line max-len
      value: `CONVERT(VARCHAR(20),  xpc_minutaMultiItens.dtCadastro, 103) + ' ' + CONVERT(VARCHAR(20),  xpc_minutaMultiItens.dtCadastro, 108) AS dt_minuta`,
    },
    { label: 'PN Trocado', value: 'C01PROD.CODIGO AS pnTrocado' },
    { label: 'Descrição Componente Lançado', value: 'C01PROD.DESCRICAO AS descComponenteLancado' },
    { label: 'Item HF', value: 'xpc_OsMultiCmpHFItens.descrItem AS itemHF' },
    { label: 'Quantidade Peças', value: 'xpc_vincOsMultimarcasComponentes.qtd' },
    { label: 'Nivel Reparo', value: 'xpc_vincCompModeloCategoria.nivelReparo' },
    { label: 'Part Number', value: 'vw_osmultimarcas.partNumber' },
    { label: 'Nr Tec', value: 'xpc_logComponenteMultimarcas.nrTec' },
    { label: 'Nome', value: 'T06TECNICO.NOME' },
    { label: 'Nome TecLaudo', value: 'vw_osmultimarcas.nomeTec AS nomeTecLaudo' },
    { label: 'Número TecLaudo', value: 'vw_osmultimarcas.laudoTec AS numTecLaudo' },
    { label: 'Nome Cliente', value: 'vw_osmultimarcas.clienteOpv' },
    { label: 'Sub Categoria', value: 'xpc_subCategoriaMulti.descricao AS Subcategoria' },
  ];

  return (
    <>
      <PageTitle>Consumo de Peças</PageTitle>

      <Formik
        initialValues={{
          type_report: [],
          input_data: new Date(),
          output_data: new Date(),
        }}
        onSubmit={handleOnSubmit}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='type_report'
                  label='Colunas disponíveis a inserir'
                  variant='outlined'
                  multiple
                  options={OptionsReport}
                  component={Select}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='input_data'
                  variant='outlined'
                  fullWidth
                  inputVariant='outlined'
                  label='Data Inicial'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='output_data'
                  variant='outlined'
                  fullWidth
                  inputVariant='outlined'
                  label='Data Final'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Button type='submit' fullWidth disabled={isLoading}>
                  Extrair Relatório
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
