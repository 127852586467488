import React, { memo, useMemo, useCallback } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import SelectMUI from '@material-ui/core/Select';

import { getFieldError } from 'helpers/formik';

const SelectField = ({
  field,
  label,
  form,
  value,
  name,
  loading,
  onChange,
  maxLabelItems,
  errorMessage: errorMessageProp,
  fullWidth,
  options,
  emptyOption,
  helperText,
  margin = 'none',
  size = 'small',
  ...props
}) => {
  const endAdornment = useMemo(
    () =>
      !loading ? null : (
        <InputAdornment position='end'>
          <div style={{ backgroundColor: 'white', zIndex: 1, position: 'relative', height: 20 }}>
            <CircularProgress color='primary' size={20} />
          </div>
        </InputAdornment>
      ),
    [loading],
  );

  const renderValue = useCallback(
    (selected) => {
      return !Array.isArray(selected)
        ? options.find((o) => selected === o.value)?.label
        : selected.length > (maxLabelItems ?? 3)
        ? `${selected.length} selecionados`
        : options
            .filter((o) => selected.includes(o.value))
            .map((o) => o.label)
            .join(', ');
    },
    [options, maxLabelItems],
  );

  const handleChange = useCallback(
    (e) => {
      let value = e.target.value;

      if (Array.isArray(value) && value.includes('')) {
        value = [];
      }

      onChange && onChange(value, e);
      form?.setFieldValue(field.name, value);
    },
    [onChange, form, field.name],
  );

  const handleBlur = useCallback(
    (e) => {
      const v = e.target.value;

      form?.setFieldTouched(field.name, true);

      if (!v) return;

      form?.setFieldValue(field.name, v);
    },
    [form, field.name],
  );

  value = field?.value ?? value;

  const errorMessage = errorMessageProp || getFieldError(field?.name, form);
  const hasError = !!errorMessage;

  return (
    <FormControl
      margin={margin}
      fullWidth={fullWidth ?? true}
      error={!!errorMessage}
      variant='outlined'
      size={size}
    >
      {!!label && <InputLabel error={!!errorMessage}>{label}</InputLabel>}
      <SelectMUI
        color='primary'
        error={hasError}
        disabled={form?.isSubmitting || props.disabled || loading}
        name={name}
        value={value ?? (props.multiple ? [] : '')}
        onChange={handleChange}
        onBlur={handleBlur}
        fullWidth={fullWidth ?? true}
        label={label}
        endAdornment={endAdornment}
        renderValue={renderValue}
        {...props}
      >
        {emptyOption && <MenuItem value={''}>{emptyOption}</MenuItem>}
        {options?.map((option) => (
          <MenuItem disabled={option.disabled} key={option.value} value={option.value}>
            {props.multiple && <Checkbox checked={value?.includes(option.value)} />}
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </SelectMUI>
      {!!(errorMessage || helperText) && (
        <FormHelperText error={!!errorMessage} variant='outlined'>
          {errorMessage || helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export const Select = memo(SelectField);
