import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Typography from '@material-ui/core/Typography';

import { Grid } from '@mui/material';
import { Button, Dialog, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { launchPrivider } from 'services';
import { useContextSelector } from 'use-context-selector';

export const DialogUnlockBlock = ({ openDialogUnlock, handleCloseDialogUnlock, infoBlock }) => {
  if (!openDialogUnlock) return null;

  const user = useContextSelector(AuthContext, (context) => context.user);

  const [isLoading, setIsLoading] = useState(false);
  const handleUnlockBlock = async (values) => {
    setIsLoading(true);
    toast.info(`Desbloqueando id ${infoBlock.idBloqueio}`);
    try {
      const { data } = await launchPrivider.unlockBlockOs({
        values,
        idBlock: infoBlock.idBloqueio,
        user: user.name,
      });

      if (data.status === 200) {
        toast.success(data.msg);
        handleCloseDialogUnlock();
        setIsLoading(false);
      } else {
        toast.error(data.msg);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error.message);
      toast.error('Erro ao desbloquear bloqueio.');
      setIsLoading(false);
    }
  };
  return (
    <>
      <Dialog open={openDialogUnlock}>
        <Dialog.Title onClose={handleCloseDialogUnlock}>Remover Bloqueio</Dialog.Title>
        <Dialog.Content>
          <Formik initialValues={{ message: '' }} onSubmit={handleUnlockBlock}>
            {(props) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography
                      variant='h1'
                      style={{ fontSize: '25px', fontWeight: 'bold', textAlign: 'center' }}
                    >
                      Deseja remover o bloqueio do id {infoBlock.idBloqueio} ?
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Field
                      size='small'
                      name='message'
                      label='Motivo Desbloqueio'
                      component={Text}
                      type='text'
                      variant='outlined'
                      value={props.values.message}
                      multiline
                      minRows={5}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={2}></Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <Button
                      onClick={handleCloseDialogUnlock}
                      fullWidth
                      color='secondary'
                      loading={isLoading}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <Button type='submit' fullWidth loading={isLoading}>
                      Confirmar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
