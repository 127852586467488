import React from 'react';
import { NavLink } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';

import notFoundImg from 'assets/images/404.svg';

import { useStyles } from './styles';

export const NotFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant='h4'>
        Oops, parece que você se perdeu... provavelmente o nosso sistema esta em manutenção, entre
        em contato com o suporte! 15996667109
      </Typography>
      <img className={classes.image} src={notFoundImg} alt='404' />
      <NavLink className={classes.backToHome} to='/home'>
        Voltar para Home
      </NavLink>
    </div>
  );
};
