export const FormatMoney = ({ children, locale = 'pt-BR', currency = 'BRL' }) => {
  const formatMoney = (value) => {
    return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(value);
  };

  if (typeof children === 'string' || typeof children === 'number') {
    return formatMoney(Number(children));
  } else {
    console.warn('@FormatMoney: Você deve fornecer um children do tipo string ou number');
    return null;
  }
};
