import React from 'react';
import { useRouteMatch } from 'react-router';

import { CreateRole } from 'pages/Private/AccessControl/CreateRole';
import { ListProfiles } from 'pages/Private/AccessControl/ListProfiles';
import { Permissions } from 'pages/Private/AccessControl/Permissions';

import { Route } from '../Route';

export const AccessControlRoutes = () => {
  const { url } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${url}/create-role`} component={CreateRole} />
      <Route isPrivate path={`${url}/permissions`} component={Permissions} />
      <Route isPrivate path={`${url}/list-profiles`} component={ListProfiles} />
    </>
  );
};
