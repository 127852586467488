import React, { useRef, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { Dialog, Text, Select, Button } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { launchPrivider } from 'services';
import { useContextSelector } from 'use-context-selector';
import * as Yup from 'yup';

import { typeFreezing } from '../../../../helpers/getTypeFreezing';

const validationSchema = Yup.object().shape({
  input_email: Yup.string().email('Email inválido').required('Tipo de congelamento é obrigatório'),
  type_freezing: Yup.string().required('Email é obrigatório'),
  input_topic: Yup.string().required('Assunto é obrigatório'),
  responsible: Yup.string().required('Responsável é obrigatório'),
});

const DialogRequestFreezing = ({ handleCloseDialog, openDialog }) => {
  if (!DialogRequestFreezing) return null;
  const user = useContextSelector(AuthContext, (context) => context.user);

  const [typesFreezing, setTypeFreezing] = useState([{ label: '', value: '' }]);
  const [file, setFile] = useState('');
  const [base64, setBase64] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getDados = useCallback(async () => {
    const dataTypeFreezing = await typeFreezing();
    setTypeFreezing(dataTypeFreezing);
  }, []);

  useEffect(() => {
    getDados();
  }, [getDados]);

  const convertToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleNewRequest = async (values) => {
    const { data } = await launchPrivider.newRequestFreezing({
      user: user.name,
      email: values.input_email,
      tipo_congelamento: values.type_freezing,
      assunto: values.input_topic,
      responsible: values.responsible,
      message: values.message,
      file_name: file.name || '',
      file_extension: file.type || '',
      filebase64: base64 || '',
    });
    if (data.msg === 'Solicitação realizada com sucesso!') {
      toast.success('Solicitação realizada com sucesso!');
    } else {
      toast.error('Erro ao realizar solicitação!');
    }
  };

  const fileInputRef = useRef(null);

  const handleFileInputChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      toast.success(`Arquivo ${file.name} recebido com sucesso!`);
      setFile(file);

      try {
        const convertedFile = await convertToBase64(file);
        setBase64(convertedFile.replace('data:text/csv;base64,', ''));
      } catch (error) {
        toast.error('Erro ao converter arquivo para base64!');
      }
    } else {
      toast.error(`Nenhum arquivo selecionado!`);
    }
  };

  const handleChooseFileClick = async () => {
    fileInputRef.current.click();
  };

  const handleReset = () => {
    setIsLoading(true);
    handleCloseDialog();
  };

  return (
    <Dialog maxWidth={'md'} open={openDialog}>
      <Dialog.Title onClose={handleCloseDialog}>SOLICITAR CONGELAMENTO</Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{
            input_email: '',
            type_freezing: '',
            input_topic: '',
            responsible: '',
            message: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleNewRequest}
        >
          {({ isValid, dirty, ...props }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <Field
                    size='small'
                    name='input_email'
                    label='Email'
                    component={Text}
                    type='text'
                    variant='outlined'
                    value={props.values.input_email}
                    placeholder='Digite os emails separados por ponto e vírgula ";"'
                    required
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Field
                    size='small'
                    name='type_freezing'
                    label='Tipo de Congelamento'
                    component={Select}
                    options={typesFreezing}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Field
                    size='small'
                    name='input_topic'
                    label='Assunto'
                    component={Text}
                    type='text'
                    variant='outlined'
                    value={props.values.input_topic}
                  />
                </Grid>
              </Grid>
              <Box mt={1}>
                <Grid container spacing={2} direction='column'>
                  <Grid item xs={12} lg={6}>
                    <Field
                      size='small'
                      name='responsible'
                      label='Responsável'
                      component={Text}
                      type='text'
                      variant='outlined'
                      value={props.values.responsible}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <input
                      type='file'
                      accept='.csv'
                      ref={fileInputRef}
                      style={{ display: 'none' }}
                      onChange={handleFileInputChange}
                    />
                    <Button color='secondary' fullWidth onClick={handleChooseFileClick}>
                      Novo Arquivo
                    </Button>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Field
                      size='small'
                      name='message'
                      label='Mensagem'
                      component={Text}
                      type='text'
                      variant='outlined'
                      value={props.values.message}
                      multiline
                      minRows={5}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mt={1}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12} align='center'>
                    <Grid item xs={12} lg={6}>
                      <Button
                        fullWidth
                        isLoading={isLoading}
                        onClick={handleReset}
                        type='submit'
                        disabled={!isValid || !dirty}
                      >
                        Solicitar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};

export { DialogRequestFreezing };
