import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';

import { Button } from 'components';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { componentService } from 'services';
import XLSX from 'xlsx';

export const StockBufferReport = () => {
  const [componentData, setComponents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [components, , loadingPartsRequisitions, refetchPartsDataDate] = useQuery(
    () => componentService.listStockBufferReport(),
    [],
  );

  useEffect(() => {
    setComponents(components);
  }, [components]);

  const handleDownloadGeneralStockRelatory = async () => {
    setIsLoading(true);
    const now = new Date();
    const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(componentData));
    const workbook = (XLSX.WorkBook = {
      Sheets: {
        vendas: worksheet,
      },
      SheetNames: ['vendas'],
    });
    XLSX.writeFile(workbook, `RelatorioVenda${now}.xlsx`);

    refetchPartsDataDate();

    setIsLoading(false);
  };

  return (
    <>
      <PageTitle>Relatório de estoque</PageTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <Button
            fullWidth
            onClick={handleDownloadGeneralStockRelatory}
            disabled={isLoading}
            loading={isLoading}
          >
            exportar relatório
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
