import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    position: 'relative',

    width: props.fullWidth ? '100%' : 'auto',

    // height: 50,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // flexDirection: 'column',
  }),
  formLabel: {
    display: 'block',
    fontSize: '1rem',
    marginBottom: theme.spacing(1.5),
  },
  fileInput: {
    position: 'absolute',
    left: 0,
    top: 0,
    cursor: 'none',
    width: '100%',
    height: '100%',
    visibility: 'hidden',
  },
  uploadIcon: {
    fontSize: 64,
    color: 'rgba(0, 0, 0, .6)',
  },
  dragAndDropText: {
    fontSize: 16,
    color: 'rgba(0, 0, 0, .6)',
  },
  orText: {
    fontSize: 16,
    color: 'rgba(0, 0, 0, .6)',
    margin: `${theme.spacing(0.5)}px 0`,
  },
}));
