import { api } from 'services/api';

export class PcpService {
  async getTableTecs() {
    return api.get('/pcp/getTableTecs');
  }
  async getOsTec(data) {
    return api.post('/pcp/getOsTec', data);
  }
  async getInfoTec(data) {
    return api.post('/pcp/getInfoTec', data);
  }
  async assignOs(data) {
    return api.post('/pcp/assignOs', data);
  }
  async sendOsToCmp(data) {
    return api.post('/pcp/sendOsToCmp', data);
  }
  async deleteOsAssign(data) {
    return api.post('/pcp/deleteOsAssign', data);
  }
  async unsignAllOs(data) {
    return api.post('/pcp/unsignAllOs', data);
  }
}

export const pcpService = new PcpService();
