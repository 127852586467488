import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, DatePicker, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { billingService } from 'services/billing';
import XLSX from 'xlsx';

export const InvoicesReport = () => {
  const today = new Date();

  const [inputData, setInputData] = useState(new Date(new Date().setDate(today.getDate() - 15)));
  const [outputData, setOutputData] = useState(new Date());
  const [rangeData, setRangeData] = useState('1k-5k');

  const [isLoading, setIsLoading] = useState(false);

  const handleExportReport = async () => {
    // setSubmitting(true);
    setIsLoading(true);

    toast.warning('Gerando o relatório de faturamento', {
      position: toast.POSITION.TOP_RIGHT,
    });

    const formattedInputDate = inputData.toLocaleDateString().replace('/', '-').replace('/', '-');
    const formattedOutputDate = outputData.toLocaleDateString().replace('/', '-').replace('/', '-');
    const now = new Date().toLocaleDateString();

    try {
      const result = await billingService.getReportInvoicing(
        formattedInputDate,
        formattedOutputDate,
        rangeData,
      );

      const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(result.data));
      const workbook = (XLSX.WorkBook = {
        Sheets: {
          Faturamento: worksheet,
        },
        SheetNames: ['Faturamento'],
      });

      XLSX.writeFile(workbook, `RelatorioFaturamento${now}.xlsx`);

      toast.success('Relatório gerado com sucesso.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (err) {
      toast.success(
        err?.response?.data?.message || 'Erro na geração do relatório de faturamento.',
        {
          position: toast.POSITION.TOP_RIGHT,
        },
      );
    } finally {
      // setSubmitting(false)
      setIsLoading(false);
    }
  };

  const selectRangeOptions = [
    {
      value: '1k-5k',
      label: '1k-5k',
    },
    {
      value: '5k-10k',
      label: '5k-10k',
    },
    {
      value: '10k-15k',
      label: '10k-15k',
    },
    {
      value: '15k-20k',
      label: '15k-20k',
    },
    {
      value: '20k-25k',
      label: '20k-25k',
    },
    {
      value: '25k-30k',
      label: '25k-30k',
    },
    {
      value: '30k-35k',
      label: '30k-35k',
    },
    {
      value: '35k-40k',
      label: '35k-40k',
    },
    {
      value: '40k-45k',
      label: '25k-45k',
    },
    {
      value: '45k-50k',
      label: '45k-50k',
    },
  ];

  return (
    <>
      <PageTitle>Relatório Faturamento</PageTitle>
      <Formik
        initialValues={{
          input_data: inputData,
          output_data: outputData,
          range_data: rangeData,
        }}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  loading={isLoading}
                  name='input_data'
                  variant='outlined'
                  fullWidth
                  inputVariant='outlined'
                  label='Data inicial'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  loading={isLoading}
                  name='output_data'
                  variant='outlined'
                  fullWidth
                  inputVariant='outlined'
                  label='Data final'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={1}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='range_data'
                  label='Range'
                  variant='outlined'
                  options={selectRangeOptions}
                  component={Select}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Button fullWidth onClick={() => handleExportReport()} loading={isLoading}>
                  Exportar relatório
                </Button>
              </Grid>
            </Grid>

            {useEffect(() => {
              setInputData(props.values.input_data);
              setOutputData(props.values.output_data);
              setRangeData(props.values.range_data);
            }, [props])}
          </Form>
        )}
      </Formik>
    </>
  );
};
