import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  listItem: {
    borderRadius: 4,
    transition: 'background .2s',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
    padding: '10px 16px',
    marginBottom: theme.spacing(1),
  },
  listIcon: {
    color: 'rgba(255, 255, 255, .9)',
    minWidth: 'unset',
    '& > svg': {
      width: 18,
      height: 18,
    },
  },
  listItemNested: {
    paddingLeft: theme.spacing(3),
  },
  listItemActive: {
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
  },
  listItemTextRoot: {
    marginBottom: 0,
  },
  listItemText: {
    paddingLeft: 16,
    fontWeight: 500,
    fontSize: 14,
  },
}));
