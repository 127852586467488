import React from 'react';
import { useRouteMatch } from 'react-router';

import { OsAssignment } from 'pages/Private/Pcp/assingnmentOs/OsAssignment';

import { Route } from '../Route';

export const PcpRoutes = () => {
  const { url } = useRouteMatch();
  return (
    <>
      <Route isPrivate path={`${url}/os-assignment`} component={OsAssignment} />
    </>
  );
};
