import XLSX from 'xlsx';

/**
 * Função para baixar os relatórios:
 * - Deve-se passar um array de objetos
 * - Cada objeto do array representa uma sheet
 * - Esse objeto precisa estar no formato --> { sheetName: string, data: Array<object> }
 * @param {string} name nome do arquivo que vai ser baixado
 * @param {Array<object>} sheetsData dados do relatório com múltiplas sheets
 */
const downloadXLSXSheets = (name, sheetsData) => {
  const now = new Date();
  const formatDate = now.toLocaleDateString('pt-BR').replace(/\//g, '_');
  const formatTime = now
    .toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })
    .replace(/:/g, '_');

  const workbook = {
    Sheets: {},
    SheetNames: [],
  };

  sheetsData.forEach((sheet) => {
    const worksheet = XLSX.utils.json_to_sheet(sheet.data);
    workbook.Sheets[sheet.sheetName] = worksheet;
    workbook.SheetNames.push(sheet.sheetName);
  });

  XLSX.writeFile(workbook, `${name}_${formatDate}_${formatTime}.xlsx`);
};

export { downloadXLSXSheets };
