import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog } from 'components';
import { Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { screeningService } from 'services/screening';
import { useContextSelector } from 'use-context-selector';

export const DialogConfirmScreening = ({
  openDialogConfirm,
  handleCloseDialogConfirm,
  selectedAccessories,
  msgBack,
  newInfo,
  infoQRCODE,
  delay = 900,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const user = useContextSelector(AuthContext, (context) => context.user);
  const [delayedOpen, setDelayedOpen] = useState(false);

  useEffect(() => {
    let timer;
    if (openDialogConfirm) {
      timer = setTimeout(() => {
        setDelayedOpen(true);
      }, delay);
    } else {
      setDelayedOpen(false);
    }

    return () => clearTimeout(timer);
  }, [openDialogConfirm, delay]);

  const ScreeningFinishedCollect = async () => {
    setIsLoading(true);

    if (selectedAccessories.length === 0) {
      try {
        const { data } = await screeningService.semDefeito({
          qrcode: infoQRCODE,
          info: newInfo,
          usuario: user,
          idTipoProcesso: msgBack.idTipoProcesso,
        });
        toast.success('Coleta Realizada com sucesso');
        await imprimeEtiqueta();
      } catch (error) {
        toast.error(error?.response?.data?.message);
      } finally {
        setIsLoading(false);
        handleCloseDialogConfirm();
        window.location.reload();
      }
    } else {
      try {
        const { data: response } = await screeningService.comDefeito({
          qrcode: infoQRCODE,
          info: newInfo,
          user: user,
          idDefeito: selectedAccessories,
          idEtapaBack: msgBack.idEtapa,
        });
        toast.success('Coleta Realizada com sucesso');

        await imprimeEtiqueta();
      } catch (error) {
        toast.error(error?.response?.data?.message);
      } finally {
        setIsLoading(false);
        handleCloseDialogConfirm();
        window.location.reload();
      }
    }
  };

  const imprimeEtiqueta = async () => {
    const { data } = await screeningService.getEtiqueta({ serial: newInfo[0]?.serial });

    if (data) {
      const file = new Blob([data], { type: 'application/pdf' });

      const header = `
      <style>
        @page {
          size: auto; /* Define o tamanho da página como automático para usar o tamanho real */
          margin: 0; /* Remove as margens */
          orientation: portrait; /* Define a orientação da página como retrato */
        }
        body {
          margin: 0; /* Remove as margens do corpo */
        }
      </style>
    `;
      const pdfWithHeader = new Blob([header, file], { type: 'application/pdf' });

      saveAs(pdfWithHeader, 'etiqueta.pdf');

      // Abrir automaticamente a página de impressão
      const pdfUrl = URL.createObjectURL(pdfWithHeader);
      const printWindow = window.open(pdfUrl, '_blank');
      if (printWindow) {
        printWindow.onload = () => {
          printWindow.print();
        };
      } else {
        console.error('Não foi possível abrir a janela de impressão.');
      }
      toast.success('Etiqueta gerada com sucesso');
    }
  };

  return (
    <>
      <Dialog open={delayedOpen}>
        <Dialog.Title onClose={handleCloseDialogConfirm}>Confirmação</Dialog.Title>
        <Dialog.Content>
          <Typography align='center' variant='h6' style={{ paddingBottom: '2rem' }}>
            {msgBack.msg}
          </Typography>
          <Formik>
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Button fullWidth color='secondary' onClick={handleCloseDialogConfirm}>
                    Não
                  </Button>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Button
                    fullWidth
                    color='primary'
                    loading={isLoading}
                    onClick={ScreeningFinishedCollect}
                  >
                    Sim
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
