export const parseXmlToJson = (xmlString) => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlString, 'text/xml');
  const json = xmlToJson(xmlDoc);
  return json;
};

const xmlToJson = (xml) => {
  // console.log('Converting XML node:', xml); // Adicione este log

  let obj = {}; // Mudei de const para let apenas para garantir
  if (xml.nodeType === 1) {
    // Element
    if (xml.attributes.length > 0) {
      obj['@attributes'] = {};
      for (let j = 0; j < xml.attributes.length; j++) {
        const attribute = xml.attributes.item(j);
        obj['@attributes'][attribute.nodeName] = attribute.nodeValue;
      }
    }
  } else if (xml.nodeType === 3) {
    // Text
    obj = xml.nodeValue;
  }

  if (xml.hasChildNodes()) {
    for (let i = 0; i < xml.childNodes.length; i++) {
      const item = xml.childNodes.item(i);
      const nodeName = item.nodeName;

      // Adicionando logs para ver o que está acontecendo aqui
      // console.log('Processing child node:', nodeName);

      if (typeof obj[nodeName] === 'undefined') {
        obj[nodeName] = xmlToJson(item);
      } else {
        if (typeof obj[nodeName].push === 'undefined') {
          const old = obj[nodeName];
          obj[nodeName] = [];
          obj[nodeName].push(old);
        }
        obj[nodeName].push(xmlToJson(item));
      }
    }
  }
  // console.log('Converted object:', obj); // Adicione este log

  return obj;
};
