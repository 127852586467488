import React from 'react';

import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import { useStyles } from './styles';

export const Title = ({ children, onClose, ...props }) => {
  const classes = useStyles();

  return (
    <DialogTitle className={classes.root} disableTypography {...props}>
      <Typography className={classes.title} variant='h6'>
        {children}
      </Typography>
      {onClose && (
        <IconButton className={classes.closeButton} aria-label='close' onClick={onClose}>
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
  );
};
