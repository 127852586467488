import React from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import FavoriteIcon from '@mui/icons-material/Favorite';
import StarIcon from '@mui/icons-material/Star';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Rating from '@mui/material/Rating';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Text } from 'components';
import { Button } from 'components';
import { Formik, Form, Field } from 'formik';
import { accountService } from 'services';
import * as yup from 'yup';

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(1),
  },
  popOver: {
    marginTop: 10,
  },
  root: {
    padding: 10,
    height: 280,
    width: 400,
    maxWidth: 350,
  },
}));

export const AvaliationField = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [value, setValue] = React.useState(2);
  const [hover, setHover] = React.useState(-1);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const labels = {
    1: 'Péssimo',
    2: 'Fraco',
    3: 'Regular',
    4: 'Bom',
    5: 'Ótimo + ',
  };

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      const { commentary } = values;

      console.log('Commentary: ', values.commentary);
      console.log('Rating: ', value);

      await accountService.rateAvaliation(commentary, value);

      toast.success('Avaliação realizada com sucesso.');
      resetForm();
    } catch (err) {
      toast.error(err.response?.data?.message || 'Não foi possível enviar sua avaliação.');
    } finally {
      handleClose();
      setSubmitting(false);
    }
  };

  const validationSchema = yup.object().shape({
    commentary: yup.string().required(),
  });

  return (
    <div>
      <FavoriteIcon variant='outlined' onClick={handleClickOpen}>
        Open Rating
      </FavoriteIcon>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='sm'
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle id='responsive-dialog-title'>
          {'Gostaria de nos avaliar e enviar uma recomendação?'}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              commentary: '',
            }}
            onSubmit={onSubmitHandler}
            validationSchema={validationSchema}
          >
            {(props) => (
              <>
                <Form onSubmit={props.handleSubmit}>
                  <Grid container>
                    <Grid item xs={12} lg={3}>
                      <Rating
                        precision={1}
                        onChange={(event, newValue) => {
                          setValue(newValue);
                        }}
                        onChangeActive={(event, newHover) => {
                          setHover(newHover);
                        }}
                        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize='inherit' />}
                      />
                    </Grid>
                    <Grid item xs={12} lg={9}>
                      {value !== null && (
                        <Box sx={{ ml: 2 }}>{labels[hover !== 0 ? hover : value]}</Box>
                      )}
                    </Grid>
                    <Grid></Grid>
                    <Field
                      variant='outlined'
                      multiline
                      maxRows={4}
                      label='Comentário'
                      rows={4}
                      fullWidth
                      name='commentary'
                      inputProps={{ editable: false, maxLength: 140 }}
                      component={Text}
                    />
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} lg={3}>
                      <Button autoFocus onClick={handleClose}>
                        Fechar
                      </Button>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                      <Button type='submit' autoFocus>
                        Avaliar
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              </>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};
