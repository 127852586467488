export const readCsv = (e) => {
  return new Promise((resolve, reject) => {
    try {
      const file = e.target.files[0];
      if (file.name.match('.xlsx')) {
        resolve(null); // Resolve imediatamente se for um arquivo xlsx
        return;
      }

      const reader = new FileReader();

      reader.onload = async (event) => {
        const result = event.target.result;
        const data_csv = await parseCsvToObject(result);
        resolve(data_csv); // Resolve com os dados do CSV após o processamento
      };

      // Lê o arquivo CSV como texto com a codificação ISO-8859-1
      reader.readAsText(file, 'ISO-8859-1');
    } catch (err) {
      reject(err); // Rejeita se houver um erro durante o processamento
    }
  });
};

const parseCsvToObject = async (csvContent) => {
  const lines = csvContent.split('\n'); // Dividir o conteúdo CSV em linhas

  const headers = lines[0].split(';'); // Obter os cabeçalhos (primeira linha)
  const data = [];

  for (let i = 1; i < lines.length; i++) {
    const line = lines[i].split(';'); // Dividir cada linha em campos separados por ponto e vírgula
    if (line.length === headers.length) {
      const obj = {};
      for (let j = 0; j < headers.length; j++) {
        // Remover caracteres especiais e substituir espaços por underscores nos cabeçalhos
        const sanitizedHeader = removeAccents(
          headers[j].trim().replace(/ç/g, 'c').replace('.', '').replace(/\s+/g, '_'),
        );
        obj[sanitizedHeader] = line[j].trim(); // Criar um objeto com os dados do CSV
      }
      data.push(obj); // Adicionar o objeto ao array de dados
    }
  }

  return data;
};

const removeAccents = (str) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const readCsvSemCabec = (e) => {
  return new Promise((resolve, reject) => {
    try {
      const file = e.target.files[0];
      if (file.name.match('.xlsx')) {
        resolve(null); // Resolve imediatamente se for um arquivo xlsx
        return;
      }

      const reader = new FileReader();

      reader.onload = async (event) => {
        const result = event.target.result;
        const data_csv = await parseCsvToArraySemCabec(result);
        resolve(data_csv); // Resolve com os dados do CSV após o processamento
      };

      reader.readAsText(file);
    } catch (err) {
      reject(err); // Rejeita se houver um erro durante o processamento
    }
  });
};

const parseCsvToArraySemCabec = async (csvContent) => {
  const lines = csvContent.split('\n'); // Dividir o conteúdo CSV em linhas
  const data = [];

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i].split(';'); // Dividir cada linha em campos separados por ponto e vírgula
    if (line.length > 0 && line[0].trim() !== '') {
      data.push(line[0].trim()); // Adicionar o valor ao array de dados
    }
  }

  return data;
};
