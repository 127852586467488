import React from 'react';
import { useRouteMatch } from 'react-router';

import { SalesReport } from 'pages/Reports/SalesReport';

import { Route } from '../Route';

export const CollaboratorsRoutes = () => {
  const { url } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${url}/sales-report`} component={SalesReport} />
    </>
  );
};
