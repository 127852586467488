import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  menuIcon: {
    marginRight: theme.spacing(2),
  },
  textTitle: {
    marginLeft: 5,
  },
  space: {
    marginTop: 48,
  },
}));
