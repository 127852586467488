import React from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Dialog, Button } from 'components';
import { AuthContext } from 'hooks/useAuth';
import { stockService } from 'services';
import { useContextSelector } from 'use-context-selector';

export const DialogUnlockAllocation = ({
  openDialogUnlockAllocation,
  handleCloseDialogUnlockAllocation,
  idCadastro,
}) => {
  if (!openDialogUnlockAllocation) {
    return null;
  }
  const user = useContextSelector(AuthContext, (context) => context.user);

  const handleUnlockAllocation = async () => {
    const { data } = await stockService.unlockAllocation({
      idCadastro: idCadastro.idCadastro,
      user: user.id,
    });

    if (data.status === 500) {
      toast.error(data.msg);
    } else {
      toast.success(data.msg);
      handleCloseDialogUnlockAllocation();
    }
  };
  return (
    <>
      <Dialog open={openDialogUnlockAllocation}>
        <Dialog.Title onClose={handleCloseDialogUnlockAllocation}>
          Desbloqueio de alocação
        </Dialog.Title>
        <Dialog.Content>
          <Typography variant='h5' align='center' style={{ fontWeight: 'bold' }}>
            Realmente deseja desbloquear a Alocação {idCadastro.alocacao ?? idCadastro.alocacao}?
          </Typography>
        </Dialog.Content>
        <Dialog.Actions>
          <Grid container spacing={2}>
            <Grid item xs={3}></Grid>
            <Grid item xs={12} lg={3}>
              <Button fullWidth color='secondary' onClick={handleCloseDialogUnlockAllocation}>
                Fechar
              </Button>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Button fullWidth onClick={() => handleUnlockAllocation()}>
                Desbloquear
              </Button>
            </Grid>
          </Grid>
        </Dialog.Actions>
      </Dialog>
    </>
  );
};
