import React from 'react';

import Grid from '@material-ui/core/Grid';

import { Select } from 'components';
import { Field, useFormikContext } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { quoteService } from 'services';

const columns = [
  { label: 'Defeito alegado pelo consumidor' },
  { label: 'Peças normalmente utilizadas para o reparo' },
  { label: 'Valor Aproximado de Cobrança' },
];

export const SmartphoneModel = () => {
  const { values, setFieldValue } = useFormikContext();

  const [smartphoneBrands, , loadingSmartphoneBrands] = useQuery(
    () => quoteService.listSmartphoneBrands(),
    [],
  );

  const [smartphoneModels, , loadingSmartphoneModels] = useQuery(
    () => quoteService.listSmartphoneModelsByBrand(values?.smartphone_brand_id),
    [values?.smartphone_brand_id],
  );

  const [smartphoneUseTime, , loadingSmartphoneUseTime] = useQuery(
    () => quoteService.listSmartphoneUseTime(),
    [],
  );

  const smartphoneBrandsOptions = (smartphoneBrands || []).map((att) => {
    return {
      value: att.id,
      label: att.description,
    };
  });

  const smartphoneModelsOptions = (smartphoneModels || []).map((att) => {
    return {
      value: att.id,
      label: att.description,
    };
  });

  const smartphoneUseTimeOptions = (smartphoneUseTime || []).map((att) => {
    return {
      value: att.id,
      label: att.description,
    };
  });

  const handleSearchChange = () => {
    setFieldValue('smartphone_model_id', null);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4} md={4} lg={4}>
          <Field
            label='Marca'
            name='smartphone_brand_id'
            size='small'
            variant='outlined'
            component={Select}
            options={smartphoneBrandsOptions}
            onChange={handleSearchChange}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <Field
            label='Modelo'
            name='smartphone_model_id'
            size='small'
            variant='outlined'
            component={Select}
            options={smartphoneModelsOptions}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <Field
            label='Tempo de uso do aparelho'
            name='smartphone_use_time_id'
            size='small'
            variant='outlined'
            component={Select}
            options={smartphoneUseTimeOptions}
          />
        </Grid>
      </Grid>
    </>
  );
};
