import React from 'react';

import MenuMUI from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import AccountIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { AuthContext } from 'hooks/useAuth';
import { browserHistory } from 'routes/browserHistory';
import { useContextSelector } from 'use-context-selector';

import { useLayoutContext } from '../../context';
import { PopOverInformations } from './PopOverInformations';
import { PopOverNotifications } from './PopOverNotifications';
import { useStyles } from './styles';

const Menu = () => {
  const classes = useStyles();

  const signOut = useContextSelector(AuthContext, (context) => context.signOut);

  const { anchorEl, mobileMoreAnchorEl, setAnchorEl, setMobileMoreAnchorEl, menuId, mobileMenuId } =
    useLayoutContext();

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const sendToProfile = () => {
    browserHistory.push('/profile');
    handleMenuClose();
  };

  const handleMobileMenuClose = () => setMobileMoreAnchorEl(null);

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  return (
    <>
      <MenuMUI
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
        className={classes.space}
      >
        <MenuItem onClick={sendToProfile}>
          <AccountIcon className={classes.menuIcon} />
          <p>Perfil</p>
        </MenuItem>
        <MenuItem onClick={signOut}>
          <ExitToAppIcon className={classes.menuIcon} />
          <p>Logout</p>
        </MenuItem>
      </MenuMUI>
      <MenuMUI
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <MenuItem>
          <PopOverNotifications className={classes.menuIcon} />
          <Typography className={classes.textTitle}>Notificações</Typography>
        </MenuItem>
        <MenuItem>
          <PopOverInformations className={classes.menuIcon} />
          <Typography className={classes.textTitle}>Informações</Typography>
        </MenuItem>

        <MenuItem onClick={sendToProfile}>
          <AccountIcon className={classes.menuIcon} />
          <p>Perfil</p>
        </MenuItem>
        <MenuItem onClick={signOut}>
          <ExitToAppIcon className={classes.menuIcon} />
          <p>Logout</p>
        </MenuItem>
      </MenuMUI>
    </>
  );
};

export default Menu;
