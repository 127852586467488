import React from 'react';

import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';

import HelpIcon from '@mui/icons-material/Help';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';

// import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(1),
  },
  popOver: {
    marginTop: 10,
  },
  root: {
    padding: 10,
    height: 260,
    width: 320,
    maxWidth: 350,
  },
}));

export const PopOverInformations = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <HelpIcon aria-describedby={id} variant='contained' color='inherit' onClick={handleClick}>
        Open Popover
      </HelpIcon>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className={classes.popOver}
      >
        <div className={classes.root}>
          <List
            sx={{
              width: '100%',
              maxWidth: 360,
            }}
          >
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <VpnKeyIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary='Token' secondary='Env Token - 25418745' />
            </ListItem>
            <Divider variant='inset' component='li' />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <QrCode2Icon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary='Ascode' secondary='Env Ascode - 25418745' />
            </ListItem>
            <Divider variant='inset' component='li' />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <LockOpenIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary='Company Code' secondary='Env Company Code - 25418745' />
            </ListItem>
            {/* <Divider variant='inset' component='li' /> */}
          </List>
        </div>
      </Popover>
    </div>
  );
};
