import XLSX from 'xlsx';

/**
 * Função para baixar os relatórios:
 * - Deve-se passar um array de objetos
 * - Cada objeto do array é uma sheet
 * - Esse objeto precisa estar no formato --> { [string]: string }
 * @param {string} name nome do arquivo que vai ser baixado
 * @param {Array<object>} data dados do relatório
 */
const downloadXlsx = (name, data) => {
  const now = new Date();
  const formatDate = now.toLocaleDateString('pt-BR').replace(/\//g, '_');
  const formatTime = now
    .toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })
    .replace(/:/g, '_');

  const worksheet = XLSX.utils.json_to_sheet(data);

  const workbook = {
    Sheets: {
      Geral: worksheet,
    },
    SheetNames: ['Geral'],
  };

  XLSX.writeFile(workbook, `${name}_${formatDate}_${formatTime}.xlsx`);
};

export { downloadXlsx };
