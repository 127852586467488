import React from 'react';

import { Tabs } from 'components';

import { TableTransactionsStatus } from './TableTransactionsStatus';

export const TableTabs = () => {
  return (
    <Tabs>
      <Tabs.Content label='Cotações para celulares' disablePadding>
        <TableTransactionsStatus />
      </Tabs.Content>
    </Tabs>
  );
};
