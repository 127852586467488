import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { Grid } from '@mui/material';
import { Button, DatePicker } from 'components';
import { Field, Form, Formik } from 'formik';
import { downloadcsv } from 'helpers/downloadcsv';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providerService } from 'services';

export const ExpressRepair = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await providerService.getExpressRepair({
        input_data: values.input_data,
        output_data: values.output_data,
      });
      if (data.length > 0) {
        downloadcsv('ingenico_reparo_expresso', data);
        toast.success('Relatório gerado com sucesso');
      } else {
        toast.error('Não há dados para esse relatório.');
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <PageTitle>Relatório Reparo Expresso</PageTitle>

      <Formik
        initialValues={{
          input_data: new Date(),
          output_data: new Date(),
        }}
        onSubmit={handleSubmit}
      >
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={2}>
              <Field
                size='small'
                disabled={isLoading}
                name='input_data'
                variant='inline'
                fullWidth
                inputVariant='outlined'
                label='Data inicial'
                component={DatePicker}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <Field
                size='small'
                disabled={isLoading}
                name='output_data'
                variant='inline'
                fullWidth
                inputVariant='outlined'
                label='Data final'
                component={DatePicker}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <Button fullWidth type='submit' loading={isLoading}>
                Exportar
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </>
  );
};
