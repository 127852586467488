import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: 700,
  },
  gridUpdateBtn: {
    marginLeft: 'auto',
  },
}));
