import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Button, Dialog } from 'components';
import { Form, Formik } from 'formik';
import { downloadcsv } from 'helpers/downloadcsv';
import { readCsv } from 'helpers/readCSV';

import { conferenceService } from '../../../../../services/conference';

export const SignalingDialog = ({ isModalOpenSignaling, handleCloseModal, selectedNote, user }) => {
  const [isLoadCSV, setIsLoadCSV] = useState([]);
  const [isLoading, SetIsLoading] = useState(false);
  const fileInputRef = useRef(null);
  const handleUploadFileClick = () => {
    // Abre pasta para o upload de arquivo
    SetIsLoading(false);
    fileInputRef.current.value = '';
    fileInputRef.current.click();
  };

  const handleFileInputChange = async (event) => {
    try {
      console.log(event);
      const data_csv = await readCsv(event);
      setIsLoadCSV(data_csv);
      toast.success('Arquivo lido com sucesso!');
    } catch (error) {
      toast.error('Erro ao ler o arquivo: ' + error.message);
    }
  };

  const handleGravar = async () => {
    toast.info('Aguarde a inicialização da conferência');
    SetIsLoading(true);

    // Verifica se um arquivo foi selecionado
    if (
      !fileInputRef.current ||
      !fileInputRef.current.files ||
      !fileInputRef.current.files.length
    ) {
      // Se nenhum arquivo foi selecionado, exibe uma mensagem de erro
      toast.error('Por favor, selecione um arquivo antes de gravar.');
      return;
    }
    // Cria um objeto temporário com as notas sinalizadas e define o atributo `nota` como verdadeiro
    const temp = { nota: true };
    console.log(isLoadCSV);
    try {
      // Chama o serviço para processar os dados CSV
      const { data } = await conferenceService.getDataCSV({
        dataCSV: isLoadCSV,
        nota_info: temp,
        selectedNote: selectedNote,
        user: user.name,
      });
      console.log('reposta:', data);
      // Verifica o status da resposta do serviço
      if (data.status === 200) {
        // Se a sinalização for bem-sucedida, exibe uma mensagem de sucesso
        toast.success('Sinalização feita com sucesso');
      } else {
        // Se houver erro ou falha na sinalização, exibe mensagens de erro e inicia o download dos arquivos de erro
        toast.error('Erro/Falha ao fazer sinalização');
        toast.info('gerando relatório de erros.');
        downloadcsv('FalhaSerializacao', data.resultado);
        // if (data.resultado.length > 0) await downloadcsv('ErrosSerializacao', data.erros);
      }
      // Atualiza os dados da tabela após a sinalização e fecha o modal de sinalização
      SetIsLoading(false);
      handleGetDataTable();
      handleCloseModalSignaling();
    } catch (error) {
      // Se ocorrer um erro durante o processo de sinalização, exibe uma mensagem de erro
      toast.error('Problemas ao fazer sinalização');
      SetIsLoading(false);
    } finally {
      SetIsLoading(false);
    }
  };

  return (
    <Dialog maxWidth={'md'} open={isModalOpenSignaling}>
      <Dialog.Title onClose={() => handleCloseModal()}>Sinalização</Dialog.Title>
      <Dialog.Content>
        <Formik>
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <Typography variant='h5' align='center' style={{ paddingBottom: '1rem' }}>
                  Upload da Sinalização
                </Typography>
                <Typography align='center'>NF: {selectedNote?.Nota}</Typography>
                <Typography align='center'>Cliente: {selectedNote?.ClienteOPV}</Typography>
                <Grid container item xs={12} justifyContent='center' alignItems='center'>
                  <UploadFileIcon
                    style={{
                      fontSize: '5rem',
                      cursor: 'pointer',
                      paddingTop: '1rem',
                    }}
                    onClick={handleUploadFileClick}
                  />
                  <input
                    type='file'
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileInputChange}
                  />
                </Grid>
                <Grid
                  container
                  spacing={2}
                  style={{ justifyContent: 'center', paddingTop: '2rem' }}
                >
                  <Grid item xs={12} lg={4} md={4} sm={4}>
                    <Button fullWidth onClick={handleGravar} disabled={isLoading}>
                      Gravar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
