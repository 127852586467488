import React from 'react';
import { toast } from 'react-toastify';

import { Button, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { browserHistory } from 'routes/browserHistory';
import { api } from 'services/api';
import * as Yup from 'yup';

import { useStyles } from './styles';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Formato de email inválido').required('Por favor, informe o e-mail'),
});

export const PasswordRecoverForm = () => {
  const classes = useStyles();

  const onSubmitHandler = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);

      const { email } = values;

      await api.post('/password/forgot', { email });

      toast.success('E-mail enviado com sucesso!');

      setTimeout(() => {
        browserHistory.push('/');
      }, 100);
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Erro ao enviar e-mail');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={onSubmitHandler}
      validationSchema={validationSchema}
    >
      <Form>
        <Field
          name='email'
          variant='outlined'
          size='small'
          label='Email'
          type='text'
          fullWidth
          className={classes.input}
          component={Text}
        />
        <Button type='submit' size='large' fullWidth>
          Enviar e-mail
        </Button>
      </Form>
    </Formik>
  );
};
