import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { Grid } from '@mui/material';
import { Button, DatePicker } from 'components';
import { Field, Form, Formik } from 'formik';
import { downloadXlsx } from 'helpers/downloadXLSX';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providerService } from 'services/providers';

export const validateInfoRecuse = () => {
  const [isLoading, setIsLoading] = useState(false);
  const handleExport = async (values) => {
    toast.info('Relatório sendo gerado...');
    setIsLoading(true);
    try {
      const { data } = await providerService.validateInfoRecuseReport({ values });

      if (data.status === 200) {
        toast.success(data.msg);
        downloadXlsx('ingenico_valida_info_recusa_rejeicao', data.result);
        setIsLoading(false);
      } else {
        toast.error(data.msg);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error.message);
      toast.error('Erro ao gerar relatório');
      setIsLoading(false);
    }
  };
  return (
    <>
      <PageTitle>Valida Info Recusa/Rejeição</PageTitle>

      <Formik
        initialValues={{ input_data: new Date(), output_data: new Date() }}
        onSubmit={handleExport}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <Field
                  size='small'
                  name='input_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data de entrada'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <Field
                  size='small'
                  name='output_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data de saída'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <Button type='submit' fullWidth loading={isLoading}>
                  Extrair Relatório
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
