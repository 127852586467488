import { React, useCallback, useState, useRef } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Button, Text, Select } from 'components';
import { Formik, Field, Form } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { screeningService } from 'services/screening';

import { LabelReading } from './Option/LabelReading';
import RequestIng from './Option/RequestIng';
import { TerminalReading } from './Option/TerminalReading';
import { TrackIdReading } from './Option/TrackIdReading';

export const DataRecovery = () => {
  const [showOptions, setShowOptions] = useState(false);
  const [optionSelect, setOptionSelect] = useState(0);
  const [data, setData] = useState(null);

  const orderServRef = useRef(null);

  const focusInput = () => {
    setTimeout(() => {
      if (orderServRef.current) {
        orderServRef.current.focus();
      }
    });
  };

  const options = [
    { value: '1', label: 'Leitura TERMINAL' },
    { value: '2', label: 'Leitura TRACK ID' },
    { value: '3', label: 'Leitura ETIQUETA' },
    { value: '4', label: 'Solicitação Ingenico' },
  ];

  const teste1 = async () => {
    console.log('teste');
  };

  const handleShowOption = async (values, { resetForm }) => {
    try {
      console.log('Oi');
      const response = await screeningService.getInfDataRecovery(values);
      console.log('response1:', response);
      setData(response.data); // Atualiza o estado com os dados recebidos da API
      setShowOptions(true);
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Erro ao consultar OS');
      resetForm();
      focusInput();
    }
    setShowOptions(true);
  };

  const handleChangeOptions = async (value) => {
    setOptionSelect(value);
  };

  const getStepContent = useCallback((activeStep) => {
    const steps = {
      0: <></>,
      1: <LabelReading orderServ={orderServRef.current?.value} />,
      2: <TrackIdReading />,
      3: <TerminalReading />,
      4: <RequestIng orderServ={orderServRef.current?.value} />,
    };
    return steps[activeStep];
  }, []);
  //12010078
  return (
    <>
      <PageTitle> Recuperação de Dados </PageTitle>
      <Formik initialValues={{ orderServ: '' }} onSubmit={handleShowOption}>
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={2}>
                <Field
                  size='small'
                  name='orderServ'
                  label='Ordem de Serviço'
                  variant='outlined'
                  component={Text}
                  inputRef={orderServRef}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={2}>
                <Button type='submit' fullWidth disabled={!props.dirty || !props.isValid}>
                  Pesquisar
                </Button>
              </Grid>
            </Grid>
            {showOptions && data ? (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    marginTop: 2,
                    marginBottom: 2,
                    '& > :not(style)': {
                      m: 1,
                      width: '100%',
                      height: 'auto',
                    },
                  }}
                >
                  <Paper elevation={2} style={{ padding: '20px', width: '100%' }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6} lg={4}>
                        <Typography variant='body2'>
                          <strong>PN Out:</strong> {data?.[0]?.partnumber}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <Typography variant='body2'>
                          <strong>Serial Out:</strong> {data?.[0]?.serial}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <Typography variant='body2'>
                          <strong>IMEI OUT:</strong> {data?.[0]?.imei}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: '0.5rem' }}>
                      <Grid item xs={12} md={6} lg={4}>
                        <Typography variant='body2'>
                          <strong>BT OUT:</strong> {data?.[0]?.btooth}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <Typography variant='body2'>
                          <strong>WF OUT:</strong> {data?.[0]?.owmac}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <Typography variant='body2'>
                          <strong>ETH OUT:</strong> {data?.[0]?.mac}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Box>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={2}>
                    <Field
                      size='small'
                      name='optionsSelect'
                      variant='outlined'
                      component={Select}
                      options={options}
                      onChange={(e) => handleChangeOptions(e)}
                      style={{ marginBottom: '25px' }}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <></>
            )}
            {getStepContent(optionSelect)}
          </Form>
        )}
      </Formik>
    </>
  );
};
