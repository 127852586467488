import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
      backgroundColor: '#1d1b31',
    },
  },
}));
