import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Typography from '@mui/material/Typography';
import { Dialog, Text, Button, Select, Table } from 'components';
import { Field, Form, Formik } from 'formik';
import { formatDate } from 'helpers/formatData';
import { AuthContext } from 'hooks/useAuth';
import { launchPrivider } from 'services';
import { useContextSelector } from 'use-context-selector';

import { downloadcsvbase64 } from '../../../../helpers/downloandCsvBase64';
import { statusFreezing } from '../../../../helpers/getStatusFreezing';

const columns = [
  { label: 'Data Cadastro' },
  { label: 'Status' },
  { label: 'Usuários' },
  { label: 'Mensagem' },
];

export const DialogUpdateRequest = ({
  idCadastroUpdateRequest,
  handleCloseDialogUpdateRequest,
  openDialogUpdateRequest,
}) => {
  if (!openDialogUpdateRequest) return null;
  const user = useContextSelector(AuthContext, (context) => context.user);

  const [typeFreezing, setTypeFreezing] = useState('');
  const [assunto, setAssunto] = useState('');
  const [responsible, setResponsible] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');
  const [typeStatus, setStatusFreezing] = useState([{ label: '', value: '' }]);
  const [dataTableAnwsers, setDataTableAnwsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const now = new Date();

  const getDados = useCallback(async (idCadastroUpdateRequest) => {
    const id_call = idCadastroUpdateRequest;
    const { data } = await launchPrivider.getDataTable({
      id_call: id_call,
      responsible: '',
      type_freezing: '',
      status: '',
    });
    const dataTypeFreezing = data[0].tipo_congelamento;
    const dataAssunto = data[0].assunto;
    const dataResponsible = data[0].ResponsavelIngenico;
    const dataMessage = data[0].mensagem;
    const dataStatus = data[0].idStatus;

    const dataStatusFreezing = await statusFreezing();

    const dataTable = await launchPrivider.getDataTableUpdateRequest({
      id_call: id_call,
    });
    setDataTableAnwsers(dataTable.data);

    setTypeFreezing(dataTypeFreezing);
    setAssunto(dataAssunto);
    setResponsible(dataResponsible);
    setMessage(dataMessage);
    setStatus(dataStatus);

    setStatusFreezing(dataStatusFreezing);
  }, []);

  useEffect(() => {
    getDados(idCadastroUpdateRequest);
  }, [idCadastroUpdateRequest, getDados]);

  const handleUptadeRequestStatus = async (values) => {
    const { data } = await launchPrivider.UptadeRequestStatus({
      user: user.name,
      id_call: idCadastroUpdateRequest,
      type_freezing: values.type_freezing,
      status: values.status,
      message: values.message,
    });
    if (data == 'Atualização realizada com sucesso') {
      toast.success('Atualização realizada com sucesso');
    } else {
      toast.error('Erro ao atualizar o status do chamado');
    }
    handleCloseDialogUpdateRequest();
    window.location.reload();
  };

  const handleGetBase64 = async () => {
    const id_call = idCadastroUpdateRequest;
    const { data } = await launchPrivider.getBase64({ id_call: id_call });

    var base64String = data[0].anexo;

    // Decode Base64 string to binary string
    const binaryString = window.atob(base64String);

    // Convert binary string to Uint8Array
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    // Create a Blob from the Uint8Array
    const blob = new Blob([bytes], { type: 'text/csv;charset=utf-8;' });

    setIsLoading(true);
    toast.info('Aguardando relatório');
    try {
      const date = formatDate(now);

      // Download the CSV file
      downloadcsvbase64(`Relatorio_de_Aprovação_Congelamento${date}`, blob);
      toast.success('Relatório Gerado com sucesso');
    } catch (error) {
      console.error(error);
      toast.error('Falha ao gerar relatório');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Dialog maxWidth={'md'} open={openDialogUpdateRequest}>
        <Dialog.Title onClose={handleCloseDialogUpdateRequest}>Atualizar Solicitação</Dialog.Title>
        <Dialog.Content>
          <Formik
            initialValues={{
              type_freezing: typeFreezing,
              input_topic: assunto,
              responsible: responsible,
              message: message,
              status: status,
            }}
            onSubmit={handleUptadeRequestStatus}
            enableReinitialize
          >
            {(props) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <Field
                      size='small'
                      name='type_freezing'
                      label='Tipo de Congelamento'
                      component={Text}
                      variant='outlined'
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Field
                      size='small'
                      name='input_topic'
                      label='Assunto'
                      component={Text}
                      type='text'
                      variant='outlined'
                      value={props.values.input_topic}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Box mt={1}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                      <Field
                        size='small'
                        name='responsible'
                        label='Responsável'
                        component={Text}
                        type='text'
                        variant='outlined'
                        value={props.values.responsible}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Field
                        size='small'
                        name='status'
                        label='Status'
                        variant='outlined'
                        options={typeStatus}
                        component={Select}
                        disabled={props.values.type_freezing === 'Pendência Ingenico'}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Button
                        isLoading={isLoading}
                        color='secondary'
                        fullWidth
                        onClick={handleGetBase64}
                      >
                        Baixar Anexo
                      </Button>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <Field
                        size='small'
                        name='message'
                        label='Mensagem'
                        component={Text}
                        type='text'
                        variant='outlined'
                        value={props.values.message}
                        multiline
                        minRows={5}
                      />
                    </Grid>
                  </Grid>
                  <Box mt={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={12} align='center'>
                        <Typography variant='h4'>Respostas</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Table name='Table' headers={columns} maxHeight disableNumeration>
                          {dataTableAnwsers &&
                            dataTableAnwsers.map((item, index) => (
                              <TableRow key={index}>
                                <TableCell>{item.dt_cadastro}</TableCell>
                                <TableCell>{item.descricao}</TableCell>
                                <TableCell>{item.usuario}</TableCell>
                                <TableCell>{item.mensagem}</TableCell>
                              </TableRow>
                            ))}
                        </Table>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box mt={1}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={12} align='center'>
                      <Grid item xs={12} lg={6}>
                        <Button type='submit' fullWidth>
                          Atualizar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
