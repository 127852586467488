import React from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Dialog, Text, Button, Select, Radio } from 'components';
import { Formik, Field, Form } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { accountService } from 'services';

import { validationSchema } from './validation';

export const ListProfilesDialog = ({ open, handleClose, profile = {}, reloadProfiles }) => {
  if (!open) return null;

  console.log('Usuário profile: ', profile);

  const [roles, , loadingRoles] = useQuery(() => accountService.listUserRoles(), []);

  const filteredRoleData = (roles || []).filter((item) => item.description.toUpperCase() !== 'TI');

  const rolesOptions = (filteredRoleData || []).map((role) => ({
    value: role.id,
    label: role.description,
  }));

  const onSubmitHandler = async (values, setFieldValue, resetForm) => {
    console.log(values);
    try {
      await accountService.updateUser(values);
      toast.success('Usuário atualizado com sucesso.');
      resetForm();
      reloadProfiles();
      handleClose();
    } catch (err) {
      console.error(err);
      toast.error(err.response?.data?.message);
    }
  };

  return (
    <Dialog
      maxWidth='xs'
      scroll='paper'
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <Dialog.Title onClose={handleClose}>Usuário: {profile?.name}</Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{
            id: profile?.id || '',
            fullname: profile?.name || '',
            email: profile?.email || '',
            phone: '',
            role_id: profile?.role_id || '',
            active: profile?.active || false,
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmitHandler}
        >
          {({ setFieldValue, resetForm, values }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <Field
                    label='Nome Completo'
                    name='fullname'
                    variant='outlined'
                    size='small'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Field
                    label='Email'
                    name='email'
                    variant='outlined'
                    size='small'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Field
                    size='small'
                    fullWidth
                    name='role_id'
                    variant='outlined'
                    label='Grupo'
                    options={rolesOptions}
                    component={Select}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Typography variant='h6' style={{ fontSize: '16px' }}>
                    Ativar / Desativar Usuário
                  </Typography>
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    color='primary'
                    name='active'
                    type='radio'
                    value='true'
                    fullWidth
                    component={Radio}
                    label='Ativo'
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    color='primary'
                    name='active'
                    type='radio'
                    value='false'
                    fullWidth
                    component={Radio}
                    label='Inativo'
                  />
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button onClick={handleClose} color='primary'>
                    Fechar
                  </Button>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => onSubmitHandler(values, setFieldValue, resetForm)}
                    style={{ marginLeft: '8px' }}
                  >
                    Atualizar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
